import { IColumn } from '@fluentui/react';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';
import { UnexpectedValueCheckCondition } from 'features/dataQuality/models/rule';
import RuleExecutionResult, {
  RuleResultType,
  UnexpectedValueCheckResult,
} from 'features/dataQuality/models/ruleExecutionResult';
import { getFailedExpectations } from 'features/dataQuality/utils/dataQualityUtils';
import {
  ResultsTableDescription,
  UserExpectationResultsBuilder,
  UserExpectationResultsDescription,
} from '../../base/userExpectationResultsBuilder';

export class UnexpectedValueCheckResultsBuilder extends UserExpectationResultsBuilder {
  public static getResultDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    const ruleResult =
      ruleExecutionResult.ruleResult as UnexpectedValueCheckResult;
    const unexpectedValueCheckCondition = ruleExecutionResult.rule.details
      .ruleCondition as UnexpectedValueCheckCondition;
    const col = ruleResult.columns[0];
    const unexpectedValues = col.unexpectedValues;
    const expectedValues =
      unexpectedValueCheckCondition.unexpectedValueChecks?.[0]?.values?.join(
        ', '
      ) ?? 'error retrieving values';
    const isPercentage =
      UserExpectationResultsBuilder.isPercentThresholds(ruleExecutionResult);
    const unit = isPercentage ? StandardUnits.PERCENT : StandardUnits.INTEGER;

    let retVal: UserExpectationResultsDescription = {
      expectationDescription: `Column "${col.name}" values should be one of these values: ${expectedValues}`,
      thresholdValueDescriptions: [],
    };

    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const { severity } =
        UnexpectedValueCheckResultsBuilder.decodeRangeParameters(
          failedExpectation,
          unexpectedValues.count,
          unexpectedValues.percentage
        );
      const severityString = severity
        ? `failed with Sev ${severity}`
        : 'failed';

      if (isPercentage) {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${unexpectedValues.percentage}% of the column values for column '${col.name}' are not one of these expected values: ${expectedValues}`;
        retVal.failureValue = unexpectedValues.percentage;
        retVal.failureValueDescription = `${unexpectedValues.percentage.toFixed(
          UnexpectedValueCheckResultsBuilder.percentDecimals
        )} percent of column values are not one of these expected values: ${expectedValues}.`;
      } else {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${unexpectedValues.count} values for the column '${col.name}' are not one of these expected values: ${expectedValues}`;
        retVal.failureValue = unexpectedValues.count;
        retVal.failureValueDescription = `${unexpectedValues.count} column values are not one of these expected values: ${expectedValues}.`;
      }

      retVal.thresholdValueDescriptions = [
        UserExpectationResultsBuilder.describeStaticRangeCondition(
          failedExpectation,
          unit,
          unexpectedValues.count,
          unexpectedValues.percentage
        ),
      ];
    } else {
      retVal.outcomeSummary = `Evaluation passed because ${
        unexpectedValues.count
      } out of ${
        unexpectedValues.totalCount
      } (${unexpectedValues.percentage.toFixed(
        UnexpectedValueCheckResultsBuilder.percentDecimals
      )}%) of the column values for column '${
        col.name
      }' are not one of these expected values: ${expectedValues}.`;
      retVal.thresholdValueDescriptions.push(
        ...UserExpectationResultsBuilder.describeNearbySuccededThresholds(
          ruleExecutionResult,
          unit,
          unexpectedValues.count,
          unexpectedValues.percentage
        )
      );
    }

    return retVal;
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [
        {
          key: 'ExecutionDate',
          name: 'Execution Date',
          fieldName: 'date',
          minWidth: 50,
          maxWidth: 100,
          isMultiline: true,
          isResizable: true,
        },
        {
          key: 'UnexpectedValueCount',
          name: 'Unexpected Value Count',
          fieldName: 'count',
          minWidth: 140,
          isMultiline: true,
          isResizable: true,
        },
        {
          key: 'TotalCount',
          name: 'Total Count',
          fieldName: 'totalCount',
          minWidth: 70,
          isMultiline: true,
          isResizable: true,
        },
        {
          key: 'UnexpectedValuePercentage',
          name: 'Unexpected Value Percentage',
          fieldName: 'percentage',
          minWidth: 185,
          isMultiline: true,
          isResizable: true,
        },
        {
          key: 'Result',
          name: 'Result',
          fieldName: 'result',
          minWidth: 50,
          isMultiline: true,
          isResizable: true,
        },
        {
          key: 'Range',
          name: 'Range',
          fieldName: 'range',
          minWidth: 50,
          isMultiline: true,
          isResizable: true,
        },
      ] as IColumn[],
      items: ruleExecutionResults.map((ruleExecutionResult, index) => {
        const item = {
          key: index.toString(),
          date: UserExpectationResultsBuilder.getShorterDate(
            ruleExecutionResult.ruleExecutionStartTime
          ),
        };

        if (
          ruleExecutionResult.ruleResult.subType === RuleResultType.ErrorResult
        ) {
          return { ...item, result: 'Error' };
        }

        const ruleResult =
          ruleExecutionResult.ruleResult as UnexpectedValueCheckResult;
        const col = ruleResult.columns[0];
        const unexpectedValues = col.unexpectedValues;
        const { result, rangeStart, rangeEnd } =
          UserExpectationResultsBuilder.calcResultRange(
            ruleExecutionResult,
            unexpectedValues.count,
            unexpectedValues.percentage
          );
        const isPercentage =
          (rangeStart === undefined && rangeEnd === undefined) ||
          UserExpectationResultsBuilder.isPercentThresholds(
            ruleExecutionResult
          );
        const p = isPercentage ? '%' : '';

        return {
          ...item,
          count: unexpectedValues.count,
          totalCount: unexpectedValues.totalCount,
          percentage:
            p && unexpectedValues.percentage !== undefined
              ? unexpectedValues.percentage + p
              : unexpectedValues.percentage,
          result,
          range: `${rangeStart || (isPercentage ? '0' : 'unbounded')}${p} - ${
            rangeEnd || (isPercentage ? '100' : 'unbounded')
          }${p}`,
        };
      }),
    };
  }
}

export default UnexpectedValueCheckResultsBuilder;
