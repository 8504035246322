import React from 'react';

import { Stack, Label } from '@fluentui/react';
import { IButtonStyles } from 'office-ui-fabric-react';
import { HintText, HintTextProps } from './hintText';

export interface LensLabelProps extends HintTextProps {
  labelText: string;
  required: boolean;
}

/**
 * Renders Lens Label with hint icon
 */
export const LensLabel = (props: LensLabelProps): JSX.Element => {
  const { labelText, hintText, required } = props;

  return (
    <Stack horizontal>
      {required && <Label styles={requiredLabelStyles}>*</Label>}
      <Label styles={labelStyles}>{labelText}</Label>
      <HintText hintText={hintText} />
    </Stack>
  );
};

const labelStyles: IButtonStyles = {
  root: {
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '3px',
  },
};

const requiredLabelStyles: IButtonStyles = {
  root: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(168, 0, 0)',
    marginRight: '3px',
  },
};
