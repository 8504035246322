import LeApi from 'api/leApi';
import { Job, JobInstance, JobExecutionStatus } from '../models/job';

class JobApi extends LeApi {
  public elevateOrchestrator = async (): Promise<string | null> => {
    return await this.postEntity<null, string | null>(
      '/principals/elevation',
      null
    );
  };

  public createJob = async (workspaceId: string, job: Job): Promise<Job> => {
    return await this.postEntity<Job, Job>(
      `/v1/workspaces/${workspaceId}/jobs`,
      job
    );
  };

  public getJob = async (workspaceId: string, jobId: string): Promise<Job> => {
    return await this.getEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`
    );
  };

  public getJobs = async (workspaceId: string): Promise<Job[]> => {
    return await this.getEntity<Job[]>(
      `/v1/workspaces/${workspaceId}/jobs/?includeInstances=true`
    );
  };

  public getJobWithVersionNumber = async (
    workspaceId: string,
    jobId: string,
    versionNumber: string
  ): Promise<Job> => {
    return await this.getEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/versions/${versionNumber}`
    );
  };

  public getJobVersions = async (
    workspaceId: string,
    jobId: string
  ): Promise<Job[]> => {
    return await this.getEntity<Job[]>(
      `v1/workspaces/${workspaceId}/jobs/${jobId}/versions`
    );
  };

  public deleteJob = async (
    workspaceId: string,
    jobId: string
  ): Promise<Job> => {
    return await this.deleteEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`
    );
  };

  public updateJob = async (
    workspaceId: string,
    jobId: string,
    job: Job
  ): Promise<Job> => {
    return await this.putEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`,
      job
    );
  };

  public enableJob = async (
    workspaceId: string,
    jobId: string
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/enable`,
      null
    );
  };

  public disableJob = async (
    workspaceId: string,
    jobId: string
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/disable`,
      null
    );
  };

  public getDependencyEvents = async (
    workspaceId: string,
    jobId: string
  ): Promise<any> => {
    return await this.getEntity<any>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/dependencies/events`
    );
  };

  // Job Instances apis

  public getJobInstances = async (
    workspaceId: string,
    jobId: string
  ): Promise<JobInstance[]> => {
    return await this.getEntity<JobInstance[]>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances`
    );
  };

  public getJobInstanceTags = async (
    workspaceId: string,
    jobId: string
  ): Promise<string[]> => {
    return await this.getEntity<string[]>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instanceTags`
    );
  };

  public getJobInstance = async (
    workspaceId: string,
    jobId: string,
    instanceId: string
  ): Promise<JobInstance> => {
    return await this.getEntity<JobInstance>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances/${instanceId}`
    );
  };

  // TODO - Nambi - looks like it is returning JObject - need to build a model corresponding to this.
  public getJobInstanceLogs = async (
    workspaceId: string,
    jobId: string,
    instanceId: string
  ): Promise<any> => {
    return await this.getEntity<any>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances/${instanceId}/logs`
    );
  };

  public scheduleJob = async (
    workspceId: string,
    jobId: string,
    settings: any | null
  ): Promise<JobInstance> => {
    return await this.postEntity<any, JobInstance>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/scheduleInstance`,
      settings
    );
  };

  public cancelJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/cancel`,
      null
    );
  };

  public cancelJobInstances = async (
    workspceId: string,
    jobId: string,
    listType: JobExecutionStatus
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/cancelInstances/${listType}`,
      null
    );
  };

  public terminateJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string | null
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/terminate`,
      null
    );
  };

  public rerunJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string
  ): Promise<JobInstance> => {
    return await this.postEntity<null, JobInstance>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/rerun`,
      null
    );
  };
}

export const jobApi = new JobApi();
export const elevateOrchestrator = jobApi.elevateOrchestrator;
export default jobApi;
