import React from 'react';
import { useHistory } from 'react-router';
import { IColumn, Selection } from '@fluentui/react';
import {
  DeleteIconProps,
  DisplayTypes,
  ElxActionButton,
  ElxTableContainer,
  IElxContainerProps,
  ITableAction,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
} from '@elixir/fx';

export interface ExpectationsListItem {
  id: string;
  result: string | JSX.Element;
  expectation: string | JSX.Element;
  classifyMode: string;
  classifyCount: number;
  [propName: string]: any;
}

export interface ExpectationsListProps {
  datasetName?: string;
  items: ExpectationsListItem[];
  isLoading?: boolean;
  loadingLabel?: string;
  onEditExpectation: (item?: ExpectationsListItem, isClone?: boolean) => void;
  onNotificationSettings: (item: ExpectationsListItem) => void;
  onRefresh: () => any;
  onDeleteItems: (items: ExpectationsListItem[]) => void;
  onResultDetails: (item: ExpectationsListItem) => void;
  onExecuteRules: (items: ExpectationsListItem[]) => void;
}

const StringOperators = ['==', '!=', 'Contains'];

const styles = {
  subHeader: {
    position: 'absolute',
    top: '0',
  },
  header: {
    paddingTop: '8px',
  },
};
const searchBoxStyles = {
  root: {
    paddingLeft: '8px',
    paddingTop: '5px',
  },
};

/**
 * A list component that will display a list of DatasetListItems that are passed in as props
 * @param props
 * @returns
 */
export const ExpectationsList = (props: ExpectationsListProps): JSX.Element => {
  const {
    datasetName,
    items,
    isLoading,
    loadingLabel,
    onEditExpectation,
    onNotificationSettings,
    onRefresh,
    onDeleteItems,
    onResultDetails,
    onExecuteRules,
  } = props;

  const selection: Selection = new Selection();
  const history = useHistory();

  const actions: ITableAction[] = [
    {
      key: 'newExpectation',
      text: 'New Expectation',
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: () => {
        onEditExpectation();
      },
      iconProps: { iconName: 'Add' },
    },
    {
      key: 'edit Expectation',
      text: 'Edit Expectation',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: ExpectationsListItem) => onEditExpectation(item),
      iconProps: { iconName: 'Edit' },
    },
    {
      key: 'clone Expectation',
      text: 'Clone Expectation',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: ExpectationsListItem) => onEditExpectation(item, true),
      iconProps: { iconName: 'Copy' },
    },
    {
      key: 'execute',
      text: 'Execute',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: false,
      onAction: (item) => onExecuteRules([item]),
      onBulkAction: onExecuteRules,
      iconProps: { iconName: 'Play' },
    },
    {
      key: 'schedule',
      text: 'Schedule',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: ExpectationsListItem) => onNotificationSettings(item),
      iconProps: { iconName: 'Timer' },
    },
    {
      key: 'notification Settings',
      text: 'Notification Settings',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: ExpectationsListItem) => onNotificationSettings(item),
      iconProps: { iconName: 'Megaphone' },
    },
    {
      key: 'view Results',
      text: 'View Results',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: onResultDetails,
      iconProps: { iconName: 'ComplianceAudit' },
    },
    {
      key: 'delete',
      text: 'Delete',
      defaultDisplay: DisplayTypes.Disabled,
      onAction: (item: ExpectationsListItem) => onDeleteItems([item]),
      onBulkAction: onDeleteItems,
      iconProps: DeleteIconProps,
    },
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: onRefresh,
      iconProps: { iconName: 'Refresh' },
    },
  ];

  let maxCount = 0;
  items.forEach((item) => (maxCount = Math.max(maxCount, item.classifyCount)));

  const columns = [...mainColumns];
  // Note: the "Range" columns are 1-based not 0-based arrays
  for (let i = 1; i <= maxCount; i = i + 1) {
    columns.push(
      ...[
        {
          ...colDefaults,
          key: 'range' + i + 'start',
          name: 'Range ' + i + ' Starts',
          fieldName: 'range' + i + 'start',
          minWidth: 80,
        },
        {
          ...colDefaults,
          key: 'range' + i + 'end',
          name: 'Range ' + i + ' Ends',
          fieldName: 'range' + i + 'end',
          minWidth: 80,
        },
        {
          ...colDefaults,
          key: 'range' + i + 'classification',
          name:
            i +
            (['', 'st', 'nd', 'rd'][(i % 100 >> 3) ^ 1 && i % 10] || 'th') +
            ' Classification', // https://codegolf.stackexchange.com/a/119563
          fieldName: 'range' + i + 'classification',
          minWidth: 90,
        },
      ]
    );
  }

  function onBack() {
    history.replace('/dataquality/datasets');
  }

  const containerProps = {
    headerText: 'Quality Report' + (datasetName ? ' - ' + datasetName : ''),
    isLoading,
    loadingLabel,
    subHeaderText: '',
    onRenderSubHeader: () => (
      <>
        <ElxActionButton
          key={3}
          text="Back to All Datasets"
          iconProps={{ iconName: 'Back' }}
          onClick={onBack}
        />
      </>
    ),
    styles: styles,
  } as IElxContainerProps;

  const resultPillFilter: FilterOption = {
    field: 'result',
    label: 'Result',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const expectationPillFilter: FilterOption = {
    field: 'expectation',
    label: 'Expectation',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const modePillFilter: FilterOption = {
    field: 'classifyMode',
    label: 'Mode',
    multiselect: true,
    pillMode: FilterOptionPillMode.Static,
    values: items
      .map((item) => item.classifyMode)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const searchProps = {
    pillFilters: [resultPillFilter, expectationPillFilter, modePillFilter],
    filterDisplayMode: FilterDisplayMode.Pill,
    styles: searchBoxStyles,
  };

  const tableStyles = {
    root: {
      '.ms-DetailsHeader-cellName': {
        fontWeight: '500 !important',
        fontSize: '12px !important',
      },
    },
  };

  return (
    <ElxTableContainer
      containerProps={containerProps}
      tableProps={{
        columns,
        items,
        selection,
        actionsName: ' ',
        actions,
        onItemInvoked: onResultDetails,
        styles: tableStyles,
      }}
      searchBoxProps={searchProps}
    />
  );
};

const colDefaults = {
  isResizable: true,
};

const mainColumns: IColumn[] = [
  {
    ...colDefaults,
    key: 'result',
    name: 'Result',
    fieldName: 'result',
    minWidth: 80,
    maxWidth: 100,
  },
  {
    ...colDefaults,
    key: 'expectation',
    name: 'Expectation',
    fieldName: 'expectationText',
    onRender: (item: ExpectationsListItem) => {
      return item.expectation;
    },
    minWidth: 240,
  },
  {
    ...colDefaults,
    key: 'classifyMode',
    name: 'Mode',
    fieldName: 'classifyMode',
    minWidth: 60,
  },
];

export default ExpectationsList;
