import { DataConnection } from './project';

export enum WorkspaceAccessFilter {
  None = 'none',
  Read = 'read',
  Write = 'write',
  Orchestrate = 'orchestrate',
  Admin = 'admin',
  Owned = 'owned',
}

export enum WorkspaceStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum WorkspaceSaveStatus {
  None = 'None',
  Saving = 'Saving',
  Saved = 'Saved',
  Error = 'Error',
}

export enum WorkspaceListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

interface WorkspaceBase {
  id: string;
  name: string;
  alias: string;
  dsoProject: string;
  accessFilter: WorkspaceAccessFilter;
  createdBy: string;
  applications: WorkspaceApplication[] | undefined;
  dataConnections: DataConnection[] | undefined;
  lastUpdated: string;
  description: string;
  isReadOpen: boolean;
  shareType: string;
  dqsWorkspace: string;
  lastUpdatedBy: string;
}
export interface Workspace extends WorkspaceBase {
  tags: string[];
  adminGroups: string[];
  adminAliases: string[];
  orchestrateGroups: string[];
  orchestrateAliases: string[];
  readOnlyGroups: string[];
  readOnlyAliases: string[];
  readWriteGroups: string[];
  readWriteAliases: string[];
}

export interface WorkspaceBeforeFormat extends WorkspaceBase {
  tags: string;
  adminGroups: string;
  adminAliases: string;
  lastUpdatedBy: string;
  orchestrateGroups: string;
  orchestrateAliases: string;
  readOnlyGroups: string;
  readOnlyAliases: string;
  readWriteGroups: string;
  readWriteAliases: string;
}

export interface WorkspaceApplication {
  tenant: string;
  id: string;
}
