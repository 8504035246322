import React from 'react';
import { Card } from 'components/cards/card';
import { EditorProps } from '../../base/userExpectation';
import {
  ClassifyResults,
  useClassifyResultsHelpers,
} from '../../../components/classifyResults/classifyResults';
import { RowCountCheckState } from './rowCountCheckExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import { getTableName } from 'features/dataQuality/utils/dataQualityUtils';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

interface RowCountCheckEditorProps extends EditorProps<RowCountCheckState> {
  state?: RowCountCheckState;
  setState: React.Dispatch<RowCountCheckState>;
}

export const RowCountCheckEditor = (
  props: RowCountCheckEditorProps
): JSX.Element => {
  const { dataset, state, setState } = props;
  const classifyHelpers = useClassifyResultsHelpers(
    state,
    setState,
    StandardUnits.INTEGER
  );

  const { tableName, hasCustomQuery } = getTableName(dataset);

  return (
    <>
      <Card>
        {hasCustomQuery ? (
          <p>
            {'For the '}
            <HighlightText>{dataset.datasetName}</HighlightText>
            {" dataset's custom query, the number of rows will be counted."}
          </p>
        ) : tableName ? (
          <p>
            {'For the '}
            <HighlightText>{tableName}</HighlightText>
            {' table, the number of rows will be counted.'}
          </p>
        ) : (
          <p>
            {'For the '}
            <HighlightText>{dataset.datasetName}</HighlightText>
            {' dataset, the number of rows will be counted.'}
          </p>
        )}
      </Card>
      <ClassifyResults {...classifyHelpers} />
    </>
  );
};

export default RowCountCheckEditor;
