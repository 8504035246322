import { IElixirRoute, IModule, setConfig } from '@elixir/fx';
import reducer from 'app/reducers';
import { initializeLensShell } from 'app/lensShellUtility';
import home from './images/home.svg';
import query from './images/query.svg';
import dashboard from './images/dashboard.svg';
import job from './images/job.svg';
import dataset from './images/dataset.svg';
import workspace from './images/workspace.svg';
import settings from './images/settings.svg';
import DataQuality from 'features/dataQuality/dataQuality';
import ManageWorkspaces from 'features/workspaces/components/manageWorkspaces';
import JobListRoute from 'features/orchestrator/jobListRoute';

export function iconProps(src: string) {
  return {
    imageProps: {
      src,
    },
    styles: {
      root: {
        height: '26px',
      },
    },
  };
}

export interface LensRoute extends IElixirRoute {
  path?: string;
  state?: string;
  subRoutes?: LensRoute[];
}
export interface LensModule extends IModule {
  route?: LensRoute;
  underDevelopment?: boolean;
}

const allModules: LensModule[] = [
  {
    name: 'Home',
    env: 'development',
    route: {
      index: 'Home',
      name: 'Home',
      iconProps: iconProps(home),
      exact: false,
      path: '/#/home/',
      state: 'app.home.start',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Queries',
    env: 'development',
    route: {
      index: 'Queries',
      name: 'Queries',
      iconProps: iconProps(query),
      exact: false,
      path: '/#/discover/query//results',
      state: 'app.discover.query.results',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Dashboards',
    env: 'development',
    route: {
      index: 'Dashboards',
      name: 'Dashboards',
      iconProps: iconProps(dashboard),
      exact: false,
      path: '/#/dashboard/',
      state: 'app.dashboard',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Jobs',
    env: 'development',
    route: {
      index: 'Jobs',
      name: 'Jobs',
      iconProps: iconProps(job),
      exact: false,
      path: '/#/jobs/list',
      state: 'app.jobs.list',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Datasets',
    env: 'development',
    route: {
      index: 'Datasets',
      name: 'Datasets',
      iconProps: iconProps(dataset),
      exact: true,
      path: '/dataquality/datasets',
      isRouteable: true,
      inSidebar: true,
      component: DataQuality,
    },
    reducer,
  },
  {
    name: 'DataQuality',
    env: 'development',
    route: {
      index: 'DataQuality',
      name: 'Data Quality',
      iconProps: iconProps(dataset),
      exact: false,
      path: '/dataquality',
      isRouteable: true,
      inSidebar: false,
      component: DataQuality,
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'QualityReport',
          name: 'Quality Report',
          isRouteable: false,
          path: '/dataquality/report/:datasetId',
          iconProps: { iconName: 'BIDashboard' },
        },
      ],
    },
    reducer,
  },
  {
    name: 'Workspaces',
    env: 'development',
    route: {
      index: 'Workspaces',
      name: 'Workspaces',
      iconProps: iconProps(workspace),
      exact: true,
      path: '/workspaces/manage',
      isRouteable: true,
      inSidebar: true,
      component: ManageWorkspaces,
    },
    reducer,
  },
  {
    name: 'Workspaces',
    env: 'development',
    route: {
      index: 'Workspaces',
      name: 'Workspaces',
      iconProps: iconProps(workspace),
      exact: false,
      path: '/workspaces', // /manage',
      component: ManageWorkspaces,
      isRouteable: true,
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'EditWorkspace',
          name: 'Edit Workspace',
          isRouteable: false,
          path: '/workspaces/edit/:workspaceAlias',
          iconProps: { iconName: 'BIDashboard' },
        },
      ],
    },
    reducer,
  },
  {
    name: 'Settings',
    env: 'development',
    route: {
      index: 'Settings',
      name: 'Settings',
      iconProps: iconProps(settings),
      exact: false,
      path: '/manage/settings',
      state: 'app.manage.settings',
      isRouteable: false,
      inSidebar: true,
      subRoutes: [
        {
          inSidebar: true,
          exact: false,
          index: 'ViewSettings',
          name: 'View Settings',
          isRouteable: true,
          path: '/manage/settings/view',
          state: 'app.manage.settings.view',
          iconProps: { iconName: 'Personalize' },
        },
        {
          inSidebar: true,
          exact: true,
          index: 'UserSettings',
          name: 'User Profile',
          isRouteable: true,
          path: '/manage/settings/user',
          state: 'app.manage.settings.user',
          iconProps: { iconName: 'PlayerSettings' },
        },
        {
          inSidebar: true,
          exact: true,
          index: 'PilotFeatures',
          name: 'Pilot Features',
          isRouteable: true,
          path: '/manage/settings/features',
          state: 'app.manage.settings.features',
          iconProps: { iconName: 'Ferry' },
        },
      ],
    },
    reducer,
  },
  {
    name: 'Jobs - under development',
    env: 'development',
    underDevelopment: true,
    route: {
      index: 'Jobsdev',
      name: 'Jobsdev',
      iconProps: iconProps(job),
      exact: false,
      path: '/job/list',
      state: 'leapp.joblist',
      isRouteable: true,
      inSidebar: false,
      component: JobListRoute,
    },
    reducer,
  },
];

export const modules: LensModule[] = allModules;

setConfig({
  authentication: 'none',
  applicationTitle: 'Lens Explorer',
});

export const lensFramework = initializeLensShell(modules);
