import { NullCheckCondition } from 'features/dataQuality/models/rule';
import UserExpectationBuilder from '../../base/userExpectationBuilder';
import { NullCheckState } from './nullCheckExpectation';

export class NullCheckBuilder extends UserExpectationBuilder<NullCheckState> {
  // insert the column name parameter into the DQS NullCheckCondition
  buildRuleDetails(state: NullCheckState) {
    const ruleDetails = super.buildRuleDetails(state);
    (ruleDetails.ruleCondition as NullCheckCondition).ColumnsToCheck = [
      {
        datasetId: this.dataset.id,
        columnName: state.column || '',
      },
    ];
    return ruleDetails;
  }

  // use the percent thresholds handler
  processThresholds = super.processPercentageThresholds;

  public getBaseName(state: NullCheckState): string {
    return super.getBaseName(state) + ' ' + state.column;
  }
}

export default NullCheckBuilder;
