import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { getDefaultAlertForExpectation } from 'features/dataQuality/utils/dataQualityUtils';
import UserExpectationBuilder from '../../base/userExpectationBuilder';
import {
  PublishFrequencyState,
  unitPrefix,
} from './publishFrequencyExpectation';

export class PublishFrequencyBuilder extends UserExpectationBuilder<PublishFrequencyState> {
  // use the absolute thresholds handler
  processThresholds = super.processAbsoluteThresholds;

  public build(
    ruleName: string,
    state: PublishFrequencyState
  ): ExpectationInfo {
    const expectationInfo = super.build(ruleName, state);

    // append a tag for the unit type on the alert
    const alert = getDefaultAlertForExpectation(expectationInfo);
    if (alert && state.unit) {
      const tags = (alert.tags || []).filter((t) => !t.startsWith(unitPrefix)); // remove existing unit tag
      const newAlert = {
        ...alert,
        tags: [...tags, `${unitPrefix}${state.unit.unitType}`],
      };
      return {
        rule: expectationInfo.rule,
        alerts: [newAlert],
      };
    }
    return expectationInfo;
  }
}

export default PublishFrequencyBuilder;
