import React from 'react';
import { ElxIconButton } from '@elixir/fx';
import { useDispatch, useSelector } from 'react-redux';
import { allActivityLogs, uiNotifier } from '../notificationsSlice';
import ReadMore from 'components/readMore/readMore';
import {
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  Stack,
} from '@fluentui/react';
import { getStyles, customSplitButtonStyles, innerStackTokens } from './styles';
import { Card } from 'components/cards/card';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

const ActivityLogs = () => {
  const styles = getStyles();
  const dispatch = useDispatch();
  const theme = useLensShellTheme();
  const removeNotificationHandler = (id: number) => {
    dispatch(uiNotifier.removeNotification(id));
  };
  const notificationList = [...useSelector(allActivityLogs)];
  if (notificationList) {
    notificationList.reverse();
  }

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'dismissError',
        id: 'error',
        text: 'Dismiss Error',
        canCheck: true,
        isChecked: false,
      },
      {
        key: 'dismissWarnings',
        id: 'warning',
        text: 'Dismiss Warning',
        canCheck: true,
        isChecked: false,
        disabled: false,
      },
      {
        key: 'dimissInfo',
        id: 'info',
        text: 'Dismiss Info',
        canCheck: true,
        isChecked: false,
        disabled: false,
      },
      {
        key: 'dimissall',
        id: 'dimissall',
        text: 'Dismiss All',
        canCheck: true,
        isChecked: false,
        disabled: false,
      },
    ],
    onItemClick: (props) => {
      dismissHandler(props?.currentTarget.id);
    },
    directionalHintFixed: false,
  };

  const moreIcon: IIconProps = { iconName: 'More' };
  function dismissHandler(eventType: string | undefined) {
    if (eventType) {
      dispatch(uiNotifier.dismissActivityLog(eventType));
    }
  }

  return (
    <Stack className={styles.stack} tokens={innerStackTokens}>
      {notificationList?.length === 0 && (
        <Stack className={styles.noNotification}>
          There are no more Activity Logs to view
        </Stack>
      )}

      {notificationList?.length > 0 && (
        <Stack>
          <Stack.Item align="end">
            <IconButton
              split
              iconProps={moreIcon}
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              styles={customSplitButtonStyles(theme)}
              menuProps={menuProps}
              ariaLabel="New item"
              disabled={false}
              checked={true}
              onClick={() => dismissHandler(undefined)}
            />
          </Stack.Item>
        </Stack>
      )}
      {notificationList.map((notification, index) => {
        return (
          <Card
            key={notification.id}
            className={styles.card}
            tokens={{ childrenGap: 16 }}
          >
            <Stack className={styles.stack}>
              <Stack.Item align="end">
                <ElxIconButton
                  label="Cancel"
                  text="Cancel"
                  width={8}
                  height={8}
                  iconProps={{ iconName: 'Cancel' }}
                  style={{ color: 'black' }}
                  onClick={() => removeNotificationHandler(notification.id)}
                />
              </Stack.Item>
              <Stack.Item className={styles.dateTime}>
                {notification.time}
              </Stack.Item>

              <Stack.Item className={styles.title}>
                <Stack horizontal verticalAlign="start">
                  <Stack>
                    {notification.type === 'info' && (
                      <Icon
                        className={styles.infoIcon}
                        title="Info"
                        iconName="InfoSolid"
                      />
                    )}
                    {notification.type === 'warning' && (
                      <Icon
                        className={styles.warningIcon}
                        title="Warning"
                        iconName="WarningSolid"
                      />
                    )}
                    {notification.type === 'error' && (
                      <Icon
                        className={styles.errorIcon}
                        title="Error"
                        iconName="StatusErrorFull"
                      />
                    )}
                  </Stack>
                  <Stack className={styles.iconSpacing}>
                    <div>{notification.title}</div>
                  </Stack>
                </Stack>
              </Stack.Item>
              {notification.type === 'error' && (
                <Stack.Item className={styles.wordBreak}>
                  <ReadMore
                    text={notification.description}
                    charLimit={200}
                    readMoreText={'...Read more ▼'}
                    readLessText={'Read less ▲'}
                    readMoreClassName={styles.readMore}
                  ></ReadMore>
                </Stack.Item>
              )}
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};

export default ActivityLogs;
