import React from 'react';
import { useSelector } from 'react-redux';
import {
  DataQualityStatus,
  useDatasetsLatestRuleResults,
  selectDataQualityLatestRuleResultEntities,
  selectDataQualityLatestRuleResultsStatus,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
} from 'features/dataQuality/dataQualitySlice';
import { getSucceededExpectations } from 'features/dataQuality/utils/dataQualityUtils';

export interface PassRateProps {
  workspaceId: string;
  datasetId: string;
}

/**
 * The percentage of the lastest rule executions that passed for this User Expectation
 * @param props
 * @returns
 */
export const PassRate = (props: PassRateProps): JSX.Element => {
  const { workspaceId, datasetId } = props;

  // this hook also loads the expectations for this datasetId
  useDatasetsLatestRuleResults(workspaceId, datasetId);

  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  const latestRuleResultEntities = useSelector(
    selectDataQualityLatestRuleResultEntities
  );
  const latestRuleResultsStatus = useSelector(
    selectDataQualityLatestRuleResultsStatus
  );

  if (expectationsStatus === DataQualityStatus.Loaded) {
    const ruleIds: string[] =
      Object.keys(expectationEntities)
        .filter((k) => expectationEntities[k]!.rule.datasetId === datasetId)
        .map((k) => expectationEntities[k]!.rule.id) || [];

    let totalExecutions = 0;
    let totalSuccesses = 0;

    ruleIds.forEach((ruleId) => {
      const ruleResultStatus = latestRuleResultsStatus[ruleId];
      if (ruleResultStatus === DataQualityStatus.Loaded) {
        const ruleResults = latestRuleResultEntities[ruleId];
        ruleResults?.results.forEach((result) => {
          totalExecutions =
            totalExecutions + (result.expectationResults?.length || 0);
          totalSuccesses =
            totalSuccesses + getSucceededExpectations(result).length;
        });
      }
    });

    if (totalExecutions > 0) {
      const passRate = (totalSuccesses / totalExecutions) * 100;
      return <>{passRate.toFixed(2) + '%'}</>;
    }
  }

  return <></>;
};
