import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IColumn, CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import {
  DisplayTypes,
  SelectionMode,
  FilterOptionPillMode,
  FilterOptionMode,
  FilterDisplayMode,
  FilterOption,
  ElxTableContainer,
  ElxIconButton,
  IElxContainerProps,
  ITableAction,
} from '@elixir/fx';
import { Icon, Label, List } from '@fluentui/react';
import {
  classNames,
  tagStyles,
  tagStyles1,
  labelStyles4,
  AccessIcon1,
} from './workspacesStyles';
import {
  workspaceAccessFilter,
  workspaceAccessIcon,
} from 'features/workspaces/utils/workspaceQuickSwitchUtils';
import { useRouter } from '@uirouter/react';
import {
  selectAllWorkspaces,
  useWorkspaceList,
  selectWorkspaceListStatus,
} from 'features/workspaces/workspaceListSlice';
import {
  useUserprofile,
  selectUserprofile,
  selectUserprofileStatus,
  loadUserprofile,
  saveFavorites,
  saveRecents,
  saveUserSelections,
} from 'features/userprofile/userprofileSlice';
import { workspaceListItem } from '../utils/workspacesListUtils';
import { UserprofileStatus } from 'features/userprofile/models/userprofile';
import { WorkspaceListStatus } from '../models/workspace';
export interface WorkspaceProps {}

const StringOperators = ['==', '!=', 'Contains', '!Contains', 'Startswith'];

/**
 * Container component combines data from workspace list component
 * @param props
 * @returns
 */
export const Workspaces = (props: WorkspaceProps): JSX.Element => {
  useUserprofile();
  useWorkspaceList();
  const workspaceListStatus = useSelector(selectWorkspaceListStatus);
  const workspaceList = useSelector(selectAllWorkspaces);
  let userprofile = useSelector(selectUserprofile);
  let userProfileStatus = useSelector(selectUserprofileStatus);
  let allWorkspaceList: workspaceListItem[] = [];
  const history = useHistory();

  function allWorkspaces() {
    if (
      !(
        userProfileStatus === UserprofileStatus.Loaded &&
        workspaceListStatus === WorkspaceListStatus.Loaded
      )
    )
      return;
    else {
      let userSelections = JSON.parse(userprofile.UserSelections);
      let currentlySelected = userSelections.workspace;
      workspaceList.forEach((workspace) => {
        let isFavorite =
          userprofile.FavoritedWorkspaces.indexOf(workspace.id) !== -1;
        let isRecent =
          userprofile.RecentWorkspaces.indexOf(workspace.id) !== -1;
        let isCurrent = currentlySelected === workspace.id ? true : false;
        let item: workspaceListItem = {
          // clean this up?
          workspaceId: workspace.id,
          name: workspace.name,
          alias: workspace.alias,
          tags: workspace.tags,
          description: workspace.description,
          isFavorite: isFavorite,
          isRecent: isRecent,
          isCurrent: isCurrent,
          readOnlyGroups: workspace.readOnlyGroups,
          adminGroups: workspace.adminGroups,
          adminAliases: workspace.adminAliases,
          orchestrateGroups: workspace.orchestrateGroups,
          orchestrateAliases: workspace.orchestrateAliases,
          readWriteGroups: workspace.readWriteGroups,
          readWriteAliases: workspace.readWriteAliases,
          createdBy: workspace.createdBy,
          lastUpdated: workspace.lastUpdated,
          accessType: workspaceAccessFilter(workspace.accessFilter),
          accessIcon: workspaceAccessIcon(workspace.accessFilter),
          isReadOpen: workspace.isReadOpen,
        };
        allWorkspaceList.push(item);
      });
    }
    return allWorkspaceList;
  }
  allWorkspaces();
  const dispatch = useDispatch();
  const refresh = () => {
    dispatch(loadUserprofile());
  };

  const updateFavoritesInUserprofile = (workspaceItem: workspaceListItem) => {
    let favoritesList = JSON.parse(userprofile.FavoritedWorkspaces);
    let index = favoritesList.indexOf(workspaceItem.workspaceId);
    if (index > -1) {
      favoritesList.splice(index, 1);
    } else {
      favoritesList.push(workspaceItem.workspaceId);
    }
    favoritesList = JSON.stringify(favoritesList); //move to slice

    dispatch(saveFavorites({ favoritesList, userprofile }));
  };

  const updateRecentsInUserprofile = (workspaceId: string) => {
    let recentsList = JSON.parse(userprofile.RecentWorkspaces);
    let index = recentsList.indexOf(workspaceId);
    if (index > -1) {
      recentsList.splice(index, 1);
    } else {
      recentsList.splice(0, 1);
    }
    recentsList.push(workspaceId);
    recentsList = JSON.stringify(recentsList);
    dispatch(saveRecents({ recentsList, userprofile }));
  };

  const updateCurrentInUserprofile = (workspaceId: string) => {
    let userSelections = JSON.parse(userprofile.UserSelections);

    userSelections.workspace = workspaceId;
    userSelections = JSON.stringify(userSelections);
    dispatch(saveUserSelections({ userSelections, userprofile }));
  };

  const switchWorkspace = (workspaceId: string) => {
    let workspaceLoc =
      window.location.origin +
      "/#/workspaces/manage?_g=(ws:'" +
      workspaceId +
      "')";
    updateRecentsInUserprofile(workspaceId);
    updateCurrentInUserprofile(workspaceId);
    window.open(workspaceLoc, '_self');
  };

  const router = useRouter();
  const actions: ITableAction[] = [
    {
      key: 'add',
      text: 'Add',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      onBulkAction: () => {
        router.stateService.go(
          'app.manage.workspace',
          { page: 'create' },
          { reload: true }
        );
      },
    },
    {
      key: 'edit',
      text: 'Edit',
      defaultDisplay: DisplayTypes.Disabled,
      iconProps: { iconName: 'Edit' },
      onAction: (item) => {
        updateRecentsInUserprofile(item.workspaceId);
        router.stateService.go(
          'app.manage.workspace',
          { page: 'edit' },
          { reload: true }
        );
      },
    },
    {
      key: 'edit2',
      text: 'Edit2',
      defaultDisplay: DisplayTypes.Disabled,
      disabled: true,
      iconProps: { iconName: 'Edit' },
      onAction: (item) => {
        history.push('/workspaces/edit/' + item.alias);
      },
    },
    {
      key: 'switch',
      text: 'Switch',
      iconProps: { iconName: 'Switch' },
      defaultDisplay: DisplayTypes.Disabled,
      onAction: (item) => {
        switchWorkspace(item.workspaceId);
      },
    },
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Refresh' },
      onBulkAction: refresh,
    },
  ];

  const colSmall = {
    minWidth: 50,
    isResizable: true,
    isMultiline: true,
    className: classNames.tableStyles,
  };
  const colMedium = {
    minWidth: 100,
    isResizable: true,
    isMultiline: true,
    className: classNames.tableStyles,
  };
  const colLarge = {
    minWidth: 130,
    isResizable: true,
    isMultiline: true,
    className: classNames.tableStyles,
  };
  const columns: IColumn[] = [
    {
      ...colLarge,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
    },
    {
      ...colLarge,
      key: 'alias',
      name: 'Alias',
      fieldName: 'alias',
    },
    {
      ...colLarge,
      key: 'permission',
      name: 'Permission',
      fieldName: 'accessType',
      onRender: (item: any) => {
        return (
          <>
            <Label styles={tagStyles1}>
              <Icon className={AccessIcon1} iconName={item.accessIcon} />
              {item.accessType}
            </Label>
          </>
        );
      },
    },
    {
      ...colLarge,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
    },
    {
      ...colMedium,
      key: 'tags',
      name: 'Tags',
      onRender: (item: any) => {
        return renderTags(item.tags);
      },
    },
    {
      ...colMedium,
      key: 'readGroups',
      name: 'Read Groups',
      fieldName: 'readOnlyGroups',
    },
    {
      ...colMedium,
      key: 'writeGroups',
      name: 'Write Groups',
      fieldName: 'readWriteGroups',
    },
    {
      ...colMedium,
      key: 'adminGroups',
      name: 'Admin Groups',
      fieldName: 'adminGroups',
    },
    {
      ...colMedium,
      key: 'lastUpdated',
      name: 'Last Updated',
      fieldName: 'lastUpdated',
    },
    {
      ...colSmall,
      key: 'createdBy',
      name: 'Created By',
      fieldName: 'createdBy',
    },
    {
      ...colSmall,
      key: 'favorites',
      name: '',
      onRender: (item: any) => {
        if (item.isFavorite) {
          return (
            <ElxIconButton
              text=""
              styles={labelStyles4}
              iconProps={{
                iconName: 'FavoriteStarFill',
                className: classNames.favorite,
              }}
              onClick={() => {
                updateFavoritesInUserprofile(item);
              }}
            />
          );
        } else {
          return (
            <ElxIconButton
              text=""
              styles={labelStyles4}
              iconProps={{
                iconName: 'FavoriteStar',
                className: classNames.favorite,
              }}
              onClick={() => {
                updateFavoritesInUserprofile(item);
              }}
            />
          );
        }
      },
    },
  ];

  const containerProps = {
    headerText: 'Workspaces',
    isLoading: false,
  } as IElxContainerProps;

  const permissionsPillFilter: FilterOption = {
    field: 'accessType',
    label: 'Permission',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: workspaceList
      .map((workspace) => workspace.accessFilter)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const createdByPillFilter: FilterOption = {
    field: 'createdBy',
    label: 'Created By',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: workspaceList
      .map((workspace) => workspace.createdBy)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: workspaceList
      .map((workspace) => workspace.name)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const descriptionPillFilter: FilterOption = {
    field: 'description',
    label: 'Description',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const searchProps = {
    pillFilters: [
      namePillFilter,
      permissionsPillFilter,
      descriptionPillFilter,
      createdByPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const tableStyles = {
    root: {
      '.ms-DetailsHeader-cellName': {
        fontWeight: '500 !important',
        fontSize: '12px !important',
      },
    },
  };

  return (
    <>
      <ElxTableContainer
        containerProps={containerProps}
        tableProps={{
          columns,
          actions,
          selectionMode: SelectionMode.single,
          items: allWorkspaceList,
          checkboxVisibility: CheckboxVisibility.hidden,
          styles: tableStyles,
        }}
        searchBoxProps={searchProps}
      />
    </>
  );
};
const onRenderTagCell = (item?: any): JSX.Element => {
  return <Label styles={tagStyles}>{item}</Label>;
};
const renderTags = (tags: string): JSX.Element => {
  if (tags !== undefined) {
    const tagsList = JSON.parse(tags);
    return <List items={tagsList} onRenderCell={onRenderTagCell} />;
  } else {
    return <></>;
  }
};

export default Workspaces;
