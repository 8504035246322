import React, { ReactNode, CSSProperties } from 'react';

interface HighlightTextProps {
  children?: ReactNode;
}

export const HighlightText = ({
  children,
}: HighlightTextProps): JSX.Element => {
  const highlightStyle: CSSProperties = {
    backgroundColor: '#c0ecf8',
  };

  return <span style={highlightStyle}>{children}</span>;
};

export default HighlightText;
