import React from 'react';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { Card } from 'components/cards/card';
import RuleExecutionResult, {
  ErrorRuleResult,
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import LabelAndValue from 'components/labelAndValue/labelAndValue';
import { Stack } from '@fluentui/react';
import { ElxActionButton, ElxTextField } from '@elixir/fx';
import { ExpectationDetailsHelpLabel } from 'utils/helpIconText';

export interface ErrorResultProps {
  expectationInfo: ExpectationInfo;
  result: RuleExecutionResult;
}

export const ErrorResult = (props: ErrorResultProps): JSX.Element => {
  const { expectationInfo, result } = props;

  if (result?.ruleResult.subType !== RuleResultType.ErrorResult) {
    return <></>;
  }

  const ruleResult = result.ruleResult as ErrorRuleResult;

  const debugInfo =
    `Workspace ID: ${expectationInfo.rule.workspaceId}\n` +
    `Rule ID: ${expectationInfo.rule.id}\n` +
    `Rule Name: ${expectationInfo.rule.name}\n` +
    `Dataset ID: ${expectationInfo.rule.workspaceId}\n` +
    `Error Message: ${ruleResult.errorMessage}\n`;

  return (
    <>
      <Card
        collapsible
        title="Expectation Details"
        tokens={{ childrenGap: 8, padding: 8 }}
        hintText={ExpectationDetailsHelpLabel}
      >
        <ElxTextField
          wrap="hard"
          readOnly={true}
          label="Execution failed with this error message"
          multiline={true}
          rows={6}
          value={ruleResult.errorMessage}
          autoAdjustHeight={false}
          labelActions={[
            <ElxActionButton
              iconProps={{ iconName: 'Copy' }}
              text="Copy to clipboard"
              disabled={!ruleResult.errorMessage}
              onClick={() => {
                navigator.clipboard.writeText(ruleResult.errorMessage);
              }}
            />,
          ]}
        />
      </Card>
      <Card
        collapsible
        title="Debug Information"
        tokens={{ childrenGap: 8, padding: 8 }}
      >
        <LabelAndValue label="Workspace ID">
          {expectationInfo.rule.workspaceId}
        </LabelAndValue>
        <LabelAndValue label="Rule ID">{expectationInfo.rule.id}</LabelAndValue>
        <LabelAndValue label="Rule Name">
          {expectationInfo.rule.name}
        </LabelAndValue>
        <LabelAndValue label="Dataset ID">
          {expectationInfo.rule.datasetId}
        </LabelAndValue>
        <Stack.Item align="end">
          <ElxActionButton
            iconProps={{ iconName: 'Copy' }}
            text="Copy to clipboard"
            onClick={() => {
              navigator.clipboard.writeText(debugInfo);
            }}
          />
        </Stack.Item>
      </Card>
    </>
  );
};

export default ErrorResult;
