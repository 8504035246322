import React from 'react';
import { AddButtonProps, ElxActionButton, ElxChoiceGroup } from '@elixir/fx';
import { Stack } from 'office-ui-fabric-react';
import { decodeThreshold, Threshold, ThresholdState } from './threshold';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { Card } from 'components/cards/card';
import { getDefaultAlertForExpectation } from 'features/dataQuality/utils/dataQualityUtils';
import { MessageBar, MessageBarType } from '@fluentui/react';
import {
  ClassifyResultHelpLabel,
  ObserveHelpLabel,
  ThresholdHelpLabel,
} from 'utils/helpIconText';
import { LensButton } from 'utils/lensButton';
import StandardUnits, { ThresholdUnit } from './thresholdUnits';
import { getUserExpectationFromRule } from 'features/dataQuality/userExpectations/userExpectationsRegistry';

export enum ClassifyMode {
  OBSERVE = 'OBSERVE',
  THRESHOLDS = 'THRESHOLDS',
}

export interface ClassifyResultsState {
  classifyMode?: ClassifyMode;
  thresholds?: ThresholdState[];
  thresholdsComplete?: boolean;
  thresholdsValidateMessage?: string;
}

export interface ClassifyResultsProps
  extends Pick<ClassifyResultsState, 'classifyMode' | 'thresholds'> {
  unit: ThresholdUnit;
  onChangeClassifyMode: (mode: ClassifyMode) => void;
  onChangeThreshold: (index: number, thresholdState: ThresholdState) => void;
  onAddThreshold: () => void;
  onDeleteThreshold: (index: number) => void;
}

export const classifyModeDisplayName = new Map<ClassifyMode, string>();
classifyModeDisplayName.set(ClassifyMode.OBSERVE, 'Observe');
classifyModeDisplayName.set(ClassifyMode.THRESHOLDS, 'Thresholds');

export const classifyModeHelpText = new Map<ClassifyMode, string>();
classifyModeHelpText.set(ClassifyMode.OBSERVE, ObserveHelpLabel);
classifyModeHelpText.set(ClassifyMode.THRESHOLDS, ThresholdHelpLabel);
export function useClassifyResultsHelpers(
  state: ClassifyResultsState | undefined,
  setState: React.Dispatch<ClassifyResultsState>,
  unit: ThresholdUnit
) {
  return {
    classifyMode: state?.classifyMode || ClassifyMode.OBSERVE,
    onChangeClassifyMode: (mode: ClassifyMode) => {
      setState({
        ...state,
        classifyMode: mode,
        ...validateThresholds(mode, state?.thresholds, unit),
      });
    },
    thresholds: state?.thresholds,
    unit: unit,
    onChangeThreshold: (index: number, thresholdState: ThresholdState) => {
      const thresholds = state?.thresholds?.slice();
      thresholds?.splice(index, 1, thresholdState);
      setState({
        ...state,
        thresholds,
        ...validateThresholds(state?.classifyMode, thresholds, unit),
      });
    },
    onAddThreshold: () => {
      const thresholds = state?.thresholds?.concat({});
      setState({
        ...state,
        thresholds,
        ...validateThresholds(state?.classifyMode, thresholds, unit),
      });
    },
    onDeleteThreshold: (index: number) => {
      const thresholds = state?.thresholds?.slice();
      thresholds?.splice(index, 1);
      setState({
        ...state,
        thresholds,
        ...validateThresholds(state?.classifyMode, thresholds, unit),
      });
    },
  };
}

function parseThresholdValue(value: string | undefined): number | undefined {
  return value && !Number.isNaN(Number.parseFloat(value))
    ? Number.parseFloat(value)
    : undefined;
}

export function validateThresholds(
  classifyMode: ClassifyMode | undefined,
  thresholds: ThresholdState[] | undefined,
  unit: ThresholdUnit
): { thresholdsComplete: boolean; thresholdsValidateMessage?: string } {
  if (classifyMode === ClassifyMode.OBSERVE) {
    return { thresholdsComplete: true };
  }
  if (!thresholds) {
    return { thresholdsComplete: false };
  }

  const overlapCompareSet = new Set(thresholds);
  let thresholdsValidateMessage: string | undefined = undefined;
  let thresholdsComplete = true;

  thresholds.forEach((t1) => {
    const range1Complete = t1.from !== undefined && t1.to !== undefined;
    if (!range1Complete || t1.level === undefined) {
      thresholdsComplete = false;
    }
    if (thresholdsValidateMessage || !range1Complete) {
      return;
    }
    const isPercent = unit.isPercent;
    overlapCompareSet.delete(t1);
    let t1From = parseThresholdValue(t1.from);
    let t1To = parseThresholdValue(t1.to);
    t1From = t1From === undefined ? (isPercent ? 0 : -Infinity) : t1From;
    t1To = t1To === undefined ? (isPercent ? 100 : Infinity) : t1To;
    const t1FromStr = unit.getDisplayString(t1From);
    const t1ToStr = unit.getDisplayString(t1To);
    if (t1To < t1From) {
      thresholdsValidateMessage = `Illegal threshold range: 'To' value (${t1ToStr}) cannot be less than 'From' value (${t1FromStr}).`;
      return;
    }
    if (range1Complete && t1From === -Infinity && t1To === Infinity) {
      if (isPercent) {
        thresholdsValidateMessage = `Thresholds must have at least endpoint greater than 0% or less than 100% ('From' value or 'To' value).`;
      } else {
        thresholdsValidateMessage = `Thresholds must have at least one finite endpoint ('From' value or 'To' value).`;
      }
      return;
    }
    overlapCompareSet.forEach((t2) => {
      const range2Complete = t2.from !== undefined && t2.to !== undefined;
      if (thresholdsValidateMessage || !range2Complete) {
        return;
      }
      let t2From = parseThresholdValue(t2.from);
      let t2To = parseThresholdValue(t2.to);
      t2From = t2From === undefined ? (isPercent ? 0 : -Infinity) : t2From;
      t2To = t2To === undefined ? (isPercent ? 100 : Infinity) : t2To;
      const t2FromStr = unit.getDisplayString(t2From);
      const t2ToStr = unit.getDisplayString(t2To);
      if (
        ((t1From as number) >= t2From && (t1From as number) < t2To) ||
        ((t1To as number) > t2From && (t1To as number) < t2To)
      ) {
        thresholdsValidateMessage = `Threshold ranges cannot overlap:  ${t1FromStr} - ${t1ToStr} overlaps with ${t2FromStr} - ${t2ToStr}.`;
        return;
      }
    });
  });
  return { thresholdsComplete, thresholdsValidateMessage };
}

// also converts undefined from/to to a concrete threshold (e.g. missing "from" implies from is 0% or -Infinity, depending on valueType)
function fillUndefinedThresholds(
  unit: ThresholdUnit,
  thresholdState?: ThresholdState
) {
  if (!thresholdState) {
    return undefined;
  }
  return {
    ...thresholdState,
    from:
      thresholdState.from || (unit.isPercent ? '0' : (-Infinity).toString()),
    to: thresholdState.to || (unit.isPercent ? '100' : Infinity.toString()),
  };
}

export function getClassifyResultsState(
  expectationInfo?: ExpectationInfo
): ClassifyResultsState {
  const observeDefault: ClassifyResultsState = {
    classifyMode: ClassifyMode.OBSERVE,
    thresholds: [{}],
    thresholdsComplete: true,
  };
  const alert = getDefaultAlertForExpectation(expectationInfo);
  if (
    !alert ||
    !expectationInfo?.rule ||
    !expectationInfo?.alerts ||
    expectationInfo?.alerts.length === 0 ||
    (!alert.expectations?.length && !alert.actions?.length)
  ) {
    return observeDefault;
  }

  const userExpectation = getUserExpectationFromRule(expectationInfo.rule);
  const unit =
    userExpectation?.getUnit(expectationInfo) || StandardUnits.NUMBER;

  const thresholds = alert.expectations
    ?.map(decodeThreshold)
    .map((t) => fillUndefinedThresholds(unit, t))
    .filter((t) => t) as ThresholdState[];

  return {
    classifyMode: ClassifyMode.THRESHOLDS,
    thresholds: thresholds || [{}],
    ...validateThresholds(ClassifyMode.THRESHOLDS, thresholds, unit),
  };
}

const classifyResultsStyles = {
  root: {
    '.elx-button-action': {
      marginLeft: '20px',
      marginTop: '-5px',
    },
    '.elx-tooltip-host': {
      marginLeft: '-20px',
    },
  },
};

export const ClassifyResults = (props: ClassifyResultsProps): JSX.Element => {
  const {
    classifyMode,
    onChangeClassifyMode,
    thresholds,
    onChangeThreshold,
    onAddThreshold,
    onDeleteThreshold,
    unit,
  } = props;

  const { thresholdsValidateMessage } = validateThresholds(
    classifyMode,
    thresholds,
    unit
  );

  // convert our ClassifyMode enum + Display names map into some dropdown options
  const options = Object.keys(ClassifyMode).map((key) => {
    let labelText = classifyModeDisplayName.get(key as ClassifyMode) || key;
    let hintText = classifyModeHelpText.get(key as ClassifyMode) || key;
    if (key === ClassifyMode.THRESHOLDS && unit.unitDisplayNamePlural) {
      labelText = labelText + ` (in ${unit.unitDisplayNamePlural})`;
    }
    return {
      key,
      text: labelText,
      onRenderLabel: () => {
        function onClickButton() {
          onChangeClassifyMode(key as ClassifyMode);
        }
        return (
          <>
            <LensButton
              buttonText={labelText}
              hintText={hintText}
              required={false}
              onClick={onClickButton}
            ></LensButton>
          </>
        );
      },
    };
  });

  return (
    <>
      <Card
        title="Classify Results"
        tokens={{ childrenGap: 16 }}
        hintText={ClassifyResultHelpLabel}
      >
        <ElxChoiceGroup
          defaultValue={ClassifyMode.OBSERVE}
          selectedKey={classifyMode}
          onChange={(e, option) => {
            onChangeClassifyMode(option?.key as ClassifyMode);
          }}
          options={options}
          styles={classifyResultsStyles}
        />
        {thresholdsValidateMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {thresholdsValidateMessage}
          </MessageBar>
        )}
        {classifyMode === ClassifyMode.THRESHOLDS &&
          thresholds?.map(function (threshold, index) {
            return (
              <Threshold
                key={index}
                state={threshold}
                setState={(state: ThresholdState) =>
                  onChangeThreshold(index, state)
                }
                thresholds={thresholds}
                unit={unit}
                deleteEnabled={thresholds?.length > 1}
                onDelete={() => onDeleteThreshold(index)}
              />
            );
          })}
        {classifyMode === ClassifyMode.THRESHOLDS && !!thresholds && (
          <Stack.Item align="end">
            <ElxActionButton
              {...AddButtonProps}
              text="Add more levels"
              onClick={onAddThreshold}
            />
          </Stack.Item>
        )}
      </Card>
    </>
  );
};

export default ClassifyResults;
