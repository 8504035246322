import React from 'react';
import UserExpectation from '../../base/userExpectation';
import { getClassifyResultsState } from '../../../components/classifyResults/classifyResults';
import {
  RegExCheckCondition,
  RegExType,
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
} from 'features/dataQuality/models/rule';
import RegExCheckEditor from './regExCheckEditor';
import RegExCheckBuilder from './regExCheckBuilder';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import { registerUserExpectation } from '../../userExpectationsRegistry';
import RegExCheckResultsBuilder from './regExCheckResultsBuilder';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export interface RegExCheckState extends EditExpectationState {
  column?: string;
  regExType?: RegExType;
  customRegExFormat?: string | null;
}

export function getDescriptionText(
  columnName: string = 'Column',
  regExType: string = 'regular expression',
  customRegExFormat: string = ''
) {
  if (regExType === RegExType.Custom.toString()) {
    return (
      columnName +
      ' values should match this ' +
      customRegExFormat +
      ' custom format'
    );
  } else {
    return (
      columnName +
      ' values should match ' +
      (regExType === RegExType.Custom.toString() ||
      regExType === RegExType.GUID.toString() ||
      regExType === 'regular expression'
        ? 'a '
        : 'an ') +
      regExType.toString() +
      ' format'
    );
  }
}

class RegExCheckExpectation extends UserExpectation<RegExCheckState> {
  id = 'RegExCheck';
  description = 'Column values should match this regular expression.';
  category = SystemDefinedRuleCategory.Conformity;
  subCategory = SystemDefinedRuleSubCategory.RegularExpressionCheck;
  ruleType = RuleType.PreDefined;
  ruleConditionType = RuleConditionType.RegExCheckCondition;

  getDescriptionElement = (state?: RegExCheckState, dataset?: Dataset) => {
    const columnName = state?.column || 'Column';
    const regExType = state?.regExType || 'regular expression';
    const customRegExFormat = state?.customRegExFormat || '';

    return (
      <span
        title={getDescriptionText(columnName, regExType, customRegExFormat)}
      >
        <HighlightText>{columnName}</HighlightText> values should match{' '}
        {regExType === RegExType.Custom.toString() &&
        customRegExFormat !== '' ? (
          <span>
            this <HighlightText>{customRegExFormat}</HighlightText> custom
            format
          </span>
        ) : (
          <span>
            {regExType === RegExType.Custom.toString() ||
            regExType === RegExType.GUID.toString() ||
            regExType === 'regular expression'
              ? ' a'
              : ' an'}{' '}
            <HighlightText>{regExType}</HighlightText> format
          </span>
        )}
      </span>
    );
  };

  Editor = RegExCheckEditor;

  getInitialState(expectationInfo?: ExpectationInfo): RegExCheckState {
    const columns2check = (
      expectationInfo?.rule?.details.ruleCondition as RegExCheckCondition
    )?.ColumnsToCheck;
    const column =
      columns2check && columns2check.length > 0
        ? columns2check[0].columnName
        : undefined;
    const regExType =
      columns2check && columns2check.length > 0
        ? columns2check[0].regExType
        : undefined;
    const customRegExFormat =
      columns2check && columns2check.length > 0
        ? columns2check[0].customRegExFormat
        : undefined;

    return {
      ...getClassifyResultsState(expectationInfo),
      column,
      regExType,
      customRegExFormat,
      stateIsReady: (column?.length || 0) > 0,
    };
  }

  public getUnit(expectationInfo?: ExpectationInfo) {
    return StandardUnits.PERCENT;
  }

  builderFactory = (
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo
  ): RegExCheckBuilder =>
    new RegExCheckBuilder(this, dataset, connection, prevExpectationInfo);

  getResultDescription = RegExCheckResultsBuilder.getResultDescription;
  getResultsTable = RegExCheckResultsBuilder.getResultsTable;
}

registerUserExpectation(new RegExCheckExpectation());
