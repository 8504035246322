import { LeApi } from 'api/leApi';
import { AxiosRequestConfig } from 'axios';
import Dataset, { DatasetRequest } from '../models/dataset';
import DataConnection, {
  FlatDataConnection,
  ValidateResult,
} from '../models/dataConnection';
import Rule, { RuleRequest } from '../models/rule';
import Alert, { AlertRequest } from '../models/alert';
import { ExecuteRulesRequest } from '../models/execute';
import RuleExecutionResult from '../models/ruleExecutionResult';

class DataQualityApi extends LeApi {
  public getDatasets = async (workspaceId: string): Promise<Dataset[]> => {
    return await this.getEntity<Dataset[]>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/datasets'
    );
  };

  public getDataset = async (
    workspaceId: string,
    datasetId: string
  ): Promise<Dataset> => {
    return await this.getEntity<Dataset>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/datasets/' + datasetId
    );
  };

  public postDataset = async (
    workspaceId: string,
    datasetRequest: DatasetRequest
  ): Promise<string> => {
    return await this.postEntity<DatasetRequest, string>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/datasets',
      datasetRequest
    );
  };

  public putDataset = async (
    workspaceId: string,
    datasetRequest: DatasetRequest
  ): Promise<DatasetRequest> => {
    return await this.putEntity<DatasetRequest>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/datasets',
      datasetRequest
    );
  };

  public deleteDataset = async (
    workspaceId: string,
    datasetId: string
  ): Promise<any> => {
    return await this.deleteEntity<any>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/datasets/' + datasetId
    );
  };

  public getConnections = async (
    workspaceId: string
  ): Promise<DataConnection[]> => {
    var connections = await this.getEntity<DataConnection[]>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/connections'
    );
    return connections;
  };

  public getRules = async (
    workspaceId: string,
    datasetId?: string
  ): Promise<Rule[]> => {
    const query = datasetId ? '?datasetId=' + datasetId : '';
    return await this.getEntity<Rule[]>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/rules' + query
    );
  };

  public getAlerts = async (
    workspaceId: string,
    ruleId: string
  ): Promise<Alert[]> => {
    return await this.getEntity<Alert[]>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/rules/' +
        ruleId +
        '/alerts'
    );
  };

  public getRule = async (
    workspaceId: string,
    datasetId: string,
    ruleId: string
  ): Promise<Rule> => {
    return await this.getEntity<Rule>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/datasets/' +
        datasetId +
        '/rules/' +
        ruleId
    );
  };

  public postRule = async (
    workspaceId: string,
    ruleRequest: RuleRequest
  ): Promise<string> => {
    // DataQualty POST Rule returns the rule guid
    return await this.postEntity<RuleRequest, string>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/rules',
      ruleRequest
    );
  };

  public putRule = async (workspaceId: string, rule: Rule): Promise<Rule> => {
    return await this.putEntity<Rule>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/rules',
      rule
    );
  };

  public deleteRule = async (
    workspaceId: string,
    datasetId: string,
    ruleId: string
  ): Promise<any> => {
    return await this.deleteEntity<any>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/datasets/' +
        datasetId +
        '/rules/' +
        ruleId
    );
  };

  public getRuleResults = async (
    workspaceId: string,
    datasetId: string,
    ruleId: string,
    count = 100
  ): Promise<RuleExecutionResult[]> => {
    let config: AxiosRequestConfig = {
      headers: {
        'x-ms-max-item-count': count, // TODO: do pagination properly
      },
    };
    return await this.getEntity<RuleExecutionResult[]>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/datasets/' +
        datasetId +
        '/rules/' +
        ruleId +
        '/results',
      config
    );
  };

  public postAlert = async (
    workspaceId: string,
    alertRequest: AlertRequest
  ): Promise<string> => {
    return await this.postEntity<AlertRequest, string>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/alerts',
      alertRequest
    );
  };

  public putAlert = async (
    workspaceId: string,
    alert: Alert
  ): Promise<Alert> => {
    return await this.putEntity<Alert>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/alerts',
      alert
    );
  };

  public deleteAlert = async (
    workspaceId: string,
    ruleId: string,
    alertId: string
  ): Promise<any> => {
    return await this.deleteEntity<any>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/rules/' +
        ruleId +
        '/alerts/' +
        alertId
    );
  };

  public executeRule = async (
    workspaceId: string,
    datasetId: string,
    ruleId: string,
    executeRulesRequest: ExecuteRulesRequest
  ): Promise<any> => {
    return await this.postEntity<ExecuteRulesRequest, any>(
      '/v1/workspaces/' +
        workspaceId +
        '/dataQuality/datasets/' +
        datasetId +
        '/rules/' +
        ruleId +
        '/execute',
      executeRulesRequest
    );
  };

  public executeRules = async (
    workspaceId: string,
    executeRulesRequest: ExecuteRulesRequest
  ): Promise<any> => {
    return await this.postEntity<ExecuteRulesRequest, any>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/executeRules',
      executeRulesRequest
    );
  };

  public postDataQualityWorkspace = async (
    workspaceId: string
  ): Promise<any> => {
    return await this.postEntity<any, any>(
      '/v1/workspaces/' + workspaceId + '/dataQuality/workspace',
      ''
    );
  };

  public postDataConnectionValidate = async (
    workspaceId: string,
    dataConnection: FlatDataConnection
  ): Promise<ValidateResult> => {
    return await this.postEntity<FlatDataConnection, ValidateResult>(
      '/v1/workspaces/' + workspaceId + '/dataConnections/validate',
      dataConnection
    );
  };
}

export const dataQualityApi = new DataQualityApi();

export const getDatasets = dataQualityApi.getDatasets;
export const getDataset = dataQualityApi.getDataset;
export const postDataset = dataQualityApi.postDataset;
export const putDataset = dataQualityApi.putDataset;
export const deleteDataset = dataQualityApi.deleteDataset;
export const getConnections = dataQualityApi.getConnections;
export const getRules = dataQualityApi.getRules;
export const getAlerts = dataQualityApi.getAlerts;
export const getRule = dataQualityApi.getRule;
export const postRule = dataQualityApi.postRule;
export const putRule = dataQualityApi.putRule;
export const deleteRule = dataQualityApi.deleteRule;
export const getRuleResults = dataQualityApi.getRuleResults;
export const postAlert = dataQualityApi.postAlert;
export const putAlert = dataQualityApi.putAlert;
export const deleteAlert = dataQualityApi.deleteAlert;
export const executeRule = dataQualityApi.executeRule;
export const executeRules = dataQualityApi.executeRules;
export const postDataQualityWorkspace = dataQualityApi.postDataQualityWorkspace;
export const isNotFoundError = dataQualityApi.isNotFoundError;
export const isConflictError = dataQualityApi.isConflictError;
export const postDataConnectionValidate =
  dataQualityApi.postDataConnectionValidate;

export default dataQualityApi;
