// #region import
import {
  DisplayTypes,
  ElxIconButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxContainerProps,
  ITableAction,
} from '@elixir/fx';
import {
  CheckboxVisibility,
  IColumn,
  Label,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import ErrorMessageBar from 'components/errorMessageBar/errorMessageBar';
import { AuthType } from 'features/dataQuality/models/dataConnection';
import {
  ConnectionDataSourceType,
  DataConnection,
} from 'features/workspaces/models/project';
import { Workspace } from 'features/workspaces/models/workspace';
import {
  selectWorkspace,
  selectWorkspaceId,
} from 'features/workspaces/workspaceSlice';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJobs,
  JobListStatus,
  selectAllJobs,
  selectJobListStatus,
} from '../jobSlice';
import { Activity, Job, JobInstanceStatus } from '../models/job';
import { classNames } from './jobListStyles';
// #endregion
// #region styles
const colSmall = {
  minWidth: 50,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
  maxWidth: 80,
};
const colMedium = {
  minWidth: 100,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
  maxWidth: 130,
};
const colLarge = {
  minWidth: 130,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
  maxWidth: 300,
};

const StringOperators = ['==', '!=', 'Contains', '!Contains', 'Startswith'];

let currId = '';
// #endregion

const renderRunHistory = (item: any, index: any, column: any) => {
  const job: Job = item as Job;
  const instances = job?.instances
    ?.slice(0)
    .sort((a, b) => (a.startTime > b.startTime ? 1 : 0))
    .slice(0, 3);

  return (
    <div style={{ display: 'inline-flex', width: '100%' }}>
      {instances &&
        instances.map((instance) => {
          switch (instance.status) {
            case JobInstanceStatus.SUCCESS:
              return (
                <ElxIconButton
                  //style={float:'right'}
                  styles={{
                    icon: {
                      color: 'green',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  iconProps={{ iconName: 'SkypeCircleCheck' }}
                ></ElxIconButton>
              );
            case JobInstanceStatus.FAILED:
              return (
                <ElxIconButton
                  styles={{
                    icon: {
                      color: 'red',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  iconProps={{ iconName: 'StatusErrorFull' }}
                ></ElxIconButton>
              );
            default:
              return (
                <ElxIconButton
                  styles={{
                    icon: {
                      color: 'grey',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  iconProps={{ iconName: 'CircleShapeSolid' }}
                ></ElxIconButton>
              );
          }
        })}
    </div>
  );
};

function getDataSource(
  id: string,
  workspace: Workspace
): DataConnection | null {
  return (
    workspace?.dataConnections?.find((x) => x.connectionName === id) || null
  );
}

const mergeAuthenticationLabels = (
  prev: string,
  curr: string | null
): string => {
  return !curr ? prev : !prev || prev === curr ? curr : 'Mixed';
};

const describeDataSourceAuthentication = (
  dataSource: DataConnection | null
) => {
  if (!dataSource) return null;

  if (dataSource.type === ConnectionDataSourceType.Cosmos) {
    return dataSource.certificateSecretUri
      ? 'Custom Certificate'
      : 'Lens Certificate';
  }

  if (
    dataSource.type === ConnectionDataSourceType.Sql ||
    dataSource.type === ConnectionDataSourceType.EventHub
  ) {
    if (dataSource.passwordSecretUri) {
      return 'SQL Authentication';
    }
    if (dataSource.connectionSecretUri) {
      return 'Connection String';
    }
    // fall-through (AADApp or User)
  }

  if (dataSource.authType === AuthType.AadApp) {
    return (
      'AAD Application' +
      (dataSource.principalId ? ' (' + dataSource.principalId + ')' : '')
    );
  }

  if (dataSource.authType === AuthType.User) {
    return (
      'User' +
      (dataSource.principalId ? ' (' + dataSource.principalId + ')' : '')
    );
  }

  return null;
};

const JobList: React.FC = () => {
  const workspaceId = useSelector(selectWorkspaceId);
  const workspace = useSelector(selectWorkspace);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (workspaceId === '' || currId === workspaceId) return;
    dispatch(getJobs({ workspaceId: workspaceId }));
    currId = workspaceId;
  }, [workspaceId]);

  const refresh = () => {
    dispatch(getJobs({ workspaceId: workspaceId }));
  };
  const jobList = useSelector(selectAllJobs);
  const jobListStatus = useSelector(selectJobListStatus);

  const getAuthentication = (item: any) => {
    const job: Job = item as Job;
    let result = '';

    result =
      job?.config?.activities?.reduce((result: string, activity: Activity) => {
        let input = getDataSource(activity?.input?.connectionName, workspace);
        let output = getDataSource(activity?.output?.connectionName, workspace);
        let inputDescription = describeDataSourceAuthentication(input);
        let outputDescription = describeDataSourceAuthentication(output);
        result = mergeAuthenticationLabels(result, inputDescription);
        result = mergeAuthenticationLabels(result, outputDescription);
        return result;
      }, '') || '';
    return <span>{result}</span>;
  };

  const columns: IColumn[] = [
    {
      ...colLarge,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
    },
    {
      ...colMedium,
      key: 'lastRun',
      name: 'Last Run',
      onRender: (item: Job) => {
        const instances = item?.instances || [];

        return (
          <span>{instances.length > 0 ? instances[0].createdDate : ''}</span>
        );
      },
    },
    {
      ...colSmall,
      key: 'runHistory',
      name: 'RunHistory',
      onRender: renderRunHistory,
    },
    {
      ...colSmall,
      key: 'state',
      name: 'State',
      fieldName: 'isEnabled',
      onRender: (item) => {
        return <span>{item.isEnabled ? 'Enabled' : 'Disabled'}</span>;
      },
    },
    {
      ...colMedium,
      key: 'lastRun',
      name: 'Last Run',
      onRender: (item: Job) => {
        return (
          <span>
            {(item.isEnabled && item.nextExecutionDate) || 'None Scheduled'}
          </span>
        );
      },
    },
    {
      ...colMedium,
      key: 'authentication',
      name: 'Authentication',
      onRender: getAuthentication,
    },
    {
      ...colMedium,
      key: 'createdBy',
      name: 'CreatedBy',
      fieldName: 'createdBy',
    },
    {
      ...colLarge,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
    },
  ];

  const actions: ITableAction[] = [
    {
      key: 'add',
      text: 'Add',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      onBulkAction: () => {
        console.log(`Handle add action`);
      },
    },
    {
      key: 'edit',
      text: 'Edit',
      defaultDisplay: DisplayTypes.Disabled,
      iconProps: { iconName: 'Edit' },
      onAction: (item) => {
        console.log(`Edit job action ${item}`);
      },
    },
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Refresh' },
      onBulkAction: refresh,
    },
  ];

  const containerProps = {
    headerText: 'Jobs',
    isLoading: false,
  } as IElxContainerProps;

  //   const permissionsPillFilter: FilterOption = {
  //     field: 'accessType',
  //     label: 'Permission',
  //     multiselect: true,
  //     pillMode: FilterOptionPillMode.Dynamic,
  //     values: workspaceList.map(workspace => workspace.accessFilter)
  //         .filter((value, index, self) => self.indexOf(value) === index),
  // };

  const createdByPillFilter: FilterOption = {
    field: 'createdBy',
    label: 'Created By',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: jobList
      .map((job) => job.createdBy)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: jobList
      .map((job) => job.name)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const descriptionPillFilter: FilterOption = {
    field: 'description',
    label: 'Description',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const searchProps = {
    pillFilters: [
      namePillFilter /**
      permissionsPillFilter
    */,
      descriptionPillFilter,
      createdByPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const tableStyles = {
    root: {
      '.ms-DetailsHeader-cellName': {
        fontWeight: '500 !important',
        fontSize: '12px !important',
      },
    },
  };

  return (
    <>
      {jobListStatus === JobListStatus.Error && (
        <ErrorMessageBar
          message={'ERROR GETTING JOBS'}
          onDismiss={() => {}}
        ></ErrorMessageBar>
      )}
      {jobListStatus === JobListStatus.Loaded && (
        <ElxTableContainer
          containerProps={containerProps}
          tableProps={{
            columns,
            actions,
            selectionMode: SelectionMode.single,
            items: jobList,
            checkboxVisibility: CheckboxVisibility.hidden,
            styles: tableStyles,
          }}
          searchBoxProps={searchProps}
        ></ElxTableContainer>
      )}
      {jobListStatus === JobListStatus.Loading && (
        <Stack
          horizontalAlign="center"
          tokens={{ childrenGap: 24, padding: 100 }}
        >
          <Spinner size={SpinnerSize.large} />
          <Label>Loading Jobs...</Label>
        </Stack>
      )}
    </>
  );
};

export default JobList;
