import {
  getTheme,
  mergeStyleSets,
  ITheme,
  ILabelStyles,
} from '@fluentui/react';

const theme: ITheme = getTheme();
const { fonts } = theme;

export const labelStyles: Partial<ILabelStyles> = {
  root: { marginTop: 0, fontSize: '13px' },
};

export const classNames = mergeStyleSets({
  favorite: {
    alignSelf: 'right',
    marginRight: 10,
    color: '#FFB900',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
});
