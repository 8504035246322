import React, { useEffect, useState } from 'react';
import {
  IComboBoxOption,
  IComboBoxStyles,
  ITextFieldStyles,
  Stack,
} from '@fluentui/react';
import { Card } from 'components/cards/card';
import { EditorProps } from '../../base/userExpectation';
import {
  ClassifyResults,
  useClassifyResultsHelpers,
} from '../../../components/classifyResults/classifyResults';
import { ElxCombobox } from '@elixir/fx/lib/components/Combobox';
import { RegExCheckState } from './regExCheckExpectation';
import { getDataSourceClient } from 'features/dataSources/registry';
import {
  KustoConnectionDetails,
  StoreType,
} from 'features/dataQuality/models/dataConnection';
import { KustoDatasetProperties } from 'features/dataQuality/models/dataset';
import { TableNode } from 'features/dataSources/models/schemaTree';
import { RegExType } from 'features/dataQuality/models/rule';
import { ElxTextField } from '@elixir/fx';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';

const RegExCheckEditor = ({
  dataset,
  connection,
  state,
  setState,
}: EditorProps<RegExCheckState>): JSX.Element => {
  const classifyHelpers = useClassifyResultsHelpers(
    state,
    setState,
    StandardUnits.PERCENT
  );

  const dataSourceClient = getDataSourceClient(
    connection.connectionDetails.subType
  );
  const [columnNames, setColumnNames] = useState<string[] | undefined>(
    undefined
  );

  useEffect(() => {
    // TODO: some way for generic fetching of column names for all dataset types.
    if (
      dataSourceClient &&
      columnNames === undefined &&
      connection.connectionDetails.subType === StoreType.Kusto
    ) {
      let kustoConnectionDetails =
        connection.connectionDetails as KustoConnectionDetails;
      let kustoDatasetProperties =
        dataset.datasetProperties as KustoDatasetProperties;
      setColumnNames([]);
      dataSourceClient
        .getSchema(
          kustoConnectionDetails.cluster,
          kustoConnectionDetails.database
        )
        .then(function (schema) {
          if (Array.isArray(schema)) {
            let table = schema?.find(
              (node) => node.name === kustoDatasetProperties.table
            ) as TableNode;
            setColumnNames(table?.children?.map((c) => c.name) || []);
          }
        });
    }
  }, [dataSourceClient, connection, columnNames, dataset.datasetProperties]);

  const columnNameOptions: IComboBoxOption[] =
    columnNames?.map((col) => {
      return {
        key: col,
        text: col,
      };
    }) || [];

  const regExTypeOptions: IComboBoxOption[] =
    Object.keys(RegExType)
      .filter((key) => {
        return key !== RegExType.None;
      })
      .map((key) => {
        return {
          key,
          text: key,
        };
      }) || [];

  // same as <HighlightText /> component
  const styles = {
    input: {
      backgroundColor: '#c0ecf8',
    },
  } as IComboBoxStyles;
  const textFieldStyles = {
    field: {
      fontWeight: 'bolder',
      color: palette.themeDark,
    },
  } as ITextFieldStyles;

  // Must check all column, regEx, and potentially customRegExFormat
  function validateState(newState: RegExCheckState | undefined) {
    if (!newState) {
      return (
        (state?.column?.length || 0) > 0 &&
        (state?.regExType?.length || 0) > 0 &&
        (state?.regExType !== RegExType.Custom ||
          (state?.customRegExFormat?.length || 0) > 0)
      );
    } else {
      return (
        (newState?.column?.length || 0) > 0 &&
        (newState?.regExType?.length || 0) > 0 &&
        (newState?.regExType !== RegExType.Custom ||
          (newState?.customRegExFormat?.length || 0) > 0)
      );
    }
  }

  return (
    <>
      <Card>
        <Stack tokens={{ childrenGap: 16 }}>
          <ElxCombobox
            label={'The column'}
            text={state?.column}
            styles={styles}
            options={columnNameOptions}
            allowFreeform
            onChange={(e, option, i, value) => {
              const column = option?.key?.toString() || value;
              let newState = state;
              if (newState) newState.column = column;
              setState({
                ...state,
                column,
                stateIsReady: validateState(newState),
              });
            }}
          />
          <label>should match this</label>
          <ElxCombobox
            label={'format'}
            text={state?.regExType}
            styles={styles}
            options={regExTypeOptions}
            onChange={(e, option, i, value) => {
              const regExType = (option?.key as RegExType) || RegExType.None;
              let newState = state;
              if (newState) newState.regExType = regExType;
              setState({
                ...state,
                regExType,
                stateIsReady: validateState(newState),
              });
            }}
          />
          {state?.regExType === RegExType.Custom && (
            <ElxTextField
              label={'custom regular expression'}
              value={state?.customRegExFormat || ''}
              styles={textFieldStyles}
              onChange={(e, value) => {
                const customRegExFormat = value;
                let newState = state;
                if (newState) newState.customRegExFormat = customRegExFormat;
                setState({
                  ...state,
                  customRegExFormat,
                  stateIsReady: validateState(newState),
                });
              }}
            />
          )}
          <p>
            You can set thresholds for percentages of values that{' '}
            <HighlightText>do not</HighlightText> match the regular expression.
          </p>
        </Stack>
      </Card>
      <ClassifyResults {...classifyHelpers} />
    </>
  );
};

export default RegExCheckEditor;
