import { decodeSeverity } from 'features/dataQuality/components/classifyResults/threshold';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';
import { KustoDatasetProperties } from 'features/dataQuality/models/dataset';
import RuleExecutionResult, {
  RowCountCheckResult,
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import { getFailedExpectations } from 'features/dataQuality/utils/dataQualityUtils';
import {
  ResultsTableDescription,
  UserExpectationResultsBuilder,
  UserExpectationResultsDescription,
} from '../../base/userExpectationResultsBuilder';

export class RowCountCheckResultsBuilder extends UserExpectationResultsBuilder {
  public static getResultsDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    const ruleResult = ruleExecutionResult.ruleResult as RowCountCheckResult;
    const tableName = (
      ruleExecutionResult.ruleDataSet
        .datasetProperties as KustoDatasetProperties
    ).table;
    const rowCount = ruleResult.rowCount;
    const unit = StandardUnits.INTEGER;

    let retVal: UserExpectationResultsDescription = {
      expectationDescription: `${tableName} must have a specific number of rows`,
      thresholdValueDescriptions: [],
    };

    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const severity = decodeSeverity(failedExpectation);
      const severityString = severity
        ? `failed with Sev ${severity}`
        : 'failed';

      retVal.outcomeSummary = `Evaluation ${severityString} because there were ${rowCount} rows in the ${tableName} table.`;
      retVal.failureValue = rowCount;
      retVal.failureValueDescription = `Table ${tableName} contained ${rowCount} rows.`;
      retVal.thresholdValueDescriptions = [
        UserExpectationResultsBuilder.describeStaticRangeCondition(
          failedExpectation,
          unit,
          rowCount
        ),
      ];
    } else {
      retVal.outcomeSummary = `Evaluation passed with ${rowCount} rows in the ${tableName} table.`;
      retVal.thresholdValueDescriptions.push(
        ...UserExpectationResultsBuilder.describeNearbySuccededThresholds(
          ruleExecutionResult,
          unit,
          rowCount
        )
      );
    }

    return retVal;
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [
        {
          ...this.colDefaults,
          minWidth: 120,
          maxWidth: 130,
          key: 'ExecutionDate',
          name: 'Execution Date',
          fieldName: 'date',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'RowCount',
          name: 'Row Count',
          fieldName: 'rowCount',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'Result',
          name: 'Result',
          fieldName: 'result',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'RangeStart',
          name: 'Range Start',
          fieldName: 'rangeStart',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          key: 'RangeEnd',
          name: 'Range End',
          fieldName: 'rangeEnd',
        },
      ],
      items: ruleExecutionResults.map((ruleExecutionResult, index) => {
        const item = {
          key: index.toString(),
          date: UserExpectationResultsBuilder.getShorterDate(
            ruleExecutionResult.ruleExecutionStartTime
          ),
        };

        if (
          ruleExecutionResult.ruleResult.subType === RuleResultType.ErrorResult
        ) {
          return { ...item, result: 'Error' };
        }

        const ruleResult =
          ruleExecutionResult.ruleResult as RowCountCheckResult;
        const rowCount = ruleResult.rowCount;
        const { result, rangeStart, rangeEnd } =
          UserExpectationResultsBuilder.calcResultRange(
            ruleExecutionResult,
            rowCount
          );

        return {
          ...item,
          rowCount,
          result,
          rangeStart,
          rangeEnd,
        };
      }),
    };
  }
}

export default RowCountCheckResultsBuilder;
