import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouter } from '@uirouter/react';
import { ElxDialog } from '@elixir/fx';

/**
 * Blocker dialog to prevent users from using forbidden routes
 */
const RouteBlockerDialog = (props: {}): JSX.Element => {
  const history = useHistory();
  const router = useRouter();
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState<string>('');

  useEffect(() => {
    setCurrentLocation(location.pathname || '');
  }, [location]);

  let isDev = localStorage.getItem('toggleDevelopment');
  if (isDev === undefined) {
    isDev = 'false';
  }
  const showBlockedDialog =
    isDev !== 'true' &&
    (currentLocation?.startsWith('/discover') ||
      currentLocation?.startsWith('/dashboard'));

  return (
    <ElxDialog
      maxWidth="540px"
      hidden={!showBlockedDialog}
      dismissable={false}
      dialogContentProps={{
        showCloseButton: false,
        title: 'Thanks for trying the Lens Data Quality Experience',
      }}
      cancelButtonText="Switch to User Settings"
      onCancelButtonClick={() =>
        router.stateService.go('app.manage.settings.features')
      }
      primaryButtonText="Switch to Datasets"
      onPrimaryButtonClick={() => history.push('/dataquality/datasets')}
    >
      <p>
        The new Lens UI is currently limited to Data Quality workflows only.
        Please return to the Datasets page, or you can switch back to classic
        Lens UI in the User Settings page.
      </p>
    </ElxDialog>
  );
};

export default RouteBlockerDialog;
