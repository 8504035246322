import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { Job, JobConfig, JobExecutionStatus } from './models/job';
import jobApi from './api/jobApi';
import notifier from 'utils/notifier';
import { RootState } from 'app/lensShellUtility';

export enum JobListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}
interface Orchestrator {
  jobList: Job[];
  jobListStatus: JobListStatus;
  jobCurrent: string | null;
}

const initialConfig: JobConfig = {
  jobName: '',
  jobDisplayName: '',
  jobDescription: '',
  tags: '',
  maxJobInstances: 1,
  retryPolicy: { type: '' },
  isDebug: false,
  appendUtcIndicator: false,
  allocationType: '',
  jobScheduleSettings: { scheduleType: '' },
  parameters: [],
  defaultICMAlerts: false,
  activities: [],
  project: '',
  // context: JobContext,
  // context: {},
};

const initialJob: Job = {
  id: '',
  name: '',
  workspace: '',
  description: '',
  tags: '',
  // config: {},
  createdBy: '',
  createdDate: '',
  nextExecutionDate: '',
  modifiedBy: '',
  modifiedDate: '',
  version: '',
  isEnabled: true,
  isQuarantined: true,
  instances: [],
  shareType: '',
  partitionKey: '',
  rowKey: '',
  timestamp: '',
  eTag: '',
};

//#region thunks
export const rerunJobInstance = createAsyncThunk(
  'job/rerunJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.rerunJobInstance(workspaceId, jobId, instanceId);
  }
);

export const terminateJobInstance = createAsyncThunk(
  'job/terminateJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.terminateJobInstance(workspaceId, jobId, instanceId);
  }
);

export const cancelJobInstances = createAsyncThunk(
  'job/cancelJobInstances',
  async ({
    workspaceId,
    jobId,
    listType,
  }: {
    workspaceId: string;
    jobId: string;
    listType: JobExecutionStatus;
  }) => {
    return await jobApi.cancelJobInstances(workspaceId, jobId, listType);
  }
);

export const cancelJobInstance = createAsyncThunk(
  'job/cancelJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.cancelJobInstance(workspaceId, jobId, instanceId);
  }
);

export const scheduleJob = createAsyncThunk(
  'job/scheduleJob',
  async ({
    workspaceId,
    jobId,
    settings,
  }: {
    workspaceId: string;
    jobId: string;
    settings: any | null;
  }) => {
    return await jobApi.scheduleJob(workspaceId, jobId, settings);
  }
);

export const getJobInstanceLogs = createAsyncThunk(
  'job/getJobInstanceLogs',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.getJobInstanceLogs(workspaceId, jobId, instanceId);
  }
);

export const getJobInstance = createAsyncThunk(
  'job/getJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.getJobInstance(workspaceId, jobId, instanceId);
  }
);

export const getJobInstanceTags = createAsyncThunk(
  'job/getJobInstanceTags',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobInstanceTags(workspaceId, jobId);
  }
);

export const getJobInstances = createAsyncThunk(
  'job/getJobInstances',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobInstances(workspaceId, jobId);
  }
);

export const getDependencyEvents = createAsyncThunk(
  'job/getDependencyEvents',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getDependencyEvents(workspaceId, jobId);
  }
);

export const disableJob = createAsyncThunk(
  'job/disableJob',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.disableJob(workspaceId, jobId);
  }
);

export const enableJob = createAsyncThunk(
  'job/enableJob',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.enableJob(workspaceId, jobId);
  }
);

export const updateJob = createAsyncThunk(
  'job/updateJob',
  async ({
    workspaceId,
    jobId,
    job,
  }: {
    workspaceId: string;
    jobId: string;
    job: Job;
  }) => {
    return await jobApi.updateJob(workspaceId, jobId, job);
  }
);

export const getJobVersions = createAsyncThunk(
  'job/getJobVersions',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobVersions(workspaceId, jobId);
  }
);

export const getJobWithVersionNumber = createAsyncThunk(
  'job/getJobWithVersionNumber',
  async ({
    workspaceId,
    jobId,
    versionNumber,
  }: {
    workspaceId: string;
    jobId: string;
    versionNumber: string;
  }) => {
    return await jobApi.getJobWithVersionNumber(
      workspaceId,
      jobId,
      versionNumber
    );
  }
);

export const getJobs = createAsyncThunk(
  'job/getJobs',
  async ({ workspaceId }: { workspaceId: string }) => {
    return await jobApi.getJobs(workspaceId);
  }
);

export const getJob = createAsyncThunk(
  'job/get',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJob(workspaceId, jobId);
  }
);

export const createJob = createAsyncThunk(
  'job/create',
  async ({ workspaceId, job }: { workspaceId: string; job: Job }) => {
    return await jobApi.createJob(workspaceId, job);
  }
);

export const deleteJob = createAsyncThunk(
  'job/delete',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.deleteJob(workspaceId, jobId);
  }
);
//#endregion thunks

const jobListAdapter = createEntityAdapter<Job>({ selectId: (Job) => Job.id });
const initialJobState = {
  jobList: jobListAdapter.getInitialState({
    jobListStatus: JobListStatus.None,
    currentJobId: null,
  }),
};
const jobSlice = createSlice({
  name: 'job',
  initialState: initialJobState,
  reducers: {
    // TODO - Nambi needs to see what should be the action for reset.
    reset: (state) => initialJobState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteJob.pending, (state, action) => {
        notifier.info(`delete job pending`);
      })
      .addCase(deleteJob.rejected, (state, action) => {
        notifier.error(`Job cannot be deleted`);
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        notifier.info(`Job deletion successful`);
      })
      .addCase(createJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(createJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(createJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobs.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
        state.jobList.jobListStatus = JobListStatus.Loading;
      })
      .addCase(getJobs.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
        state.jobList.jobListStatus = JobListStatus.Error;
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
        jobListAdapter.setAll(state.jobList, action.payload);
        state.jobList.jobListStatus = JobListStatus.Loaded;
      })
      .addCase(getJobWithVersionNumber.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobWithVersionNumber.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobWithVersionNumber.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobVersions.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobVersions.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobVersions.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(updateJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(updateJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(enableJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(enableJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(enableJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(disableJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(disableJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(disableJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getDependencyEvents.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getDependencyEvents.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getDependencyEvents.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobInstances.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobInstances.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstances.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobInstanceTags.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobInstanceTags.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstanceTags.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobInstance.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(getJobInstanceLogs.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(getJobInstanceLogs.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstanceLogs.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(scheduleJob.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(scheduleJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(scheduleJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(cancelJobInstance.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(cancelJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(cancelJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(cancelJobInstances.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(cancelJobInstances.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(cancelJobInstances.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(terminateJobInstance.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(terminateJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(terminateJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      })
      .addCase(rerunJobInstance.pending, (state, action) => {
        notifier.info(`${action.type} pending`);
      })
      .addCase(rerunJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(rerunJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful - NEED TO HANDLE`);
      });
  },
});

export const {
  selectAll: selectAllJobs,
  selectEntities: selectJobListEntities,
  selectById: selectJobById,
  selectIds: selectJobIds,
} = jobListAdapter.getSelectors((state: RootState) => state.job.jobList);

export const selectJobListStatus = (state: RootState) =>
  state.job.jobList.jobListStatus;

export const { reset } = jobSlice.actions;

export default jobSlice.reducer;
