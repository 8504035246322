import userUtils from 'utils/userUtils';
import {
  Workspace,
  WorkspaceBeforeFormat,
} from 'features/workspaces/models/workspace';

let leApp: any; // AngularJS dependency - minimize use

/**
 * Gets the current workspace. Ensures that the workspace is a real workspace object retrieved
 * from the backend (not null or a dummy object for the private workspace).
 * @returns A promise that returns the current workspace.
 */

export function getCurrentWorkspaceId(): string | undefined {
  return leApp?.getCurrentWorkspaceId();
}

/**
 * Handles workspace switch events.
 * @param handler - The event handler.
 * @returns The deregistration function for the handler.
 */
export const onWorkspaceSwitch = (
  handler: (event: any, workspace: any) => void
): (() => void) | undefined => {
  return leApp?.onWorkspaceSwitch(handler);
};

export const getPrivateWorkspaceId = () => `private-${userUtils.getAlias()}`;

/**
 * Switches to a workspace of share type 'shared' or to private if now share type is provided.
 * @param {string} alias - alias of the workspace to switch to.  null will switch to private workspace
 * @param {boolean} checkOnly - a flag indicating whether workspace switch should happen, or just perform permission checks
 * @param {boolean} force - Forces the workspace to be reloaded.
 * @returns {Promise} - A promise returning the workspace.
 */
export const switchToWorkspace = (
  alias: string | null,
  checkOnly: boolean = false,
  force: boolean = false
): Promise<any> => {
  return leApp?.switchToWorkspace(alias, checkOnly, force);
};

export function inject(_leApp: any) {
  leApp = _leApp;
}

const formatData = (field: string) => {
  return typeof field === 'string' && field !== '' ? field?.split(',') : [];
};

/**
 * formats the workspace object from api call to a workspace object with values more appropriate to modification
 * @param workspace workspace object from the api call
 * @returns new workspace object where the string values representing lists are turn into js arrays
 */
export const formatWorkspaceForUse = (
  workspace: WorkspaceBeforeFormat
): Workspace => {
  let {
    id,
    name,
    alias,
    dsoProject,
    accessFilter,
    createdBy,
    applications,
    dataConnections,
    lastUpdated,
    description,
    isReadOpen,
    shareType,
    dqsWorkspace,
    lastUpdatedBy,
  } = workspace;

  let workspaceReturn: Workspace = {
    id: id,
    name: name,
    alias: alias,
    dsoProject: dsoProject,
    applications: applications,
    dataConnections: dataConnections,
    createdBy: createdBy,
    accessFilter: accessFilter,
    lastUpdated: lastUpdated,
    description: description,
    lastUpdatedBy: lastUpdatedBy,
    isReadOpen: isReadOpen,
    shareType: shareType,
    dqsWorkspace: dqsWorkspace,
    tags: [],
    adminGroups: [],
    adminAliases: [],
    orchestrateGroups: [],
    orchestrateAliases: [],
    readOnlyGroups: [],
    readOnlyAliases: [],
    readWriteGroups: [],
    readWriteAliases: [],
  };

  if (workspace.shareType === 'shared') {
    workspaceReturn.tags =
      typeof workspace.tags === 'string' ? JSON.parse(workspace.tags) : [];
    workspaceReturn.adminGroups = formatData(workspace.adminGroups);
    workspaceReturn.adminAliases = formatData(workspace.adminAliases);
    workspaceReturn.orchestrateGroups = formatData(workspace.orchestrateGroups);
    workspaceReturn.orchestrateAliases = formatData(
      workspace.orchestrateAliases
    );
    workspaceReturn.readOnlyGroups = formatData(workspace.readOnlyGroups);
    workspaceReturn.readOnlyAliases = formatData(workspace.readOnlyAliases);
    workspaceReturn.readWriteGroups = formatData(workspace.readWriteGroups);
    workspaceReturn.readWriteAliases = formatData(workspace.readWriteAliases);
  }
  return workspaceReturn;
};

/**
 * Revert the changes that were done to values of some workspace field in the formatWorkspaceForUse function
 * @param workspace Workspace object with arrays as values for some field , easy for modification
 * @returns workspace in the format that was returned from the api
 */
export const formatWorkspaceForSave = (
  workspace: Workspace
): WorkspaceBeforeFormat => {
  let {
    id,
    name,
    alias,
    dsoProject,
    accessFilter,
    createdBy,
    applications,
    dataConnections,
    lastUpdated,
    description,
    isReadOpen,
    shareType,
    dqsWorkspace,
    lastUpdatedBy,
  } = workspace;

  let workspaceReturn: WorkspaceBeforeFormat = {
    id: id,
    name: name,
    alias: alias,
    dsoProject: dsoProject,
    applications: applications,
    dataConnections: dataConnections,
    createdBy: createdBy,
    accessFilter: accessFilter,
    lastUpdated: lastUpdated,
    description: description,
    lastUpdatedBy: lastUpdatedBy,
    isReadOpen: isReadOpen,
    shareType: shareType,
    dqsWorkspace: dqsWorkspace,
    tags: '',
    adminGroups: '',
    adminAliases: '',
    orchestrateGroups: '',
    orchestrateAliases: '',
    readOnlyGroups: '',
    readOnlyAliases: '',
    readWriteGroups: '',
    readWriteAliases: '',
  };
  workspaceReturn.tags = JSON.stringify(workspace.tags);
  workspaceReturn.adminGroups = workspace.adminGroups?.toString();
  workspaceReturn.adminAliases = workspace.adminAliases?.toString();
  workspaceReturn.orchestrateGroups = workspace.orchestrateGroups?.toString();
  workspaceReturn.orchestrateAliases = workspace.orchestrateAliases?.toString();
  workspaceReturn.readOnlyGroups = workspace.readOnlyGroups?.toString();
  workspaceReturn.readOnlyAliases = workspace.readOnlyAliases?.toString();
  workspaceReturn.readWriteGroups = workspace.readWriteGroups?.toString();
  workspaceReturn.readWriteAliases = workspace.readWriteAliases?.toString();
  return workspaceReturn;
};
