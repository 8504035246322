import { ILabelStyles, IStackStyles, IStyleSet, ITheme } from '@fluentui/react';
import { CSSProperties } from 'react';

export const usePivotContainerStyles = (theme: ITheme): IStackStyles => {
  return {
    root: {
      left: '1px',
      right: '-2px',
      top: '0px',
      bottom: '0px',
      background: theme.palette.white,
    },
  };
};
export const useWorkspaceNameStyle = (theme: ITheme): CSSProperties => {
  return {
    height: '52px',
    left: ' 25px',
    top: '14px',

    /* Page Title (H1), KPIs */

    fontFamily: 'Segoe UI',
    fontSize: '22px',
    lineHeight: '32px',
    /* or 145% */

    display: 'flex',
    alignItems: ' center',
    color: theme.palette.neutralPrimary,
  };
};
export const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { marginTop: 10 },
};
