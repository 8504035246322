import DataConnection, { StoreType } from './dataConnection';

export { StoreType };

export interface DatasetSchema {
  name?: string | null;
  type?: string | null;
}

export interface DatasetProperties {
  subType: StoreType;
}

export interface KustoDatasetProperties extends DatasetProperties {
  subType: StoreType.Kusto;
  table?: string | null;
  schema?: DatasetSchema[] | null;
  query?: string | null;
}

export enum DataCatalogType {
  None = 'None',
  GenevaCatalog = 'GenevaCatalog',
}

export interface Dataset {
  datasetProperties: DatasetProperties | KustoDatasetProperties;
  dataCatalogType: DataCatalogType;
  entityName?: string | null;
  workspaceName: string;
  workspaceId: string;
  dataConnectionName: string;
  dataConnection?: DataConnection | null; // included in RuleExecutionResult
  datasetName: string;
  id: string;
  deleted: boolean;
}

export interface DatasetRequest {
  id?: string;
  workspaceId: string;
  workspaceName: string;
  dataConnectionName: string;
  datasetName: string;
  datasetProperties?: DatasetProperties | KustoDatasetProperties;
  // Other DQP properties that we don't need on UI
  [propName: string]: any;
}

export default Dataset;
