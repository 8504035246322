import { RuleFrequency, RuleSchedule } from 'features/dataQuality/models/rule';
import {
  Action,
  ActionType,
  Email,
  Expectation,
  IcM,
  SupportedActionType,
} from 'features/dataQuality/models/alert';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { EditNotificationState } from './editNotification';
import { v4 as uuidv4 } from 'uuid';
import { decodeSeverity } from '../classifyResults/threshold';
import { getDefaultAlertForExpectation } from 'features/dataQuality/utils/dataQualityUtils';

function scheduleHasChanged(
  prevSchedule: RuleSchedule,
  state: EditNotificationState
) {
  // We expend some effort to retain the original start dateTime if the user is not altering the schedule time
  const prevStartTime =
    (!!prevSchedule?.startTime && new Date(prevSchedule.startTime)) ||
    undefined;
  const newStartTime = state.scheduleTime;

  const startTimeChanged =
    prevStartTime?.getUTCHours() !== newStartTime.hours ||
    prevStartTime?.getUTCMinutes() !== newStartTime.minutes ||
    prevStartTime?.getUTCSeconds() !== newStartTime.seconds;

  const frequencyChanged = prevSchedule?.frequency !== state.scheduleFrequency;

  const weekdayChanged =
    prevSchedule?.frequency === RuleFrequency.Week &&
    state.scheduleFrequency === RuleFrequency.Week &&
    prevStartTime?.getUTCDay() !== state.scheduleWeekday;

  return startTimeChanged || frequencyChanged || weekdayChanged;
}

function generateActions(
  expectations: Expectation[],
  state: EditNotificationState
) {
  let actions: Action[] = [];

  expectations.forEach((expectation) => {
    let actionTypes: ActionType[] = [];

    if (state.emailEnabled) {
      actionTypes.push({
        supportedActionType: SupportedActionType.Email,
        id: uuidv4(),
        to: state.emailTo.split(/[ ,;]+/).map((address) => {
          return { address };
        }),
        cc:
          (state.emailCc?.length > 0 &&
            state.emailCc.split(/[ ,;]+/).map((address) => {
              return { address };
            })) ||
          undefined,
      } as Email);
    }
    if (state.icmEnabled) {
      const severity = decodeSeverity(expectation);
      if (severity !== undefined && state.icmSeverities.includes(severity)) {
        actionTypes.push({
          supportedActionType: SupportedActionType.IcM,
          id: uuidv4(),
          incidentSeverity: severity,
          serviceName: state.icmServiceName,
        } as IcM);
      }
    }
    actions.push({
      id: uuidv4(),
      expectationId: expectation.id,
      actionTypes,
    });
  });

  return actions;
}

function computeNextAvailableStartDate(state: EditNotificationState) {
  let startTime = new Date();
  startTime.setUTCMilliseconds(0);
  startTime.setUTCSeconds(0);

  // compute the next closest real date that matches the requested frequency
  startTime.setUTCMinutes(state.scheduleTime.minutes || 0);

  if (
    state.scheduleFrequency === RuleFrequency.Day ||
    state.scheduleFrequency === RuleFrequency.Week
  ) {
    startTime.setUTCHours(state.scheduleTime.hours || 0);
  }

  // adjusting weekday, compute minimal day count to hit the next weekday matching requested weekdaay and then add it to current start time
  if (state.scheduleFrequency === RuleFrequency.Week) {
    startTime.setUTCDate(
      ((7 + state.scheduleWeekday - startTime.getUTCDay()) % 7) +
        startTime.getUTCDate()
    );
  }

  // adjust the computed initial start time so that it is in the future
  while (startTime.getTime() - Date.now() < 10 * 60 * 1000) {
    switch (state.scheduleFrequency) {
      case RuleFrequency.Hour:
        startTime.setUTCMinutes(startTime.getUTCMinutes() + 60);
        break;
      case RuleFrequency.Day:
        startTime.setUTCHours(startTime.getUTCHours() + 24);
        break;
      case RuleFrequency.Week:
        startTime.setUTCDate(startTime.getUTCDate() + 7);
        break;
      default:
        break;
    }
  }
  return startTime;
}

export function buildNotificationExpectationInfo(
  state: EditNotificationState,
  prevExpectationInfo: ExpectationInfo
): ExpectationInfo {
  if (!prevExpectationInfo?.rule)
    throw new Error(
      'Unexpected Error: Trying to update Notification for an undefined rule'
    );

  let newRule = { ...prevExpectationInfo?.rule };

  const prevSchedule = prevExpectationInfo?.rule?.schedule;

  if (prevSchedule && !state.scheduleEnabled) {
    newRule.schedule = undefined;
  } else if (
    (!prevSchedule && state.scheduleEnabled) ||
    (prevSchedule && scheduleHasChanged(prevSchedule, state))
  ) {
    let startTime = computeNextAvailableStartDate(state);

    newRule.schedule = {
      startTime: startTime.toISOString().replace(/\.000/, ''),
      frequency: state.scheduleFrequency,
      frequencyValue: '1',
    };
  }

  let prevAlert = getDefaultAlertForExpectation(prevExpectationInfo);

  if (!prevAlert)
    throw new Error(
      'Unexpected Error: Trying to update Notification for a rule with no alert set'
    );

  let newAlert = {
    ...prevAlert,
    actions: generateActions(prevAlert.expectations, state),
  };

  return {
    rule: newRule,
    alerts: [newAlert],
  };
}
