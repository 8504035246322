import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/lensShellUtility';
import { supportNotificationEntity } from './azureNotifications/models/supportNotificationEntity';
import { Notification } from './activityLogs/models/notification';

interface NotificationSliceState {
  notifications: {
    activityLog: Notification[];
    supportNotification: supportNotificationEntity[];
  };
}

const initialNotificationsState: NotificationSliceState = {
  notifications: {
    activityLog: [],
    supportNotification: [],
  },
};

const createActivityLogObj = (message: any, type: string, id: number) => {
  return {
    id: id,
    title: typeof message === 'string' ? message : message?.message,
    type: type,
    time: new Date().toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    }),
    description:
      typeof message !== 'string' ? JSON.stringify(message, null, 2) : message,
  };
};
const addNotification = (state: any, type: string, message: any) => {
  return [
    ...state.notifications.activityLog,
    createActivityLogObj(message, type, state.notifications.activityLog.length),
  ];
};
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationsState,
  reducers: {
    error: (state, action) => {
      state.notifications.activityLog = addNotification(
        state,
        'error',
        action.payload
      );
    },
    info: (state, action: PayloadAction<string>) => {
      state.notifications.activityLog = addNotification(
        state,
        'info',
        action.payload
      );
    },
    warning: (state, action) => {
      state.notifications.activityLog = addNotification(
        state,
        'warning',
        action.payload
      );
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications.activityLog = state.notifications.activityLog.filter(
        (item) => item.id !== action.payload
      );
    },
    dismissActivityLog: (state, action: PayloadAction<string>) => {
      if (action.payload === 'dimissall') {
        state.notifications.activityLog = [];
        return;
      }
      state.notifications.activityLog = state.notifications.activityLog.filter(
        (item) => item.type !== action.payload
      );
    },
    addSupportNotification: (
      state,
      action: PayloadAction<supportNotificationEntity[]>
    ) => {
      state.notifications.supportNotification = [...action.payload];
    },
    updateSupportNotificationObj: (state, action: PayloadAction<string>) => {
      const supportNotificationCopy = [
        ...state.notifications.supportNotification,
      ];
      const index = supportNotificationCopy.findIndex(
        (item) => item.Id === action.payload
      );
      if (index !== -1) {
        supportNotificationCopy[index] = {
          ...supportNotificationCopy[index],
          Show: false,
        };
        state.notifications.supportNotification = supportNotificationCopy;
      }
    },
    showHideSupportNotification: (state, action: PayloadAction<boolean>) => {
      const supportNotificationCopy =
        state.notifications.supportNotification.map((item) => ({
          ...item,
          Show: action.payload,
        }));
      state.notifications.supportNotification = supportNotificationCopy;
    },
  },
});

export const notificationObj = (state: RootState) =>
  state.notifications.notifications;

export const allActivityLogs = (state: RootState) =>
  state.notifications.notifications.activityLog;

export const allSupportNotifications = (state: RootState) =>
  state.notifications.notifications.supportNotification;

export const uiNotifier = notificationsSlice.actions;
export default notificationsSlice.reducer;
