import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { framework } from '@elixir/fx/lib/framework/store/Reducers';

import userprofileReducer from 'features/userprofile/userprofileSlice';
import workspaceReducer from 'features/workspaces/workspaceSlice';
import dataQualityReducer from 'features/dataQuality/dataQualitySlice';
import catalogReducer from 'features/catalog/catalogSlice';
import workspaceListReducer from 'features/workspaces/workspaceListSlice';
import notificationReducer from 'features/notifications/notificationsSlice';
import jobListReducer from 'features/orchestrator/jobSlice';

export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history), // This reducer is needed for Elixir breadcrumbs
  userprofile: userprofileReducer,
  workspace: workspaceReducer,
  dataQuality: dataQualityReducer,
  workspaceList: workspaceListReducer,
  catalog: catalogReducer,
  notifications: notificationReducer,
  job: jobListReducer,
  framework, // This reducer is needed for Elixir UI state managmenet.
});

export default reducer;
