import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Workspaces from '../components/workspaces';
import EditWorkspace from './editWorkspace/editWorkspace';

/**
 * Data Quality landing page and container component
 * @param props
 * @returns
 */
export const ManageWorkspaces = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Route
          path="/workspaces/edit/:workspaceAlias"
          exact={true}
          component={() => <EditWorkspace />}
        />
        <Route exact path="/workspaces">
          <Redirect to="/workspaces/manage" />
        </Route>

        <Route path="/workspaces/manage" component={Workspaces} />
      </Switch>
    </>
  );
};

export default ManageWorkspaces;
