import React from 'react';
import UserExpectation from '../../base/userExpectation';
import { getClassifyResultsState } from '../../../components/classifyResults/classifyResults';
import {
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
  UnexpectedValueCheckCondition,
} from 'features/dataQuality/models/rule';
import UnexpectedValueCheckEditor from './unexpectedValueCheckEditor';
import { UnexpectedValueCheckBuilder } from './unexpectedValueCheckBuilder';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import { registerUserExpectation } from '../../userExpectationsRegistry';
import UnexpectedValueCheckResultsBuilder from './unexpectedValueCheckResultsBuilder';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export interface UnexpectedValueCheckState extends EditExpectationState {
  column?: string;
  unexpectedValues?: string[];
}

class UnexpectedValueCheckExpectation extends UserExpectation<UnexpectedValueCheckState> {
  id = 'UnexpectedValueCheck';
  description = 'Column values must be one of these values';
  category = SystemDefinedRuleCategory.Accuracy;
  subCategory = SystemDefinedRuleSubCategory.UnexpectedValueCheck;
  ruleType = RuleType.PreDefined;
  ruleConditionType = RuleConditionType.UnexpectedValueCheckCondition;

  getDescriptionElement = (
    state?: UnexpectedValueCheckState,
    dataset?: Dataset
  ) => {
    const columnName = state?.column || 'Column';
    const values = state?.unexpectedValues?.join(', ') || 'values';
    return (
      <span title={columnName + ' values should be one of these ' + values}>
        <HighlightText>{columnName}</HighlightText> values should be one of
        these <HighlightText>{values}</HighlightText>
      </span>
    );
  };

  Editor = UnexpectedValueCheckEditor;

  getInitialState(
    expectationInfo?: ExpectationInfo
  ): UnexpectedValueCheckState {
    const columns2check = (
      expectationInfo?.rule?.details
        .ruleCondition as UnexpectedValueCheckCondition
    )?.unexpectedValueChecks;
    const column =
      columns2check && columns2check.length > 0
        ? columns2check[0].sourceColumn?.columnName
        : undefined;
    const unexpectedValues =
      columns2check && columns2check.length > 0
        ? [...(columns2check[0].values || [])]
        : [];

    return {
      ...getClassifyResultsState(expectationInfo),
      column,
      unexpectedValues,
      stateIsReady: (column?.length || 0) > 0,
    };
  }

  public getUnit(expectationInfo?: ExpectationInfo) {
    return StandardUnits.PERCENT;
  }

  builderFactory = (
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo
  ): UnexpectedValueCheckBuilder =>
    new UnexpectedValueCheckBuilder(
      this,
      dataset,
      connection,
      prevExpectationInfo
    );

  getResultDescription =
    UnexpectedValueCheckResultsBuilder.getResultDescription;
  getResultsTable = UnexpectedValueCheckResultsBuilder.getResultsTable;
}

registerUserExpectation(new UnexpectedValueCheckExpectation());
