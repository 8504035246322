import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { getCatalogEntityByName } from './api/catalogApi';
import { CatalogEntity } from './models/catalogEntity';
import { notifier } from 'utils/notifier';
import { RootState } from 'app/lensShellUtility';

/**
 * Custom hook to use Data Catalog Entities
 */
export const useCatalogEntity = (name?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (name) {
      dispatch(loadCatalogEntity(name));
    }
  }, [dispatch, name]);
};

const loadCatalogEntity = createAsyncThunk(
  'catalog/loadCatalogEntities',
  async (name: string) => {
    return await getCatalogEntityByName(name);
  }
);

const catalogEntitiesAdapter = createEntityAdapter<CatalogEntity>({
  selectId: (entity) => entity.Name,
});

const initialState = {
  catalogentities: catalogEntitiesAdapter.getInitialState(),
};

/**
 * Redux slice representing Data Catalog Entities that are loaded on demand.
 */
const datasetsSlice = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCatalogEntity.fulfilled, (state, action) => {
        if (action.payload) {
          catalogEntitiesAdapter.upsertOne(
            state.catalogentities,
            action.payload
          );
        }
      })
      .addCase(loadCatalogEntity.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

export const {
  selectAll: selectAllCatalogEntities,
  selectEntities: selectCatalogEntityEntities,
  selectById: selectCatalogEntityByName,
  selectIds: selectCatalogEntityNames,
} = catalogEntitiesAdapter.getSelectors(
  (state: RootState) => state.catalog.catalogentities
);

export default datasetsSlice.reducer;
