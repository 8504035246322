import React, { useState } from 'react';
import { DefaultButton } from '@fluentui/react';
import { ElxContainer } from '@elixir/fx';
import { createDataQualityWorkspace } from '../../dataQualitySlice';
import {
  loadProject,
  loadWorkspace,
  selectWorkspace,
  enableOrchestration,
} from 'features/workspaces/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { AppDispatch } from 'app/lensShellUtility';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel,
  panelDefaults,
} from 'components/panelManager/panelManager';
import WorkspaceQuickSwitch from 'features/workspaces/components/workspaceQuickSwitch';
import { SidePanel } from '../../../shell/components/sidebar/lensSidebar';

/**
 * This component would display the Enable DQ banner if the user does not have Data Quality Enabled for the current workspace
 * @param props
 * @returns
 */

const enableButtonStyles = {
  root: {
    marginLeft: '100px',
    width: '30px',
  },
};

const switchButtonStyles = {
  root: {
    marginLeft: '100px',
  },
  textContainer: {
    width: '120px',
  },
};

export const EnableDQ = (): JSX.Element => {
  const { palette } = useLensShellTheme();
  const borderStyle = {
    paddingBottom: '18px',
    borderBottom: '1px solid ' + palette.neutralLighter,
  };
  const workspace = useSelector(selectWorkspace);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const panelState = usePanelManagerState();

  function enableDQWorkspace() {
    dispatch(createDataQualityWorkspace(workspace.id)).then(() => {
      dispatch(loadWorkspace());
      dispatch(loadProject());
      setIsLoading(false);
    });
  }

  async function onClick() {
    try {
      setIsLoading(true);
      if (!workspace.dsoProject || workspace.dsoProject === '') {
        dispatch(enableOrchestration(workspace.id)).then(() => {
          enableDQWorkspace();
        });
      } else {
        enableDQWorkspace();
      }
    } finally {
    }
  }

  function onClickSwitchWorkspace() {
    onShowPanel(panelState, SidePanel.WORKSPACESELECTOR, true);
  }

  return (
    <>
      <ElxContainer headerText="Datasets" isLoading={isLoading}>
        {' '}
        {workspace.shareType !== 'private' && (
          <p style={borderStyle}>
            {
              'Data Quality feature is not enabled for this workspace. To enable it, click on the enable button'
            }
            <DefaultButton
              text="Enable"
              onClick={onClick}
              styles={enableButtonStyles}
            />
          </p>
        )}
        {workspace.shareType === 'private' && (
          <p style={borderStyle}>
            {
              'Data Quality is not supported in a private workspace.  Please switch to another workspace.'
            }
            <DefaultButton
              text="Switch Workspace"
              onClick={onClickSwitchWorkspace}
              styles={switchButtonStyles}
            />
          </p>
        )}
      </ElxContainer>
      <PanelManager panelState={panelState}>
        <WorkspaceQuickSwitch
          panelId={SidePanel.WORKSPACESELECTOR}
          {...panelDefaults}
        />
      </PanelManager>
    </>
  );
};

export default EnableDQ;
