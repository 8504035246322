import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState, AppDispatch } from 'app/lensShellUtility';
import { notifier } from 'utils/notifier';
import {
  Workspace,
  WorkspaceListStatus,
  WorkspaceStatus,
} from './models/workspace';
import { workspacesApi } from './api/workspacesApi';
import { selectWorkspaceStatus } from './workspaceSlice';

export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Custom hook to use the workspace list.
 */
export const useWorkspaceList = () => {
  const dispatch = useDispatch();
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  useEffect(() => {
    if (workspaceStatus === WorkspaceStatus.None) {
      dispatch(loadWorkspaces());
    }
  }, [dispatch, workspaceStatus]);
};

const loadWorkspaces = createAsyncThunk(
  'workspace/loadWorkspaces',
  async (): Promise<Workspace[]> => {
    let workspaceList = await workspacesApi.getWorkspaceList();

    return workspaceList;
  }
);

const workspaceListAdapter = createEntityAdapter<Workspace>();

const initialState = {
  workspaceList: workspaceListAdapter.getInitialState({
    status: WorkspaceListStatus.None,
  }),
};

/**
 * Redux slice representing the list of workspaces.
 */
const workspaceListSlice = createSlice({
  name: 'workspaceList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkspaces.pending, (state, action) => {
        state.workspaceList.status = WorkspaceListStatus.Loading;
      })
      .addCase(loadWorkspaces.fulfilled, (state, action) => {
        state.workspaceList.status = WorkspaceListStatus.Loaded;
        workspaceListAdapter.setAll(state.workspaceList, action.payload);
      })
      .addCase(loadWorkspaces.rejected, (state, action) => {
        state.workspaceList.status = WorkspaceListStatus.Error;
        notifier.error(action.error);
      });
  },
});

export const {
  selectAll: selectAllWorkspaces,
  selectEntities: selectWorkspaceListEntities,
  selectById: selectWorkspaceById,
  selectIds: selectWorkspaceIds,
} = workspaceListAdapter.getSelectors(
  (state: RootState) => state.workspaceList.workspaceList
);
export const selectWorkspaceListStatus = (state: RootState) =>
  state.workspaceList.workspaceList.status;

export default workspaceListSlice.reducer;
