import { mergeStyleSets, memoizeFunction } from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

export const getStyles = memoizeFunction(() => {
  const { palette } = useLensShellTheme();
  return mergeStyleSets({
    stackItemSelected: {
      background: palette.themePrimary,
      width: 2,
      marginRight: 5,
      color: palette.themePrimary,
    },
    stackItemHidden: {
      color: palette.white,
      width: 2,
      marginRight: 5,
    },
    innerStack: {
      alignItems: 'left',
      color: '#444444',
      display: 'flex',
      flexFlow: 'wrap',
      padding: 5,
      width: 176,
      height: 48,
      marginBottom: 20,
      FontSizes: 13,
      boxShadow: '1px 0px 0px #E8E8E8',
      cursor: 'pointer',
    },
    stack: {
      width: 176,
      height: 778,
      boxShadow: '1px 0px 0px #E8E8E8',
    },
  });
});
