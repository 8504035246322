import React from 'react';
import { Image } from '@fluentui/react';
import { ElxIconButton, ElxShell } from '@elixir/fx';
import { LensCustomShell } from './components/lensCustomShell/lensCustomShell';
import Discover from './components/discover/discover';
import { UserIconWithDropdown } from './components/user/user';
import { modules } from 'app/elxAdapter';
import gaLogo from './images/GA_LensLogo.png';
import preview from './images/preview.png';
import Feedback from './components/feedback/feedback';
import Notifications from './components/notifications/notifications';
import Help from './components/help/help';
import { IElxShellStyles } from '@elixir/fx/lib/framework/shell/ElxShell.types';
import { useLensShellTheme } from './lensShellStyles';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel,
  panelDefaults,
} from 'components/panelManager/panelManager';
import './lensShell.scss';
import '../../app/register-web-components';
import { defineCustomElements } from '@cseofeedback/feedback-lite/loader';
import { LensRoute, lensFramework } from 'app/elxAdapter';
import { Provider } from 'react-redux';

// This file contains the main Lens shell component

const { store } = lensFramework;
export enum SidePanel {
  FEEDBACK = 'Feedback',
  NOTIFICATIONS = 'Notifications',
  // DISCOVER = 'Discover',
  HELP = 'Help',
}

export function LensShell() {
  const panelState = usePanelManagerState();
  const theme = useLensShellTheme();

  const shellStyles = {
    root: {
      margin: 0, // elxshell seems to start with negative margin?
    },
    body: {
      position: 'relative',
    },
  } as IElxShellStyles;

  const footerRoutes: LensRoute[] = [
    {
      index: 'Workspace Selector',
      name: 'Workspace Selector',
      inSidebar: true,
    },
  ];
  defineCustomElements();
  return (
    <Provider store={store}>
      <LensCustomShell
        styles={shellStyles}
        modules={modules}
        defaultPath={undefined}
        theme={theme}
        footerRoutes={footerRoutes}
      >
        <ElxShell.Branding
          onRenderLogo={(): JSX.Element => (
            <div className="branding-container">
              <Image
                src={gaLogo}
                alt="Lens Explorer"
                height={22}
                style={{ padding: '0px 10px' }}
              />
              <Image
                src={preview}
                alt="Preview"
                style={{
                  position: 'fixed',
                  left: '0px',
                  top: '0px',
                  padding: '0',
                  zIndex: 10,
                  height: '49px',
                }}
              />
            </div>
          )}
          applicationName={'Lens Explorer'}
        />
        <ElxShell.Actions>
          <Discover />
          <ElxIconButton
            label="Feedback"
            text="Feedback"
            iconProps={{ iconName: 'Feedback' }}
            className="elx-header-action"
            onClick={() => onShowPanel(panelState, SidePanel.FEEDBACK)}
          />
          <ElxIconButton
            label="Help"
            text="Help"
            iconProps={{ iconName: 'Unknown' }}
            className="elx-header-action"
            onClick={() => onShowPanel(panelState, SidePanel.HELP)}
          />
          <ElxIconButton
            label="Notifications"
            text="Notifications"
            iconProps={{ iconName: 'Ringer' }}
            className="elx-header-action"
            onClick={() => onShowPanel(panelState, SidePanel.NOTIFICATIONS)}
          />
          <UserIconWithDropdown />
        </ElxShell.Actions>
      </LensCustomShell>
      <PanelManager panelState={panelState}>
        <Help panelId={SidePanel.HELP} {...panelDefaults} />
        <Notifications panelId={SidePanel.NOTIFICATIONS} {...panelDefaults} />
        <Feedback panelId={SidePanel.FEEDBACK} {...panelDefaults} />
      </PanelManager>
    </Provider>
  );
}

export default LensShell;
