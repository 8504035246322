export enum AuthType {
  None = 'None',
  AadApp = 'AADApp',
  User = 'User',
  ConnectionString = 'ConnectionString',
}

export enum SecretType {
  None = 'None',
  ClientSecret = 'ClientSecret',
  DQCertificate = 'DQCertificate',
  UserCertificate = 'UserCertificate',
}

export interface AuthenticationDetails {
  authType: AuthType;
  secretValue?: string | null;
  secretType?: SecretType | null;
  principalId: string;
  managedByLens?: boolean | null;
  tenant?: string;
}

export enum StoreType {
  None = 'None',
  Kusto = 'Kusto',
  StorageAccount = 'StorageAccount',
}

export interface ConnectionDetails {
  subType: StoreType;
}

export interface KustoConnectionDetails extends ConnectionDetails {
  subType: StoreType.Kusto;
  cluster: string;
  database: string;
}

export interface StorageAccountConnectionDetails extends ConnectionDetails {
  subType: StoreType.StorageAccount;
  storageAccount: string;
}

export interface DataConnection {
  connectionName: string;
  authDetails: AuthenticationDetails;
  connectionDetails: ConnectionDetails;
}

export interface dataconnectionProperties {
  cluster?: string;
  database?: string;
  storageAccount?: string;
}

export interface FlatDataConnection
  extends AuthenticationDetails,
    dataconnectionProperties {
  connectionName: string;
  connectionDisplayName: string;
  type: StoreType;
}

export type ResultDetail = {
  isAuthorized: boolean;
  authType: AuthType;
  permission: 'Write' | 'Read';
  message: string;
};

export type ValidateResult = {
  connectionName: string;
  results: [ResultDetail];
};

export default DataConnection;
