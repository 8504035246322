import React, { useEffect, useState, ReactNode, useRef } from 'react';
import {
  DataQualityStatus,
  selectDataQualityConnectionEntities,
  useConnections,
  useDatasets,
  selectDataQualityDatasetEntities,
  selectDataQualityConnectionsStatus,
  selectDataQualityAllDatasetsStatus,
  loadDatasets,
  loadConnections,
  deleteDataset,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
  unwrapDqError,
} from '../../dataQualitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { ElxDialog } from '@elixir/fx';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel as showPanel,
  panelDefaults,
} from 'components/panelManager/panelManager';
import EditExpectation from '../editExpectation/editExpectation';
import EditNotification from '../editNotification/editNotification';
import { DatasetsList, DatasetsListItem } from './datasetsList';
import { Dataset } from '../../models/dataset';
import { DataConnection } from '../../models/dataConnection';
import {
  getConnectionInfo,
  getCatalogEntityName,
} from '../../utils/dataQualityUtils';
import { Dictionary, nanoid, unwrapResult } from '@reduxjs/toolkit';
import { CatalogEntityValue } from 'features/catalog/components/catalogEntityValue/catalogEntityValue';
import { CatalogEntity } from 'features/catalog/models/catalogEntity';
import Notifications from 'features/shell/components/notifications/notifications';
import { AddDataset } from 'features/dataQuality/components/datasets/addDataset';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import { SidePanel } from 'features/shell/lensShell';
import { EnableDQ } from './enableDataQuality';
import {
  useUserprofile,
  selectUserprofile,
} from 'features/userprofile/userprofileSlice';
import { PassRate } from './passRate';
import { ExpectationCount } from './expectationCount';
import { LastModified } from './lastModified';
import { LastExecuted } from './lastExecuted';
import BatchDelete, {
  useBatchDelete,
} from 'components/batchDelete/batchDelete';
import { AppDispatch } from 'app/lensShellUtility';
import dataQualityApi, {
  isNotFoundError,
} from 'features/dataQuality/api/dataQualityApi';
import notifier from 'utils/notifier';

export enum DatasetsPanel {
  EXPECTATIONS = 'Expectations',
  NOTIFICATION_SETTINGS = 'NotificationSettings',
  RESULT_DETAILS = 'ResultDetails',
  EDIT_DATACONNECTION = 'EditDataConnection',
  ADD_DATASET = 'AddDataset',
}

export interface DatasetsProps {
  workspaceId: string;
}

export interface DatasetsState {}

// only works for string children
const AutoTooltipSpan = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const title = React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      return child;
    }
  })
    ?.filter((k) => !!k)
    .join(' ');
  return <span title={title}>{children}</span>;
};

/**
 * Container component combines data from Datasets, Connections and Catalog slices into props for the dataset list component
 * @param props
 * @returns
 */
export const Datasets = ({ workspaceId }: DatasetsProps): JSX.Element => {
  const panelState = usePanelManagerState();
  const dispatch = useDispatch<AppDispatch>();

  const workspace = useSelector(selectWorkspace);

  useDatasets(workspaceId);
  const allDatasetsStatus = useSelector(selectDataQualityAllDatasetsStatus);
  const datasetEntities = useSelector(selectDataQualityDatasetEntities);

  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  useConnections(workspaceId);
  const connectionEntities = useSelector(selectDataQualityConnectionEntities);
  const connectionsStatus = useSelector(selectDataQualityConnectionsStatus);

  useUserprofile();
  const userprofile = useSelector(selectUserprofile);

  let datasetNameList: string[] = [];

  if (datasetEntities) {
    Object.keys(datasetEntities).map((key) =>
      createDatasetNameList(datasetEntities[key] as Dataset)
    );
  }

  function createDatasetNameList(dataset: Dataset) {
    datasetNameList.push(dataset.datasetName);
  }

  const [items, setItems] = useState<DatasetsListItem[]>([]);
  useEffect(() => {
    setItems(
      createItems(
        workspaceId,
        datasetEntities,
        connectionEntities,
        userprofile.FavoriteDatasets
      )
    );
  }, [
    workspaceId,
    datasetEntities,
    connectionEntities,
    userprofile.FavoriteDatasets,
  ]);

  const [selectedDataset, setSelectedDataset] = useState<string>();

  const [batchDeleteState, onDeleteItems] = useBatchDelete();

  const initExecuteState = {
    hidden: true,
    header: '',
    completed: [] as string[],
    summary: '',
  };
  const [executeState, setExecuteState] = useState(initExecuteState);
  const executeStateRef = useRef(initExecuteState);
  executeStateRef.current = executeState;

  const dataset =
    selectedDataset && allDatasetsStatus === DataQualityStatus.Loaded
      ? datasetEntities[selectedDataset]
      : undefined;
  const connection =
    dataset && connectionsStatus === DataQualityStatus.Loaded
      ? connectionEntities[dataset.dataConnectionName]
      : undefined;

  function onShowPanel(panelId: DatasetsPanel, item?: DatasetsListItem) {
    if (item) {
      setSelectedDataset(item.id);
    } else {
      setSelectedDataset('');
    }

    showPanel(panelState, panelId);
  }

  async function executeRules(items: DatasetsListItem[]) {
    const plural = items.length > 1 ? 's' : '';
    setExecuteState({
      hidden: false,
      header: `Executing expectations from ${items.length} dataset${plural}`,
      completed: [],
      summary: '',
    });

    const tasks = items.map((item) => {
      const ruleIdsPromise =
        datasetsExpectationsStatus[item.id] === DataQualityStatus.Loaded
          ? Promise.resolve(
              Object.keys(expectationEntities).filter(
                (k) => expectationEntities[k]!.rule.datasetId === item.id
              )
            )
          : dataQualityApi
              .getRules(workspaceId, item.id)
              .then((rules) => rules.map((rule) => rule.id))
              .catch((error) => {
                if (isNotFoundError(error)) {
                  return [];
                } // 404: not found is expected and is not an error
                throw error;
              });

      return ruleIdsPromise
        .then((ruleIds) => {
          if (ruleIds.length === 0) {
            setExecuteState({
              ...executeStateRef.current,
              completed: [
                ...executeStateRef.current.completed,
                `No expectations found for ${item.datasetName}.`,
              ],
            });
            return 0;
          }
          return dataQualityApi
            .executeRules(workspaceId, {
              datasetId: item.id,
              name: `lens explorer execute rules for dataset ${
                item.id
              } ${nanoid(10)}`,
              ruleIds,
            })
            .then(() => {
              setExecuteState({
                ...executeStateRef.current,
                completed: [
                  ...executeStateRef.current.completed,
                  `Succeeded to execute expectations for ${item.datasetName}.`,
                ],
              });
              return ruleIds.length;
            });
        })
        .catch((error) => {
          const unwrapped = unwrapDqError(error);
          notifier.error(unwrapped);
          setExecuteState({
            ...executeStateRef.current,
            completed: [
              ...executeStateRef.current.completed,
              `Failed to execute expectations for ${item.datasetName}.`,
            ],
          });
          return 0;
        });
    });

    // execute all tasks in order https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
    return tasks
      .reduce((promiseChain: Promise<any>, currentTask: Promise<any>) => {
        return promiseChain.then((chainResults) =>
          currentTask.then((currentResult) => [...chainResults, currentResult])
        );
      }, Promise.resolve([]))
      .then((arrayOfResults) => {
        const count = Array.isArray(arrayOfResults)
          ? arrayOfResults.reduce((x, y) => x + y)
          : 0;
        setExecuteState({
          ...executeStateRef.current,
          header: `Completed executing expectations from ${items.length} datasets`,
          summary:
            count > 0
              ? `Please be patient for any submitted expectations to complete execution in a few minutes.`
              : 'No expectations were executed.',
        });
      });
  }

  async function deleteItem(item: DatasetsListItem) {
    const returned = await dispatch(
      deleteDataset({ workspaceId, datasetId: item.id })
    );
    return unwrapResult(returned);
  }

  function onRefresh() {
    dispatch(loadDatasets(workspaceId));
    dispatch(loadConnections(workspaceId));
  }

  return (
    <>
      {workspace && !workspace.dqsWorkspace ? (
        <EnableDQ />
      ) : (
        <>
          <DatasetsList
            items={items}
            isLoading={allDatasetsStatus === DataQualityStatus.Loading}
            loadingLabel="Loading..."
            onShowPanel={onShowPanel}
            onDeleteItems={onDeleteItems}
            onRefresh={onRefresh}
            onExecuteRules={executeRules}
          />
          <BatchDelete
            batchDeleteState={batchDeleteState}
            deleteItemFn={deleteItem}
            itemIdPropName="id"
            itemDescriptionPropName="datasetName"
            singular="dataset"
            plural="datasets"
          />
          <ElxDialog
            maxWidth="66%"
            hidden={executeState.hidden}
            header={executeState.header}
            primaryButtonText={executeState.summary ? 'Dismiss' : undefined}
            onPrimaryButtonClick={
              executeState.summary
                ? () => setExecuteState(initExecuteState)
                : undefined
            }
          >
            <Stack styles={{ root: { minHeight: 100 } }}>
              <Stack
                style={{ overflow: 'auto' }}
                tokens={{ childrenGap: 8, padding: 16, maxHeight: 300 }}
              >
                {executeState.completed.map((completed, index) => (
                  <p key={index}>{completed}</p>
                ))}
              </Stack>
              <Stack.Item>
                {executeState.summary ? (
                  <p>{executeState.summary}</p>
                ) : (
                  <Spinner
                    style={{ marginBottom: 16 }}
                    size={SpinnerSize.medium}
                  />
                )}
              </Stack.Item>
            </Stack>
          </ElxDialog>
          <PanelManager panelState={panelState}>
            {dataset && connection && (
              <EditExpectation
                {...panelDefaults}
                panelId={DatasetsPanel.EXPECTATIONS}
                workspaceId={workspaceId}
                dataset={dataset}
                connection={connection}
              />
            )}
            <EditNotification
              {...panelDefaults}
              panelId={DatasetsPanel.NOTIFICATION_SETTINGS}
              workspaceId={workspaceId}
              dataset={dataset}
              // connection={connection}
            />
            {workspace && (
              <AddDataset
                {...panelDefaults}
                panelId={DatasetsPanel.ADD_DATASET}
                dataset={dataset! || null}
                show={true}
                workspace={workspace}
                datasetNameList={datasetNameList}
              />
            )}

            <Notifications
              panelId={SidePanel.NOTIFICATIONS}
              {...panelDefaults}
            />
          </PanelManager>
        </>
      )}
    </>
  );
};

function createItems(
  workspaceId: string,
  datasets: Dictionary<Dataset>,
  connectionEntities: Dictionary<DataConnection>,
  favoriteDatasets: string
): DatasetsListItem[] {
  function createItem(dataset: Dataset): DatasetsListItem {
    const connection = connectionEntities[dataset.dataConnectionName];
    const entityName = getCatalogEntityName(
      dataset,
      connection?.connectionDetails
    );
    const serviceSelector = (entity?: CatalogEntity) =>
      entity?.AdditionalProperties?.Service;
    const descriptionSelector = (entity?: CatalogEntity) => entity?.Description;

    const { container, table } =
      getConnectionInfo(dataset, connection?.connectionDetails) || {};

    return {
      key: dataset.id,
      id: dataset.id,
      datasetName: dataset.datasetName,
      datasetNameElement: (
        <AutoTooltipSpan>{dataset.datasetName}</AutoTooltipSpan>
      ),
      container,
      table,
      tableElement: <AutoTooltipSpan>{table}</AutoTooltipSpan>,
      passRate: (
        <PassRate workspaceId={workspaceId} datasetId={dataset.id}></PassRate>
      ),
      expectations: <ExpectationCount datasetId={dataset.id} />,
      lastExecuted: <LastExecuted datasetId={dataset.id} />,
      service: entityName && (
        <CatalogEntityValue
          entityName={entityName}
          valueSelector={serviceSelector}
        />
      ),
      description: entityName && (
        <CatalogEntityValue
          entityName={entityName}
          valueSelector={descriptionSelector}
        />
      ),
      lastModified: <LastModified datasetId={dataset.id} />,
      isFavorite:
        (favoriteDatasets && favoriteDatasets.indexOf(dataset.id) !== -1) ||
        false,
    };
  }

  return Object.keys(datasets).map((key) =>
    createItem(datasets[key] as Dataset)
  );
}

export default Datasets;
