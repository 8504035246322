import RuleExecutionResult, {
  RegularExpressionCheckResult,
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import { getFailedExpectations } from 'features/dataQuality/utils/dataQualityUtils';
import {
  ResultsTableDescription,
  UserExpectationResultsBuilder,
  UserExpectationResultsDescription,
} from '../../base/userExpectationResultsBuilder';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { RegExCheckCondition } from 'features/dataQuality/models/rule';
import { getDescriptionText } from './regExCheckExpectation';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export class RegExCheckResultsBuilder extends UserExpectationResultsBuilder {
  public static getResultDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    const ruleResult =
      ruleExecutionResult.ruleResult as RegularExpressionCheckResult;
    const col = ruleResult.columns[0];
    const ruleCheckCondition = ruleExecutionResult.rule.details
      .ruleCondition as RegExCheckCondition;
    const regExType = ruleCheckCondition.ColumnsToCheck[0].regExType;
    const customRegExFormat =
      ruleCheckCondition.ColumnsToCheck[0].customRegExFormat || '';
    const isPercentage =
      UserExpectationResultsBuilder.isPercentThresholds(ruleExecutionResult);
    const unit = isPercentage ? StandardUnits.PERCENT : StandardUnits.INTEGER;

    let retVal: UserExpectationResultsDescription = {
      expectationDescription: getDescriptionText(
        col.name,
        regExType.toString(),
        customRegExFormat.toString()
      ),
      thresholdValueDescriptions: [],
    };

    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const { severity } = RegExCheckResultsBuilder.decodeRangeParameters(
        failedExpectation,
        col.mismatchedRowCount,
        col.mismatchPercentage
      );
      const severityString = severity
        ? `failed with Sev ${severity}`
        : 'failed';

      if (isPercentage) {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${col.mismatchPercentage}% of the record values for column '${col.name}' did not match the regular expression`;
        retVal.failureValue = col.mismatchPercentage;
        retVal.failureValueDescription = `${col.mismatchPercentage.toFixed(
          RegExCheckResultsBuilder.percentDecimals
        )} percent of records did not match the regular expression.`;
      } else {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${col.mismatchedRowCount} records did not match the regular expression for the column '${col.name}'`;
        retVal.failureValue = col.mismatchedRowCount;
        retVal.failureValueDescription = `${col.mismatchedRowCount} records did not match the regular expression.`;
      }

      retVal.thresholdValueDescriptions = [
        UserExpectationResultsBuilder.describeStaticRangeCondition(
          failedExpectation,
          unit,
          col.mismatchedRowCount,
          col.mismatchPercentage
        ),
      ];
    } else {
      retVal.outcomeSummary = `Evaluation passed because ${
        col.mismatchedRowCount
      } out of ${col.totalCount} (${col.mismatchPercentage.toFixed(
        RegExCheckResultsBuilder.percentDecimals
      )}%) record values for column '${
        col.name
      }' did not match the regular expression.`;
      retVal.thresholdValueDescriptions.push(
        ...UserExpectationResultsBuilder.describeNearbySuccededThresholds(
          ruleExecutionResult,
          unit,
          col.mismatchedRowCount,
          col.mismatchPercentage
        )
      );
    }

    return retVal;
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [
        {
          ...this.colDefaults,
          key: 'ExecutionDate',
          name: 'Execution Date',
          fieldName: 'date',
          minWidth: 60,
          maxWidth: 120,
        },
        {
          ...this.colDefaults,
          key: 'MismatchedRowCount',
          name: 'Mismatched Row Count',
          fieldName: 'mismatchedRowCount',
          minWidth: 140,
        },
        {
          ...this.colDefaults,
          key: 'TotalCount',
          name: 'Total Count',
          fieldName: 'totalCount',
          minWidth: 70,
        },
        {
          ...this.colDefaults,
          key: 'MismatchPercentage',
          name: 'Mismatch Percentage',
          fieldName: 'mismatchPercentage',
          minWidth: 135,
        },
        {
          ...this.colDefaults,
          key: 'Result',
          name: 'Result',
          fieldName: 'result',
          minWidth: 60,
        },
        {
          ...this.colDefaults,
          key: 'Range',
          name: 'Range',
          fieldName: 'range',
          minWidth: 80,
        },
      ] as IColumn[],
      items: ruleExecutionResults.map((ruleExecutionResult, index) => {
        const item = {
          key: index.toString(),
          date: UserExpectationResultsBuilder.getShorterDate(
            ruleExecutionResult.ruleExecutionStartTime
          ),
        };

        if (
          ruleExecutionResult.ruleResult.subType === RuleResultType.ErrorResult
        ) {
          return { ...item, result: 'Error' };
        }

        const ruleResult =
          ruleExecutionResult.ruleResult as RegularExpressionCheckResult;
        const col = ruleResult.columns[0];
        const { result, rangeStart, rangeEnd } =
          UserExpectationResultsBuilder.calcResultRange(
            ruleExecutionResult,
            col.mismatchedRowCount,
            col.mismatchPercentage
          );
        const isPercentage =
          (rangeStart === undefined && rangeEnd === undefined) ||
          UserExpectationResultsBuilder.isPercentThresholds(
            ruleExecutionResult
          );
        const p = isPercentage ? '%' : '';

        return {
          ...item,
          mismatchedRowCount: col.mismatchedRowCount,
          totalCount: col.totalCount,
          mismatchPercentage:
            p && col.mismatchPercentage !== undefined
              ? col.mismatchPercentage + p
              : undefined,
          result,
          range: `${rangeStart || (isPercentage ? '0' : 'unbounded')}${p} - ${
            rangeEnd || (isPercentage ? '100' : 'unbounded')
          }${p}`,
        };
      }),
    };
  }
}

export default RegExCheckResultsBuilder;
