import React from 'react';
import {
  DataQualityStatus,
  ExpectationInfo,
  selectDataQualityLatestRuleResultsByRuleId,
  selectDataQualityLatestRuleResultsStatus,
  useLatestRuleResults,
} from 'features/dataQuality/dataQualitySlice';
import Dataset from 'features/dataQuality/models/dataset';
import { useSelector } from 'react-redux';
import { RootState } from 'app/lensShellUtility';
import { getFailedExpectations } from 'features/dataQuality/utils/dataQualityUtils';
import { decodeSeverity } from '../classifyResults/threshold';
import { RuleResultType } from 'features/dataQuality/models/ruleExecutionResult';

export interface LatestResultProps {
  workspaceId: string;
  dataset: Dataset;
  expectationInfo: ExpectationInfo;
}

export const LatestResult = (props: LatestResultProps): JSX.Element => {
  const { workspaceId, dataset, expectationInfo } = props;

  const ruleId = expectationInfo.rule.id;
  useLatestRuleResults(workspaceId, dataset.id, ruleId);

  const latestRuleResultsStatus = useSelector(
    selectDataQualityLatestRuleResultsStatus
  );
  const latestRuleResultStatus = latestRuleResultsStatus[ruleId];
  const ruleResults = useSelector((state: RootState) =>
    selectDataQualityLatestRuleResultsByRuleId(state, ruleId)
  );

  const results =
    (latestRuleResultStatus === DataQualityStatus.Loaded &&
      ruleResults?.results) ||
    undefined;

  if (!results) {
    return <></>;
  }

  if (results.length === 0) {
    if (!expectationInfo.rule.schedule) {
      return <>Not scheduled</>;
    }
    return <>Not run</>;
  }

  const latestResult = results[0];

  if (latestResult.ruleResult.subType === RuleResultType.ErrorResult) {
    return <>Error</>;
  }

  const failedExpectations = getFailedExpectations(latestResult);

  if (failedExpectations.length === 0) {
    return <>Passed</>;
  }

  const severity = decodeSeverity(failedExpectations[0]);

  return severity ? <>Sev&nbsp;{severity}</> : <>Fail</>;
};

export default LatestResult;
