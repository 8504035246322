import React, { CSSProperties } from 'react';
import { ElxLink } from '@elixir/fx';
import { FontIcon } from '@fluentui/react';

export interface HelpLinkProps {
  iconName?: string;
  linkText?: string;
  href?: string;
}
/**
 * Simple Link for help documentation.  Expected to be displayed alone on a single line (it is a a <span> element).
 * Includes an icon on the left side before the link and an 'external link' icon on the right.
 * @param props
 * @returns JSX Element
 */
export const HelpLink = ({
  iconName = 'Unknown',
  linkText,
  href,
}: HelpLinkProps): JSX.Element => {
  return (
    <span>
      <FontIcon
        style={{ ...iconStyle, marginRight: '10px' }}
        iconName={iconName}
      />
      <ElxLink href={href} target="_blank">
        {linkText}
        <FontIcon
          style={{ ...iconStyle, marginLeft: '6px', height: '16px' }}
          iconName="OpenInNewWindow"
        />
      </ElxLink>
    </span>
  );
};

const iconStyle = {
  position: 'relative',
  top: '2px',
} as CSSProperties;

export default HelpLink;
