import lensShell from 'features/shell/lensShell';

export const routes = [
  {
    name: 'leapp',
    abstract: true,
    views: {
      lensshell: {
        component: lensShell,
      },
    },
  },
  {
    name: 'app',
    abstract: true,
    parent: 'leapp',
    template:
      '<div class="application lens-classic embedded-kibana"><div class="outer-view"><ui-view class="application"></ui-view></div></div>',
  },
  {
    name: 'leapp.dataquality',
    url: '/dataquality',
    abstract: true,
  },
  {
    name: 'leapp.dataquality.datasets',
    url: '/datasets',
  },
  {
    name: 'leapp.dataquality.report',
    url: '/report/{datasetId}',
  },
  {
    name: 'leapp.workspaces',
    url: '/workspaces',
  },
  {
    name: 'leapp.workspaces.manage',
    url: '/manage',
  },
  {
    name: 'leapp.workspaces.edit',
    url: '/edit/{workspaceAlias}',
  },
  {
    name: 'leapp.joblist',
    url: '/job/list',
  },
];

export default routes;
