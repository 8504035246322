import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';
import RuleExecutionResult, {
  NullCheckResult,
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import { getFailedExpectations } from 'features/dataQuality/utils/dataQualityUtils';
import {
  ResultsTableDescription,
  UserExpectationResultsBuilder,
  UserExpectationResultsDescription,
} from '../../base/userExpectationResultsBuilder';

export class NullCheckResultsBuilder extends UserExpectationResultsBuilder {
  public static getResultDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    const ruleResult = ruleExecutionResult.ruleResult as NullCheckResult;
    const col = ruleResult.columns[0];
    const isPercentage =
      UserExpectationResultsBuilder.isPercentThresholds(ruleExecutionResult);
    const unit = isPercentage ? StandardUnits.PERCENT : StandardUnits.INTEGER;

    let retVal: UserExpectationResultsDescription = {
      expectationDescription: `"${col.name}" should be fully/mostly non-null`,
      thresholdValueDescriptions: [],
    };

    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const { severity } = NullCheckResultsBuilder.decodeRangeParameters(
        failedExpectation,
        col.nullCount,
        col.nullPercentage
      );
      const severityString = severity
        ? `failed with Sev ${severity}`
        : 'failed';

      if (isPercentage) {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${col.nullPercentage}% of the record values for column '${col.name}' were null`;
        retVal.failureValue = col.nullPercentage;
        retVal.failureValueDescription = `${col.nullPercentage.toFixed(
          NullCheckResultsBuilder.percentDecimals
        )} percent of records are null.`;
      } else {
        retVal.outcomeSummary = `Evaluation ${severityString} because ${col.nullCount} records contained null for the column '${col.name}'`;
        retVal.failureValue = col.nullCount;
        retVal.failureValueDescription = `${col.nullCount} records are null.`;
      }

      retVal.thresholdValueDescriptions = [
        UserExpectationResultsBuilder.describeStaticRangeCondition(
          failedExpectation,
          unit,
          col.nullCount,
          col.nullPercentage
        ),
      ];
    } else {
      retVal.outcomeSummary = `Evaluation passed with ${col.nullCount} out of ${
        col.totalCount
      } (${col.nullPercentage.toFixed(
        NullCheckResultsBuilder.percentDecimals
      )}%) record values for column '${col.name}' being null.`;
      retVal.thresholdValueDescriptions.push(
        ...UserExpectationResultsBuilder.describeNearbySuccededThresholds(
          ruleExecutionResult,
          unit,
          col.nullCount,
          col.nullPercentage
        )
      );
    }

    return retVal;
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [
        {
          ...this.colDefaults,
          minWidth: 120,
          maxWidth: 130,
          key: 'ExecutionDate',
          name: 'Execution Date',
          fieldName: 'date',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'NullCount',
          name: 'Null Count',
          fieldName: 'nullCount',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'TotalCount',
          name: 'Total Count',
          fieldName: 'totalCount',
        },
        {
          ...this.colDefaults,
          minWidth: 80,
          maxWidth: 90,
          key: 'NullPercentage',
          name: 'Null Percentage',
          fieldName: 'nullPercentage',
        },
        {
          ...this.colDefaults,
          minWidth: 60,
          maxWidth: 80,
          key: 'Result',
          name: 'Result',
          fieldName: 'result',
        },
        {
          ...this.colDefaults,
          minWidth: 100,
          key: 'Range',
          name: 'Range',
          fieldName: 'range',
        },
      ],
      items: ruleExecutionResults.map((ruleExecutionResult, index) => {
        const item = {
          key: index.toString(),
          date: UserExpectationResultsBuilder.getShorterDate(
            ruleExecutionResult.ruleExecutionStartTime
          ),
        };

        if (
          ruleExecutionResult.ruleResult.subType === RuleResultType.ErrorResult
        ) {
          return { ...item, result: 'Error' };
        }

        const ruleResult = ruleExecutionResult.ruleResult as NullCheckResult;
        const col = ruleResult.columns[0];
        const { result, rangeStart, rangeEnd } =
          UserExpectationResultsBuilder.calcResultRange(
            ruleExecutionResult,
            col.nullCount,
            col.nullPercentage
          );
        const isPercentage =
          (rangeStart === undefined && rangeEnd === undefined) ||
          UserExpectationResultsBuilder.isPercentThresholds(
            ruleExecutionResult
          );
        const p = isPercentage ? '%' : '';

        const rangeStartStr = `${
          rangeStart || (isPercentage ? '0' : 'unbounded')
        }${p}`;
        const rangeEndStr =
          rangeEnd !== undefined
            ? ` - ${rangeEnd}${p}`
            : isPercentage
            ? ' - 100%'
            : '+';

        return {
          ...item,
          nullCount: col.nullCount,
          totalCount: col.totalCount,
          nullPercentage:
            p && col.nullPercentage !== undefined ? col.nullPercentage + p : '',
          result,
          range: `${rangeStartStr}${rangeEndStr}`,
        };
      }),
    };
  }
}

export default NullCheckResultsBuilder;
