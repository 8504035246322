/**
 * Class to represent units of measurement in Threshold ranges.  This is meant to be used in the Ux layer
 * to present an underlying base value in some base unit (e.g. seconds) but the user can choose to see and
 * update the value in another more fitting unit (e.g. days).
 */
export abstract class ThresholdUnit {
  public unitType: string;
  public unitDisplayName?: string;
  public unitDisplayNamePlural?: string;

  constructor(
    unitType: string,
    unitDisplayName?: string,
    unitDisplayNamePlural?: string
  ) {
    this.unitType = unitType;
    this.unitDisplayName = unitDisplayName;
    this.unitDisplayNamePlural = unitDisplayNamePlural || unitDisplayName;
  }

  public isPercent = false;

  /**
   * Encode an input string into the "base unit" that is stored in DQS backend
   * @param input string entered by the user
   * @returns number value in base units
   */
  public abstract calcUserInput(input: string): number;

  /**
   * Generate a display string after converting from the "base unit" that is stored in DQS backend
   * @param value the base value stored in the backend
   * @returns display string that has been adjusted back to desired units
   */
  public abstract getDisplayString(
    value: string | number | undefined,
    fractionDigits?: number
  ): string;

  /**
   * Generate a display phrase suitable for embedding in a sentence that includes the
   * value after converting from the "base unit" that is stored in DQS backend.
   * For named units e.g. days/hours/minutes/seconds, the unit names are appended to the phrase
   * @param value the base value stored in the backend
   * @returns display string that has been adjusted back to desired units, appended with the unit name(s)
   */
  public getDisplayPhrase(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    return this.getDisplayString(value, fractionDigits);
  }
}

/**
 * TimeUnits are Units that are stored in the backend as seconds, but are presented to the
 * user as days/hours/seconds.
 */
class TimeUnit extends ThresholdUnit {
  protected numSeconds: number; // number of seconds in unit

  constructor(unitType: string, unitDisplayName: string, numSeconds: number) {
    super(unitType, unitDisplayName, unitDisplayName + 's');
    this.numSeconds = numSeconds;
  }

  public calcUserInput(input: string): number {
    const num = Number.parseFloat(input);
    if (Number.isNaN(num)) {
      return Number.NaN;
    }
    return Math.round(num * this.numSeconds);
  }

  public getDisplayString(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    if (value === undefined) {
      return '';
    }
    const num = typeof value === 'string' ? Number.parseFloat(value) : value;
    if (Number.isNaN(num)) {
      return Number.NaN.toString();
    }
    const adjusted = num / this.numSeconds;
    fractionDigits =
      Math.round(adjusted) === adjusted ? undefined : fractionDigits; // don't print useless .00s
    return fractionDigits === undefined
      ? adjusted.toString()
      : adjusted.toFixed(fractionDigits);
  }

  public getDisplayPhrase(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    const displayStr = this.getDisplayString(value, fractionDigits);
    if (value === undefined) {
      return '';
    }
    const plural = Number.parseFloat(displayStr) !== 1;
    return `${displayStr} ${
      plural ? this.unitDisplayNamePlural : this.unitDisplayName
    }`;
  }
}

class PercentUnit extends ThresholdUnit {
  constructor() {
    super('PERCENT');
  }

  public isPercent = true;

  public calcUserInput(percent: string): number {
    percent = percent?.replace(/%+$/, '');
    const num = Number.parseFloat(percent);
    if (Number.isNaN(num)) {
      return Number.NaN;
    }
    return Math.max(0.0, Math.min(num, 100.0));
  }

  public getDisplayString(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    if (value === undefined) {
      return '';
    }
    const num = typeof value === 'string' ? Number.parseFloat(value) : value;
    if (Number.isNaN(num)) {
      return Number.NaN.toString();
    }
    fractionDigits = Math.round(num) === num ? undefined : fractionDigits; // don't print useless .00s
    return (
      (fractionDigits === undefined
        ? num.toString()
        : num.toFixed(fractionDigits)) + '%'
    );
  }
}

class IntegerUnit extends ThresholdUnit {
  constructor() {
    super('INTEGER');
  }

  public calcUserInput(input: string): number {
    const num = Number.parseInt(input);
    if (Number.isNaN(num)) {
      return Number.NaN;
    }
    return Math.round(num);
  }

  public getDisplayString(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    if (value === undefined) {
      return '';
    }
    const num = typeof value === 'string' ? Number.parseInt(value) : value;
    if (Number.isNaN(num)) {
      return Number.NaN.toString();
    }
    return Math.round(num).toString();
  }
}

class NumberUnit extends ThresholdUnit {
  constructor() {
    super('NUMBER');
  }

  public calcUserInput(input: string): number {
    const num = Number.parseFloat(input);
    if (Number.isNaN(num)) {
      return Number.NaN;
    }
    return num;
  }

  public getDisplayString(
    value: string | number | undefined,
    fractionDigits?: number
  ): string {
    if (value === undefined) {
      return '';
    }
    const num = typeof value === 'string' ? Number.parseFloat(value) : value;
    if (Number.isNaN(num)) {
      return Number.NaN.toString();
    }
    fractionDigits = Math.round(num) === num ? undefined : fractionDigits; // don't print useless .00s
    return fractionDigits === undefined
      ? num.toString()
      : num.toFixed(fractionDigits);
  }
}

export const StandardUnits = {
  NUMBER: new NumberUnit(),
  INTEGER: new IntegerUnit(),
  PERCENT: new PercentUnit(),
  DAY: new TimeUnit('DAY', 'day', 84600),
  HOUR: new TimeUnit('HOUR', 'hour', 3600),
  MINUTE: new TimeUnit('MINUTE', 'minute', 60),
  SECOND: new TimeUnit('SECOND', 'second', 1),
};

export default StandardUnits;
