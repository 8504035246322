import React from 'react';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { Card } from 'components/cards/card';
import { getUserExpectationFromRule } from 'features/dataQuality/userExpectations/userExpectationsRegistry';
import RuleExecutionResult, {
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import LabelAndValue from 'components/labelAndValue/labelAndValue';
import ErrorResult from './errorResult';
import {
  EvaluationDetailsHelpLabel,
  ExpectationDetailsHelpLabel,
} from 'utils/helpIconText';

export interface SingleResultProps {
  expectationInfo: ExpectationInfo;
  result?: RuleExecutionResult;
}

export const SingleResult = (props: SingleResultProps): JSX.Element => {
  const { expectationInfo, result } = props;

  const userExpectation = getUserExpectationFromRule(expectationInfo.rule);

  if (result?.ruleResult.subType === RuleResultType.ErrorResult) {
    return <ErrorResult expectationInfo={expectationInfo} result={result} />;
  }

  const resultDescription =
    result && userExpectation?.getResultDescription(result);

  return (
    <>
      {resultDescription && (
        <>
          <Card
            collapsible
            title="Expectation Details"
            tokens={{ childrenGap: 8, padding: 8 }}
            hintText={ExpectationDetailsHelpLabel}
          >
            <LabelAndValue label="Expectation Description">
              {resultDescription.expectationDescription}
            </LabelAndValue>
          </Card>
          <Card
            collapsible
            title="Evaluation Details"
            tokens={{ childrenGap: 8, padding: 8 }}
            hintText={EvaluationDetailsHelpLabel}
          >
            <LabelAndValue label="Outcome Summary">
              {resultDescription.outcomeSummary}
            </LabelAndValue>
            {(resultDescription.failureValueDescription ||
              resultDescription.failureValue) && (
              <LabelAndValue label="Failure Value">
                {resultDescription.failureValueDescription ||
                  resultDescription.failureValue}
              </LabelAndValue>
            )}
            <LabelAndValue label="Threshold Value">
              {resultDescription.thresholdValueDescriptions?.map((d, i) => (
                <p key={i}>{d}</p>
              ))}
            </LabelAndValue>
          </Card>
        </>
      )}
    </>
  );
};

export default SingleResult;
