import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ElxDialog } from '@elixir/fx';
import { Icon, Spinner, SpinnerSize, Stack } from '@fluentui/react';

export interface BatchSubmitProps {
  /**
   * internal state retrieved by calling the useBatchSubmit() hook
   */
  batchSubmitState: BatchSubmitState;
  /**
   * the name of a string property inside each item that can be used as a unique identifier
   */
  itemIdPropName: string;
  /**
   * the name of a string | JSX.Element property inside each item that can be used to list out the items to be submitted
   */
  itemDescriptionPropName: string;
  /**
   * the singular word for the items being submitted e.g. 'dataset' or 'expectation'
   */
  singular: string;
  /**
   * the plural word for the items being submitted e.g. 'datasets' or 'expectations'
   */
  plural: string;
}

interface BatchSubmitState {
  itemsState: ItemsState;
  setItemsState: Dispatch<SetStateAction<ItemsState>>;
}

enum SubmitStatus {
  NONE = 'NONE',
  SUBMITTING = 'SUBMITTING',
  COMPLETE = 'COMPLETE',
}

/**
 * Custom hook required to use the Batch Submit Dialog JSX.Element
 * @returns batchSubmitState, which is used internally and an onSubmitItems function that initiates submission
 * process for a list of items.
 * @param submitFn the function that submits a single item
 * @param itemIdPropName the unique ID property of each item
 */
export function useBatchSubmit(
  submitFn: (item: any) => Promise<any>,
  itemIdPropName: string
): [BatchSubmitState, (items: any[]) => void] {
  const [itemsState, setItemsState] = useState<ItemsState>(initItemsState);
  const itemsStateRef = useRef<ItemsState>(initItemsState);
  itemsStateRef.current = itemsState;

  const batchSubmitState = { itemsState, setItemsState, itemsStateRef };

  function onSubmitItems(items: any[]) {
    setItemsState({
      status: SubmitStatus.SUBMITTING,
      submitItems: [...items],
      successfulItemIds: [],
      failedItemIds: [],
    });

    Promise.allSettled(
      items.map(async (item) => {
        try {
          await submitFn(item);
          setItemsState({
            ...itemsStateRef.current,
            successfulItemIds: [
              ...itemsStateRef.current.successfulItemIds,
              item[itemIdPropName],
            ],
          });
        } catch (error) {
          setItemsState({
            ...itemsStateRef.current,
            failedItemIds: [
              ...itemsStateRef.current.failedItemIds,
              item[itemIdPropName],
            ],
          });
          throw error;
        }
      })
    ).finally(() => {
      setItemsState({
        ...itemsStateRef.current,
        status: SubmitStatus.COMPLETE,
      });
    });
  }

  return [batchSubmitState, onSubmitItems];
}

/**
 * Testing utility function that can be hooked up to test batch submission of items.
 * Emulates an async submit function that pretends to submit an item and resolves or rejects
 * in 8s or less.  Rejects 40% of the time.  The item passed in is ignored and is not executed.
 * @param item
 */
export function testSubmitFn(item: any) {
  const delay = 8000;
  return new Promise(function (resolve, reject) {
    if (Math.random() < 0.4) {
      setTimeout(reject, Math.random() * delay);
    } else {
      setTimeout(resolve, Math.random() * delay);
    }
  });
}

interface ItemsState {
  status: SubmitStatus;
  submitItems: any[];
  successfulItemIds: string[];
  failedItemIds: string[];
}

const initItemsState: ItemsState = {
  status: SubmitStatus.NONE,
  submitItems: [],
  successfulItemIds: [],
  failedItemIds: [],
};

/**
 * A batch submit dialog that allows users to submit a list of items and wait for completion + see status of submission
 */
const BatchSubmit = (props: BatchSubmitProps): JSX.Element => {
  const {
    batchSubmitState,
    itemIdPropName,
    itemDescriptionPropName,
    singular,
    plural,
  } = props;
  const { itemsState, setItemsState } = batchSubmitState;

  function onCancelSubmit() {
    setItemsState({ ...itemsState, status: SubmitStatus.NONE });
  }

  let submitHeader = '';
  let submitCancelButtonText = '';
  let onDismissSubmit = undefined;
  const submitCount = itemsState.submitItems.length;
  const itemTypeName = submitCount > 1 ? plural : singular;
  switch (itemsState.status) {
    case SubmitStatus.SUBMITTING:
      submitHeader = `Submitting ${submitCount} ${itemTypeName} for execution`;
      break;

    case SubmitStatus.NONE:
    case SubmitStatus.COMPLETE:
    default:
      if (
        itemsState.failedItemIds.length >= itemsState.submitItems.length ||
        itemsState.successfulItemIds.length === 0
      ) {
        submitHeader = `Failed to submit ${
          submitCount > 1
            ? (submitCount === 2 ? 'either' : 'any') +
              ' of these ' +
              submitCount
            : 'the'
        } ${itemTypeName} for execution`;
      } else if (
        itemsState.successfulItemIds.length >= itemsState.submitItems.length
      ) {
        submitHeader = `Successfully submitted ${
          submitCount === 2
            ? 'both'
            : (submitCount > 1 ? 'all ' : '') + submitCount
        } ${itemTypeName} for execution`;
      } else {
        submitHeader = `Only ${itemsState.successfulItemIds.length} out of ${submitCount} ${itemTypeName} were successfully submitted for execution`;
      }
      submitCancelButtonText = 'Dismiss';
      onDismissSubmit = onCancelSubmit;
      break;
  }

  let summaryElement = <p> </p>;
  if (itemsState.status === SubmitStatus.COMPLETE) {
    const successCount = itemsState.successfulItemIds.length;
    if (successCount === 0) {
      summaryElement = (
        <p>The {itemTypeName} could not be submitted for execution.</p>
      );
    } else if (successCount === 1) {
      summaryElement = (
        <p>
          Please be patient for the submitted {singular} to complete its
          execution in a few minutes.
        </p>
      );
    } else {
      summaryElement = (
        <p>
          Please be patient for the submitted {plural} to complete their
          executions in a few minutes.
        </p>
      );
    }
  }

  return (
    <ElxDialog
      maxWidth="66%"
      hidden={itemsState.status === SubmitStatus.NONE}
      dismissable={itemsState.status !== SubmitStatus.COMPLETE}
      header={submitHeader}
      primaryButtonText={submitCancelButtonText}
      onPrimaryButtonClick={onCancelSubmit}
      onDismiss={onDismissSubmit}
    >
      <Stack
        style={{ overflow: 'auto' }}
        tokens={{ childrenGap: 8, padding: 16, maxHeight: 300 }}
      >
        {itemsState.submitItems.map((item) => (
          <Stack
            key={item[itemIdPropName]}
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 12 }}
          >
            <Stack.Item>{item[itemDescriptionPropName]}</Stack.Item>
            <span style={{ minWidth: 16, minHeight: 20 }}>
              {itemsState.failedItemIds.includes(item[itemIdPropName]) ? (
                <Icon title="Submission failed" iconName="Cancel" />
              ) : itemsState.successfulItemIds.includes(
                  item[itemIdPropName]
                ) ? (
                <Icon title="Submission succeeded" iconName="CheckMark" />
              ) : (
                itemsState.status === SubmitStatus.SUBMITTING && (
                  <Spinner size={SpinnerSize.small} />
                )
              )}
            </span>
          </Stack>
        ))}
      </Stack>
      {summaryElement}
    </ElxDialog>
  );
};

export default BatchSubmit;
