import 'set-public-path';
import angular from 'angular';
import { UI_ROUTER_REACT_HYBRID } from '@uirouter/react-hybrid';
// import { Visualizer } from '@uirouter/visualizer';
import { notifier } from 'utils/notifier';
import { inject as workspaceUtilsInject } from 'features/workspaces/utils/workspaceUtils';
import { inject as asideUtilsInject } from 'features/shell/utils/asideUtils';
import { inject as authUtilsInject } from './utils/authUtils';
import { inject as sharedLibsInject } from 'utils/sharedLibs';
export { routes } from 'app/routes';

export const sharedModules = {
  angular,
  UI_ROUTER_REACT_HYBRID /* Visualizer, */,
};

/**
 * Injects AngularJS dependencies into React. Called in leAppService.js.
 */
export const injectAngular2React = (leApp: any) => {
  // Avoid injecting AngularJS dependencies - $rootScope, laTelemetry, workspaceManager...
  // AngularJS dependencies may be deprecated and replaced with React in the future.
  // Wrap minimal AngularJS dependencies in leApp methods in leAppService.js for now.
  notifier.inject(leApp);
  workspaceUtilsInject(leApp);
  asideUtilsInject(leApp);
  authUtilsInject(leApp);
  sharedLibsInject(leApp);
};
