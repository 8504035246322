import React, { ReactNode } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';

interface LabelAndValueProps {
  label: string;
  labelWidth?: string;
  children?: ReactNode;
}

/**
 * A label: value horizontal stack for showing named values in panel.
 *
 * @param props.label the label text on left side
 * @param props.labelWidth the width of the label column as a CSS width, default 25%
 * @param props.children the content to place on the value side.  Can be a string or an element
 * @returns
 */
export const LabelAndValue = ({
  label,
  labelWidth = '25%',
  children,
}: LabelAndValueProps): JSX.Element => {
  return (
    <Stack horizontal>
      <Stack.Item grow={0} shrink={0} styles={{ root: { width: labelWidth } }}>
        <label>{label}:</label>
      </Stack.Item>
      <Stack.Item grow={1}>{children}</Stack.Item>
    </Stack>
  );
};

export default LabelAndValue;
