import { LeWebApi } from 'api/leWebApi';
import { supportNotificationEntity } from '../models/supportNotificationEntity';

class AzureSupportNotificationApi extends LeWebApi {
  public getSupportNotifications = async (): Promise<
    supportNotificationEntity[]
  > => {
    return await this.getEntity<supportNotificationEntity[]>(
      '/supportnotifications/active?bypassCache=false'
    );
  };
}

export const azureSupportNotificationApi = new AzureSupportNotificationApi();
export const getSupportNotifications =
  azureSupportNotificationApi.getSupportNotifications;

export default azureSupportNotificationApi;
