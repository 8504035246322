import {
  IContextualMenuItem,
  IContextualMenuProps,
} from '@fluentui/react/lib/ContextualMenu';
import {
  runAsAdministrator,
  runAsUser,
} from 'features/userprofile/userprofileSlice';
import { getEmail, getName, logOut, switchAADTenant } from 'utils/authUtils';
import { UserIcon } from './user';
import { isAdmin } from 'utils/authUtils';

export function getUserMenuItems(
  canAdmin: boolean
): IContextualMenuProps | undefined {
  const userNameStyles = {
    list: {
      '.ms-ContextualMenu-item': { height: '36px' },
      '.userActionsName': { height: 'fit-content' },
      '.userActionsName .ms-ContextualMenu-itemText': { color: '#0078d4' },
      '.userActionsName .ms-ContextualMenu-link': { height: 'fit-content' },
      '.userActionsName .ms-ContextualMenu-linkContent': { flexFlow: 'column' },
    },
  };
  let menuItems: IContextualMenuItem[] = [
    {
      key: 'userName',
      text: getName(),
      secondaryText: getEmail(),
      onRenderIcon: () => UserIcon(),
      className: 'userActionsName',
      disabled: true,
      itemType: 0,
      itemProps: { styles: { root: { paddingTop: '10px' } } },
    },
    {
      key: 'divider1',
      itemType: 1,
    },
  ];

  if (canAdmin) {
    let adminOption: IContextualMenuItem;
    if (!isAdmin()) {
      adminOption = {
        key: 'adminMode',
        text: 'Run as Admin',
        iconProps: { iconName: 'Admin' },
        onClick: () => runAsAdministrator(),
      };
    } else {
      adminOption = {
        key: 'userMode',
        text: 'Run as User',
        iconProps: { iconName: 'FrontCamera' },
        onClick: () => runAsUser(),
      };
    }
    menuItems.push(adminOption);
  }

  menuItems.push(
    {
      key: 'switchDirectory',
      text: 'Switch Directory',
      iconProps: { iconName: 'Switch' },
      onClick: () => switchAADTenant(),
    },
    {
      key: 'divider2',
      itemType: 1,
    },
    {
      key: 'signOut',
      text: 'Sign Out',
      iconProps: { iconName: 'SignOut' },
      onClick: () => logOut(),
    }
  );

  return {
    isBeakVisible: false,
    items: menuItems,
    className: 'userActionsMenu',
    styles: userNameStyles,
  };
}
