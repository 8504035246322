import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ElxDropdown,
  ElxLink,
  ElxPanel,
  ElxSecondaryButton,
  ElxTextField,
  IContainerAction,
  InputMessageTypes,
  PanelSize,
} from '@elixir/fx';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { BasePanelProps } from 'components/panelManager/panelManager';
import {
  AadConnectionAuthType,
  AadConnectionAuthTypeDisplayNames,
  ConnectionDataSourceType,
  KustoDataConnection,
  KustoDataConnectionRequest,
} from 'features/workspaces/models/project';
import { IDropdownOption, Label, Stack } from '@fluentui/react';
import {
  Workspace,
  WorkspaceSaveStatus,
} from 'features/workspaces/models/workspace';
import { buildErrorMessage } from 'components/errorMessageBar/errorMessageBar';
import { SidePanel } from 'features/shell/lensShell';
import dataSourceClients, {
  getDataSourceClient,
} from 'features/dataSources/registry';
import { Database } from 'features/dataSources/models/dataSource';
import useDebounce from 'components/customHooks/useDebounce';
import {
  loadConnections,
  useConnections,
} from 'features/dataQuality/dataQualitySlice';
import {
  createDataConnection,
  loadProject,
  selectWorkspaceDataConnectionSaveStatus,
} from 'features/workspaces/workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import { v4 as uuidv4 } from 'uuid';
import { onRenderSpinnerOption } from 'components/lensElxUtils';
import { LensLabel } from 'utils/lensLabel';
import {
  AuthenticationModeHelpLabel,
  ConnectionNameHelpLabel,
  ConnectionTypeHelpLabel,
} from 'utils/helpIconText';
import {
  AuthType,
  FlatDataConnection,
  ResultDetail,
  StoreType,
  ValidateResult,
} from 'features/dataQuality/models/dataConnection';
import { dataQualityApi } from 'features/dataQuality/api/dataQualityApi';
import ChooseAapId from 'components/chooseAppId';

const dataConnectionsHelpLink =
  'https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensv3/jobauthoringdataconnections';

export interface EditDataConnectionProps extends BasePanelProps {
  workspace: Workspace;
  connectionId?: string;
  newConnectionName: (newDataConnection: string) => void;
  connectionNameList: string[];
}

export interface EditDataConnectionState {
  type: string;
  cluster: string;
  database: string;
  dataQualityServiceConnectionId: string;
  authMode: AuthType;
  appId: string;
}

const connectionTypeOptions: IDropdownOption[] = dataSourceClients.map(
  (dsc) => {
    return {
      key: dsc.dataSourceType,
      text: dsc.displayName,
    };
  }
);

const defaultDataSourceType = dataSourceClients[0].dataSourceType;

const authModeOptions: IDropdownOption[] = Object.keys(
  AadConnectionAuthType
).map((key) => {
  return {
    key,
    text: AadConnectionAuthTypeDisplayNames[key as AadConnectionAuthType],
  };
});

enum ClusterInfoStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

interface ClusterInfo {
  status: ClusterInfoStatus;
  databases?: Database[];
}

interface ClusterInfos {
  [clusterName: string]: ClusterInfo;
}

enum ValidateButtonStatus {
  NONE = 'NONE',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

const checkConnectionData = (dc: FlatDataConnection): boolean => {
  let res = true;
  for (const [, v] of Object.entries(dc)) {
    if (!v || v.length === 0) {
      res = false;
    }
  }
  return res;
};

export const EditDataConnection = (
  props: EditDataConnectionProps
): JSX.Element => {
  const {
    show,
    onShowPanel,
    onAddPanel,
    workspace,
    connectionId,
    newConnectionName,
    connectionNameList,
  } = props;
  const theme = useLensShellTheme();

  const dispatch = useDispatch<AppDispatch>();

  useConnections(workspace.id);

  const connection = workspace.dataConnections?.find(
    (connection) => connection.connectionName === connectionId
  );
  const kustoConnection =
    connection?.type === defaultDataSourceType
      ? (connection as KustoDataConnection)
      : undefined;
  const DataConnectionInitialState: EditDataConnectionState = {
    type: kustoConnection?.type || defaultDataSourceType,
    cluster: kustoConnection?.cluster || '',
    database: kustoConnection?.database || '',
    dataQualityServiceConnectionId:
      kustoConnection?.dataQualityServiceConnectionId || uuidv4(),
    authMode: AuthType.AadApp,
    appId: '',
  };
  const [state, setState] = useState<EditDataConnectionState>(
    DataConnectionInitialState
  );
  const [clusterInfos, setClusterInfos] = useState<ClusterInfos>({});
  const [customName, setCustomName] = useState<string>('');
  const [customNameFilled, setCustomNameFilled] = useState<boolean>(false);
  const [validateButtonStatus, setValidateButtonStatus] = useState(true);

  const clusterInfosRef = useRef<ClusterInfos>();
  clusterInfosRef.current = clusterInfos;

  const [databasesLoadingCluster, setDatabasesLoadingCluster] = useState('');
  const databasesLoadingClusterRef = useRef('');
  databasesLoadingClusterRef.current = databasesLoadingCluster;

  const savingStatus = useSelector(selectWorkspaceDataConnectionSaveStatus);
  const [errorMessage, setErrorMessage] = useState('');
  const debouncedCluster = useDebounce<string>(state.cluster, 500);
  const dataSourceClient = getDataSourceClient(state.type);
  const clusterHostname = state.cluster.replace(/(^\w+:|^)\/\//, '');
  let generatedName =
    clusterHostname && state.database && !customNameFilled
      ? (clusterHostname.split('.')[0] + '.' + state.database)
          .split(' ')
          .join('')
      : '';

  const [dataConnectionStatus, setDataConnectionStatus] =
    useState<ValidateButtonStatus>(ValidateButtonStatus.NONE);

  const currentName = customName || generatedName;
  const nameConflict = connectionNameList.includes(currentName);

  useEffect(() => {
    if (
      dataSourceClient &&
      debouncedCluster.length > 0 &&
      !clusterInfos[debouncedCluster]
    ) {
      setClusterInfos({
        ...clusterInfos,
        [debouncedCluster]: { status: ClusterInfoStatus.LOADING },
      });
      setDatabasesLoadingCluster(debouncedCluster);
      dataSourceClient
        .getDatabases(debouncedCluster)
        .then(function (databases) {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setClusterInfos({
              ...clusterInfosRef.current,
              [debouncedCluster]: {
                status: ClusterInfoStatus.LOADING,
                databases,
              },
            });
          }
        })
        .catch(function () {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setClusterInfos({
              ...clusterInfosRef.current,
              [debouncedCluster]: { status: ClusterInfoStatus.ERROR },
            });
          }
        })
        .finally(() => {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setDatabasesLoadingCluster('');
            setState((s) => ({ ...s, database: '' }));
          }
        });
    }
  }, [
    dataSourceClient,
    debouncedCluster,
    clusterInfos,
    databasesLoadingCluster,
  ]);

  const databaseOptions: IDropdownOption[] = databasesLoadingCluster
    ? [
        {
          key: 0,
          text: 'Loading databases...',
          data: { spinner: 'left' },
        },
      ]
    : clusterInfos[state.cluster]?.databases?.map((database) => {
        return {
          key: database.DatabaseName,
          text: database.PrettyName || database.DatabaseName,
        };
      }) || [];

  const aadAppOptions: IDropdownOption[] =
    workspace.applications?.map((app) => {
      return {
        key: app.id,
        text: app.id,
      };
    }) || [];

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Save',
        text: connectionId ? 'Save connection' : 'Add connection',
        isPrimary: true,
        disabled:
          savingStatus === WorkspaceSaveStatus.Saving ||
          state.appId === '' ||
          state.cluster === '' ||
          !clusterHostname ||
          state.database === '' ||
          state.type === '' ||
          nameConflict ||
          currentName === '',
        onClick: onSave,
      },
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
    ];
  }

  function onDismiss() {
    setClusterInfos({});
    setCustomName('');
    setCustomNameFilled(false);
    setDatabasesLoadingCluster('');
    setErrorMessage('');
    setState(DataConnectionInitialState);
    onShowPanel(false);
  }

  async function onSave() {
    setErrorMessage('');

    if (!workspace || !clusterHostname) {
      return; // won't get here because save button will be disabled.
    }

    try {
      var tenant = '';
      const workspaceId = workspace.id;
      const name = currentName;

      workspace.applications?.forEach((app) => {
        if (app.id === state.appId) {
          tenant = app.tenant;
        }
      });

      const connectionRequest: KustoDataConnectionRequest = {
        Name: name,
        Type: ConnectionDataSourceType.Kusto,
        ApplicationId: state.appId,
        AuthenticationMode: AadConnectionAuthType.AADApp,
        Tenant: tenant,
        Cluster: clusterHostname,
        Database: state.database,
        DataQualityServiceConnectionId: state.dataQualityServiceConnectionId,
      };
      const response = await dispatch(
        createDataConnection({ workspaceId, connectionRequest })
      ).then(unwrapResult);
      const connectionId = response.id;
      dispatch(loadConnections(workspace.id));
      dispatch(loadProject()).then(() => {
        newConnectionName(connectionId);
        onDismiss();
      });
    } catch (error) {
      setErrorMessage(
        (error as Error)?.message || (error as string) || 'unknown error'
      );
    } finally {
    }
  }

  const onDismissError = () => setErrorMessage('');
  const onShowNotifications = () => onAddPanel(SidePanel.NOTIFICATIONS);

  const onSelectHandler = (option: string) => {
    setState({ ...state, appId: option });
  };

  const getResultMsg = (results: ResultDetail[]): null | string => {
    let failed = false;
    results.forEach(
      (result) => (failed = failed || result.isAuthorized === false)
    );
    if (!failed) {
      setDataConnectionStatus(ValidateButtonStatus.VALID);
      return null;
    }
    setDataConnectionStatus(ValidateButtonStatus.INVALID);
    let msg: string = '';
    results.forEach((result) => {
      msg += `Permission: ${result.permission} - ${result.message} \n`;
    });

    return msg;
  };

  useEffect(() => {
    setErrorMessage('');
    setDataConnectionStatus(ValidateButtonStatus.NONE);
  }, [
    state.type,
    state.cluster,
    state.database,
    state.authMode,
    state.appId,
    customName,
    currentName,
    workspace,
  ]);

  let dc: FlatDataConnection = {
    connectionName:
      customName && customName.length > 0 ? customName : currentName,
    connectionDisplayName:
      customName && customName.length > 0 ? customName : currentName,
    type: state.type as StoreType,
    cluster: state.cluster,
    database: state.database,
    authType: state.authMode,
    principalId: state.appId,
    tenant:
      state.appId &&
      workspace.applications?.find((app) => app.id === state.appId)?.tenant,
  };

  let checkConnection = checkConnectionData(dc);

  const validateDataConnectionHandler = async () => {
    if (!workspace) {
      return;
    }
    setValidateButtonStatus(false);
    let result: ValidateResult =
      await dataQualityApi.postDataConnectionValidate(workspace.id, dc);
    let resultmsg = getResultMsg(result.results);
    setErrorMessage(resultmsg ?? '');
    setValidateButtonStatus(true);
  };

  const initDataConn = {
    width: '200px',
    height: '32px',
    borderRadius: '4px',
  };

  const invalidDataConn = {
    background: '#fb4343',
    border: '1px solid #ff0000',
  };

  const validDataConn = {
    background: '#E9F6E8',
    border: '1px solid #107C10',
  };

  const dcStyle =
    dataConnectionStatus !== ValidateButtonStatus.NONE
      ? dataConnectionStatus === ValidateButtonStatus.INVALID
        ? { ...initDataConn, ...invalidDataConn }
        : { ...initDataConn, ...validDataConn }
      : { ...initDataConn };

  const dcText =
    dataConnectionStatus !== ValidateButtonStatus.NONE
      ? dataConnectionStatus === ValidateButtonStatus.INVALID
        ? 'Connection Invalid'
        : 'Connection Valid'
      : 'Validate Connection';

  console.log(
    !checkConnection ||
      dataConnectionStatus !== ValidateButtonStatus.NONE ||
      !validateButtonStatus
  );

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={
          connectionId ? 'Edit data connection' : 'Add data connection'
        }
        headerContent={
          <span>
            {'Need additional help? '}
            <ElxLink
              style={{ verticalAlign: 'baseline' }}
              href={dataConnectionsHelpLink}
              target="_blank"
            >
              Learn more about data connections
            </ElxLink>
          </span>
        }
        isOpen={show}
        message={
          (errorMessage &&
            buildErrorMessage(
              errorMessage,
              onDismissError,
              onShowNotifications
            )) ||
          undefined
        }
        size={PanelSize.medium}
        onDismiss={onDismiss}
        actions={renderActions()}
        isLoading={savingStatus === WorkspaceSaveStatus.Saving}
        loadingLabel={
          savingStatus === WorkspaceSaveStatus.Saving ? 'Saving...' : ''
        }
      >
        <Stack tokens={{ childrenGap: 16, padding: 24 }} verticalFill>
          <Label>Workspace: {workspace.name} </Label>
          <ElxTextField
            label="Name"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Name"
                  hintText={ConnectionNameHelpLabel}
                  required={true}
                ></LensLabel>
              </>
            )}
            value={currentName}
            placeholder="Enter the data connection name"
            message={
              (nameConflict && {
                type: InputMessageTypes.Error,
                content: `A data connection named "${currentName}" already exists`,
              }) ||
              undefined
            }
            required
            onChange={(_, newValue) => {
              setCustomName(newValue || '');
              setCustomNameFilled(true);
            }}
          />
          <ElxDropdown
            label="Type"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Type"
                  hintText={ConnectionTypeHelpLabel}
                  required={true}
                ></LensLabel>
              </>
            )}
            options={connectionTypeOptions}
            selectedKey={state.type}
            required
            onChange={(_, option) =>
              setState({
                ...state,
                type: option?.key.toString() || defaultDataSourceType,
              })
            }
          />
          <ElxTextField
            label="Cluster"
            value={state.cluster}
            placeholder={
              'cluster_name.' +
              window.startUpConfig.serviceEndpoints.kustoClusterSuffix
            }
            required
            onChange={(_, newValue) => {
              setState({ ...state, cluster: newValue || '' });
            }}
          />
          {state.cluster && (
            <ElxDropdown
              label="Database"
              selectedKey={state.database}
              placeholder="Database"
              options={databaseOptions}
              onRenderOption={onRenderSpinnerOption}
              required
              onChange={(_, option) => {
                setState({ ...state, database: option?.key.toString() || '' });
              }}
            />
          )}
          <ElxDropdown
            label="Authentication mode"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Authentication Mode"
                  hintText={AuthenticationModeHelpLabel}
                  required={true}
                ></LensLabel>
              </>
            )}
            options={authModeOptions}
            selectedKey={state.authMode}
            required
            onChange={(_, option) =>
              setState({
                ...state,
                authMode: (option?.key as AuthType) || AuthType.AadApp,
              })
            }
          />

          {state.authMode === AuthType.AadApp && (
            <ChooseAapId
              options={aadAppOptions}
              onSelect={onSelectHandler}
              selectedAppId={state.appId}
            ></ChooseAapId>
          )}
          <Stack verticalAlign="end" verticalFill>
            <ElxSecondaryButton
              text={dcText}
              onClick={validateDataConnectionHandler}
              disabled={
                !checkConnection ||
                dataConnectionStatus !== ValidateButtonStatus.NONE ||
                !validateButtonStatus
              }
              style={dcStyle}
              iconProps={{
                iconName:
                  dataConnectionStatus !== ValidateButtonStatus.NONE
                    ? dataConnectionStatus === ValidateButtonStatus.INVALID
                      ? 'Cancel'
                      : 'CheckMark'
                    : '',
              }}
            />
            {dataConnectionStatus === ValidateButtonStatus.INVALID && (
              <span style={{ color: 'red' }}>
                Data connection is not correctly set!
              </span>
            )}
          </Stack>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default EditDataConnection;
