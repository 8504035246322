import React, { useMemo, useState } from 'react';
import {
  ElxActionButton,
  ElxLink,
  ElxPanel,
  ElxTable,
  IContainerAction,
  PanelSize,
} from '@elixir/fx';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import Dataset from 'features/dataQuality/models/dataset';
import {
  DataQualityStatus,
  ExpectationInfo,
  selectDataQualityRuleResultsByRuleId,
  selectDataQualityRuleResultsStatus,
  useRuleResults,
} from 'features/dataQuality/dataQualitySlice';
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  FontIcon,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import { Card } from 'components/cards/card';
import { useSelector } from 'react-redux';
import { RootState } from 'app/lensShellUtility';
import { getUserExpectationFromRule } from 'features/dataQuality/userExpectations/userExpectationsRegistry';
import SingleResult from './singleResult';
import { ResultsTableItem } from 'features/dataQuality/userExpectations/base/userExpectationResultsBuilder';
import LabelAndValue from 'components/labelAndValue/labelAndValue';
import { QualityReportPanel } from '../qualityReport/qualityReport';
import { HistoricalDetailsHelpLabel } from 'utils/helpIconText';

export interface ResultDetailsProps extends BasePanelProps {
  workspaceId: string;
  dataset: Dataset;
  expectationInfo: ExpectationInfo;
}

export const ResultDetails = (props: ResultDetailsProps): JSX.Element => {
  const {
    show,
    onShowPanel,
    onSwitchPanel,
    workspaceId,
    dataset,
    expectationInfo,
  } = props;
  const theme = useLensShellTheme();

  const ruleId = expectationInfo.rule.id;
  useRuleResults(workspaceId, dataset.id, ruleId);

  const ruleResultsStatus = useSelector(selectDataQualityRuleResultsStatus);
  const ruleResultStatus = ruleResultsStatus[ruleId];
  const ruleResults = useSelector((state: RootState) =>
    selectDataQualityRuleResultsByRuleId(state, ruleId)
  );

  const resultsTable = useMemo(() => {
    if (ruleResultStatus === DataQualityStatus.Loaded && ruleResults) {
      const userExpectation = getUserExpectationFromRule(expectationInfo.rule);
      return userExpectation?.getResultsTable(ruleResults.results);
    }
  }, [ruleResults, ruleResultStatus, expectationInfo.rule]);

  const [index, setIndex] = useState<number>(0);
  const [historyMode, setHistoryMode] = useState<boolean>(false);

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
    ];
  }

  function onDismiss() {
    setHistoryMode(false);
    setIndex(0);
    onShowPanel(false);
  }

  function onHistorySelect(item?: ResultsTableItem, index?: number) {
    setHistoryMode(true);
    setIndex(index || 0);
  }

  function onClickBack() {
    setHistoryMode(false);
    setIndex(0);
  }

  const onClickNext = () => setIndex(Math.max(0, index - 1));
  const onClickPrev = () =>
    setIndex(Math.min(index + 1, (resultsTable?.items.length || 0) - 1));

  function onShowExpectation() {
    setHistoryMode(false);
    setIndex(0);
    onSwitchPanel(QualityReportPanel.EXPECTATION);
  }

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={
          historyMode ? 'Historical Result Details' : 'Result Details'
        }
        headerContent={
          <Stack horizontal horizontalAlign="space-between">
            {historyMode ? (
              <ElxActionButton
                iconProps={{ iconName: 'Back' }}
                text="Back to Result Details"
                onClick={onClickBack}
              />
            ) : (
              <Stack.Item>&nbsp;</Stack.Item>
            )}
            <ElxLink
              style={{ verticalAlign: 'baseline', marginRight: 16, height: 22 }}
              onClick={onShowExpectation}
            >
              Go to this Expectation&nbsp;
              <FontIcon iconName="OpenInNewWindow" />
            </ElxLink>
          </Stack>
        }
        isOpen={show}
        isLoading={
          !ruleResultStatus || ruleResultStatus === DataQualityStatus.Loading
        }
        size={PanelSize.large}
        fillBackground={true}
        onDismiss={onDismiss}
        actions={renderActions()}
      >
        {ruleResultStatus === DataQualityStatus.Loaded && (
          <Stack tokens={{ childrenGap: 16, padding: 16 }}>
            {historyMode && resultsTable && (
              <>
                <Stack horizontal horizontalAlign="space-between">
                  <Stack.Item align="start">
                    <ElxActionButton
                      disabled={index >= resultsTable.items.length - 1}
                      onClick={onClickPrev}
                    >
                      <FontIcon iconName="ChevronLeft" />
                      &nbsp;Previous Execution
                    </ElxActionButton>
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxActionButton
                      disabled={index === 0}
                      onClick={onClickNext}
                    >
                      Next Execution&nbsp;
                      <FontIcon iconName="ChevronRight" />
                    </ElxActionButton>
                  </Stack.Item>
                </Stack>
                <Card collapsible title="Execution Details">
                  <LabelAndValue label="Execution Date">
                    {resultsTable.items[index].date}
                  </LabelAndValue>
                </Card>
              </>
            )}
            <SingleResult
              expectationInfo={expectationInfo}
              result={ruleResults?.results[index]}
            />
            {!historyMode && (
              <Card
                collapsible
                title="Historical Details"
                tokens={{ childrenGap: 8, padding: 8 }}
                hintText={HistoricalDetailsHelpLabel}
              >
                <Stack>
                  <ElxTable
                    columns={resultsTable?.columns || []}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    selectionMode={SelectionMode.single}
                    key={resultsTable?.items[index]?.key}
                    items={resultsTable?.items || []}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onActiveItemChanged={onHistorySelect}
                  />
                </Stack>
              </Card>
            )}
          </Stack>
        )}
      </ElxPanel>
    </ThemeProvider>
  );
};

export default ResultDetails;
