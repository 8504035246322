import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelTokenSource,
  AxiosResponse,
  AxiosError,
} from 'axios';
import constants from 'utils/constants';
import { _, acquireToken } from 'utils/sharedLibs';
import { utils } from 'utils/utils';
import { userUtils } from 'utils/userUtils';
import { isAdmin } from 'utils/authUtils';

export enum HttpHeaders {
  LensAdmin = 'lens-admin',
  LensRetries = 'lens-retries',
  LensRetry = 'lens-retry',
  XMsApp = 'x-ms-app',
  XMsClientRequestId = 'x-ms-client-request-id',
  XMsUserId = 'x-ms-user-id',
}

/**
 * Represents a base class for REST API clients.
 */
export abstract class RestApi {
  private static readonly DefaultHttpRequestTimeout = 180000; // 3 minutes in msec
  private static readonly DefaultHttpRequestRetries = 2;
  private static readonly MaxHttpRequestRetries = 10;
  private static readonly HttpRetryDelayTimeMsec = 500;

  protected readonly api: AxiosInstance;

  /**
   * Creates a new instance of the REST API client.
   * @param baseUrl - The API's base URL.
   * @param resource - The resource id to acquire the bearer token.
   */
  constructor(baseUrl: string, resource: string) {
    let headers: any = {};
    headers[HttpHeaders.XMsApp] = constants.ApplicationName;
    headers[HttpHeaders.XMsUserId] = userUtils.getObjectId();

    this.api = axios.create({
      baseURL: baseUrl,
      timeout: RestApi.DefaultHttpRequestTimeout, // msec
      headers: headers,
    });

    // Intercept requests
    this.api.interceptors.request.use(
      (config) => {
        if (!config.headers[HttpHeaders.XMsClientRequestId]) {
          config.headers[HttpHeaders.XMsClientRequestId] = utils.newGuid();
        }

        if (isAdmin()) {
          config.headers[HttpHeaders.LensAdmin] = true;
        }

        // Add retry parameters to the request headers so that they are available in the response interceptor.
        let retries = config.headers[HttpHeaders.LensRetries];
        if (!_.isNumber(retries)) {
          retries = RestApi.DefaultHttpRequestRetries;
        }
        config.headers[HttpHeaders.LensRetries] = Math.min(
          retries,
          RestApi.MaxHttpRequestRetries
        );

        let retry = config.headers[HttpHeaders.LensRetry];
        if (!_.isNumber(retry)) {
          retry = 0;
        }
        config.headers[HttpHeaders.LensRetry] = Math.max(0, retry);

        // Acquire the bearer token
        return new Promise((resolve, reject) => {
          acquireToken(resource).then((token: string) => {
            if (!token) reject(config);

            config.headers.Authorization = `Bearer ${token}`;
            resolve(config);
          });
        });
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Intercept responses
    this.api.interceptors.response.use(
      // onFulfilled handes any status code within the range of 2xx
      (f) => f,
      // onRejected handles any status code outside of the range of 2xx
      (error) => {
        // Do not retry a cancelled request.
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        // Check if the HTTP status code should be retried. Retry on:
        // -1 - client-side errors, client could not create connection to the server
        // 401 - Not Authorized, allow ADAL to refresh the token
        // 5xx - server-side errors, mostly 500 Internal Server Error, 503 Service Unavailable
        const status = error.response.status;
        if (status !== -1 && status !== 401 && status < 500) {
          return Promise.reject(error);
        }

        // Check if any retries are remaining.
        const config = error.config;
        let retries = config.headers[HttpHeaders.LensRetries];
        if (!_.isNumber(retries) || retries <= 0) {
          return Promise.reject(error);
        }
        config.headers[HttpHeaders.LensRetries] = retries - 1;

        // Retry with exponential backoff.
        // 1st retry: 2^1 * 500 = 1s
        // 2st retry: 2^2 * 500 = 2s
        let retry = config.headers[HttpHeaders.LensRetry];
        if (!_.isNumber(retry)) {
          return Promise.reject(error);
        }
        config.headers[HttpHeaders.LensRetry] = retry = Math.max(0, retry + 1);
        let delay = Math.pow(2, retry) * RestApi.HttpRetryDelayTimeMsec;
        return utils.wait(delay).then(() => this.api.request(config));
      }
    );
  }

  /**
   * Gets an entity. Can be used for REST-ful APIs that return the entity in the response.
   * @param url - The URL relative to this API's base URL.
   * @param config - The optional request configuration.
   * @returns the entity.
   */
  protected getEntity = async <T>(
    url: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> => {
    return await this.api.get<T>(url, config).then((resp) => resp && resp.data);
  };

  /**
   * Deletes an entity. Can be used for REST-ful APIs that return the entity in the response.
   * @param url - The URL relative to this API's base URL.
   * @param config - The optional request configuration.
   * @returns the deleted entity.
   */
  protected deleteEntity = async <T>(
    url: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> => {
    return await this.api
      .delete<T>(url, config)
      .then((resp) => resp && resp.data);
  };

  /**
   * Creates an entity. Can be used for REST-ful APIs that take the entity in the request and return the entity in the response.
   * @param url - The URL relative to this API's base URL.
   * @param entity - The entity to create.
   * @param config - The optional request configuration.
   * @returns the created entity.
   */
  protected postEntity = async <T, R>(
    url: string,
    entity: T,
    config?: AxiosRequestConfig | undefined
  ): Promise<R> => {
    return await this.api
      .post<T, AxiosResponse<R>>(url, entity, config)
      .then((resp) => resp && resp.data);
  };

  /**
   * Updates an entity. Can be used for REST-ful APIs that take the entity in the request and return the entity in the response.
   * @param url - The URL relative to this API's base URL.
   * @param entity - The entity to update.
   * @param config - The optional request configuration.
   * @return the updated entity.
   */
  protected putEntity = async <T>(
    url: string,
    entity: T,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> => {
    return await this.api
      .put<T>(url, entity, config)
      .then((resp) => resp && resp.data);
  };

  /**
   * Initializes cancellation for a request.
   * @param config - The request configuration.
   * @param cancelToken - The optional cancellation token source. Can be used to cancel multiple requests.
   * @returns The cancellation token source.
   * @example
   * ```
   * const config = {}
   * const source = api.initCancellation(config)
   * api.getEntity<Entity>(url, config).then(entity => {...})
   * ...
   * api.cancel(source) // or source.cancel()
   * ```
   */
  public initCancellation(
    config: AxiosRequestConfig,
    source?: CancelTokenSource
  ): CancelTokenSource {
    if (config.cancelToken) throw Error('cancellation already configured');
    if (!source) {
      source = axios.CancelToken.source();
    }
    config.cancelToken = source.token;
    return source;
  }

  /**
   * Cancels a request. `source.cancel()` can also be used instead.
   * @param source - The cancellation token source.
   * @param message - The optional cancellation message.
   */
  public cancel(source: CancelTokenSource, message?: string | undefined) {
    source.cancel(message);
  }

  /**
   * Helper function to identify rest api 404: not found errors
   * @param error
   * @returns true if error is an http 404: not found error
   */
  public isNotFoundError(error: AxiosError | any) {
    const axiosError = error as AxiosError;
    return axiosError?.isAxiosError && axiosError?.response?.status === 404;
  }

  /**
   * Helper function to identify rest api 409: conflict errors
   * @param error
   * @returns true if error is an http 409: conflict error
   */
  public isConflictError(error: AxiosError | any) {
    const axiosError = error as AxiosError;
    return axiosError?.isAxiosError && axiosError?.response?.status === 409;
  }
}
