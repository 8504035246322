import { LeApi } from 'api/leApi';
import { Workspace, WorkspaceBeforeFormat } from '../models/workspace';
import { DataConnectionRequest, Project } from '../models/project';

class WorkspacesApi extends LeApi {
  public getWorkspace = async (
    workspaceId: string
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.getEntity<WorkspaceBeforeFormat>(
      '/v1/workspaces/' + workspaceId
    );
  };

  public getProject = async (workspaceId: string): Promise<Project> => {
    return await this.getEntity<Project>(
      '/v1/workspaces/' + workspaceId + '/orchestratorProject'
    );
  };

  public getWorkspaceList = async (): Promise<Workspace[]> => {
    return await this.getEntity<Workspace[]>('/v1/workspaces/');
  };

  public postEnableOrchestration = async (
    workspaceId: string
  ): Promise<any> => {
    return await this.postEntity<any, any>(
      'v1/workspaces/' + workspaceId + '/enableOrchestration',
      ''
    );
  };

  public postConnection = async (
    workspaceId: string,
    connectionRequest: DataConnectionRequest
  ): Promise<any> => {
    return await this.postEntity<DataConnectionRequest, string>(
      '/v1/workspaces/' + workspaceId + '/datasources',
      connectionRequest
    );
  };

  public putConnection = async (
    workspaceId: string,
    connectionRequest: DataConnectionRequest
  ): Promise<any> => {
    return await this.putEntity<DataConnectionRequest>(
      '/v1/workspaces/' + workspaceId + '/datasources/' + connectionRequest.Id,
      connectionRequest
    );
  };

  public deleteWorkspace = async (workspaceId: string): Promise<Workspace> => {
    return await this.deleteEntity<Workspace>('/v1/workspaces/' + workspaceId);
  };

  public saveWorkspace = async (
    workspaceId: string,
    workspace: WorkspaceBeforeFormat
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.putEntity<WorkspaceBeforeFormat>(
      '/v1/workspaces/' + workspaceId,
      workspace
    );
  };
}

export const workspacesApi = new WorkspacesApi();

export default workspacesApi;
