export interface workspaceItem {
  workspaceId?: string;
  name: string;
  isFavorite: boolean;
  isRecent?: boolean;
  isCurrent?: boolean;
  createdBy: string;
  accessType: string;
  accessIcon: string;
}

export const workspaceAccessFilter = (accessFilter: string) => {
  switch (accessFilter) {
    case 'owned': {
      return 'Owned by me';
    }
    case 'admin': {
      return 'Admin Access';
    }
    case 'orchestrate': {
      return 'Orchestrate Access';
    }
    case 'write': {
      return 'Write Access';
    }
    case 'read': {
      return 'Read Access';
    }
    case 'none': {
      return 'Unauthorized';
    }
    default: {
      return 'none';
    }
  }
};

export const workspaceAccessIcon = (accessFilter: string) => {
  switch (accessFilter) {
    case 'owned': {
      return 'Permissions';
    }
    case 'admin': {
      return 'Settings';
    }
    case 'orchestrate': {
      return 'BranchFork2';
    }
    case 'write': {
      return 'Edit';
    }
    case 'read': {
      return 'ReadingMode';
    }
    case 'none': {
      return 'ProtectRestrict';
    }
    default: {
      return 'none';
    }
  }
};
