import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router';
import {
  DataQualityStatus,
  useExpectations,
  selectDataQualityExpectationEntities,
  ExpectationInfo,
  useDataset,
  selectDataQualityDatasetById,
  useConnections,
  selectDataQualityConnectionEntities,
  selectDataQualityConnectionsStatus,
  selectDataQualityDatasetsExpectationsStatus,
  loadDataset,
  loadConnections,
  loadExpectations,
  clearRuleResults,
  selectDataQualityDatasetsStatus,
  deleteExpectation,
  executeRule,
} from '../../dataQualitySlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel as showPanel,
  panelDefaults,
} from 'components/panelManager/panelManager';
import EditExpectation from '../editExpectation/editExpectation';
import EditNotification from '../editNotification/editNotification';
import { ExpectationsList, ExpectationsListItem } from './expectationsList';
import { getUserExpectationFromRule } from '../../userExpectations/userExpectationsRegistry';
import {
  ClassifyMode,
  classifyModeDisplayName,
  ClassifyResultsState,
} from '../classifyResults/classifyResults';
import { Dictionary, unwrapResult } from '@reduxjs/toolkit';
import { decodeSeverity, decodeThreshold } from '../classifyResults/threshold';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import Notifications from 'features/shell/components/notifications/notifications';
import ResultDetails from '../resultDetails/resultDetails';
import { SidePanel } from 'features/shell/lensShell';
import Dataset from 'features/dataQuality/models/dataset';
import LatestResult from './latestResult';
import {
  selectWorkspace,
  selectWorkspaceStatus,
} from 'features/workspaces/workspaceSlice';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';
import BatchDelete, {
  useBatchDelete,
} from 'components/batchDelete/batchDelete';
import { v4 as uuidv4 } from 'uuid';
import BatchSubmit, { useBatchSubmit } from './batchSubmit';
import StandardUnits, {
  ThresholdUnit,
} from '../classifyResults/thresholdUnits';

export enum QualityReportPanel {
  EXPECTATION = 'Expectation',
  NOTIFICATION_SETTINGS = 'NotificationSettings',
  RESULT_DETAILS = 'ResultDetails',
}

export interface QualityReportState {}

export interface QualityReportProps {
  workspaceId: string;
}

interface QualityReportRouteParams {
  datasetId: string;
}

type QualityReportRouteProps = RouteComponentProps<QualityReportRouteParams>;

/**
 * Container component combines data from Expectations, Connections and Catalog slices into props for the expectation list component
 * @param props
 * @returns
 */
const QualityReport = ({
  workspaceId,
  match,
}: QualityReportProps & QualityReportRouteProps): JSX.Element => {
  const panelState = usePanelManagerState();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const workspaceStatus = useSelector(selectWorkspaceStatus);
  const workspace = useSelector(selectWorkspace);

  const datasetId = match.params.datasetId;
  useDataset(workspaceId, datasetId);
  const dataset = useSelector((state: RootState) =>
    selectDataQualityDatasetById(state, datasetId)
  );
  const datasetsStatus = useSelector(selectDataQualityDatasetsStatus);

  useConnections(workspaceId);
  const connectionEntities = useSelector(selectDataQualityConnectionEntities);
  const connectionsStatus = useSelector(selectDataQualityConnectionsStatus);

  useExpectations(workspaceId, datasetId);
  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  const [items, setItems] = useState<ExpectationsListItem[]>([]);
  useEffect(() => {
    setItems(createItems(expectationEntities, workspaceId, datasetId, dataset));
  }, [expectationEntities, workspaceId, datasetId, dataset]);

  const [selectedExpectation, setSelectedExpectation] =
    useState<ExpectationInfo>();

  const [isClone, setIsClone] = useState<boolean>(false);

  const [batchDeleteState, onDeleteItems] = useBatchDelete();

  const [batchSubmitState, onSubmitItems] = useBatchSubmit(submitItem, 'id');

  const connection =
    datasetsStatus[datasetId] === DataQualityStatus.Loaded &&
    dataset &&
    connectionsStatus === DataQualityStatus.Loaded
      ? connectionEntities[dataset.dataConnectionName]
      : undefined;

  function onEditExpectation(item?: ExpectationsListItem, isClone = false) {
    setIsClone(isClone);
    setSelectedExpectation(item ? expectationEntities[item.id] : undefined);
    showPanel(panelState, QualityReportPanel.EXPECTATION);
  }

  function onNotificationSettings(item: ExpectationsListItem) {
    setSelectedExpectation(expectationEntities[item.id]);
    showPanel(panelState, QualityReportPanel.NOTIFICATION_SETTINGS);
  }

  function submitItem(item: ExpectationsListItem) {
    const expectationInfo = expectationEntities[item.id];
    if (expectationInfo) {
      setSelectedExpectation(expectationEntities[item.id]);
      return dispatch(
        executeRule({
          workspaceId,
          datasetId,
          ruleId: expectationInfo.rule.id,
          name: 'execute_' + uuidv4(),
        })
      ).then(unwrapResult);
    }
    return Promise.reject();
  }

  function onRefresh() {
    dispatch(clearRuleResults());
    dispatch(loadDataset({ workspaceId, datasetId }));
    dispatch(loadConnections(workspaceId));
    dispatch(loadExpectations({ workspaceId, datasetId }));
  }

  async function deleteItem(item: ExpectationsListItem) {
    const expectationInfo = expectationEntities[item.id];
    if (expectationInfo) {
      const returned = await dispatch(
        deleteExpectation({ workspaceId, expectationInfo })
      );
      return unwrapResult(returned);
    }
    return Promise.resolve(); // not supposed to happen, but nothing will be deleted.
  }

  function onResultDetails(item: ExpectationsListItem) {
    const expectationInfo = expectationEntities[item.id];
    if (expectationInfo) {
      setSelectedExpectation(expectationEntities[item.id]);
      showPanel(panelState, QualityReportPanel.RESULT_DETAILS);
    }
  }

  // if dataset can't be loaded, or user is in wrong workspace, reset to Datasets list
  useEffect(() => {
    if (
      datasetsStatus[datasetId] === DataQualityStatus.Error ||
      (datasetsStatus[datasetId] === DataQualityStatus.Loaded &&
        dataset &&
        workspaceStatus === WorkspaceStatus.Loaded &&
        workspace &&
        dataset.workspaceId !== workspace.dqsWorkspace)
    ) {
      history.replace('/dataquality/datasets');
    }
  }, [dataset, datasetId, datasetsStatus, history, workspace, workspaceStatus]);

  return (
    <>
      <ExpectationsList
        datasetName={dataset?.datasetName}
        items={items}
        isLoading={
          !expectationsStatus ||
          expectationsStatus === DataQualityStatus.Loading ||
          datasetsStatus[datasetId] === DataQualityStatus.Loading ||
          !dataset ||
          !connection
        }
        loadingLabel="Loading..."
        onEditExpectation={onEditExpectation}
        onNotificationSettings={onNotificationSettings}
        onRefresh={onRefresh}
        onDeleteItems={onDeleteItems}
        onResultDetails={onResultDetails}
        onExecuteRules={onSubmitItems}
      />
      <BatchDelete
        batchDeleteState={batchDeleteState}
        deleteItemFn={deleteItem}
        itemIdPropName="id"
        itemDescriptionPropName="expectation"
        singular="expectation"
        plural="expectations"
      />
      <BatchSubmit
        batchSubmitState={batchSubmitState}
        itemIdPropName="id"
        itemDescriptionPropName="expectation"
        singular="expectation"
        plural="expectations"
      />
      <PanelManager panelState={panelState}>
        {dataset && connection && (
          <EditExpectation
            {...panelDefaults}
            panelId={QualityReportPanel.EXPECTATION}
            workspaceId={workspaceId}
            dataset={dataset}
            connection={connection}
            expectationInfo={selectedExpectation}
            isClone={isClone}
          />
        )}
        <EditNotification
          {...panelDefaults}
          panelId={QualityReportPanel.NOTIFICATION_SETTINGS}
          workspaceId={workspaceId}
          dataset={dataset}
          connection={connection}
          expectationInfo={selectedExpectation}
        />
        {datasetsStatus[datasetId] === DataQualityStatus.Loaded &&
          dataset &&
          selectedExpectation && (
            <ResultDetails
              {...panelDefaults}
              panelId={QualityReportPanel.RESULT_DETAILS}
              workspaceId={workspaceId}
              dataset={dataset}
              expectationInfo={selectedExpectation}
            />
          )}
        <Notifications panelId={SidePanel.NOTIFICATIONS} {...panelDefaults} />
      </PanelManager>
    </>
  );
};

interface ClassificationItem {
  start: string;
  end: string;
  classification: string;
}

function decodeClassifications(
  expectationInfo: ExpectationInfo,
  unit: ThresholdUnit
): ClassificationItem[] {
  let classifications: ClassificationItem[] = [];
  expectationInfo.alerts.forEach((alert) => {
    alert.expectations.forEach((expectation) => {
      const threshold = decodeThreshold(expectation);
      if (threshold) {
        const severity = decodeSeverity(expectation);
        const classification = severity ? `Sev ${severity}` : '';
        const from = threshold.from || (unit.isPercent ? 0 : -Infinity);
        const to = threshold.to || (unit.isPercent ? 100 : Infinity);
        classifications.push({
          start: unit.getDisplayString(from, 2),
          end: unit.getDisplayString(to, 2),
          classification,
        });
      }
    });
  });
  return classifications;
}

function createItems(
  expectationInfos: Dictionary<ExpectationInfo>,
  workspaceId: string,
  datasetId: string,
  dataset?: Dataset
): ExpectationsListItem[] {
  function createItem(
    id: string,
    expectationInfo: ExpectationInfo
  ): ExpectationsListItem {
    const userExpectation = getUserExpectationFromRule(expectationInfo.rule);
    const expectationState = userExpectation?.getInitialState(expectationInfo);
    const classifyState = expectationState as ClassifyResultsState;
    const unit =
      userExpectation?.getUnit(expectationInfo) || StandardUnits.NUMBER;
    const classifications = decodeClassifications(expectationInfo, unit);

    let item = {
      id,
      result:
        (dataset && (
          <LatestResult
            workspaceId={workspaceId}
            dataset={dataset}
            expectationInfo={expectationInfo}
          />
        )) ||
        '',
      expectation:
        userExpectation?.getDescriptionElement(expectationState, dataset) ||
        userExpectation?.description ||
        '',
      expectationText:
        userExpectation?.getDescriptionElement(expectationState, dataset).props
          .title || '',
      classifyMode:
        classifyModeDisplayName.get(
          classifyState?.classifyMode || ClassifyMode.OBSERVE
        ) || '',
      classifyCount: classifications.length,
    } as ExpectationsListItem;

    classifications.forEach((c: ClassificationItem, i: number) => {
      const n = i + 1; // Note that these ranges are 1-based for user-friendliness in the table column names
      item['range' + n + 'start'] = c.start;
      item['range' + n + 'end'] = c.end;
      item['range' + n + 'classification'] = c.classification;
    });

    return item;
  }

  return Object.keys(expectationInfos)
    .filter((key) => expectationInfos[key]?.rule.datasetId === datasetId)
    .map((key) =>
      createItem(key, expectationInfos[key] as ExpectationInfo)
    ) as ExpectationsListItem[];
}

export default withRouter(QualityReport);
