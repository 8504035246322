import { IMessage } from '@elixir/fx';
import { MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

export interface ErrorMessageBarProps {
  message: string;
  onShowNotifications?: () => void;
  onDismiss: () => void;
}

// const NotificationsLink = ({ onShowNotifications } :{ onShowNotifications: () => void }): JSX.Element =>
//     <ElxLink
//         style={{ fontSize: 12, marginTop: '-50px', marginRight: '35px'}} // match to message bar
//         onClick={onShowNotifications}
//     >
//         see notifications
//     </ElxLink>

export function buildErrorMessage(
  message: string,
  onDismiss: () => void,
  onShowNotifications: () => void
): IMessage {
  return {
    message,
    type: MessageBarType.error,
    isMultiline: true,
    onDismiss,
    // actions: (onShowNotifications && <NotificationsLink onShowNotifications={onShowNotifications} />) || undefined,
  };
}

export const ErrorMessageBar = ({
  message,
  onShowNotifications,
  onDismiss,
}: ErrorMessageBarProps): JSX.Element => {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      onDismiss={onDismiss}
      dismissButtonAriaLabel="Close"
      // actions={onShowNotifications && <NotificationsLink onShowNotifications={onShowNotifications} />}
    >
      {message}
    </MessageBar>
  );
};

export default ErrorMessageBar;
