import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import {
  SearchBox,
  Stack,
  FontIcon,
  Label,
  Pivot,
  PivotItem,
  List,
} from '@fluentui/react';
import {
  ElxActionButton,
  ElxIconButton,
  ElxLink,
  ElxPanel,
  IContainerAction,
  PanelSize,
} from '@elixir/fx';
import {
  useUserprofile,
  selectUserprofile,
  selectUserprofileStatus,
  loadUserprofile,
  saveFavorites,
  saveRecents,
} from 'features/userprofile/userprofileSlice';
import {
  selectAllWorkspaces,
  useWorkspaceList,
  selectWorkspaceListStatus,
} from 'features/workspaces/workspaceListSlice';
import { useRouter } from '@uirouter/react';
import {
  Userprofile,
  UserprofileStatus,
} from 'features/userprofile/models/userprofile';
import {
  AccessIcon,
  AccessLabel,
  itemAlignmentsStackTokens,
  searchStyles,
  stackItemStyles,
  labelStyles1,
  labelStyles2,
  labelStyles3,
  stackStyles,
  classNames,
} from './workspacesStyles';
import {
  workspaceItem,
  workspaceAccessFilter,
  workspaceAccessIcon,
} from '../utils/workspaceQuickSwitchUtils';
import {
  Workspace,
  WorkspaceListStatus,
} from 'features/workspaces/models/workspace';
import {
  getCurrentWorkspaceId,
  getPrivateWorkspaceId,
  switchToWorkspace,
} from '../utils/workspaceUtils';
import { selectWorkspaceId } from '../workspaceSlice';

interface Items {
  favorites: workspaceItem[];
  all: workspaceItem[];
}
const favoritesAndRecents = (
  currentWorkspaceId: string,
  userprofile: Userprofile,
  workspaceList: Workspace[],
  privateWorkspace: workspaceItem
) => {
  let favoritesAndRecentsList: workspaceItem[] = [];
  let allWorkspaceList: workspaceItem[] = [];
  workspaceList.forEach((workspace: Workspace) => {
    let isFavorite =
      (userprofile.FavoritedWorkspaces &&
        userprofile.FavoritedWorkspaces.indexOf(workspace.id) !== -1) ||
      false;
    let isRecent =
      (userprofile.RecentWorkspaces &&
        userprofile.RecentWorkspaces.indexOf(workspace.id) !== -1) ||
      false;
    let isCurrent = workspace.id === currentWorkspaceId;
    let item: workspaceItem = {
      workspaceId: workspace.id,
      name: workspace.name,
      isFavorite: isFavorite,
      isRecent: isRecent,
      isCurrent: isCurrent,
      createdBy: workspace.createdBy,
      accessType: workspaceAccessFilter(workspace.accessFilter),
      accessIcon: workspaceAccessIcon(workspace.accessFilter),
    };
    if (isCurrent) {
      favoritesAndRecentsList.unshift(item);
      allWorkspaceList.unshift(item);
    } else {
      if (isFavorite || isRecent) {
        favoritesAndRecentsList.push(item);
      }
      allWorkspaceList.push(item);
    }
  });
  favoritesAndRecentsList.unshift(privateWorkspace);
  allWorkspaceList.unshift(privateWorkspace);

  return { all: allWorkspaceList, favorites: favoritesAndRecentsList };
};

const updateResultsForSearch = (searchString: string, items: Items) => {
  let favorites: workspaceItem[] = [];
  let all: workspaceItem[] = [];
  if (searchString.length > 0) {
    items.favorites.forEach((workspace: workspaceItem, index) => {
      if (workspace.name.toLowerCase().includes(searchString.toLowerCase())) {
        favorites.push(workspace);
      }
    });
    items.all.forEach((workspace: workspaceItem, index) => {
      if (workspace.name.toLowerCase().includes(searchString.toLowerCase())) {
        all.push(workspace);
      }
    });
  }
  return { all, favorites };
};

const getPrivateWorkspace = (
  upn: string,
  currentWorkspaceId: string
): workspaceItem => {
  return {
    name: 'Private Workspace',
    isFavorite: true,
    isCurrent: currentWorkspaceId === getPrivateWorkspaceId(),
    createdBy: upn,
    accessType: workspaceAccessFilter('owned'),
    accessIcon: workspaceAccessIcon('owned'),
  };
};

//WorkspaceQuickSwitch Panel
export const WorkspaceQuickSwitch = ({
  onShowPanel,
  show,
}: BasePanelProps): JSX.Element => {
  useUserprofile();
  useWorkspaceList();
  const currentWorkspaceId = useSelector(selectWorkspaceId);
  const workspaceListStatus = useSelector(selectWorkspaceListStatus);
  const workspaceList = useSelector(selectAllWorkspaces);
  const userProfileStatus = useSelector(selectUserprofileStatus);
  const userprofile = useSelector(selectUserprofile);
  const router = useRouter();
  const dispatch = useDispatch();

  const [items, setItems] = useState<Items>({ all: [], favorites: [] });

  const [filteredItems, setFilteredItems] = useState<Items>({
    all: [],
    favorites: [],
  });
  const [search, setSearch] = useState({ searchString: '' });

  const privateWorkspace = useMemo(
    () => getPrivateWorkspace(userprofile?.UPN, currentWorkspaceId),
    [userprofile?.UPN, currentWorkspaceId]
  );

  useEffect(() => {
    if (
      userProfileStatus === UserprofileStatus.Loaded &&
      workspaceListStatus === WorkspaceListStatus.Loaded
    ) {
      const { all, favorites } = favoritesAndRecents(
        currentWorkspaceId,
        userprofile,
        workspaceList,
        privateWorkspace
      );
      setItems({ all, favorites });
    }
  }, [
    currentWorkspaceId,
    userProfileStatus,
    workspaceListStatus,
    workspaceList,
    userprofile,
    privateWorkspace,
  ]);

  useEffect(() => {
    if (search.searchString.length > 0) {
      const { all, favorites } = updateResultsForSearch(
        search.searchString,
        items
      );
      setFilteredItems({ all, favorites });
    } else {
      setFilteredItems(items);
    }
  }, [search, items]);

  const onDismiss = () => {
    onShowPanel(false);
  };

  const refresh = () => {
    dispatch(loadUserprofile());
  };

  const updateFavoritesInUserprofile = async (workspaceItem: workspaceItem) => {
    if (workspaceItem.name === 'Private Workspace') return;
    let favoritesList = JSON.parse(userprofile.FavoritedWorkspaces);
    let index = favoritesList.indexOf(workspaceItem.workspaceId);
    if (index > -1) {
      favoritesList.splice(index, 1);
    } else {
      favoritesList.push(workspaceItem.workspaceId);
    }
    favoritesList = JSON.stringify(favoritesList);
    await dispatch(saveFavorites({ favoritesList, userprofile }));
    refresh();
  };

  const updateRecentsInUserprofile = async (workspaceItem: workspaceItem) => {
    if (workspaceItem.name === 'Private Workspace') return;
    let recentsList = JSON.parse(userprofile.RecentWorkspaces);
    let index = recentsList.indexOf(workspaceItem.workspaceId);
    if (index > -1) {
      recentsList.splice(index, 1);
    } else {
      recentsList.splice(0, 1);
    }
    recentsList.push(workspaceItem.workspaceId);
    recentsList = JSON.stringify(recentsList);
    await dispatch(saveRecents({ recentsList, userprofile }));
    refresh();
  };

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
      {
        key: 'Refresh Workspaces',
        text: 'Refresh Workspaces',
        onClick: refresh,
      },
    ];
  }

  const onRenderCell = (workspaceItem?: workspaceItem): JSX.Element => {
    if (workspaceItem) {
      let createdBy =
        workspaceItem.accessType + ' | Created by ' + workspaceItem.createdBy;
      let CurrentlySelected = workspaceItem.isCurrent
        ? ' (Currently Selected)'
        : '';
      return (
        <>
          <div className={classNames.itemCell} data-is-focusable={true}>
            <Stack horizontal tokens={itemAlignmentsStackTokens}>
              <Stack.Item styles={stackItemStyles}>
                <Label
                  styles={labelStyles2}
                  onClick={() => goToWorkspace(workspaceItem)}
                >
                  {workspaceItem.name}
                  {CurrentlySelected}
                </Label>
                <Stack horizontal tokens={itemAlignmentsStackTokens}>
                  <FontIcon
                    iconName={workspaceItem.accessIcon}
                    className={AccessIcon}
                  />
                  <Label className={AccessLabel}>{createdBy}</Label>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <ElxIconButton
                  text=""
                  styles={labelStyles1}
                  iconProps={{
                    iconName: workspaceItem.isFavorite
                      ? 'FavoriteStarFill'
                      : 'FavoriteStar',
                    className: classNames.favorite,
                  }}
                  onClick={() => {
                    if (workspaceItem.name !== 'Private Workspace') {
                      updateFavoritesInUserprofile(workspaceItem);
                    }
                  }}
                />
              </Stack.Item>
            </Stack>
          </div>
        </>
      );
    } else return <></>;
  };

  const goToWorkspace = (workspaceItem: workspaceItem) => {
    updateRecentsInUserprofile(workspaceItem).finally(function () {
      const alias =
        workspaceItem.name === 'Private Workspace'
          ? null
          : workspaceItem.workspaceId!;
      switchToWorkspace(alias);
      onDismiss();
    });
  };

  const theme = useLensShellTheme();
  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        styles={{
          headerContent: { marginTop: -16 },
          overlay: { backgroundColor: 'rgb(0 0 0 / 40%)' },
        }}
        headerText="Select Workspace"
        headerContent={
          <span>
            {'To manage your workspaces, go to the '}
            <ElxLink
              style={{ fontSize: '1em' }}
              onClick={() => {
                onDismiss();
                router.stateService.go('app.manage.workspace');
              }}
            >
              {'workspace page'}
            </ElxLink>
          </span>
        }
        isOpen={show}
        actions={renderActions()}
        size={PanelSize.medium}
        fillBackground={false}
        onDismiss={() => onShowPanel(false)}
      >
        {show && (
          <Stack tokens={{ padding: '10px 20px' }}>
            <Stack horizontal styles={stackStyles}>
              <ElxActionButton
                iconProps={{ iconName: 'Add' }}
                text="New Workspace"
                onClick={() => {
                  onDismiss();
                  router.stateService.go(
                    'app.manage.workspace',
                    { page: 'create' },
                    { reload: true }
                  );
                }}
              />
              <ElxActionButton
                iconProps={{ iconName: 'Edit' }}
                text="Edit Current Workspace"
                disabled={
                  //disable eidt current workspace for private workspace
                  getCurrentWorkspaceId() === 'private-' + userprofile.UPN
                }
                onClick={() => {
                  onDismiss();
                  router.stateService.go(
                    'app.manage.workspace',
                    { page: 'edit' },
                    { reload: true }
                  );
                }}
              />
            </Stack>
            <Label styles={labelStyles3}>Search for a workspace </Label>
            <SearchBox
              className="discover-search"
              styles={searchStyles}
              value={search.searchString}
              ariaLabel="Search workspaces..."
              title="Search workspaces..."
              placeholder="Search workspaces..."
              onChange={(_, value) => {
                setSearch({ searchString: value || '' });
              }}
              onClear={() => {
                setSearch({ searchString: '' });
              }}
            />
            <Pivot aria-label="Tabs">
              <PivotItem headerText="Favorites and recents">
                <List
                  items={filteredItems.favorites}
                  onRenderCell={onRenderCell}
                  onShouldVirtualize={() => false}
                />
              </PivotItem>
              <PivotItem headerText="All">
                <List
                  items={filteredItems.all}
                  onRenderCell={onRenderCell}
                  onShouldVirtualize={() => false}
                />
              </PivotItem>
            </Pivot>
          </Stack>
        )}
      </ElxPanel>
    </ThemeProvider>
  );
};

export default WorkspaceQuickSwitch;
