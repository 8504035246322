import {
  RegExCheckCondition,
  RegExType,
} from 'features/dataQuality/models/rule';
import UserExpectationBuilder from '../../base/userExpectationBuilder';
import { RegExCheckState } from './regExCheckExpectation';

export class RegExCheckBuilder extends UserExpectationBuilder<RegExCheckState> {
  // insert the column name parameter into the DQS NullCheckCondition
  buildRuleDetails(state: RegExCheckState) {
    const ruleDetails = super.buildRuleDetails(state);
    (ruleDetails.ruleCondition as RegExCheckCondition).ColumnsToCheck = [
      {
        regExType: state.regExType || RegExType.None,
        datasetId: this.dataset.id,
        columnName: state.column || '',
        customRegExFormat: state.customRegExFormat || '',
      },
    ];
    return ruleDetails;
  }

  // use the percent thresholds handler
  processThresholds = super.processPercentageThresholds;
}

export default RegExCheckBuilder;
