import React, { useEffect, useState } from 'react';
import {
  CommandButton,
  IComboBoxOption,
  IComboBoxStyles,
  Stack,
} from '@fluentui/react';
import { Card } from 'components/cards/card';
import { EditorProps } from '../../base/userExpectation';
import {
  ClassifyResults,
  useClassifyResultsHelpers,
} from '../../../components/classifyResults/classifyResults';
import { ElxCombobox } from '@elixir/fx/lib/components/Combobox';
import { UnexpectedValueCheckState } from './unexpectedValueCheckExpectation';
import { getDataSourceClient } from 'features/dataSources/registry';
import {
  KustoConnectionDetails,
  StoreType,
} from 'features/dataQuality/models/dataConnection';
import { KustoDatasetProperties } from 'features/dataQuality/models/dataset';
import { TableNode } from 'features/dataSources/models/schemaTree';
import { ElxTextField } from '@elixir/fx';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';

const UnexpectedValueCheckEditor = ({
  dataset,
  connection,
  state,
  setState,
}: EditorProps<UnexpectedValueCheckState>): JSX.Element => {
  const classifyHelpers = useClassifyResultsHelpers(
    state,
    setState,
    StandardUnits.PERCENT
  );

  const dataSourceClient = getDataSourceClient(
    connection.connectionDetails.subType
  );
  const [columnNames, setColumnNames] = useState<string[] | undefined>(
    undefined
  );

  useEffect(() => {
    // TODO: some way for generic fetching of column names for all dataset types.
    if (
      dataSourceClient &&
      columnNames === undefined &&
      connection.connectionDetails.subType === StoreType.Kusto
    ) {
      let kustoConnectionDetails =
        connection.connectionDetails as KustoConnectionDetails;
      let kustoDatasetProperties =
        dataset.datasetProperties as KustoDatasetProperties;
      setColumnNames([]);
      dataSourceClient
        .getSchema(
          kustoConnectionDetails.cluster,
          kustoConnectionDetails.database
        )
        .then(function (schema) {
          if (Array.isArray(schema)) {
            let table = schema?.find(
              (node) => node.name === kustoDatasetProperties.table
            ) as TableNode;
            setColumnNames(table?.children?.map((c) => c.name) || []);
          }
        });
    }
  }, [dataSourceClient, connection, columnNames, dataset.datasetProperties]);

  useEffect(() => {
    if (
      state?.unexpectedValues?.length &&
      state?.unexpectedValues?.length > 0
    ) {
      var lastExpectedValue = document.getElementById(
        'expectedValue' + (state?.unexpectedValues?.length - 1)
      );
      lastExpectedValue?.focus();
    }
  }, [state?.unexpectedValues?.length]);

  const columnNameOptions: IComboBoxOption[] =
    columnNames?.map((col) => {
      return {
        key: col,
        text: col,
      };
    }) || [];

  // same as <HighlightText /> component
  const styles = {
    input: {
      backgroundColor: '#c0ecf8',
    },
  } as IComboBoxStyles;

  // Must check column and that there are any unexpectedValues
  function validateState(newState: UnexpectedValueCheckState | undefined) {
    if (!newState) {
      return (
        (state?.column?.length || 0) > 0 &&
        (state?.unexpectedValues?.length || 0) > 0
      );
    } else {
      return (
        (newState?.column?.length || 0) > 0 &&
        (newState?.unexpectedValues?.length || 0) > 0
      );
    }
  }

  return (
    <>
      <Card>
        <Stack tokens={{ childrenGap: 16 }}>
          <ElxCombobox
            label={"The column's"}
            text={state?.column}
            styles={styles}
            options={columnNameOptions}
            allowFreeform
            onChange={(e, option, i, value) => {
              const column = option?.key?.toString() || value;
              let newState = state;
              if (newState) newState.column = column;
              setState({
                ...state,
                column,
                stateIsReady: validateState(newState),
              });
            }}
          />
          <label>values should be one of these</label>
        </Stack>
        <label>values</label>
        {state?.unexpectedValues?.map((val, ind) => {
          const index = ind as number;
          return (
            <Stack key={ind + 'stack'} horizontal>
              <Stack.Item align="stretch">
                <ElxTextField
                  value={val}
                  id={'expectedValue' + ind}
                  key={ind + 'textField'}
                  onChange={(e, value) => {
                    const unexpectedValues = [
                      ...(state?.unexpectedValues || []),
                    ];
                    if (unexpectedValues.length > index)
                      unexpectedValues[index] = value || '';
                    let newState = state;
                    if (newState) newState.unexpectedValues = unexpectedValues;
                    setState({
                      ...state,
                      unexpectedValues,
                      stateIsReady: validateState(newState),
                    });
                  }}
                />
              </Stack.Item>
              <Stack.Item align="end">
                <CommandButton
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={() => {
                    let unexpectedValues = [...(state?.unexpectedValues || [])];
                    if (unexpectedValues.length > index)
                      unexpectedValues.splice(ind, 1);
                    let newState = state;
                    if (newState) newState.unexpectedValues = unexpectedValues;
                    setState({
                      ...state,
                      unexpectedValues,
                      stateIsReady: validateState(newState),
                    });
                  }}
                />
              </Stack.Item>
            </Stack>
          );
        })}
        <CommandButton
          iconProps={{ iconName: 'Add' }}
          text={'New value'}
          onClick={() => {
            const unexpectedValues = state?.unexpectedValues || [];
            unexpectedValues.push('');
            let newState = state;
            if (newState) newState.unexpectedValues = unexpectedValues;
            setState({
              ...state,
              unexpectedValues,
              stateIsReady: validateState(newState),
            });
          }}
        />
        <p>
          You can set thresholds for the percentages of values that{' '}
          <HighlightText>are not</HighlightText> one of the expected values.
        </p>
      </Card>
      <ClassifyResults {...classifyHelpers} />
    </>
  );
};

export default UnexpectedValueCheckEditor;
