import React from 'react';

import { getUserMenuItems } from './userMenu';
import { getCurrentUser, getEmail, getName } from 'utils/authUtils';
import {
  ElixirPersonaTypes,
  ElxIconButton,
  ElxPersona,
  Null,
} from '@elixir/fx';
import {
  selectUserprofileCanAdmin,
  selectUserprofileCanAdminStatus,
  useUserprofile,
} from 'features/userprofile/userprofileSlice';
import { useSelector } from 'react-redux';
import { UserprofileStatus } from 'features/userprofile/models/userprofile';

export const UserIcon = (): JSX.Element => {
  const user = getCurrentUser();
  if (!user) {
    return <></>;
  }

  return (
    <ElxPersona
      type={ElixirPersonaTypes.Image}
      displayName={getName()}
      userPrincipalName={getEmail()}
    />
  );
};

export const UserIconWithDropdown = (): JSX.Element => {
  useUserprofile();
  const userprofileCanAdminStatus = useSelector(
    selectUserprofileCanAdminStatus
  );
  const userProfileCanAdmin = useSelector(selectUserprofileCanAdmin);

  const canAdmin =
    userprofileCanAdminStatus === UserprofileStatus.Loaded &&
    userProfileCanAdmin;
  let menuItems = getUserMenuItems(canAdmin);

  return (
    <ElxIconButton
      label="LensUserMenu"
      className="elx-header-action"
      ariaLabel={`User ${getName()}`}
      text={getName()}
      onRenderIcon={() => UserIcon()}
      onRenderMenuIcon={Null}
      menuProps={menuItems}
    />
  );
};
