import React, { useState } from 'react';
import {
  FontIcon,
  getTheme,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import { Depths } from '@fluentui/theme/lib/effects/FluentDepths';
import { HintText } from 'utils/hintText';

// padding is so that the content of the card isn't placed directly at the top/left
// corner of the card.
const theme = getTheme();
const mainStackStyle: IStackStyles = {
  root: {
    boxShadow: Depths.depth8,
    padding: '16px',
    backgroundColor: theme.semanticColors.cardStandoutBackground,
  },
};

export interface CardProps extends React.PropsWithChildren<unknown> {
  /** provides style override for stack. */
  styles?: IStackStyles;
  tokens?: IStackTokens;
  title?: string;
  collapsible?: boolean;
  startCollapsed?: boolean;
  hintText?: string;
  className?: string;
}

/**
 * Wraps the display of the child component with a header and navigation action.
 */
export const Card: React.FunctionComponent<CardProps> = ({
  styles,
  tokens,
  title,
  collapsible,
  startCollapsed,
  children,
  hintText,
  className,
}: CardProps): JSX.Element => {
  const mergedStyles = mergeStyleSets(mainStackStyle, styles);
  const [collapsed, setCollapsed] = useState<boolean>(!!startCollapsed);
  const stackStyles: Partial<IStackStyles> = {
    root: {
      '.elx-tooltip-host': {
        marginTop: '-5px',
      },
    },
  };

  return (
    <Stack
      className={'card ' + className}
      styles={mergedStyles}
      tokens={tokens}
    >
      {(collapsible || title) && (
        <Stack horizontal tokens={{ childrenGap: 5 }} styles={stackStyles}>
          {collapsible && (
            <FontIcon
              aria-label={collapsed ? 'Expand' : 'Collapse'}
              iconName={collapsed ? 'ChevronRightSmall' : 'ChevronDownSmall'}
              style={{ cursor: collapsible ? 'pointer' : undefined }}
              onClick={collapsible ? () => setCollapsed(!collapsed) : undefined}
            />
          )}
          {(title?.length || 0) > 0 && (
            <span
              style={{
                fontSize: '120%',
                fontWeight: 600,
                cursor: collapsible ? 'pointer' : undefined,
              }}
              onClick={collapsible ? () => setCollapsed(!collapsed) : undefined}
            >
              {title}
            </span>
          )}
          {hintText && <HintText hintText={hintText} />}
        </Stack>
      )}
      {!collapsed && children}
    </Stack>
  );
};
