import React, { useEffect, useState } from 'react';
import { ElxPanel, IContainerAction, PanelSize } from '@elixir/fx';
import { Stack } from '@fluentui/react';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import ExpectationSelector from './expectationSelector';
import Dataset from 'features/dataQuality/models/dataset';
import DataConnection from 'features/dataQuality/models/dataConnection';
import UserExpectation from '../../userExpectations/base/userExpectation';
import {
  getUserExpectationById,
  getExpectationBuilderById as getBuilderById,
} from '../../userExpectations/userExpectationsRegistry';
import '../../userExpectations/index';
import { useDispatch } from 'react-redux';
import {
  loadExpectation,
  ExpectationInfo,
  saveExpectation,
  useExpectations,
  createExpectation,
} from 'features/dataQuality/dataQualitySlice';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/lensShellUtility';
import { getUserExpectationFromRule } from '../../userExpectations/userExpectationsRegistry';
import { buildErrorMessage } from 'components/errorMessageBar/errorMessageBar';
import { SidePanel } from 'features/shell/lensShell';
import { ClassifyResultsState } from '../classifyResults/classifyResults';
import { isConflictError } from 'features/dataQuality/api/dataQualityApi';
import { panelStyles } from 'utils/sharedstyles';

export interface ExpectationProps extends BasePanelProps {
  workspaceId: string;
  dataset: Dataset;
  connection: DataConnection;
  expectationInfo?: ExpectationInfo;
  isClone?: boolean;
}

export interface EditExpectationState extends ClassifyResultsState {
  stateIsReady?: boolean; // custom expectation code should set this to true when state is ready to save.
}

export const EditExpectation = (props: ExpectationProps): JSX.Element => {
  const {
    show,
    onShowPanel,
    workspaceId,
    dataset,
    connection,
    expectationInfo,
    isClone,
    onAddPanel,
  } = props;
  const theme = useLensShellTheme();
  const dispatch = useDispatch<AppDispatch>();
  useExpectations(workspaceId, dataset.id);

  const [userExpectation, setUserExpectation] =
    useState<UserExpectation<EditExpectationState>>();
  useEffect(() => {
    setUserExpectation(getUserExpectationFromRule(expectationInfo?.rule));
  }, [show, expectationInfo]);

  const [expectationState, setExpectationState] =
    useState<EditExpectationState>();
  useEffect(() => {
    setExpectationState(userExpectation?.getInitialState(expectationInfo));
  }, [userExpectation, expectationInfo]);

  const [saving, setSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  function uniqueRuleName(baseName: string) {
    return baseName + ' ' + nanoid(4);
  }

  function onDismiss() {
    setErrorMessage('');
    setUserExpectation(undefined);
    setExpectationState(undefined);
    onShowPanel(false);
  }

  async function onSave() {
    if (!workspaceId || !userExpectation?.id || !dataset || !connection) {
      return; // won't get here because save button will be disabled.
    }
    setErrorMessage('');
    setSaving(true);
    try {
      const expectationBuilder = getBuilderById(
        userExpectation.id,
        dataset,
        connection,
        expectationInfo
      );
      const baseName = expectationBuilder.getBaseName(expectationState);
      const ruleName =
        (!isClone && expectationInfo?.rule.name) || uniqueRuleName(baseName);
      const newExpectationInfo = expectationBuilder.build(
        ruleName,
        expectationState
      );
      let ruleId;
      if (expectationInfo && !isClone) {
        ruleId = expectationInfo.rule.id;
        await dispatch(
          saveExpectation({ workspaceId, expectationInfo: newExpectationInfo })
        ).then(unwrapResult);
      } else {
        try {
          ruleId = await dispatch(
            createExpectation({
              workspaceId,
              expectationInfo: newExpectationInfo,
            })
          ).then(unwrapResult);
        } catch (error) {
          if (isConflictError(error)) {
            // one in 16 million chance of seeing this error...
            throw new Error(
              'Conflicting expectation name encountered, please just save again.'
            );
          }
          throw error;
        }
      }
      await dispatch(
        loadExpectation({ workspaceId, datasetId: dataset.id, ruleId })
      ).then(unwrapResult);
      onDismiss();
    } catch (error) {
      setErrorMessage(
        (error as Error)?.message || (error as string) || 'unknown error'
      );
    } finally {
      setSaving(false);
    }
  }

  const saveButtonDisabled =
    !expectationState?.thresholdsComplete ||
    !!expectationState?.thresholdsValidateMessage ||
    !expectationState.stateIsReady ||
    saving ||
    !workspaceId ||
    !userExpectation?.id ||
    !dataset ||
    !connection;

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Save',
        text: 'Save',
        isPrimary: true,
        disabled: saveButtonDisabled,
        onClick: onSave,
      },
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
    ];
  }

  function onSelectExpectation(id: string) {
    const newExpectation = getUserExpectationById(id);
    setUserExpectation(newExpectation);
    setExpectationState({
      ...newExpectation.getInitialState(),
      ...expectationState, // use misc settings that might carry over from other expectations
    });
  }

  const onDismissError = () => setErrorMessage('');
  const onShowNotifications = () => onAddPanel(SidePanel.NOTIFICATIONS);

  const Configuration = userExpectation?.Editor || (() => <></>);

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText="Edit Expectation"
        headerContent={dataset && <>Dataset: {dataset.datasetName}</>}
        message={
          (errorMessage &&
            buildErrorMessage(
              errorMessage,
              onDismissError,
              onShowNotifications
            )) ||
          undefined
        }
        isOpen={show}
        size={PanelSize.medium}
        fillBackground={true}
        onDismiss={() => onShowPanel(false)}
        actions={renderActions()}
        isLoading={saving}
        loadingLabel="Saving..."
        styles={panelStyles}
      >
        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
          <ExpectationSelector
            selectedKey={userExpectation?.id}
            onSelect={onSelectExpectation}
            dataset={dataset}
          />
          <Configuration
            dataset={dataset}
            connection={connection}
            state={expectationState}
            setState={setExpectationState}
          />
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default EditExpectation;
