import {
  getFocusStyle,
  getTheme,
  ITheme,
  mergeStyleSets,
} from '@fluentui/react';

const theme: ITheme = getTheme();
const { semanticColors, fonts } = theme;

export const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      height: 50,
      padding: 0,
      marginLeft: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
    },
  ],
  favorite: {
    alignSelf: 'right',
    marginRight: 10,
    color: '#FFB900',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  favoriteStar: {
    align: 'right',
  },
  tableStyles: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
  },
  pillFilter: {
    borderRadius: '10px',
  },
});
