import { serviceEndpoints, _ } from 'utils/sharedLibs';

declare global {
  interface Window {
    IS_EXTERNAL: string;
    LEAPI_BASE_URL: string;
    AAD_TENANT: string;
  }
}

class Constants {
  public readonly ApplicationName = 'Lens Explorer';

  public readonly KustoSeparator = ' | ';

  // private readonly IsExternal = window.IS_EXTERNAL === 'true'

  //private readonly serviceEndpoints = _.get(window, 'startUpConfig.serviceEndpoints', {})
  //private readonly WikiBaseUrl = IsExternal ? 'https://docs.lensexplorer.io/' : 'https://gadocs.msftcloudes.com/'
  public readonly Urls = {
    //    LensUrl: 'https://aka.ms/lensexplorer',
    LEApiBaseUrl: _.trimEnd(window.LEAPI_BASE_URL, '/') || '',
    OrchestratorApiUrl: _.trimEnd(serviceEndpoints.orchestratorUrl, '/') || '',
    //    AadGraphUrlFormat: '<%= baseUrl %>/me<%= apiMethod %>?<%= apiVersion %>',
    //    GraphApiBaseUrl: _.trimEnd(serviceEndpoints.aadGraphUrl, '/') || '',
    //    GraphApiV2BaseUrl: _.trimEnd(serviceEndpoints.msGraphUrl, '/') || '',
    //    CrawlerAPIBaseUrl: _.trimEnd(serviceEndpoints.theiaCrawlerConfigUrl, '/') || '',
    //    LanguageReferenceLink: IsExternal ? 'http://aka.ms/kdocs' : 'http://aka.ms/csl',
    //    IdWebGroupSearchLinkPrefix: 'https://idweb/identitymanagement/aspx/groups/AllGroups.aspx?searchtype=e0c132db-08d8-4258-8bce-561687a8a51e&content=',
    //    LensReleaseNotesLink: 'https://aka.ms/lensreleasenotes',
    //    DocumentationLink: IsExternal ? 'http://aka.ms/lenswiki' : 'http://aka.ms/lensv2wiki',
    //    GenevaAnalyticsLink: 'https://aka.ms/genevaanalytics',
    //    LensSupportLink: 'mailto:' + (IsExternal ? 'LensPreviewSupport@microsoft.com' : 'lensxpsup@microsoft.com'),
    //    LensFeedBackLink: 'http://aka.ms/lensfeedback',
    //    TheiaSupportLink: 'mailto:theiasupport@microsoft.com',
    //    KustoWikiLink: IsExternal ? 'http://aka.ms/kdocs' : 'http://aka.ms/csl',
    //    SelfHelpLink: 'https://sherlockserviceprod.azurewebsites.net/?Product=Lens%20Explorer&stackOverflowTag=lensexplorer&helpAlias=LensXPSup@microsoft.com&title=Lens%20Explorer',
    //    TheiaDocsLink: 'https://aka.ms/theiadocs',
    //    DataCatalogLink: 'https://datastudio.msftcloudes.com/#/entities',
    //    SubscribeNewsLetterLink: IsExternal ? 'https://www.microsoft.com' : 'https://idwebelements/GroupManagement.aspx?Group=LensXPAnnounce&Operation=join',
    //    LensStackOverFlowLink: 'https://stackoverflow.microsoft.com/questions/tagged/lensexplorer',
    //    accessPortalString: 'https://idweb/',
    //    //
    //    // Documentation Links
    //    //
    //    DashboardCachingUrl: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Dashboard%20Caching.html',
    //    TheiaJobManagement: WikiBaseUrl + 'Lens%20Explorer/Project%20Theia/Job%20Management.html',
    //    TheiaJobAuthoring: WikiBaseUrl + 'Lens%20Explorer/Project%20Theia/Job%20Authoring.html',
    //    LinkSectionInUrlWiki: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Groups.html',
    //    // Lens wiki on how to use Kusto bot to get data permission info
    //    KustoBotUrl: 'http://aka.ms/gaia',
    //    KustoBotWiki: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/AmdWiki/UseKustoBot.aspx',
    //    KustoQueryOptimizationUrl:  WikiBaseUrl + 'Lens%20Explorer/Queries/Best%20Practices.html',
    //    AutofocusAnalyticsWikiURL: WikiBaseUrl + 'Lens%20Explorer/Visualizations/Autofocus%20Analytics.html',
    //    RenderModeWiki: WikiBaseUrl + 'Lens%20Explorer/Visualizations/QueryAssist%20And%20Render%20Mode.html#render-mode',
    //    ApplyAttributeWikiLink: WikiBaseUrl + 'Lens%20Explorer/Queries/Control%20Filter%20Placement.html',
    //    DashboardGroupWikiLink: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Groups.html',
    //    QueryFormattingLink: WikiBaseUrl + 'Lens%20Explorer/Visualizations/QueryTable%20Formatting%20Options.html',
    //    TermsAndConditionUrl: 'https://docs.lensexplorer.io/Lens%20Explorer/TermsAndConditions.html',
    //    ExportToJarvisLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/AmdWiki/LensV2_ExportToJarvis.aspx',
    //    ConnectToKustoLink: WikiBaseUrl + 'Lens%20Explorer/Connect/Connect%20to%20Kusto.html',
    //    VisualizationLink: WikiBaseUrl + 'Lens%20Explorer/Visualizations/Working%20with%20Visualizations.html',
    //    CollaborateWorkspaceLink: WikiBaseUrl + 'Lens%20Explorer/Workspaces/Collaborate%20with%20workspaces.html',
    //    //Media Links
    //    LensOverview: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/Shared%20Documents/Videos/LensV2Overview.mp4',
    //    VoiceOfCustomerLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Argon/Marketing%20Content/KeyVaultCustomerVoice.mp4',
    //    ProductVideoLink: 'https://microsoft.sharepoint.com/:v:/r/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Argon/Marketing%20Content/Lens%20Ad%20V3%20-%201080p.mp4',
    //    MarchBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20March%20Update%20Brown%20Bag%20-%20Tuesday,%20March%2028,%202017%2012.04.56%20PM.mp4?csf=1',
    //    OverviewBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20v2%20Brown%20Bag%20-%20Tuesday,%20April%2011,%202017%201.13.38%20PM.mp4?csf=1',
    //    AprilBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20April%20Update%20Brown%20Bag%20-%20Monday,%20May%208,%202017%2012.07.16%20PM.mp4?csf=1',
    //    JuneBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20June%20Update%20Brown%20Bag%20-%20Thursday,%20June%2022,%202017%2012.07.24%20PM.mp4?csf=1',
    //    JulyBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20v2%20July%20Update%20Brown%20Bag.mp4?csf=1',
    //    //Image Links
    //    DefaultImageLink: 'https://assets.onestore.ms/cdnfiles/external/uhf/long/9a49a7e9d8e881327e81b9eb43dabc01de70a9bb/images/microsoft-gray.png',
    //    // Privacy terms link
    //    PrivacyTermsLink: 'https://privacy.microsoft.com/en-US/data-privacy-notice'
  };
  public readonly KustoProxyUrl = '/kustoproxy/';
  public readonly SampleClusterProxyId = 'CA95E6E9-2536-4388-AE66-9966527F95D7';
  public readonly SampleClusterProxyUrl =
    this.KustoProxyUrl + this.SampleClusterProxyId;
  public readonly OrchestratorDefaultVersion = 'v1';

  public readonly OperationCanceledMessage = 'Operation canceled';

  public readonly FilterPanelItem = 'filter_panel_item'; // name for the filter item; used in the custom filter UI to auto-populate values for the selected field.

  // search_source properties
  public readonly SearchSource = {
    Type: 'type',
    Query: 'query',
    Filter: 'filter',
    Sort: 'sort',
    Highlight: 'highlight',
    Aggs: 'aggs',
    From: 'from',
    Size: 'size',
    MaxRecords: 'maxrecords',
    Source: 'source',
    FieldDataFields: 'fielddata_fields',
    DataSourceType: 'datasourceType',
    Cluster: 'cluster',
    Database: 'database',
    TimeField: 'timefield',
    TimeFilter: 'timefilter',
    Tab: 'tab',
    GroupByColumns: 'groupByColumns',
    AllQueries: 'allQueries',
    RemoveUserQuery: 'removeUserQuery',
    IsInclude: 'isInclude', // boolean: include or exclude filters
    ChooseAll: 'chooseAll', // boolean: include all or exclude all
    FieldList: 'fieldList', // array: array of fields to include or exclude
    IgnoreTime: 'ignoreTime', // boolean: ignore time filter
    DataSourceId: 'dataSourceId', // for datasource stored in WS use this to uniquely identify.
    VcName: 'vcName',
    Folder: 'folder',
    File: 'file',
    AdlaAccount: 'adlaAccount',
    SubscriptionId: 'subscriptionId',
    ResourceGroup: 'resourceGroup',
  };

  public readonly ConditionsAgg = {
    aggName: 'conditions',
    paramName: 'conditions',
    paramItem: 'condition',
    aggTitle: 'Conditions',
    aggNamePrefix: 'Condition',
    ColumnNamePrefix: 'Condition_Col',
  };

  // Regex for various Kusto language statements
  public readonly KustoLanguageRegex = {
    Comment:
      /((['"])(?:(?!\2|\\).|\\.)*\2)|\/\/[^\n]*|\/\*(?:[^*]|\*(?!\/))*\*\//g,
    RenderStatement:
      /render\s+(\S+)(\s+kind\s*=\s*(default|unstacked|stacked(100)?))?/,
  };

  // begin rison filter param constants
  public readonly FilterOperator = {
    Default: '==',
    NegateDefault: '<>',
  };

  public readonly AllowedFilterOperators = ['==', 'startswith', 'contains'];

  public readonly RisonDataType = {
    String: 's',
    Number: 'n',
    DateTime: 'd',
  };
  public readonly RisonNonEmptyKeyError =
    'Filter in URL parameters must have a non-empty k field for filter key.';
  public readonly RisonOperatorNotSupportedError =
    "Only '==', 'startswith' and 'contains' operators are supported.";

  public readonly RisonOperatorSupportedForStringError =
    ' is supported only for string field type.';
  // end rison filter param constants

  // begin timepicker constants
  public readonly TimeFormat = {
    Local: 'Local',
    UTC: 'UTC',
  };
  // end timepicker constants

  // The data source types.  The values are the same as LEWeb.Models.DataSourceType.
  public readonly DataSourceTypes = {
    KUSTO: 'Kusto',
    APPLICATIONINSIGHTS: 'ApplicationInsights',
    OMSLOGANALYTICS: 'OmsLogAnalytics',
    COSMOS: 'Cosmos',
    SQL: 'Sql',
    AZURESTORAGE: 'AzureStorage',
    DSO: 'Dso',
    EVENTHUB: 'EventHub',
    ADLSGEN2: 'AzureDataLakeStorageGen2',
    AZURESUBSCRIPTION: 'Subscription',
    ADLA: 'AzureDataLakeAnalytics',
    MDM: 'Mdm',
    AZUREDATAFACTORY: 'AzureDataFactory',
  };

  public readonly SchemaTypes = {
    Cluster: 'cluster',
    Database: 'database',
    Folder: 'folder',
    Function: 'function',
    Table: 'table',
    Job: 'Dso',
    Source: 'source',
    Catalog: 'catalog',
    // column types
    Boolean: 'Boolean',
    Byte: 'Byte',
    DateTime: 'DateTime',
    Double: 'Double',
    Dynamic: 'Dynamic',
    Guid: 'Guid',
    Int32: 'Int32',
    UInt32: 'UInt32',
    Int64: 'Int64',
    Object: 'Object',
    SByte: 'SByte',
    SqlDecimal: 'SqlDecimal',
    DataSqlTypesSqlDecimal: 'Data.SqlTypes.SqlDecimal',
    String: 'String',
    TimeSpan: 'TimeSpan',
  };

  public readonly QueryLimit = function (configuredLimit: number) {
    if (_.isNumber(configuredLimit) && configuredLimit > 0) {
      return configuredLimit;
    } else {
      return 5000;
    }
  };

  public readonly AccessDenied = 'Access Denied';
  public readonly QueryErrorMessage = 'Error retrieving data';
  public readonly QuerySyntaxErrorMessage =
    'Error retrieving data. Please refine your query and try again.';

  public readonly SyntaxErrorMessage = 'Syntax Error';
  public readonly ThrottlingErrorMessage =
    'You have exceeded the maximum number of requests per minute. Please try again later.';
  public readonly ConnectionErrorMessage = 'Connection Error';
  public readonly ConnectionErrorMessageDetails =
    'We have experienced a connection issue while retrieving data. This is usually an indication that the network is down' +
    ' or a firewall or browser extension (such as an ad blocker) is mistakenly preventing access.';

  public readonly ErrorMessagePrefix = {
    SyntaxError: 'Syntax error',
    SemanticError: 'Semantic error',
  };

  public readonly SessionIsExpiredMessage =
    'Your session timed out. Refresh the page to log in again';

  public readonly Queries = {
    GetTimelineData: 'TimeChartQuery',
    BuildSchemaOnCluster: 'BuildSchemaOnCluster',
    BuildSchemaTemplate: 'BuildSchema_<%= type %>_Query',
    GetFacetsDistinctCountAndTopValues: 'FacetsQuery',
    GetLogicalSchema: 'GetLogicalSchema',
    GetDatabaseFunctions: 'GetDatabaseFunctions',
    SlowQueryThresholdInMs: 5000,
  };

  // App name, explore or dashboard
  public readonly AppName = {
    Explore: 'explore',
    Dashboard: 'dashboard',
    QuickEditor: 'quickeditor',
  };

  public readonly FilterPlacement = {
    GenericPattern: /apply_attribute lens:filters/g,
    TimeFilterPattern: /apply_attribute lens:time:[^|);\r\n]+/g,
    TimeValueFromPattern: /apply_attribute lens:timefrom\?<[^|<>]*>/g,
    TimeValueToPattern: /apply_attribute lens:timeto\?<[^|<>]*>/g,
    IndividualFilterPattern: /apply_attribute lens:filter:[^|);\r\n]+/g,
    IndividualValuePattern: /apply_attribute lens:value:[^|<>}]+\?<[^|<>]*>/g,
    EmptyPipePattern: /\|\s*(\|\s*)+/g,
    IsolatedPipePattern: /\|[\s\n\r]*([);])/g,
    EndOfQueryPipePattern: /\|\s*$/g,
    TimeFilterPrefix: 'apply_attribute lens:time:',
    TimeValueFromPrefix: 'apply_attribute lens:timefrom?',
    TimeValueToPrefix: 'apply_attribute lens:timeto?',
    IndividualFilterPrefix: 'apply_attribute lens:filter:',
    IndividualValuePrefix: 'apply_attribute lens:value:',
  };

  // Aggs placement constant values
  public readonly AggsPlacement = {
    GenericPattern: /apply_attribute lens:aggs/g,
  };

  // Kusto management command prefix
  public readonly KustoManagementCommandPrefix = '.';

  // Kusto management create command
  public readonly KustoManagementCreateCommand = '.create';

  // Kusto management alter command
  public readonly KustoManagementAlterCommand = '.alter';

  // Kusto truncation prefixes. If one of this is detected in user query, we don't add our own truncation statement
  public readonly KustoTruncationPrefixes = [
    /set\s+notruncation/i,
    /set\s+truncationmaxsize/i,
    /set\s+truncationmaxrecords/i,
    /set\s+query_take_max_records/i,
  ];

  public readonly LensSupportAddress = 'lensxpsup@microsoft.com';

  public readonly KustoStatementDelimeter = ';';

  // Prefix for the temporary table used in visual queries
  public readonly LensTempTablePrefix = 'lensTmp_';

  public readonly LensLimitError =
    'Query result set has exceeded the internal record count limit';

  // begin custom filter constants
  public readonly CustomFilter = {
    UnaryOperators: ['isempty()', 'isnotempty()', 'isnull()', 'isnotnull()'],
    Operators: {
      IsEmpty: 'isempty()',
      IsNotEmpty: 'isnotempty()',
      IsNull: 'isnull()',
      IsNotNull: 'isnotnull()',
    },
    GroupingErrorMessages: {
      Default: 'Grouping Error: ',
      SelectAtleastTwoClauses: 'Select atleast two clauses to group.',
      GroupAlreadyExists: 'Group already exists.',
      ClausesFromDifferentGroups: 'Clauses are from different groups.',
      SelectedItemsHasSpaceBetween: 'Selected clauses have space in between.',
    },
    AllowAutoComplete: [
      this.AppName.Explore,
      this.AppName.QuickEditor,
      this.FilterPanelItem,
      this.ConditionsAgg.paramItem,
    ],
  };
  // end custom filter constants

  // Query limit for query table
  public readonly QueryTableQueryLimit = 1000;

  public readonly QueryContainTooManyDataMessagePrefix =
    'Query result set has exceeded the internal record count limit';

  public readonly QueryContainTooManyDataWarningMessage =
    ' " is plotting more than 5000 data points. This will affect performance of the browser. Please update the query to display fewer points.';

  public readonly TimePicker = {
    TabTypes: {
      Filter: 'filter',
      RefreshInterval: 'interval',
      Settings: 'settings',
    },
  };

  public readonly AriaProxyURL = 'kusto.aria.microsoft.com';

  public readonly KustoConnectionStringPattern =
    /(cosmosdb_)?sql_request\([^)]*\)/g;
  public readonly KustoObfuscatedString = '**CredentialRemoved**';
}

export const constants = new Constants();

export const $laConstants = constants;

export default constants;
