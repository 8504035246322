import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { ElxDialog } from '@elixir/fx';
import { Icon, Spinner, SpinnerSize, Stack } from '@fluentui/react';

export interface BatchDeleteProps {
  /**
   * internal state retrieved by calling the useBatchDelete() hook
   */
  batchDeleteState: BatchDeleteState;
  /**
   * the name of a string property inside each item that can be used as a unique identifier
   */
  itemIdPropName: string;
  /**
   * the name of a string | JSX.Element property inside each item that can be used to list out the items to be deleted
   */
  itemDescriptionPropName: string;
  /**
   * the singular word for the items being deleted e.g. 'dataset' or 'expectation'
   */
  singular: string;
  /**
   * the plural word for the items being deleted e.g. 'datasets' or 'expectations'
   */
  plural: string;
  /**
   * The async function that will actually delete an item
   */
  deleteItemFn: (item: any) => Promise<any>;
}

interface BatchDeleteState {
  itemsState: ItemsState;
  setItemsState: Dispatch<SetStateAction<ItemsState>>;
  itemsStateRef: MutableRefObject<ItemsState>;
}

enum DeleteStatus {
  NONE = 'NONE',
  CONFIRM = 'CONFIRM',
  DELETING = 'DELETING',
  COMPLETE = 'COMPLETE',
}

/**
 * Custom hook required to use the Batch Delete Dialog JSX.Element
 * @returns batchDeleteState, which is used internally and an onDeleteItems function that initiates deletion
 * process for a list of items.
 */
export function useBatchDelete(): [BatchDeleteState, (items: any[]) => void] {
  const [itemsState, setItemsState] = useState<ItemsState>(initItemsState);
  const itemsStateRef = useRef<ItemsState>(initItemsState);
  itemsStateRef.current = itemsState;

  const batchDeleteState = { itemsState, setItemsState, itemsStateRef };

  function onDeleteItems(items: any[]) {
    setItemsState({
      status: DeleteStatus.CONFIRM,
      deleteItems: [...items],
      successfulItemIds: [],
      failedItemIds: [],
    });
  }

  return [batchDeleteState, onDeleteItems];
}

/**
 * Testing utility function that can be hooked up to test batch deletion of items.
 * Emulates an async delete function that pretends to delete an item and resolves or rejects
 * in 8s or less.  Rejects 40% of the time.  The item passed in is ignored and is not harmed.
 * @param item
 */
export function testDeleteFn(item: any) {
  const delay = 8000;
  return new Promise(function (resolve, reject) {
    if (Math.random() < 0.4) {
      setTimeout(reject, Math.random() * delay);
    } else {
      setTimeout(resolve, Math.random() * delay);
    }
  });
}

interface ItemsState {
  status: DeleteStatus;
  deleteItems: any[];
  successfulItemIds: string[];
  failedItemIds: string[];
}

const initItemsState: ItemsState = {
  status: DeleteStatus.NONE,
  deleteItems: [],
  successfulItemIds: [],
  failedItemIds: [],
};

/**
 * A batch delete dialog that allows users to delete a list of items and wait for completion + see status of deletion
 */
const BatchDelete = (props: BatchDeleteProps): JSX.Element => {
  const {
    batchDeleteState,
    itemIdPropName,
    itemDescriptionPropName,
    singular,
    plural,
    deleteItemFn: deleteFn,
  } = props;
  const { itemsState, setItemsState, itemsStateRef } = batchDeleteState;

  let okToCancel = true;

  async function onDeleteConfirmed() {
    okToCancel = false;
    setItemsState({ ...itemsState, status: DeleteStatus.DELETING });
    Promise.allSettled(
      itemsState.deleteItems.map(async (item) => {
        try {
          await deleteFn(item);
          setItemsState({
            ...itemsStateRef.current,
            successfulItemIds: [
              ...itemsStateRef.current.successfulItemIds,
              item[itemIdPropName],
            ],
          });
        } catch (error) {
          setItemsState({
            ...itemsStateRef.current,
            failedItemIds: [
              ...itemsStateRef.current.failedItemIds,
              item[itemIdPropName],
            ],
          });
          throw error;
        }
      })
    ).finally(() => {
      setItemsState({
        ...itemsStateRef.current,
        status: DeleteStatus.COMPLETE,
      });
    });
  }

  function onCancelDelete() {
    if (okToCancel) {
      setItemsState({ ...itemsState, status: DeleteStatus.NONE });
    }
  }

  let deleteHeader = '';
  let deleteButtonText = '';
  let deleteCancelButtonText = '';
  let deleteButtonClick = undefined;
  let onDismissDelete = undefined;
  const deleteCount = itemsState.deleteItems.length;
  switch (itemsState.status) {
    case DeleteStatus.CONFIRM:
      deleteHeader = `Confirm deletion of ${deleteCount} ${
        deleteCount > 1 ? plural : singular
      }`;
      deleteButtonText =
        'Delete' + (deleteCount > 1 ? ' ' + deleteCount + ' ' + plural : '');
      deleteCancelButtonText = 'Cancel';
      deleteButtonClick = onDeleteConfirmed;
      onDismissDelete = onCancelDelete;
      break;

    case DeleteStatus.DELETING:
      deleteHeader = `Deleting ${deleteCount} ${
        deleteCount > 1 ? plural : singular
      }`;
      deleteButtonText = 'Deleting...';
      break;

    case DeleteStatus.NONE:
    case DeleteStatus.COMPLETE:
    default:
      if (
        itemsState.failedItemIds.length >= itemsState.deleteItems.length ||
        itemsState.successfulItemIds.length === 0
      ) {
        deleteHeader = `Failed to delete ${
          deleteCount > 1
            ? (deleteCount === 2 ? 'either' : 'any') +
              ' of these ' +
              deleteCount
            : 'the'
        } ${deleteCount > 1 ? plural : singular}`;
      } else if (
        itemsState.successfulItemIds.length >= itemsState.deleteItems.length
      ) {
        deleteHeader = `Successfully deleted ${
          deleteCount === 2
            ? 'both'
            : (deleteCount > 1 ? 'all ' : '') + deleteCount
        } ${deleteCount > 1 ? plural : singular}`;
      } else {
        deleteHeader = `Only deleted ${
          itemsState.successfulItemIds.length
        } out of ${deleteCount} ${deleteCount > 1 ? plural : singular}`;
      }
      deleteCancelButtonText = 'Dismiss';
      onDismissDelete = onCancelDelete;
      break;
  }

  return (
    <ElxDialog
      maxWidth="66%"
      hidden={itemsState.status === DeleteStatus.NONE}
      dismissable={
        itemsState.status !== DeleteStatus.COMPLETE &&
        itemsState.status !== DeleteStatus.CONFIRM
      }
      header={deleteHeader}
      primaryButtonText={deleteButtonText}
      onPrimaryButtonClick={deleteButtonClick}
      cancelButtonText={deleteCancelButtonText}
      onCancelButtonClick={onCancelDelete}
      onDismiss={onDismissDelete}
    >
      <Stack
        style={{ overflow: 'auto' }}
        tokens={{ childrenGap: 8, padding: 16, maxHeight: 300 }}
      >
        {itemsState.deleteItems.map((item) => (
          <Stack
            key={item[itemIdPropName]}
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 12 }}
          >
            <Stack.Item>{item[itemDescriptionPropName]}</Stack.Item>
            <span style={{ minWidth: 16, minHeight: 20 }}>
              {itemsState.failedItemIds.includes(item[itemIdPropName]) ? (
                <Icon title="Deletion failed" iconName="Cancel" />
              ) : itemsState.successfulItemIds.includes(
                  item[itemIdPropName]
                ) ? (
                <Icon title="Deletion succeeded" iconName="CheckMark" />
              ) : (
                itemsState.status === DeleteStatus.DELETING && (
                  <Spinner size={SpinnerSize.small} />
                )
              )}
            </span>
          </Stack>
        ))}
      </Stack>
    </ElxDialog>
  );
};

export default BatchDelete;
