import { getUser } from 'utils/sharedLibs';

class UserUtils {
  public getName = (): string => {
    const user = getUser();
    return user.name;
  };

  public getAlias = (): string => {
    const email = this.getEmail();
    if (email?.indexOf('@') > 0) {
      return email.substring(0, email.indexOf('@'));
    }
    return email;
  };

  public getEmail = (): string => {
    const user = getUser();
    return user?.username;
  };

  public getObjectId = (): string => {
    const user = getUser();
    return user?.idTokenClaims?.oid;
  };
}

export const userUtils = new UserUtils();

export default userUtils;
