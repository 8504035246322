import React, { useEffect } from 'react';
import {
  Label,
  Pivot,
  PivotItem,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router';
import EditWorkspaceGeneral from './editWorkspaceGeneral';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';
import {
  editWorkspace,
  editWorkspaceStatus,
  loadEditWorkspace,
} from 'features/workspaces/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  labelStyles,
  usePivotContainerStyles,
  useWorkspaceNameStyle,
} from './editworkspaceStyles';
import { WorkspaceHr } from './workspaceHelperComponents';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

interface WorkspaceEditRouterPrams {
  workspaceAlias: string;
}

type WorkspaceEditRouterProps = RouteComponentProps<WorkspaceEditRouterPrams>;

const EditWorkspace = ({ match }: WorkspaceEditRouterProps): JSX.Element => {
  const theme = useLensShellTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const editWorkspaceId = match.params.workspaceAlias;
  let show = false;

  const workspaceStatus = useSelector(editWorkspaceStatus);
  const workspace = useSelector(editWorkspace);

  useEffect(() => {
    dispatch(loadEditWorkspace(editWorkspaceId));
  }, [dispatch, editWorkspaceId]);

  if (workspaceStatus === WorkspaceStatus.Loaded) {
    if (workspace.shareType === 'private') {
      //handles editing private workspace
      history.push('/workspaces/manage');
    } else {
      show = true;
    }
  }

  const pivotContainerStyles = usePivotContainerStyles(theme);
  const workspaceNameStyle = useWorkspaceNameStyle(theme);

  return (
    <>
      {!show ? (
        <Stack //should make this a separate component?
          horizontalAlign="center"
          tokens={{ childrenGap: 24, padding: 100 }}
        >
          <Spinner size={SpinnerSize.large} />
          <Label>Loading workspace...</Label>
        </Stack>
      ) : (
        <Stack styles={pivotContainerStyles}>
          <h1 style={workspaceNameStyle} className="padding-1">
            {'Edit ' + workspace.name + ' Workspace'}
          </h1>
          <Stack>
            <Pivot aria-label="Edit Workspace Options">
              <PivotItem
                headerText="General"
                headerButtonProps={{
                  'data-order': 1,
                  'data-title': 'General',
                }}
              >
                <WorkspaceHr />
                <EditWorkspaceGeneral workspace={workspace} />
              </PivotItem>
              <PivotItem headerText="Data Connections">
                <WorkspaceHr />
                <Label styles={labelStyles}>Data Connections</Label>
              </PivotItem>
              <PivotItem headerText="Settings">
                <WorkspaceHr />
                <Label styles={labelStyles}>Settings</Label>
              </PivotItem>
              <PivotItem headerText="History">
                <WorkspaceHr />
                <Label styles={labelStyles}>History</Label>
              </PivotItem>
            </Pivot>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default withRouter(EditWorkspace);
