import React from 'react';
import { registerUserExpectation } from '../../userExpectationsRegistry';
import UserExpectation from '../../base/userExpectation';
import { getClassifyResultsState } from '../../../components/classifyResults/classifyResults';
import {
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
} from 'features/dataQuality/models/rule';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import RowCountCheckEditor from './rowCountCheckEditor';
import Dataset from 'features/dataQuality/models/dataset';
import DataConnection from 'features/dataQuality/models/dataConnection';
import RowCountCheckBuilder from './rowCountCheckBuilder';
import RowCountCheckResultsBuilder from './rowCountCheckResultsBuilder';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import { getTableName } from 'features/dataQuality/utils/dataQualityUtils';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export interface RowCountCheckState extends EditExpectationState {}

class RowCountCheckExpectation extends UserExpectation<RowCountCheckState> {
  id = 'RowCountCheck';
  description = 'Table must have a specific number of rows';
  category = SystemDefinedRuleCategory.Consistency;
  subCategory = SystemDefinedRuleSubCategory.RowCountCheck;
  ruleType = RuleType.PreDefined;
  ruleConditionType = RuleConditionType.RowCountCondition;

  getDescriptionElement = (state?: RowCountCheckState, dataset?: Dataset) => {
    const { tableName, hasCustomQuery } = getTableName(dataset);
    return hasCustomQuery ? (
      <span title={'The dataset must have a specific number of rows'}>
        The dataset must have a specific number of rows
      </span>
    ) : tableName ? (
      <span title={`${tableName} must have a specific number of rows`}>
        <HighlightText>{tableName}</HighlightText> must have a specific number
        of rows
      </span>
    ) : (
      <span title={'The Table must have a specific number of rows'}>
        The<HighlightText>{' Table'}</HighlightText> must have a specific number
        of rows
      </span>
    );
  };

  Editor = RowCountCheckEditor;

  getInitialState(expectationInfo: ExpectationInfo): RowCountCheckState {
    return {
      ...getClassifyResultsState(expectationInfo),
      stateIsReady: true,
    };
  }

  public getUnit(expectationInfo?: ExpectationInfo) {
    return StandardUnits.INTEGER;
  }

  builderFactory = (
    dataset: Dataset,
    connection: DataConnection,
    expectationInfo?: ExpectationInfo
  ): RowCountCheckBuilder =>
    new RowCountCheckBuilder(this, dataset, connection, expectationInfo);

  getResultDescription = RowCountCheckResultsBuilder.getResultsDescription;
  getResultsTable = RowCountCheckResultsBuilder.getResultsTable;
}

registerUserExpectation(new RowCountCheckExpectation());
