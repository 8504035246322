import { DataConnection } from 'features/dataQuality/models/dataConnection';
import { Dataset } from 'features/dataQuality/models/dataset';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import { RuleDetails } from 'features/dataQuality/models/rule';
import {
  Action,
  ConditionOperator,
  ConditionType,
  ConditionUnit,
  Expectation,
  StaticCondition,
} from 'features/dataQuality/models/alert';
import { getDefaultAlertForExpectation } from 'features/dataQuality/utils/dataQualityUtils';
import { v4 as uuidv4 } from 'uuid';
import UserExpectation from './userExpectation';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import { ClassifyMode } from 'features/dataQuality/components/classifyResults/classifyResults';

export const dummyPlaceHolderId = 'Uninitialized';

export interface ExpectationsAndActions {
  expectations: Expectation[];
  actions: Action[];
}

/**
 * The UserExpectationBuilder will take the current Editor state and build various DQS assets suitable
 * for saving with the DQS api.  Logic shared by all User Expectation types is contained in this class
 * as the protected methods.  The only method that is called from the UX is the public build() method.
 * This is called by the UX to build the DQS Rule and DQS Alerts, which are saved to DQS api.
 *
 * This builder is used by the Expectation editor which must save the full DQS Rule and DQS Alerts
 * on each save operation.
 */
export abstract class UserExpectationBuilder<T extends EditExpectationState> {
  public userExpectation: UserExpectation<T>;
  public dataset: Dataset;
  public connection: DataConnection;
  public prevExpectationInfo?: ExpectationInfo;

  constructor(
    userExpectation: UserExpectation<T>,
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo
  ) {
    this.userExpectation = userExpectation;
    this.dataset = dataset;
    this.connection = connection;
    this.prevExpectationInfo = prevExpectationInfo;
  }

  /**
   * Build DQS RuleDetails structure by extracting the settings from the current UserExpectation for this User Expectation type.
   * This method must be customized by concrete User Expectation classes that have their own custom data required in the DQS RuleDetails.
   * @param state current editor state
   * @returns
   */
  protected buildRuleDetails(state: T): RuleDetails {
    return {
      ruleType: this.userExpectation.ruleType,
      systemDefinedRuleCategories: this.userExpectation.category,
      systemDefinedRuleSubCategories: this.userExpectation.subCategory,
      ruleCondition: {
        ruleConditionType: this.userExpectation.ruleConditionType,
      },
    };
  }

  /**
   * Processes the percentage thresholds set in the UX and builds the DQS Expectations and Actions
   * @param state current editor state
   * @returns
   */
  protected processPercentageThresholds(state: T): ExpectationsAndActions {
    const thresholds = state.thresholds || [];
    const expectations: Expectation[] = [];
    const actions: Action[] = [];

    thresholds.forEach(function (threshold) {
      const id = uuidv4();
      const conditions: StaticCondition[] = [];
      const fromValue = threshold.from?.replace(/%+$/, '');
      const toValue = threshold.to?.replace(/%+$/, '');

      if (
        fromValue &&
        !Number.isNaN(Number.parseFloat(fromValue)) &&
        Number.parseFloat(fromValue) !== 0
      ) {
        conditions.push({
          subType: ConditionType.Static,
          unit: ConditionUnit.Percentage,
          operator: ConditionOperator.GreaterThanOrEqualTo,
          value: fromValue,
          label: 'from',
        });
      }

      if (
        toValue &&
        !Number.isNaN(Number.parseFloat(toValue)) &&
        Number.parseFloat(toValue) !== 100
      ) {
        conditions.push({
          subType: ConditionType.Static,
          unit: ConditionUnit.Percentage,
          operator: ConditionOperator.LessThan,
          value: toValue,
          label: 'to',
        });
      }

      // Encode the threshold level (severity) as a tag.
      const tags = threshold.level ? [threshold.level] : undefined;

      expectations.push({
        id,
        conditions,
        expression: conditions.map((c) => c.label).join(' && '),
        tags,
      });

      actions.push({
        id: uuidv4(),
        expectationId: id,
        actionTypes: [],
        tags,
      });
    });

    return {
      expectations,
      actions,
    };
  }

  /**
   * Processes the absolute thresholds set in the UX and builds the DQS Expectations and Actions
   * @param state current editor state
   * @returns
   */
  protected processAbsoluteThresholds(state: T): ExpectationsAndActions {
    const thresholds = state.thresholds || [];
    const expectations: Expectation[] = [];
    const actions: Action[] = [];

    thresholds.forEach(function (threshold) {
      const id = uuidv4();
      const conditions: StaticCondition[] = [];

      if (
        threshold.from &&
        !Number.isNaN(Number.parseFloat(threshold.from)) &&
        Number.parseFloat(threshold.from) !== -Infinity
      ) {
        conditions.push({
          subType: ConditionType.Static,
          unit: ConditionUnit.Absolute,
          operator: ConditionOperator.GreaterThanOrEqualTo,
          value: threshold.from,
          label: 'from',
        });
      }

      if (
        threshold.to &&
        !Number.isNaN(Number.parseFloat(threshold.to)) &&
        Number.parseFloat(threshold.to) !== Infinity
      ) {
        conditions.push({
          subType: ConditionType.Static,
          unit: ConditionUnit.Absolute,
          operator: ConditionOperator.LessThan,
          value: threshold.to,
          label: 'to',
        });
      }

      // Encode the threshold level (severity) as a tag.
      const tags = threshold.level ? [threshold.level] : undefined;

      expectations.push({
        id,
        conditions,
        expression: conditions.map((c) => c.label).join(' && '),
        tags,
      });

      actions.push({
        id: uuidv4(),
        expectationId: id,
        actionTypes: [],
        tags,
      });
    });

    return {
      expectations,
      actions,
    };
  }

  /**
   * Concrete classes should set this method to point to either processPercentageThresholds or processAbsoluteThresholds
   * as required by the User Expectation type
   * @param state current editor state
   */
  protected abstract processThresholds(state: T): ExpectationsAndActions;

  /**
   * Builds expectations and actions which make up most of the DQS alert structure
   * @param state current editor state
   */
  private buildExpectationsAndActions(state: T): ExpectationsAndActions {
    switch (state.classifyMode) {
      case ClassifyMode.OBSERVE:
      default:
        return { expectations: [], actions: [] };
      case ClassifyMode.THRESHOLDS:
        return this.processThresholds(state);
    }
  }

  /**
   * Base name to use for naming DQS rules and alerts.  This should be somewhat human readable and should
   * include things like target column names.
   * @param state
   * @returns a human readable name for the Expectation, reflecting the current
   */
  public getBaseName(state: T): string {
    return this.userExpectation.id;
  }

  /**
   * Main public build method that takes rule name/description + current editor state and assembles
   * them into a valid DQS Rule and Alert.
   * @param ruleName
   * @param description
   * @param state
   * @returns
   */
  public build(ruleName: string, state: T): ExpectationInfo {
    const newRule = {
      id: dummyPlaceHolderId,
      tags: [],
      version: '1.0',
      ...this.prevExpectationInfo?.rule,
      name: ruleName,
      description: this.userExpectation.description,
      workspaceId: this.dataset.workspaceId,
      datasetId: this.dataset.id,
      details: this.buildRuleDetails(state),
    };

    const prevAlert = getDefaultAlertForExpectation(this.prevExpectationInfo);

    const newAlert = {
      id: this.prevExpectationInfo?.rule ? uuidv4() : dummyPlaceHolderId, // used in case previous rule, but no previous alert
      ruleId: this.prevExpectationInfo?.rule.id || dummyPlaceHolderId, // use previous rule id, if exists
      ...prevAlert,
      name: `${ruleName} Alert`,
      ruleName: ruleName,
      description: `Alert for ${this.userExpectation.description}`,
      workspaceId: this.dataset.workspaceId,
      workspaceDisplayName: this.dataset.workspaceName,
      datasetId: this.dataset.id,
      ...this.buildExpectationsAndActions(state),
    };

    return {
      rule: newRule,
      alerts: [newAlert],
    };
  }
}

export default UserExpectationBuilder;
