import { IStackStyles, IStackTokens, ITheme } from '@fluentui/react';

export const useEditContainerStyles = (theme: ITheme): IStackStyles => {
  return {
    root: {
      right: '0%',
      bottom: '0%',

      background: theme.palette.white,
      border: '1px solid ' + theme.palette.neutralLight,

      boxShadow:
        '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: '4px',

      width: '703px',
      left: '0px',
      top: '0px',
      padding: '16px',
    },
  };
};

export const useEditContainerHeaderStyle = (theme: ITheme): IStackStyles => {
  return {
    root: {
      left: '3.84%',
      right: '48.51%',
      top: '3.77%',
      bottom: '87.54%',
      fontFamily: 'Segoe UI',
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.palette.neutralPrimary,
      fontWeight: '700',
    },
  };
};

export const themedSmallStackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 'm',
};

export const smallStackTokens: IStackTokens = {
  childrenGap: 1,
};

export const useCommandBarStackStyles = (theme: ITheme): IStackStyles => {
  return {
    root: {
      width: '100%',
      paddingLeft: '10px',
      background: theme.palette.white,
    },
  };
};

export const wideSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
};
export const actionsGapStackTokens: IStackTokens = {
  childrenGap: 1,
};

export const workspacePadding: IStackStyles = {
  root: { padding: '16px' },
};

// export const padding1 = useClassNames('padding-1');
// export const iconStyles: Partial<IIconStyles> = { root: { marginBottom: 3 } };
