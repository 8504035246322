import { Dataset, KustoDatasetProperties } from '../models/dataset';
import {
  ConnectionDetails,
  KustoConnectionDetails,
  StoreType,
} from '../models/dataConnection';
import { ExpectationInfo } from '../dataQualitySlice';
import Alert, {
  ConditionType,
  Expectation,
  StaticCondition,
} from '../models/alert';
import RuleExecutionResult from '../models/ruleExecutionResult';

const removeProtocol = (url: string) => url?.replace(/(^\w+:|^)\/\//, '');

interface DatasetConnectionInfo {
  cluster: string;
  container: string;
  table?: string;
  query?: string;
}

export function getConnectionInfo(
  dataset: Dataset,
  connectionDetails?: ConnectionDetails
): DatasetConnectionInfo | undefined {
  switch (connectionDetails?.subType) {
    case StoreType.Kusto:
      const kustoDetails = connectionDetails as KustoConnectionDetails;
      const kustoDatasetProperties =
        dataset.datasetProperties as KustoDatasetProperties;
      return {
        cluster: removeProtocol(kustoDetails.cluster),
        container: kustoDetails.database,
        table: kustoDatasetProperties.table || undefined,
        query: kustoDatasetProperties.query || undefined,
      };
    case StoreType.StorageAccount:
    case StoreType.None:
    default:
      return;
  }
}

export function getCatalogEntityName(
  dataset: Dataset,
  connectionDetails?: ConnectionDetails
): string | undefined {
  if (dataset.entityName && dataset.entityName.length > 0) {
    return dataset.entityName;
  }

  switch (connectionDetails?.subType) {
    case StoreType.Kusto:
      const kustoDetails = connectionDetails as KustoConnectionDetails;
      const cluster = removeProtocol(kustoDetails.cluster);
      const database = kustoDetails.database;
      const table = (dataset.datasetProperties as KustoDatasetProperties)
        ?.table;
      if (
        cluster?.length > 0 &&
        database?.length > 0 &&
        table &&
        table?.length > 0
      ) {
        return `kusto|${cluster}|${database}|${table}`;
      }
      return;
    case StoreType.StorageAccount:
    case StoreType.None:
    default:
      return;
  }
}

export function getDefaultAlertForExpectation(
  expectationInfo?: ExpectationInfo
): Alert | undefined {
  // in future, the default alert can be tagged
  return expectationInfo?.alerts && expectationInfo.alerts.length > 0
    ? expectationInfo.alerts[0]
    : undefined;
}

export function getDefaultAlertForRuleExecutionResult(
  result: RuleExecutionResult
): Alert | undefined {
  // in future, the default alert can be tagged
  return result.alerts && result.alerts.length > 0
    ? result.alerts[0]
    : undefined;
}

export function getStaticConditionByLabel(
  expectation: Expectation,
  label: 'from' | 'to'
) {
  return expectation.conditions.find(
    (c) => c.label.startsWith(label) && c.subType === ConditionType.Static
  ) as StaticCondition | undefined;
}

export function parseStaticConditionValue(
  condition: StaticCondition | undefined
) {
  return condition?.value && !Number.isNaN(Number.parseFloat(condition.value))
    ? Number.parseFloat(condition.value)
    : undefined;
}

function getExpectationsByStatus(
  ruleExecutionResult: RuleExecutionResult,
  status: 'True' | 'False'
): Expectation[] {
  const alert = getDefaultAlertForRuleExecutionResult(ruleExecutionResult);
  if (!alert) {
    return [];
  }
  const results =
    ruleExecutionResult.expectationResults?.filter(
      (r) => r.expectationResultValue === status
    ) || [];
  return (
    results
      .map((result) =>
        alert.expectations.find(
          (expectation) => expectation.id === result.expectationId
        )
      )
      .filter((e): e is Expectation => !!e) || []
  );
}

/**
 * return the list of expectations that failed
 * @param ruleExecutionResult
 * @returns failed expectations
 */
export function getFailedExpectations(
  ruleExecutionResult: RuleExecutionResult
): Expectation[] {
  return getExpectationsByStatus(ruleExecutionResult, 'True');
}

/**
 * return the list of expectations that didn't fail
 * @param ruleExecutionResult
 * @returns list of expectations that didn't fail
 */
export function getSucceededExpectations(
  ruleExecutionResult: RuleExecutionResult
): Expectation[] {
  return getExpectationsByStatus(ruleExecutionResult, 'False');
}

export function getTableName(dataset?: Dataset): {
  tableName?: string;
  hasCustomQuery: boolean;
} {
  let tableName: string | undefined = undefined;
  let hasCustomQuery = false;

  switch (dataset?.datasetProperties?.subType) {
    case StoreType.Kusto:
      const kustoProperties =
        dataset.datasetProperties as KustoDatasetProperties;
      hasCustomQuery = !!kustoProperties.query;
      tableName = kustoProperties.table || undefined;
      break;
    case StoreType.StorageAccount:
    case StoreType.None:
    default:
      break;
  }

  return {
    tableName,
    hasCustomQuery,
  };
}
