import { Client } from './client';

export enum RuleType {
  None = 'None',
  PreDefined = 'PreDefined',
  UserDefined = 'UserDefined',
}

export enum SystemDefinedRuleCategory {
  None = 'None',
  Timeliness = 'Timeliness',
  Completeness = 'Completeness',
  Consistency = 'Consistency',
  Conformity = 'Conformity',
  Uniqueness = 'Uniqueness',
  Accuracy = 'Accuracy',
  Integrity = 'Integrity',
}

export enum SystemDefinedRuleSubCategory {
  None = 'None',
  DataLatencyCheck = 'DataLatencyCheck',
  NullCheck = 'NullCheck',
  RowCountCheck = 'RowCountCheck',
  SchemaChangeCheck = 'SchemaChangeCheck',
  RegularExpressionCheck = 'RegularExpressionCheck',
  UniqueKeyViolationCheck = 'UniqueKeyViolationCheck',
  RowCountChangeCheck = 'RowCountChangeCheck',
  UnexpectedValueCheck = 'UnexpectedValueCheck',
  DataVarianceCheck = 'DataVarianceCheck',
  ReferenceDataMismatchCheck = 'ReferenceDataMismatchCheck',
  CoverageCheck = 'CoverageCheck',
  PublishFrequency = 'PublishFrequency',
}

export enum RuleConditionType {
  None = 'None',
  DataLatencyCondition = 'DataLatencyCondition',
  NullCheckCondition = 'NullCheckCondition',
  RowCountCondition = 'RowCountCondition',
  RegExCheckCondition = 'RegExCheckCondition',
  SchemaMismatchCheckCondition = 'SchemaMismatchCheckCondition',
  UniqueKeyViolationCheckCondition = 'UniqueKeyViolationCheckCondition',
  ReferenceDataMismatchCondition = 'ReferenceDataMismatchCondition',
  CoverageCondition = 'CoverageCondition',
  UnexpectedValueCheckCondition = 'UnexpectedValueCheckCondition',
  DataVarianceCondition = 'DataVarianceCondition',
  PublishFrequencyCondition = 'PublishFrequencyCondition',
}

export interface ColumnDetails {
  datasetId: string;
  columnName: string;
}

export type Column = ColumnDetails;

export interface RegExColumn extends ColumnDetails {
  regExType: RegExType;
  customRegExFormat?: string | null;
}

export interface UnexpectedValues {
  sourceColumn?: Column | null;
  values?: string[] | null;
}

export enum QueryLanguage {
  None = 'None',
  SPARK_SQL = 'SPARK_SQL',
}

export interface Query {
  language: QueryLanguage | string;
  queryText: string;
}

export interface RuleCondition {
  ruleConditionType: RuleConditionType | string;
  filter?: Query | null;
  dimensionColumns?: string[] | null;
}

export interface NullCheckCondition extends RuleCondition {
  ruleConditionType: RuleConditionType.NullCheckCondition;
  ColumnsToCheck?: ColumnDetails[] | null;
}

export interface RowCountCondition extends RuleCondition {
  ruleConditionType: RuleConditionType.RowCountCondition;
}

export interface RegExCheckCondition extends RuleCondition {
  ruleConditionType: RuleConditionType.RegExCheckCondition;
  ColumnsToCheck: RegExColumn[];
}

export interface UnexpectedValueCheckCondition extends RuleCondition {
  ruleConditionType: RuleConditionType.UnexpectedValueCheckCondition;
  unexpectedValueChecks?: UnexpectedValues[] | null;
}

export interface PublishFrequencyCondition extends RuleCondition {
  ruleConditionType: RuleConditionType.PublishFrequencyCondition;
}

export enum RegExType {
  None = 'None',
  Email = 'Email',
  GUID = 'GUID',
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
  URL = 'URL',
  Custom = 'Custom',
}

export interface RuleDetails {
  ruleType: RuleType | string;
  systemDefinedRuleCategories?: SystemDefinedRuleCategory | string | null;
  systemDefinedRuleSubCategories?: SystemDefinedRuleSubCategory | string | null;
  ruleCondition?: RuleCondition;
}

export enum RuleFrequency {
  None = 'None',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  CronExpression = 'CronExpression',
}

export interface RuleSchedule {
  frequency: RuleFrequency | string;
  frequencyValue: string;
  startTime: string;
  endTime?: string | null;
}

export interface Rule {
  id: string;
  name: string;
  description: string;
  version: string;
  tags: string[];
  workspaceId: string;
  datasetId: string;
  relatedDatasets?: string[] | null; // returned by RuleExecutionResult
  details: RuleDetails;
  advancedProperties?: {
    executeOnStore?: boolean | null;
    isPublic?: boolean | null;
    isActive?: boolean | null;
  };
  schedule?: RuleSchedule | null;
  createdBy?: Client;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  isDeleted?: boolean | null;
  _etag?: string | null;
  // Other DQP properties that we don't need on UI
  [propName: string]: any;
}

export interface RuleRequest {
  id?: string;
  name: string;
  description: string;
  version: string;
  tags: string[];
  workspaceId: string;
  datasetId: string;
  details: RuleDetails;
  advancedProperties?: {
    executeOnStore?: boolean | null;
    isPublic?: boolean | null;
    isActive?: boolean | null;
  };
  schedule?: RuleSchedule | null;
  // Other DQP properties that we don't need on UI
  [propName: string]: any;
}

/**
 * Dataset rule interface. A rule is scoped to a specific workspace and a specific dataset.
 */
export default Rule;
