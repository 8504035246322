import React from 'react';
import { Label, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';
import {
  selectWorkspaceId,
  selectWorkspaceStatus,
} from 'features/workspaces/workspaceSlice';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Datasets from './components/datasets/datasets';
import QualityReport from './components/qualityReport/qualityReport';

/**
 * Data Quality landing page and container component
 * @param props
 * @returns
 */
export const DataQuality = (): JSX.Element => {
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  const workspaceId = useSelector(selectWorkspaceId);

  if (workspaceStatus === WorkspaceStatus.Loaded && workspaceId) {
    return (
      <Switch>
        <Route
          path="/dataquality/datasets"
          component={() => <Datasets workspaceId={workspaceId} />}
        />
        <Route
          path="/dataquality/report/:datasetId"
          component={() => <QualityReport workspaceId={workspaceId} />}
        />
      </Switch>
    );
  }

  return (
    <Stack horizontalAlign="center" tokens={{ childrenGap: 24, padding: 100 }}>
      <Spinner size={SpinnerSize.large} />
      <Label>Loading workspace...</Label>
    </Stack>
  );
};

export default DataQuality;
