import {
  DeleteIconProps,
  ElxActionButton,
  ElxDialog,
  ElxTextField,
} from '@elixir/fx';
import { Workspace } from 'features/workspaces/models/workspace';
import {
  deleteWorkspace,
  saveWorkspace,
} from 'features/workspaces/workspaceSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import PeoplePicker from './peoplePicker';
import WorkspaceTagPicker from './tagPicker';
import {
  smallStackTokens,
  useEditContainerHeaderStyle,
  themedSmallStackTokens,
  useCommandBarStackStyles,
  wideSpacingStackTokens,
  actionsGapStackTokens,
  useEditContainerStyles,
  workspacePadding,
} from './editWorkspaceGeneralStyle';
import { switchToWorkspace } from 'features/workspaces/utils/workspaceUtils';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { Icon, Stack, Toggle } from '@fluentui/react';
import { FormError } from './workspaceHelperComponents';

interface EditWorkspaceGeneralProps {
  workspace: Workspace;
}

type FormValues = {
  name: string;
  description: string;
  tags: string[];
  administrators: string[];
  writers: string[];
  readersAllUsers: boolean;
  readers: string[];
  orchestrators: string[];
};

const getDefaultValues = (workspace: Workspace): FormValues => {
  return {
    name: workspace.name,
    description: workspace.description || '',
    tags: workspace.tags,
    administrators: workspace.adminGroups.concat(workspace.adminAliases),
    writers: workspace.readWriteGroups.concat(workspace.readWriteAliases),
    readersAllUsers: workspace.isReadOpen,
    readers: workspace.readOnlyGroups.concat(workspace.readOnlyAliases),
    orchestrators: workspace.orchestrateGroups.concat(
      workspace.orchestrateAliases
    ),
  };
};

const EditWorkspaceGeneral = (
  props: EditWorkspaceGeneralProps
): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useLensShellTheme();

  const [readOpen, setReadOpen] = React.useState(props.workspace.isReadOpen);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(props.workspace);

  const { control, formState, reset } = useForm<FormValues>({
    defaultValues: getDefaultValues(props.workspace),
    mode: 'onChange', //causes rerender onChange, possible perf issue
  });
  const { isDirty, isValid, errors } = formState;

  const updateParentState = (workspace: Workspace): void => {
    setWorkspace(workspace);
  };

  return (
    <Stack
      styles={{
        root: {
          background: theme.palette.neutralLighterAlt,
          childrenGap: 1,
          // maxHeight: '700px' commenting this out but need to find a fix to this. This affects the settings page
        },
      }}
    >
      <Stack.Item>
        <ElxDialog
          hidden={!showDeleteDialog}
          dismissable={false}
          header="Are you sure you want to delete this workspace?"
          primaryButtonText="Delete"
          onPrimaryButtonClick={() => {
            dispatch(deleteWorkspace(props.workspace.id));
            history.push('/workspaces/manage');
            switchToWorkspace(null); //switch back to private workspace rn. Will change after updating the url
          }}
          cancelButtonText="Cancel"
          onCancelButtonClick={() => {}}
          onDismiss={() => {
            setShowDeleteDialog(false);
          }}
        >
          <span>
            All saved objects and jobs in this workspace will be deleted.
          </span>
        </ElxDialog>
      </Stack.Item>
      <Stack.Item
        tokens={smallStackTokens}
        styles={{ root: { overflowY: 'auto' } }}
      >
        <form>
          <Stack tokens={actionsGapStackTokens}>
            <Stack
              horizontal
              styles={useCommandBarStackStyles(theme)}
              tokens={wideSpacingStackTokens}
            >
              <ElxActionButton
                iconProps={DeleteIconProps}
                text="Delete workspace"
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
              />
              <ElxActionButton
                iconProps={{
                  iconName: 'save',
                  title: 'Save workspace',
                  'aria-label': 'Save workspace',
                }}
                disabled={!isDirty || !isValid}
                text="Save workspace"
                onClick={() => {
                  dispatch(saveWorkspace(workspace));
                  reset(getDefaultValues(workspace));
                }}
              />
              <ElxActionButton
                iconProps={{
                  iconName: 'FabricMovetoFolder',
                  title: 'Migrate workspace',
                  'aria-label': 'Migrate workspace',
                }}
                text="Migrate workspace"
              />
            </Stack>
          </Stack>

          <Stack styles={workspacePadding} tokens={wideSpacingStackTokens}>
            <Stack.Item styles={useEditContainerStyles(theme)}>
              <Stack.Item styles={useEditContainerHeaderStyle(theme)}>
                About the Workspace
              </Stack.Item>
              <Stack styles={workspacePadding} tokens={themedSmallStackTokens}>
                <Stack>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: true,
                      minLength: 3,
                      pattern: /^[a-zA-Z0-9-_+\-'."\s]*$/,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ElxTextField
                        label="Name"
                        placeholder="workspace name"
                        defaultValue={props.workspace.name}
                        inputActions={[]}
                        onChange={(e, value) => {
                          setWorkspace({ ...workspace, name: value || '' });
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  <FormError
                    showError={errors.name}
                    errorMsg="This is required with minimum length of 3 and no special characters."
                  />
                </Stack>
                <Stack>
                  <span>
                    <label>Description</label>
                    <Icon
                      iconName="info"
                      title="The workspace description. This field is metadata explaining the purpose of this workspace."
                      aria-label="The workspace description. This field is metadata explaining the purpose of this workspace."
                    />
                  </span>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      pattern: /^(|[a-zA-Z0-9_+\-',?!%$."\s]*)$/,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ElxTextField
                        placeholder="workspace description"
                        multiline={true}
                        defaultValue={props.workspace.description}
                        required={false}
                        inputActions={[]}
                        onChange={(e, value) => {
                          setWorkspace({
                            ...workspace,
                            description: value || '',
                          });
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  <FormError
                    showError={errors.description}
                    errorMsg="No special characters are allowed."
                  />
                </Stack>
                <Stack>
                  <span>
                    <label>Tags</label>
                    <Icon
                      iconName="info"
                      title="The tags for the workspace Dashboard Notification Test."
                      aria-label="The tags for the workspace Dashboard Notification Test."
                    />
                  </span>
                  <Controller
                    control={control}
                    name="tags"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <WorkspaceTagPicker
                        workspace={props.workspace}
                        updateParentState={updateParentState}
                        onChange={onChange}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack.Item>

            <Stack.Item styles={useEditContainerStyles(theme)}>
              <Stack.Item styles={useEditContainerHeaderStyle(theme)}>
                Permissions
              </Stack.Item>
              <Stack styles={workspacePadding} tokens={themedSmallStackTokens}>
                <Stack.Item>
                  <label>Administrators</label>
                  <Icon
                    iconName="info"
                    title="Administrators. The applications, security groups, and users with admin access. Max 20."
                    aria-label="Administrators. The applications, security groups, and users with admin access. Max 20."
                  />
                  <Controller
                    control={control}
                    name="administrators"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PeoplePicker
                        people={props.workspace.adminGroups?.concat(
                          props.workspace.adminAliases
                        )}
                        workspace={props.workspace}
                        updateParentState={updateParentState}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <FormError
                    showError={errors.administrators}
                    errorMsg=" There must be at least one security group or user with
                      admin access"
                  />
                </Stack.Item>
                <Stack.Item>
                  <label>Writers</label>
                  <Icon
                    iconName="info"
                    title="Writers. The applications, security groups, and users with read-write access. Max 20."
                    aria-label="Writers. The applications, security groups, and users with read-write access. Max 20."
                  />
                  <Controller
                    control={control}
                    name="writers"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PeoplePicker
                        people={props.workspace.readWriteGroups?.concat(
                          props.workspace.readWriteAliases
                        )}
                        workspace={props.workspace}
                        updateParentState={updateParentState}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <FormError
                    showError={errors.writers}
                    errorMsg="There must be at least one security group or user with
                      write access"
                  />
                </Stack.Item>
                <>
                  <span>
                    <label>Readers</label>
                    <Icon
                      iconName="info"
                      title="Readers. The applications, security groups, and users with read-only access. Max 20."
                      aria-label="Readers. The applications, security groups, and users with read-only access. Max 20."
                    />
                  </span>
                  <Controller
                    control={control}
                    name="readersAllUsers"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Toggle
                        onText="Grant read only access to all users"
                        offText="Grant read only access to select users"
                        defaultChecked={readOpen}
                        onChange={(e, value) => {
                          setReadOpen(!!value);
                          setWorkspace({ ...workspace, isReadOpen: !!value });
                          onChange({
                            target: {
                              name: 'toggleReadersOption',
                              value: !!value,
                            },
                          });
                        }}
                      />
                    )}
                  />
                  {!readOpen && (
                    <>
                      <Controller
                        control={control}
                        name="readers"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <PeoplePicker
                            people={props.workspace.readOnlyGroups?.concat(
                              props.workspace.readOnlyAliases
                            )}
                            workspace={props.workspace}
                            updateParentState={updateParentState}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <FormError
                        showError={errors.readers}
                        errorMsg="There must be at least one security group or user with
                      read access, if read access is not open to all."
                      />
                    </>
                  )}
                </>
                <Stack.Item>
                  <label>Orchestrators</label>
                  <Icon
                    iconName="info"
                    title="Orchestrators. The applications, security groups, and users with orchestration access. Orchestrators can manage and author jobs using workspace data connections. Max 20."
                    aria-label="Orchestrators. The applications, security groups, and users with orchestration access. Orchestrators can manage and author jobs using workspace data connections. Max 20."
                  />
                  <Controller
                    control={control}
                    name="orchestrators"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PeoplePicker
                        people={props.workspace.orchestrateGroups?.concat(
                          props.workspace.orchestrateAliases
                        )}
                        workspace={props.workspace}
                        updateParentState={updateParentState}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </form>
      </Stack.Item>
    </Stack>
  );
};
export default EditWorkspaceGeneral;
