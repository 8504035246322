import React from 'react';
import { IDropdownOption, ISelectableOption } from '@fluentui/react';
import { ElxDropdown } from '@elixir/fx';
import { getUserExpectationSelectOptions } from '../../userExpectations/userExpectationsRegistry';
import Dataset from 'features/dataQuality/models/dataset';
import { LensLabel } from 'utils/lensLabel';
import { SelectSentenceHelpLabel } from 'utils/helpIconText';

export interface ExpectationSelectorProps {
  selectedKey?: string;
  onSelect: (key: string) => void;
  dataset: Dataset;
}

function renderHighlightedOption(
  props?: ISelectableOption,
  defaultRender?: (props?: ISelectableOption) => JSX.Element | null
): JSX.Element | null {
  if (props?.data) {
    return props.data;
  }
  if (defaultRender) {
    return defaultRender(props);
  }
  return null;
}

function renderHighlightedTitle(
  props?: ISelectableOption[],
  defaultRender?: (props?: ISelectableOption[]) => JSX.Element | null
): JSX.Element | null {
  return renderHighlightedOption(props?.[0]); // works for single-select options
}

export const ExpectationSelector = (
  props: ExpectationSelectorProps
): JSX.Element => {
  const { onSelect, selectedKey, dataset } = props;
  const styles = {
    dropdownItem: {
      whiteSpace: 'pre-wrap',
    },
  };

  return (
    <ElxDropdown
      placeholder="Select a template..."
      label="Select the sentence that best describes your expectation"
      onRenderLabel={() => (
        <>
          <LensLabel
            labelText="Select the sentence that best describes your expectation"
            hintText={SelectSentenceHelpLabel}
            required={true}
          ></LensLabel>
        </>
      )}
      options={getUserExpectationSelectOptions(dataset)}
      selectedKey={selectedKey}
      onChange={(_, option?: IDropdownOption) => {
        if (option) {
          onSelect(option.key?.toString());
        }
      }}
      onRenderOption={renderHighlightedOption}
      onRenderTitle={renderHighlightedTitle}
      styles={styles}
    />
  );
};

export default ExpectationSelector;
