import { useLensShellTheme } from 'features/shell/lensShellStyles';
import React from 'react';
import { FieldError } from 'react-hook-form';

export const WorkspaceHr = (): JSX.Element => {
  const theme = useLensShellTheme();
  return (
    <hr style={{ margin: '0.1em auto', color: theme.palette.neutralLight }} />
  );
};

interface FormErrorProps {
  showError: FieldError | FieldError[] | undefined;
  errorMsg: string;
}
export const FormError = (props: FormErrorProps): JSX.Element => {
  const theme = useLensShellTheme();
  return (
    <>
      {props.showError && (
        <p style={{ color: theme.palette.red }}>{props.errorMsg}</p>
      )}
    </>
  );
};
