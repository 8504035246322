import { TelemetryClient } from 'applicationinsights';

let leApp: any; // AngularJS dependency - minimize use

export function inject(_leApp: any) {
  leApp = _leApp;
}

declare global {
  interface Window {
    BUILD_NUM: string;
    AAD_CLIENT_ID: string;
    appInsights: TelemetryClient;
    msalInstance: any;
    startUpConfig: {
      resourceIds: {
        [key: string]: string;
      };
      serviceEndpoints: {
        [key: string]: string;
      };
      showOCVFeedback: boolean;
    };
  }
}

// Export standard JS libraries - LoDash, ADAL...
// Avoid using AngularJS dependencies - $rootScope, laTelemetry, workspaceManager...
// AngularJS dependencies may be deprecated and replaced with React in the future.
export const _ = window._; // this is auto detected as type 'LoDashStatic'
export const AAD_CLIENT_ID = window.AAD_CLIENT_ID;
export const BUILD_NUM = window.BUILD_NUM;
export const appInsights = window.appInsights;
export const resourceIds = window.startUpConfig.resourceIds;
export const serviceEndpoints = window.startUpConfig.serviceEndpoints;
export const showOCVFeedback = window.startUpConfig?.showOCVFeedback;
// Use this method to aquire AAD tokens.  The adal embedded frame for token refresh appears to be tied to
// the adal-angular authenticationContext, not the window.authContextInstance authenticationContext (which
// is used for initial login).
export const acquireToken = (resource: string): Promise<string> => {
  let scopes =
    leApp.angularServices.msalAuthenticationService.getScopesForEndpoint(
      resource
    );
  return leApp.angularServices.msalAuthenticationService.acquireTokenSilent(
    scopes
  );
};

export const getUser = (): any => {
  return window.msalInstance.getActiveAccount();
};
