import React from 'react';

import { ElxActionButton, useId } from '@elixir/fx';
import { Stack } from '@fluentui/react';
import { IButtonStyles } from 'office-ui-fabric-react';

export interface HintTextProps {
  /**
   * hint text
   */
  hintText: string | undefined;
}

/**
 * Renders hint text for metadata properties
 */
export const HintText = (props: HintTextProps): JSX.Element => {
  const { hintText } = props;
  const tooltipId = useId('HintText');

  return (
    <Stack>
      <ElxActionButton
        text="i"
        styles={iconButtonStyles}
        tooltipProps={{ content: hintText, id: tooltipId }}
      />
    </Stack>
  );
};

const iconButtonStyles: IButtonStyles = {
  root: {
    '.ms-Button-label': {
      fontSize: '10px',
    },
    marginTop: '5px',
  },
  flexContainer: {
    fontSize: '10px !important',
    borderWidth: 'thin',
    borderStyle: 'solid',
    color: 'rgb(16, 110, 190);',
    borderRadius: '100%',
    width: '12px',
    height: '12px',
  },
};
