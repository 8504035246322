import React from 'react';
import { IComboBoxOption, Stack } from '@fluentui/react';
import {
  DeleteButtonProps,
  ElxActionButton,
  ElxDropdown,
  IElxDropdownProps,
} from '@elixir/fx';
import { ElxCombobox } from '@elixir/fx/lib/components/Combobox';
import {
  Expectation,
  StaticCondition,
} from 'features/dataQuality/models/alert';
import { getStaticConditionByLabel } from 'features/dataQuality/utils/dataQualityUtils';
import { ThresholdUnit } from './thresholdUnits';

export enum ThresholdLevel {
  SEV1 = 'SEV1',
  SEV2 = 'SEV2',
  SEV3 = 'SEV3',
  SEV4 = 'SEV4',
}

export interface ThresholdState {
  from?: string;
  to?: string;
  level?: ThresholdLevel;
}

export interface ThresholdProps {
  state?: ThresholdState;
  setState: (state: ThresholdState) => void;
  thresholds: ThresholdState[];
  unit: ThresholdUnit;
  deleteEnabled?: boolean;
  onDelete: () => void;
}

// the Expectation will encode the threshold level (severity) as a tag
function decodeThresholdLevel(
  expectation: Expectation,
  fromCondition?: StaticCondition,
  toCondition?: StaticCondition
) {
  fromCondition =
    fromCondition || getStaticConditionByLabel(expectation, 'from');
  toCondition = toCondition || getStaticConditionByLabel(expectation, 'to');

  // ThresholdLevel should be stored as tags
  if (expectation.tags?.includes(ThresholdLevel.SEV4)) {
    return ThresholdLevel.SEV4;
  }
  if (expectation.tags?.includes(ThresholdLevel.SEV3)) {
    return ThresholdLevel.SEV3;
  }
  if (expectation.tags?.includes(ThresholdLevel.SEV2)) {
    return ThresholdLevel.SEV2;
  }
  if (expectation.tags?.includes(ThresholdLevel.SEV1)) {
    return ThresholdLevel.SEV1;
  }

  // Oudated for backward compatibility: severity stored in condition labels.  Delete after DQMVP.
  return (fromCondition?.label?.substr(-4) ||
    toCondition?.label?.substr(-4)) as ThresholdLevel;
}

export function decodeThreshold(
  expectation: Expectation
): ThresholdState | undefined {
  const fromCondition = getStaticConditionByLabel(expectation, 'from');
  const toCondition = getStaticConditionByLabel(expectation, 'to');

  return {
    from: fromCondition?.value,
    to: toCondition?.value,
    level: decodeThresholdLevel(expectation, fromCondition, toCondition),
  };
}

export function decodeSeverity(expectation: Expectation): number | undefined {
  const threshold = decodeThreshold(expectation);
  if (threshold?.level === ThresholdLevel.SEV1) {
    return 1;
  }
  if (threshold?.level === ThresholdLevel.SEV2) {
    return 2;
  }
  if (threshold?.level === ThresholdLevel.SEV3) {
    return 3;
  }
  if (threshold?.level === ThresholdLevel.SEV4) {
    return 4;
  }
}

const lowerPercentOptions = [
  { key: '0', text: '0%' },
  { key: '10', text: '10%' },
  { key: '50', text: '50%' },
  { key: '75', text: '75%' },
  { key: '90', text: '90%' },
  { key: '98', text: '98%' },
];

const upperPercentOptions = [
  { key: '10', text: '10%' },
  { key: '50', text: '50%' },
  { key: '75', text: '75%' },
  { key: '90', text: '90%' },
  { key: '98', text: '98%' },
  { key: '100', text: '100%' },
];

const lowerBoundOptions = [
  { key: '0', text: '0' },
  { key: '-Infinity', text: '-Infinity' },
];

const upperBoundOptions = [{ key: 'Infinity', text: '+Infinity' }];

export interface ValueBoxProps {
  state?: ThresholdState;
  extraOptions: IComboBoxOption[];
  setState: (state: ThresholdState) => void;
  field: 'from' | 'to';
  unit: ThresholdUnit;
}

const ValueBox = ({
  state,
  extraOptions,
  setState,
  field,
  unit,
}: ValueBoxProps): JSX.Element => {
  const lowerOptions = unit.isPercent ? lowerPercentOptions : lowerBoundOptions;
  const upperOptions = unit.isPercent ? upperPercentOptions : upperBoundOptions;

  return (
    <ElxCombobox
      text={unit.getDisplayString(state?.[field])}
      onChange={(e, option, i, value) => {
        const num = unit.calcUserInput(option?.text || value || '');
        const numStr = Number.isNaN(num) ? '' : num.toString();
        setState({
          ...state,
          [field]: numStr,
        });
      }}
      options={[
        ...extraOptions,
        ...(field === 'from' ? lowerOptions : upperOptions),
      ]}
      openOnKeyboardFocus={true}
      allowFreeform={true}
      autoComplete={'on'}
      useComboBoxAsMenuWidth={true}
    />
  );
};

const thresholdOptions = [
  { key: ThresholdLevel.SEV1, text: 'Sev 1' },
  { key: ThresholdLevel.SEV2, text: 'Sev 2' },
  { key: ThresholdLevel.SEV3, text: 'Sev 3' },
  { key: ThresholdLevel.SEV4, text: 'Sev 4' },
];

const ThresholdLevelDropdown = (
  props: Omit<IElxDropdownProps, 'options'>
): JSX.Element => {
  return <ElxDropdown {...props} required={true} options={thresholdOptions} />;
};

// keys that are built-in and shouldn't be included in suggested range options.
const blockedKeys = new Set([
  ...lowerPercentOptions.map((o) => o.key),
  ...upperPercentOptions.map((o) => o.key),
  ...lowerBoundOptions.map((o) => o.key),
  ...upperBoundOptions.map((o) => o.key),
]);

export const Threshold = ({
  state,
  setState,
  thresholds,
  unit,
  deleteEnabled,
  onDelete,
}: ThresholdProps): JSX.Element => {
  // Compute a useful set of values for user to choose if they are entering contiguous ranges
  const existingFromValues = new Set(
    thresholds.map((t) => t.from).filter((t) => !!t) as string[]
  );
  const existingToValues = new Set(
    thresholds.map((t) => t.to).filter((t) => !!t) as string[]
  );

  // remove illegal options
  const promptFromValues = [...existingToValues].filter(
    (v) =>
      !existingFromValues.has(v) &&
      !blockedKeys.has(v) &&
      v < (state?.to || Infinity)
  );
  const promptToValues = [...existingFromValues].filter(
    (v) =>
      !existingToValues.has(v) &&
      !blockedKeys.has(v) &&
      v > (state?.from || -Infinity)
  );

  // convert to options array
  const fromOptions = promptFromValues.map((v) => ({
    key: unit.getDisplayString(v),
    text: unit.getDisplayString(v),
  }));
  const toOptions = promptToValues.map((v) => ({
    key: unit.getDisplayString(v),
    text: unit.getDisplayString(v),
  }));

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center">
      <label>From</label>
      <ValueBox
        state={state}
        setState={setState}
        extraOptions={fromOptions}
        field="from"
        unit={unit}
      />
      <label>To</label>
      <ValueBox
        state={state}
        setState={setState}
        extraOptions={toOptions}
        field="to"
        unit={unit}
      />
      <label>is&nbsp;a</label>
      <ThresholdLevelDropdown
        styles={{ root: { minWidth: 70 } }}
        selectedKey={state?.level}
        onChange={(e, option) =>
          setState({ ...state, level: option?.key as ThresholdLevel })
        }
      />
      <ElxActionButton
        {...DeleteButtonProps}
        text=""
        title="Delete threshold"
        onClick={onDelete}
        disabled={!deleteEnabled}
      />
    </Stack>
  );
};

export default Threshold;
