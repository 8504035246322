import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ElxDropdown,
  ElxLink,
  ElxPanel,
  ElxPicker,
  IContainerAction,
  PanelSize,
  ElxActionButton,
  ElxTextField,
  InputMessageTypes,
} from '@elixir/fx';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { IDropdownOption, IPickerItem, Stack } from 'office-ui-fabric-react';
import { Workspace } from 'features/workspaces/models/workspace';
import { buildErrorMessage } from 'components/errorMessageBar/errorMessageBar';
import { SidePanel } from 'features/shell/lensShell';
import {
  useConnections,
  createDataset,
  updateDataset,
  loadDatasets,
  loadConnections,
} from '../../dataQualitySlice';
import { DatasetsPanel } from './datasets';
import {
  DatasetRequest,
  StoreType,
  KustoDatasetProperties,
  Dataset,
} from 'features/dataQuality/models/dataset';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel as showPanel,
  panelDefaults,
} from 'components/panelManager/panelManager';
import EditDataConnection from 'features/workspaces/components/editDataConnection/editDataConnection';
import { getDataSourceClient } from 'features/dataSources/registry';
import { loadProject, loadWorkspace } from 'features/workspaces/workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import {
  AadConnectionAuthType,
  ConnectionDataSourceType,
  KustoDataConnection,
} from 'features/workspaces/models/project';
import { panelStyles } from 'utils/sharedstyles';
import { LensLabel } from 'utils/lensLabel';
import {
  AddQueryHelpLabel,
  DatasetNameHelpLabel,
  SelectConnectionHelpLabel,
  SelectTableHelpLabel,
} from 'utils/helpIconText';

const dataConnectionsHelpLink =
  'https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensv3/jobauthoringdataconnections';

export interface AddDataConnectionProps extends BasePanelProps {
  panelId: DatasetsPanel;
  workspace: Workspace;
  dataset: Dataset;
  show: boolean;
  datasetNameList: string[];
}

interface PickerItem extends IPickerItem {
  key: string;
  text: string;
}

export interface KustoDatasetState {
  table?: string;
  query?: string;
}

interface KustoConnectionOption extends IDropdownOption {
  data: KustoDataConnection;
}

const inputStyle = { root: { width: '390px' } };
const inputStyle2 = {
  root: {
    '.elx-picker-button': { width: '390px' },
  },
};

// This component is used for Adding and Editing Data Sets
// dataset property decides if its a Add or Edit
// dataset as null means Add a new DataSet
// dataset as some obj is Edit exsiting Dataset
export const AddDataset = (props: AddDataConnectionProps): JSX.Element => {
  const panelState = usePanelManagerState();
  const { show, onShowPanel, onAddPanel, workspace, datasetNameList, dataset } =
    props;
  const theme = useLensShellTheme();
  var connectionsOptions: KustoConnectionOption[] = [];
  const [tables, setTables] = useState<any[]>([]);
  useConnections(workspace.id);

  const dispatch = useDispatch<AppDispatch>();
  const [isNew, setIsNew] = useState<boolean>(dataset ? true : false);
  const [customName, setCustomName] = useState<string>('');
  const [customNameFilled, setCustomNameFilled] = useState<boolean>(false);
  const [table, setTable] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [connectionName, setConnectionName] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const kustoConnections =
    (workspace.dataConnections?.filter(
      (c) => c.type === ConnectionDataSourceType.Kusto
    ) as KustoDataConnection[]) || [];
  const validConnections = kustoConnections
    .filter((c) => c.authType === AadConnectionAuthType.AADApp)
    .filter((c) => !!c.dataQualityServiceConnectionId);
  const connectionNameList = validConnections.map(
    (c) => c.connectionDisplayName
  );
  // Read Values of Existing DataSet -- Starts Here
  const datasetName = dataset ? dataset.datasetName : '';
  const existingConnectionName =
    dataset &&
    dataset.dataConnectionName &&
    validConnections.filter(
      (c) => c.dataQualityServiceConnectionId === dataset.dataConnectionName
    );
  const datasetConnectionName =
    existingConnectionName && existingConnectionName.length === 1
      ? (existingConnectionName[0] as KustoDataConnection).connectionName
      : '';
  const datasetTableName =
    dataset &&
    dataset.datasetProperties &&
    (dataset.datasetProperties as KustoDatasetProperties).table
      ? (dataset.datasetProperties as KustoDatasetProperties).table
      : '';
  const datasetQuery =
    dataset &&
    dataset.datasetProperties &&
    (dataset.datasetProperties as KustoDatasetProperties).query
      ? (dataset.datasetProperties as KustoDatasetProperties).query
      : '';
  // Read Values of Existing DataSet -- Ends Here
  const connectionMap: { [name: string]: KustoDataConnection } = {};
  validConnections.forEach(function (c) {
    connectionMap[c.connectionName] = c;
  });
  const connection = connectionMap[connectionName];

  connectionsOptions = validConnections.map((c) => {
    return {
      key: c.connectionName,
      text: c.connectionDisplayName,
      data: c,
    };
  });

  const connectionDisplayName = connectionName
    ? connectionMap[connectionName].connectionDisplayName
    : '';
  const generatedName =
    connectionDisplayName && table && !customNameFilled
      ? (connectionDisplayName + '.' + table).split(' ').join('')
      : '';

  const currentName = customName || generatedName;
  const nameConflict = datasetNameList.includes(currentName) && !dataset;

  function newConnectionName(newConnection: string) {
    setConnectionName(newConnection);
  }

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Save',
        text: 'Save Dataset',
        isPrimary: true,
        disabled:
          saving ||
          !connection ||
          connectionName === '' ||
          table === '' ||
          nameConflict,
        onClick: onSave,
      },
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
    ];
  }

  function onDismiss() {
    setIsNew(!isNew);
    setTables([]);
    setCustomName('');
    setCustomNameFilled(false);
    setTable('');
    setQuery('');
    setConnectionName('');
    setSaving(false);
    setTableLoading(false);
    setErrorMessage('');
    onShowPanel(false);
  }

  async function onSave() {
    if (!workspace || !connection) {
      return; // won't get here because save button will be disabled.
    }
    setErrorMessage('');
    setSaving(true);
    try {
      const workspaceId = workspace.id;
      const KustoDatasetProperties: KustoDatasetProperties = {
        subType: StoreType.Kusto,
        table: table,
        query: query,
      };

      const datasetRequest: DatasetRequest = {
        workspaceId: workspace.dqsWorkspace,
        workspaceName: workspace.name,
        dataConnectionName: connection.dataQualityServiceConnectionId!,
        datasetName: currentName,
        datasetProperties: KustoDatasetProperties,
        id: dataset ? dataset.id : undefined,
      };

      dispatch(
        dataset
          ? updateDataset({ workspaceId, datasetRequest })
          : createDataset({ workspaceId, datasetRequest })
      ).then(() => {
        dispatch(loadDatasets(workspace.id));
        dispatch(loadConnections(workspace.id));
        dispatch(loadProject());
        dispatch(loadWorkspace());
        onDismiss();
      });
    } catch (error) {
      setErrorMessage(
        (error as Error)?.message || (error as string) || 'unknown error'
      );
    } finally {
      setSaving(false);
    }
  }

  const onDismissError = () => setErrorMessage('');
  const onShowNotifications = () => onAddPanel(SidePanel.NOTIFICATIONS);

  function showEditPanel(panelId: DatasetsPanel) {
    showPanel(panelState, panelId);
  }

  const tableOptions: PickerItem[] = tables.map((table: any) => {
    return {
      key: table.name,
      text: table.name,
    };
  });

  // State will be updated based on dependencies [datasetName,datasetConnectionName,datasetTableName,datasetQuery]
  useEffect(() => {
    setCustomName(datasetName);
    setConnectionName(datasetConnectionName);
    setTable(datasetTableName!);
    setQuery(datasetQuery!);
  }, [
    datasetName,
    datasetConnectionName,
    datasetTableName,
    datasetQuery,
    isNew,
  ]);
  useEffect(() => {
    if (connection) {
      const dataSourceClient = getDataSourceClient(connection.type);
      if (
        dataSourceClient &&
        connection.cluster &&
        connection.cluster !== '' &&
        connection.database &&
        connection.database !== ''
      ) {
        setTableLoading(true);
        dataSourceClient
          .getTables(connection.cluster, connection.database)
          .then(function (tables) {
            setTables(tables);
          })
          .catch(function (error) {
            setErrorMessage(
              (error as Error)?.message || (error as string) || 'unknown error'
            );
          })
          .finally(function () {
            setTableLoading(false);
          });
      }
    }
  }, [connection]);

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={dataset ? 'Edit Dataset' : 'Add Dataset'}
        headerContent={
          <span>
            {'Need additional help? '}
            <ElxLink
              style={{ verticalAlign: 'baseline' }}
              href={dataConnectionsHelpLink}
              target="_blank"
            >
              Learn more about data connections
            </ElxLink>
          </span>
        }
        isOpen={show}
        message={
          (errorMessage &&
            buildErrorMessage(
              errorMessage,
              onDismissError,
              onShowNotifications
            )) ||
          undefined
        }
        size={PanelSize.medium}
        onDismiss={onDismiss}
        actions={renderActions()}
        isLoading={saving || tableLoading}
        loadingLabel={
          saving ? 'Saving...' : tableLoading ? 'Loading tables...' : ''
        }
        styles={panelStyles}
      >
        <Stack tokens={{ childrenGap: 16, padding: 24 }}>
          <ElxTextField
            label="Dataset Name"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Dataset Name"
                  hintText={DatasetNameHelpLabel}
                  required={true}
                ></LensLabel>
              </>
            )}
            disabled={dataset ? true : false}
            value={currentName}
            placeholder="Enter the dataset name"
            message={
              (nameConflict &&
                !dataset && {
                  type: InputMessageTypes.Error,
                  content: `A dataset named "${currentName}" already exists`,
                }) ||
              undefined
            }
            onChange={(_, newValue) => {
              setCustomName(newValue || '');
              setCustomNameFilled(true);
            }}
            styles={inputStyle}
          />
          <ElxDropdown
            label="Select a connection"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Select a connection"
                  hintText={SelectConnectionHelpLabel}
                  required={true}
                ></LensLabel>
              </>
            )}
            selectedKey={connectionName}
            options={connectionsOptions}
            onChange={(_, newValue) => {
              if (newValue) {
                setConnectionName(newValue.key as string);
              }
            }}
            styles={inputStyle}
            emptyOption={connectionsOptions.length < 1 ? true : false}
            emptyOptionText={'Please create a new connection'}
            disabled={connectionsOptions.length < 1 ? true : false}
            required
          />
          <ElxActionButton
            iconProps={{ iconName: 'Add' }}
            text="Create new connection"
            onClick={() => showEditPanel(DatasetsPanel.EDIT_DATACONNECTION)}
          />
          {connection && tableOptions.length > 0 && !tableLoading && (
            <>
              <ElxPicker
                label="Select a Table"
                onRenderLabel={() => (
                  <>
                    <LensLabel
                      labelText="Select a table"
                      hintText={SelectTableHelpLabel}
                      required={true}
                    ></LensLabel>
                  </>
                )}
                styles={inputStyle2}
                items={tableOptions}
                selectedKey={table}
                placeHolder="Select a database table"
                searchBoxProps={{
                  placeholder: 'Search tables',
                }}
                onChange={(newValue) => setTable(newValue.key)}
                isLoading={tableLoading}
                calloutWidth={390}
                required
              />

              <ElxTextField
                label="Query"
                onRenderLabel={() => (
                  <>
                    <LensLabel
                      labelText="Query"
                      hintText={AddQueryHelpLabel}
                      required={false}
                    ></LensLabel>
                  </>
                )}
                value={query}
                placeholder="Enter the query (optional)"
                onChange={(_, newValue) => setQuery(newValue || '')}
                styles={inputStyle}
                multiline={true}
                autoAdjustHeight={true}
              />
            </>
          )}
          <PanelManager panelState={panelState}>
            <EditDataConnection
              {...panelDefaults}
              panelId={DatasetsPanel.EDIT_DATACONNECTION}
              workspace={workspace}
              newConnectionName={newConnectionName}
              connectionNameList={connectionNameList}
            />
          </PanelManager>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default AddDataset;
