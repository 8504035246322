import { decodeSeverity } from 'features/dataQuality/components/classifyResults/threshold';
import RuleExecutionResult, {
  PublishFrequencyResult,
  RuleResultType,
} from 'features/dataQuality/models/ruleExecutionResult';
import {
  getDefaultAlertForRuleExecutionResult,
  getFailedExpectations,
  getTableName,
} from 'features/dataQuality/utils/dataQualityUtils';
import {
  ResultsTableDescription,
  UserExpectationResultsBuilder,
  UserExpectationResultsDescription,
} from '../../base/userExpectationResultsBuilder';
import { decodeUnit } from './publishFrequencyExpectation';

export class PublishFrequencyResultsBuilder extends UserExpectationResultsBuilder {
  public static getResultDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    const ruleResult = ruleExecutionResult.ruleResult as PublishFrequencyResult;
    const { tableName } = getTableName(ruleExecutionResult.ruleDataSet);
    const publishDurationInSeconds = ruleResult.publishDurationInSeconds;
    const latestPublishTime = ruleResult.latestPublishTime;
    const isDatasetUpdated = ruleResult.isDatasetUpdated;
    const alert = getDefaultAlertForRuleExecutionResult(ruleExecutionResult);
    const unit = decodeUnit(alert);
    const durationPhrase = unit.getDisplayPhrase(publishDurationInSeconds, 2);

    let retVal: UserExpectationResultsDescription = {
      expectationDescription: `${tableName} must be updated regularly`,
      thresholdValueDescriptions: [],
    };

    if (publishDurationInSeconds === 0) {
      retVal.outcomeSummary = `Not enough data to calculate the update interval, at least two tracked updates to ${tableName} are required.`;
      retVal.thresholdValueDescriptions.push('Insufficient Data');
    }
    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const severity = decodeSeverity(failedExpectation);
      const severityString = severity
        ? `failed with Sev ${severity}`
        : 'failed';

      retVal.outcomeSummary = `Evaluation ${severityString} because ${tableName} was updated ${durationPhrase} after the previously tracked update.`;
      retVal.failureValue = publishDurationInSeconds;
      retVal.failureValueDescription = `Table ${tableName} was updated ${durationPhrase} after the previously tracked update.`;
      retVal.thresholdValueDescriptions.push(
        UserExpectationResultsBuilder.describeStaticRangeCondition(
          failedExpectation,
          unit,
          publishDurationInSeconds
        )
      );
    } else {
      retVal.outcomeSummary = `Evaluation passed with ${durationPhrase} between the two most recent tracked updates.`;
      retVal.thresholdValueDescriptions.push(
        ...UserExpectationResultsBuilder.describeNearbySuccededThresholds(
          ruleExecutionResult,
          unit,
          publishDurationInSeconds
        )
      );
    }

    if (!isDatasetUpdated) {
      retVal.thresholdValueDescriptions.push(
        `During this execution of the Expectation, no new updates to ${tableName} were tracked.`
      );
    }

    retVal.thresholdValueDescriptions.push(
      `Last tracked update for ${tableName} was at ${latestPublishTime}`
    );

    return retVal;
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [
        {
          ...this.colDefaults,
          minWidth: 120,
          maxWidth: 130,
          key: 'ExecutionDate',
          name: 'Execution Date',
          fieldName: 'date',
        },
        {
          ...this.colDefaults,
          minWidth: 80,
          maxWidth: 120,
          key: 'updateInterval',
          name: 'Update Interval',
          fieldName: 'updateInterval',
        },
        {
          ...this.colDefaults,
          minWidth: 80,
          maxWidth: 100,
          key: 'newUpdate',
          name: 'New Update',
          fieldName: 'newUpdate',
        },
        {
          ...this.colDefaults,
          minWidth: 50,
          key: 'Result',
          name: 'Result',
          fieldName: 'result',
        },
        {
          ...this.colDefaults,
          minWidth: 120,
          key: 'Range',
          name: 'Range',
          fieldName: 'range',
        },
        {
          ...this.colDefaults,
          minWidth: 120,
          key: 'latestUpdate',
          name: 'Latest Update',
          fieldName: 'latestUpdate',
        },
      ],
      items: ruleExecutionResults.map((ruleExecutionResult, index) => {
        const alert =
          getDefaultAlertForRuleExecutionResult(ruleExecutionResult);
        const unit = decodeUnit(alert);

        const item = {
          key: index.toString(),
          date: UserExpectationResultsBuilder.getShorterDate(
            ruleExecutionResult.ruleExecutionStartTime
          ),
        };

        if (
          ruleExecutionResult.ruleResult.subType === RuleResultType.ErrorResult
        ) {
          return { ...item, result: 'Error' };
        }

        const ruleResult =
          ruleExecutionResult.ruleResult as PublishFrequencyResult;
        const publishDurationInSeconds = ruleResult.publishDurationInSeconds;

        const { result, rangeStart, rangeEnd } =
          UserExpectationResultsBuilder.calcResultRange(
            ruleExecutionResult,
            ruleResult.publishDurationInSeconds
          );

        const rangeStartStr = unit.getDisplayString(rangeStart || 0, 2);
        const rangeEndStr =
          rangeEnd !== undefined
            ? ' - ' + unit.getDisplayPhrase(rangeEnd, 2)
            : '+ ' + unit.unitDisplayNamePlural;

        return {
          ...item,
          updateInterval:
            publishDurationInSeconds === 0
              ? 'no data'
              : unit.getDisplayPhrase(publishDurationInSeconds, 2),
          newUpdate: ruleResult.isDatasetUpdated ? 'updated' : 'unchanged',
          result,
          latestUpdate: UserExpectationResultsBuilder.getShorterDate(
            ruleResult.latestPublishTime
          ),
          range: `${rangeStartStr}${rangeEndStr}`,
        };
      }),
    };
  }
}

export default PublishFrequencyResultsBuilder;
