import { UnexpectedValueCheckCondition } from 'features/dataQuality/models/rule';
import UserExpectationBuilder from '../../base/userExpectationBuilder';
import { UnexpectedValueCheckState } from './unexpectedValueCheckExpectation';

export class UnexpectedValueCheckBuilder extends UserExpectationBuilder<UnexpectedValueCheckState> {
  // insert the column name parameter into the DQS UnexpectedValueCheckCondition
  buildRuleDetails(state: UnexpectedValueCheckState) {
    const ruleDetails = super.buildRuleDetails(state);
    (
      ruleDetails.ruleCondition as UnexpectedValueCheckCondition
    ).unexpectedValueChecks = [
      {
        sourceColumn: {
          datasetId: this.dataset.id,
          columnName: state.column || '',
        },
        values: state.unexpectedValues,
      },
    ];
    return ruleDetails;
  }

  // use the percent thresholds handler
  processThresholds = super.processPercentageThresholds;
}

export default UnexpectedValueCheckBuilder;
