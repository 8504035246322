import Alert from './alert';
import Dataset from './dataset';
import Rule from './rule';

export enum RuleResultType {
  None = 'None',
  DataLatencyCheckResult = 'DataLatencyCheckResult',
  NullCheckResult = 'NullCheckResult',
  RowCountCheckResult = 'RowCountCheckResult',
  SchemaChangeCheckResult = 'SchemaChangeCheckResult',
  RegularExpressionCheckResult = 'RegularExpressionCheckResult',
  UniqueKeyViolationCheckResult = 'UniqueKeyViolationCheckResult',
  RowCountChangeCheckResult = 'RowCountChangeCheckResult',
  UnexpectedValueCheckResult = 'UnexpectedValueCheckResult',
  DataVarianceCheckResult = 'DataVarianceCheckResult',
  ReferenceDataMismatchCheckResult = 'ReferenceDataMismatchCheckResult',
  ErrorResult = 'ErrorResult',
  CoverageCheckResult = 'CoverageCheckResult',
  UserDefinedRuleCheckResult = 'UserDefinedRuleCheckResult',
  PublishFrequencyResult = 'PublishFrequencyResult',
}

export interface RuleResult {
  subType: RuleResultType;
  truncated?: boolean | null;
  resultDetailLocation?: string | null;
  additionalProperties?: {
    [propName: string]: string;
  } | null;
}

export interface ErrorRuleResult {
  subType: RuleResultType.ErrorResult;
  errorMessage: string;
}

export enum LatencyExpressionType {
  None = 'None',
  TimeInSeconds = 'TimeInSeconds',
  Boolean = 'Boolean',
}

export interface LatencyCheckResult extends RuleResult {
  subType: RuleResultType.DataLatencyCheckResult;
  latencyExpressionType: LatencyExpressionType;
  expressionResult: string;
}

export interface UnexpectedValueCheckResult extends RuleResult {
  subType: RuleResultType.UnexpectedValueCheckResult;
  columns: IntegrityDatasetColumn[];
}

export interface DatasetColumn {
  name: string;
  columnProperties?: {
    [propName: string]: string;
  } | null;
}

export interface CompletenessDatasetColumn extends DatasetColumn {
  nullCount: number;
  totalCount: number;
  nullPercentage: number;
  dimensions?: {
    [propName: string]: string;
  } | null;
}

export interface ConformityDatasetColumn extends DatasetColumn {
  mismatchedRowCount: number;
  totalCount: number;
  mismatchPercentage: number;
}

export interface IntegrityDatasetColumn extends DatasetColumn {
  unexpectedValues: UnexpectedValue;
}

export interface UnexpectedValue {
  value: string[];
  count: number;
  totalCount: number;
  percentage: number;
  unexpectedValueProperties?: {
    [propName: string]: string;
  } | null;
}

export interface NullCheckResult extends RuleResult {
  subType: RuleResultType.NullCheckResult;
  columns: CompletenessDatasetColumn[];
}

export interface RowCountCheckResult extends RuleResult {
  subType: RuleResultType.RowCountCheckResult;
  rowCount: number;
}

export interface UnexpectedValueCheckResult extends RuleResult {
  subType: RuleResultType.UnexpectedValueCheckResult;
  columns: IntegrityDatasetColumn[];
}

export interface RegularExpressionCheckResult extends RuleResult {
  subType: RuleResultType.RegularExpressionCheckResult;
  columns: ConformityDatasetColumn[];
}

export interface PublishFrequencyResult extends RuleResult {
  subType: RuleResultType.PublishFrequencyResult;
  publishDurationInSeconds: number;
  latestPublishTime: string;
  isDatasetUpdated: boolean;
}

export interface RuleExecutionResult {
  id: string;
  jobId: string;
  workspaceId: string;
  workspaceDisplayName: string;
  jobName: string;
  ruleDataSet: Dataset;
  relatedDatasets?: Dataset[] | null;
  rule: Rule;
  ruleResult: RuleResult;
  alerts?: Alert[] | null;
  expectationResults?: [
    {
      expectationId?: string | null;
      expectationResultValue?: string | null;
    }
  ];
  ruleExecutionStartTime: number;
  ruleExecutionTimeSeconds: string;
}

export default RuleExecutionResult;
