import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Userprofile,
  UserprofileStatus,
} from 'features/userprofile/models/userprofile';
// import { userprofileApi, putUserprofile, putFavoriteWorkspaces, putFavoriteDatasets, putRecents, putUserSelections } from 'features/userprofile/api/userprofileApi'
import { notifier } from 'utils/notifier';
import { RootState } from 'app/lensShellUtility';
import { elevateOrchestrator } from 'features/orchestrator/api/jobApi';
import { runAsAdmin } from 'utils/authUtils';
import UserprofileApi from 'features/userprofile/api/userprofileApi';

const userprofileApi = new UserprofileApi();
const putUserprofile = userprofileApi.saveUserprofile;
const putFavoriteWorkspaces = userprofileApi.saveFavoriteWorkspaces;
const putRecents = userprofileApi.saveRecents;
const putUserSelections = userprofileApi.saveUserSelections;
const putFavoriteDatasets = userprofileApi.saveFavoriteDatasets;
const putSupportNotificationStatus =
  userprofileApi.saveSupportNotificationStatus;

/**
 * Custom hook to use the current userprofile.
 */
export const useUserprofile = () => {
  const dispatch = useDispatch();
  const userprofileStatus = useSelector(selectUserprofileStatus);
  useEffect(() => {
    if (userprofileStatus === UserprofileStatus.None) {
      dispatch(loadUserprofile());
      dispatch(loadCanAdmin());
    }
  }, [dispatch, userprofileStatus]);
};

export const loadUserprofile = createAsyncThunk(
  'userprofile/loadUserprofile',
  async () => {
    console.log('Calling userprofileApi.getUserprofile()...');
    return await userprofileApi.getUserprofile();
  }
);

export const saveUserprofile = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({ userprofile }: { userprofile: Userprofile }) => {
    // TODO: sync the current userprofile with AngularJS
    return await putUserprofile(userprofile);
  }
);

export const saveFavorites = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    favoritesList,
  }: {
    userprofile: Userprofile;
    favoritesList: string;
  }) => {
    return await putFavoriteWorkspaces(userprofile, favoritesList);
  }
);

export const saveRecents = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    recentsList,
  }: {
    userprofile: Userprofile;
    recentsList: string;
  }) => {
    return await putRecents(userprofile, recentsList);
  }
);

export const saveUserSelections = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    userSelections,
  }: {
    userprofile: Userprofile;
    userSelections: string;
  }) => {
    return await putUserSelections(userprofile, userSelections);
  }
);

export const saveFavoriteDatasets = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    favoritesList,
  }: {
    userprofile: Userprofile;
    favoritesList: string;
  }) => {
    return await putFavoriteDatasets(userprofile, favoritesList);
  }
);

export const saveSupportNotificationStatus = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    supportNotificationStatus,
  }: {
    userprofile: Userprofile;
    supportNotificationStatus: string;
  }) => {
    return await putSupportNotificationStatus(
      userprofile,
      supportNotificationStatus
    );
  }
);

// Begin Admin/Debug Utils
export const loadCanAdmin = createAsyncThunk(
  'userprofile/getCanAdmin',
  async () => {
    return await userprofileApi.getCanAdmin();
  }
);

export function runAsAdministrator(): undefined {
  // eslint-disable-next-line no-restricted-globals
  const cnfm = !confirm(
    'Do you want to run as administrator? This will grant you Lens Explorer administrative privileges and temporary Geneva Orchestrator elevated privileges.'
  );
  if (cnfm) {
    return;
  }

  elevateOrchestrator()
    .then(() => {
      runAsAdmin(true);
    })
    .catch((err) => {
      alert('Could not elevate with Geneva Orchestrator: ' + err);
    });
}

export function runAsUser() {
  runAsAdmin(false);
}
// End Admin Utils

let initialLoadedUserprofile: Userprofile = {
  UPN: '',
  Name: '',
  DataSources: [],
  FavoritedWorkspaces: '',
  RecentWorkspaces: '',
  UserSelections: '',
  FavoriteDatasets: '',
  SupportNotificationStatus: '',
};

let initialUserprofile = {
  currentUserprofile: initialLoadedUserprofile,
  userprofileStatus: UserprofileStatus.None,
  canAdminStatus: UserprofileStatus.None,
  canAdmin: false,
};

/**
 * Redux slice representing the current userprofile.
 */
const userprofileSlice = createSlice({
  name: 'userprofile',
  initialState: initialUserprofile,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserprofile.pending, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loading;
      })
      .addCase(loadUserprofile.fulfilled, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loaded;

        const userprofile = action.payload;

        state.currentUserprofile = userprofile as Userprofile;
        //// Convert the model to UI properties.
        //trimSearchColumns = _.parseInt(trimSearchColumns)
        //if (_.isNaN(trimSearchColumns)) {
        //    trimSearchColumns = constants.DefaultDisplayColumnCount
        //}
        //userSelections = utilities.toJson(UserSelections) || {}
        //favoritedWorkspaces = FavoritedWorkspaces && FavoritedWorkspaces !== "{}" ? JSON.parse(FavoritedWorkspaces) : []
        //recentWorkspaces = RecentWorkspaces && RecentWorkspaces !== "{}" ? JSON.parse(RecentWorkspaces) : []
        //recentVisSearches = RecentVisSearches && RecentVisSearches !== "{}" ?
        //    // exclude invalid records from recentVis array
        //    _.reject(JSON.parse(RecentVisSearches), function (recentAsset) { return typeof recentAsset === 'string' || !recentAsset }) : []
        //favoriteVisSearches = FavoriteVisSearches && FavoriteVisSearches !== "{}" ? JSON.parse(FavoriteVisSearches) : []
        //favoritedDashboards = FavoritedDashboards || []
        //recentDashboards = RecentDashboards || []
        //recentTables = RecentTables || []
        //favoriteTables = FavoriteTables || []
        //favoriteJobs = FavoriteJobs || []
        //recentJobs = RecentJobs || []
        //turnOffVisualAnalyzerTeaser = !!turnOffVisualAnalyzerTeaser
        //timeZone = TimeZone || UserprofileTimeZones.UTC
        //supportNotificationStatus = SupportNotificationStatus && SupportNotificationStatus !== "{}" ? JSON.parse(SupportNotificationStatus) : []
      })
      .addCase(loadUserprofile.rejected, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Error;
        notifier.error(action.error);
        throw new Error('Failed to fetch userprofile ' + action.error.message);
      })
      .addCase(saveUserprofile.pending, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loading;

        //if (_.isNil(upn) || upn.length === 0) {
        //    throw new Error("upn is null or empty.")
        //}

        //var theiaHomePageEnabled = _.some(userprofile.features, { Type: 'Theia', IsEnabled: true }) && _.some(userprofile.features, { Type: 'TheiaHomePage', IsEnabled: true })
        //localStorage.set(Constants.TheiaHomePageEnabledKey, theiaHomePageEnabled)

        //var userSelections = {
        //    // workspace: workspaceManager.getMode() === workspaceManager.Modes.Shared ? workspaceManager.getCurrentWorkspaceAlias() : null,
        //}

        // Clean up existing userprofiles that have image data in the recent and favorites array
        //_.each(userprofile.favoriteVisSearches, function (search) { delete search.Image })
        //_.each(userprofile.recentVisSearches, function (search) { delete search.Image })
        //_.each(userprofile.favoritedDashboards, function (dashboard) { delete dashboard.Image })
        //_.each(userprofile.recentDashboards, function (dashboard) { delete dashboard.Image })

        //Convert UI properties to the model.
        //userprofile.UserSelections = JSON.stringify(userSelections)
        //userprofile.FavoritedWorkspaces = JSON.stringify(favoritedWorkspaces)
        //userprofile.RecentWorkspaces = JSON.stringify(recentWorkspaces)
        //userprofile.FavoriteVisSearches = JSON.stringify(favoriteVisSearches)
        //userprofile.RecentVisSearches = JSON.stringify(recentVisSearches)
        //userprofile.FavoritedDashboards = favoritedDashboards
        //userprofile.RecentDashboards = recentDashboards
        //userprofile.RecentTables = recentTables
        //userprofile.FavoriteTables = favoriteTables
        //userprofile.FavoriteJobs = favoriteJobs
        //userprofile.RecentJobs = recentJobs
        //userprofile.themeColorPreference = themeColorPreference
        //userprofile.trimSearchColumns = JSON.stringify(trimSearchColumns)
        //userprofile.disableAutoRunningQuery = disableAutoRunningQuery
        //userprofile.turnOffVisualAnalyzerTeaser = !!turnOffVisualAnalyzerTeaser
        //userprofile.FeatureSettings = features
        //userprofile.TimeZone = timeZone
        //userprofile.SupportNotificationStatus = JSON.stringify(state.supportNotificationStatus)

        //lscache.remove(cacheKeys.retrievedUserprofile)
      })
      .addCase(saveUserprofile.fulfilled, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loaded;

        const userprofile = action.payload;
        state.currentUserprofile = userprofile as Userprofile;
        //lscache.set(cachekeys.retrieveduserprofile, userprofile)
      })
      .addCase(saveUserprofile.rejected, (state, action) => {
        notifier.error(action.error);
      })
      .addCase(loadCanAdmin.pending, (state, action) => {
        state.canAdminStatus = UserprofileStatus.Loading;
      })
      .addCase(loadCanAdmin.fulfilled, (state, action) => {
        state.canAdminStatus = UserprofileStatus.Loaded;
        state.canAdmin = action.payload;
      })
      .addCase(loadCanAdmin.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

//export const {
//} = userprofileSlice.actions

export const selectUserprofileStatus = (state: RootState) =>
  state.userprofile.userprofileStatus;
export const selectUserprofile = (state: RootState) =>
  state.userprofile.currentUserprofile;
export const selectUserprofileCanAdminStatus = (state: RootState) =>
  state.userprofile.canAdminStatus;
export const selectUserprofileCanAdmin = (state: RootState) =>
  state.userprofile.canAdmin;
export const selectUserprofileNotifications = (state: RootState) =>
  state.userprofile.currentUserprofile.SupportNotificationStatus;
export default userprofileSlice.reducer;
