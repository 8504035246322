import React from 'react';
import UserExpectation from '../../base/userExpectation';
import { getClassifyResultsState } from '../../../components/classifyResults/classifyResults';
import {
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
} from 'features/dataQuality/models/rule';
import PublishFrequencyBuilder from './publishFrequencyBuilder';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import { registerUserExpectation } from '../../userExpectationsRegistry';
import PublishFrequencyResultsBuilder from './publishFrequencyResultsBuilder';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import {
  getDefaultAlertForExpectation,
  getTableName,
} from 'features/dataQuality/utils/dataQualityUtils';
import StandardUnits, {
  ThresholdUnit,
} from 'features/dataQuality/components/classifyResults/thresholdUnits';
import PublishFrequencyEditor from './publishFrequencyEditor';
import Alert from 'features/dataQuality/models/alert';

export interface PublishFrequencyState extends EditExpectationState {
  unit?: ThresholdUnit;
}

export const unitPrefix = 'lensUnit:';

export function decodeUnit(alert?: Alert): ThresholdUnit {
  const unitTag = (alert?.tags || []).filter((t) => t.startsWith(unitPrefix));
  const unitType =
    unitTag?.[0]?.substring(unitPrefix.length) || StandardUnits.MINUTE.unitType;
  const selected = [
    StandardUnits.DAY,
    StandardUnits.HOUR,
    StandardUnits.MINUTE,
    StandardUnits.SECOND,
  ].filter((unit) => unit.unitType === unitType);
  return selected.length > 0 ? selected[0] : StandardUnits.MINUTE;
}

class PublishFrequencyExpectation extends UserExpectation<PublishFrequencyState> {
  id = 'PublishFrequency';
  description = 'The Table must be updated regularly';
  category = SystemDefinedRuleCategory.Timeliness;
  subCategory = SystemDefinedRuleSubCategory.PublishFrequency;
  ruleType = RuleType.PreDefined;
  ruleConditionType = RuleConditionType.PublishFrequencyCondition;

  getDescriptionElement = (
    state?: PublishFrequencyState,
    dataset?: Dataset
  ) => {
    const { tableName } = getTableName(dataset);
    return tableName ? (
      <span title={`${tableName} must be updated regularly`}>
        <HighlightText>{tableName}</HighlightText> table must be updated&nbsp;
        <HighlightText>{'regularly'}</HighlightText>
      </span>
    ) : (
      <span title={'The Table must be updated regularly'}>
        The<HighlightText>{'Table'}</HighlightText> must be updated&nbsp;
        <HighlightText>{'regularly'}</HighlightText>
      </span>
    );
  };

  Editor = PublishFrequencyEditor;

  getInitialState(expectationInfo?: ExpectationInfo): PublishFrequencyState {
    const alert = getDefaultAlertForExpectation(expectationInfo);
    return {
      ...getClassifyResultsState(expectationInfo),
      unit: decodeUnit(alert),
      stateIsReady: true,
    };
  }

  public getUnit(expectationInfo?: ExpectationInfo) {
    return decodeUnit(getDefaultAlertForExpectation(expectationInfo));
  }

  builderFactory = (
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo
  ): PublishFrequencyBuilder =>
    new PublishFrequencyBuilder(this, dataset, connection, prevExpectationInfo);

  getResultDescription = PublishFrequencyResultsBuilder.getResultDescription;
  getResultsTable = PublishFrequencyResultsBuilder.getResultsTable;
}

registerUserExpectation(new PublishFrequencyExpectation());
