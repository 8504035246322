import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import dataQualityApi, { isNotFoundError } from './api/dataQualityApi';
import { notifier } from 'utils/notifier';
import Dataset, { DatasetRequest } from './models/dataset';
import { RootState } from 'app/lensShellUtility';
import DataConnection from './models/dataConnection';
import Rule, { RuleRequest } from './models/rule';
import Alert, { AlertRequest } from './models/alert';
import RuleExecutionResult from './models/ruleExecutionResult';
import { ExecuteRulesRequest } from './models/execute';
import {
  getPrivateWorkspaceId,
  onWorkspaceSwitch,
} from 'features/workspaces/utils/workspaceUtils';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';

export enum DataQualityStatus {
  None = 'None',
  Loading = 'Loading',
  Saving = 'Saving',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface ExpectationInfo {
  rule: Rule;
  alerts: Alert[];
}

export interface ExpectationRequest {
  rule: RuleRequest;
  alerts: AlertRequest[];
}

export interface RuleExecutionResults {
  id: string; // ruleId
  results: RuleExecutionResult[];
}

export const latestRuleResultsCount = 1;
const privateWorkspaceId = getPrivateWorkspaceId();

/**
 * Custom hook that ensures that Data Quality state is reset on workspace switch.
 * The root Data Quality component should be the only element to call this hook.
 * This hook connects onWorkspaceSwitch to reset the redux state.
 */
export const useDataQuality = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // reset redux state on the workspace switch event.
    let resetOnWorkspaceSwitch = onWorkspaceSwitch(
      (event: any, workspace: any) => {
        dispatch(reset(workspace?.Id || privateWorkspaceId)); // workspace.Id from AngularJS side has capital 'Id'
      }
    );

    return () => {
      // cleanup
      if (resetOnWorkspaceSwitch) {
        resetOnWorkspaceSwitch();
        resetOnWorkspaceSwitch = undefined;
      }
    };
  }, [dispatch]);
};

/**
 * Custom hook to use DQ workspace Data Connections
 */
export const useConnections = (workspaceId: string) => {
  const dispatch = useDispatch();
  const connectionsStatus = useSelector(selectDataQualityConnectionsStatus);
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);
  const workspace = useSelector(selectWorkspace);
  const dqsWorkspace = workspace.dqsWorkspace;

  useEffect(() => {
    if (
      isCorrectworkspace &&
      connectionsStatus === DataQualityStatus.None &&
      dqsWorkspace
    ) {
      dispatch(loadConnections(workspaceId));
    }
  }, [
    dispatch,
    workspaceId,
    connectionsStatus,
    isCorrectworkspace,
    dqsWorkspace,
  ]);
};

/**
 * Custom hook to use a specific DQ  Datasets
 */
export const useDataset = (workspaceId: string, datasetId: string) => {
  const dispatch = useDispatch();
  const datasetsStatus = useSelector(selectDataQualityDatasetsStatus);
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (isCorrectworkspace && !datasetsStatus[datasetId]) {
      dispatch(loadDataset({ workspaceId, datasetId }));
    }
  }, [dispatch, workspaceId, datasetId, datasetsStatus, isCorrectworkspace]);
};

/**
 * Custom hook to use DQ workspace Datasets
 */
export const useDatasets = (workspaceId: string) => {
  const dispatch = useDispatch();
  const allDatasetsStatus = useSelector(selectDataQualityAllDatasetsStatus);
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (isCorrectworkspace && allDatasetsStatus === DataQualityStatus.None) {
      dispatch(loadDatasets(workspaceId));
    }
  }, [dispatch, workspaceId, allDatasetsStatus, isCorrectworkspace]);
};

/**
 * Custom hook to use DQ workspace Expectations
 */
export const useExpectations = (workspaceId: string, datasetId?: string) => {
  const dispatch = useDispatch();
  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (
      isCorrectworkspace &&
      datasetId &&
      !datasetsExpectationsStatus[datasetId]
    ) {
      dispatch(loadExpectations({ workspaceId, datasetId }));
    }
  }, [
    dispatch,
    workspaceId,
    datasetId,
    datasetsExpectationsStatus,
    isCorrectworkspace,
  ]);
};

/**
 * Custom hook to use a single known DQ workspace rule
 */
export const useExpectation = (
  workspaceId: string,
  datasetId: string,
  ruleId: string
) => {
  const dispatch = useDispatch();
  const expectationsStatus = useSelector(selectDataQualityExpectationsStatus);
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (
      isCorrectworkspace &&
      datasetId &&
      ruleId &&
      !expectationsStatus[ruleId]
    ) {
      dispatch(loadExpectation({ workspaceId, datasetId, ruleId }));
    }
  }, [
    dispatch,
    workspaceId,
    datasetId,
    ruleId,
    expectationsStatus,
    isCorrectworkspace,
  ]);
};

/**
 * Custom hook to use DQ rule execution results for a given dataset and ruleId
 */
export const useRuleResults = (
  workspaceId: string,
  datasetId: string,
  ruleId: string
) => {
  const dispatch = useDispatch();
  const ruleResultsStatus = useSelector(selectDataQualityRuleResultsStatus);
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (isCorrectworkspace && !ruleResultsStatus[ruleId]) {
      dispatch(loadRuleResults({ workspaceId, datasetId, ruleId }));
    }
  }, [
    dispatch,
    workspaceId,
    datasetId,
    ruleId,
    ruleResultsStatus,
    isCorrectworkspace,
  ]);
};

/**
 * Custom hook to use only the latest few DQ rule execution results for a given dataset.  The number loaded for
 * "latest" is set by the latestRuleResultsCount constant
 */
export const useDatasetsLatestRuleResults = (
  workspaceId: string,
  datasetId: string
) => {
  const dispatch = useDispatch();
  const latestRuleResultsStatus = useSelector(
    selectDataQualityLatestRuleResultsStatus
  );
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useExpectations(workspaceId, datasetId);
  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  useEffect(() => {
    if (
      isCorrectworkspace &&
      expectationsStatus === DataQualityStatus.Loaded &&
      expectationEntities
    ) {
      const expectationInfos = Object.keys(expectationEntities)
        .filter((key) => expectationEntities[key]?.rule.datasetId === datasetId)
        .map((key) => expectationEntities[key] as ExpectationInfo);

      expectationInfos.forEach((expectationInfo) => {
        if (!latestRuleResultsStatus[expectationInfo.rule.id]) {
          dispatch(
            loadLatestRuleResults({
              workspaceId,
              datasetId,
              ruleId: expectationInfo.rule.id,
            })
          );
        }
      });
    }
  }, [
    dispatch,
    workspaceId,
    datasetId,
    expectationEntities,
    expectationsStatus,
    latestRuleResultsStatus,
    isCorrectworkspace,
  ]);
};

/**
 * Custom hook to use only the latest few DQ rule execution results for a given dataset and ruleId
 */
export const useLatestRuleResults = (
  workspaceId: string,
  datasetId: string,
  ruleId: string
) => {
  const dispatch = useDispatch();
  const ruleResultsStatus = useSelector(
    selectDataQualityLatestRuleResultsStatus
  );
  const isCorrectworkspace = useCorrectWorkspace(workspaceId);

  useEffect(() => {
    if (isCorrectworkspace && !ruleResultsStatus[ruleId]) {
      dispatch(loadLatestRuleResults({ workspaceId, datasetId, ruleId }));
    }
  }, [
    dispatch,
    workspaceId,
    datasetId,
    ruleId,
    ruleResultsStatus,
    isCorrectworkspace,
  ]);
};

const useCorrectWorkspace = (workspaceId: string) => {
  const currentWorkspaceId = useSelector(selectDataQualityCurrentWorkspaceId);
  return currentWorkspaceId === workspaceId;
};

export const loadConnections = createAsyncThunk(
  'dataQuality/loadConnections',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      return await dataQualityApi.getConnections(workspaceId);
    } catch (error) {
      // 404: not found is expected and is not an error
      if (isNotFoundError(error)) {
        return [];
      }
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const loadDatasets = createAsyncThunk(
  'dataQuality/loadDatasets',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      return await dataQualityApi.getDatasets(workspaceId);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const loadDataset = createAsyncThunk(
  'dataQuality/loadDataset',
  async (
    { workspaceId, datasetId }: { workspaceId: string; datasetId: string },
    { rejectWithValue }
  ) => {
    try {
      return await dataQualityApi.getDataset(workspaceId, datasetId);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const deleteDataset = createAsyncThunk(
  'dataQuality/deleteDataset',
  async (
    { workspaceId, datasetId }: { workspaceId: string; datasetId: string },
    { rejectWithValue }
  ) => {
    try {
      return await dataQualityApi.deleteDataset(workspaceId, datasetId);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const createDataQualityWorkspace = createAsyncThunk(
  'dataQuality/createDataQualityWorkspace',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      return await dataQualityApi.postDataQualityWorkspace(workspaceId);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const createDataset = createAsyncThunk(
  'dataQuality/createDataset',
  async (
    {
      workspaceId,
      datasetRequest,
    }: { workspaceId: string; datasetRequest: DatasetRequest },
    { rejectWithValue }
  ) => {
    try {
      await dataQualityApi.postDataset(workspaceId, datasetRequest);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const updateDataset = createAsyncThunk(
  'dataQuality/updateDataset',
  async (
    {
      workspaceId,
      datasetRequest,
    }: { workspaceId: string; datasetRequest: DatasetRequest },
    { rejectWithValue }
  ) => {
    try {
      await dataQualityApi.putDataset(workspaceId, datasetRequest);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

async function buildExpectationFromRule(
  workspaceId: string,
  rule: Rule
): Promise<ExpectationInfo> {
  try {
    const alerts = await dataQualityApi.getAlerts(workspaceId, rule.id);
    return { rule, alerts };
  } catch (error) {
    // 404: not found is expected and is not an error
    const notFound = isNotFoundError(error);
    if (!notFound) {
      notifier.error(error as any);
    }
    return { rule, alerts: [] };
  }
}

// load all rules and then immediatly load all alerts for each rule
export const loadExpectations = createAsyncThunk(
  'dataQuality/loadExpectations',
  async (
    { workspaceId, datasetId }: { workspaceId: string; datasetId: string },
    { rejectWithValue }
  ) => {
    return await dataQualityApi
      .getRules(workspaceId, datasetId)
      .then((rules) => {
        const expectationPromises = rules.map((rule) =>
          buildExpectationFromRule(workspaceId, rule)
        );
        return Promise.all(expectationPromises);
      })
      .catch((error) => {
        if (isNotFoundError(error)) {
          return [];
        }
        return rejectWithValue(unwrapDqError(error));
      });
  }
);

export const loadExpectation = createAsyncThunk(
  'dataQuality/loadExpectation',
  async (
    {
      workspaceId,
      datasetId,
      ruleId,
    }: { workspaceId: string; datasetId: string; ruleId: string },
    { rejectWithValue }
  ) => {
    try {
      const rule = await dataQualityApi.getRule(workspaceId, datasetId, ruleId);
      return buildExpectationFromRule(workspaceId, rule);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const createExpectation = createAsyncThunk(
  'dataQuality/createExpectation',
  async (
    {
      workspaceId,
      expectationInfo,
    }: { workspaceId: string; expectationInfo: ExpectationInfo },
    { rejectWithValue }
  ) => {
    const ruleRequest = {
      ...expectationInfo.rule,
      id: undefined,
    };
    try {
      const ruleId = await dataQualityApi.postRule(workspaceId, ruleRequest);
      // now we know the ruleId, set it into the new alert requests
      const alertRequests = expectationInfo.alerts.map(function (alert) {
        return {
          ...alert,
          id: undefined,
          ruleName: ruleRequest.name,
          ruleId,
        };
      });
      const alertPostPromises = alertRequests.map((alertRequest) =>
        dataQualityApi.postAlert(workspaceId, alertRequest)
      );
      await Promise.all(alertPostPromises);
      return ruleId;
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const saveExpectation = createAsyncThunk(
  'dataQuality/saveExpectation',
  async (
    {
      workspaceId,
      expectationInfo,
    }: { workspaceId: string; expectationInfo: ExpectationInfo },
    { rejectWithValue }
  ) => {
    try {
      const alertPutPromises = expectationInfo.alerts.map((alert) =>
        dataQualityApi.putAlert(workspaceId, alert)
      );
      await dataQualityApi.putRule(workspaceId, expectationInfo.rule);
      await Promise.all(alertPutPromises);
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const deleteExpectation = createAsyncThunk(
  'dataQuality/deleteExpectation',
  async (
    {
      workspaceId,
      expectationInfo,
    }: { workspaceId: string; expectationInfo: ExpectationInfo },
    { rejectWithValue }
  ) => {
    try {
      const alertDeletePromises = expectationInfo.alerts.map((alert) =>
        dataQualityApi.deleteAlert(
          workspaceId,
          expectationInfo.rule.id,
          alert.id
        )
      );
      await Promise.all(alertDeletePromises);
      await dataQualityApi.deleteRule(
        workspaceId,
        expectationInfo.rule.datasetId,
        expectationInfo.rule.id
      );
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const loadLatestRuleResults = createAsyncThunk(
  'dataQuality/loadLatestRuleResults',
  async (
    {
      workspaceId,
      datasetId,
      ruleId,
    }: { workspaceId: string; datasetId: string; ruleId: string },
    { rejectWithValue }
  ) => {
    try {
      return {
        id: ruleId,
        results: await dataQualityApi.getRuleResults(
          workspaceId,
          datasetId,
          ruleId,
          latestRuleResultsCount
        ),
      };
    } catch (error) {
      // 404: not found is expected and is not an error
      if (isNotFoundError(error)) {
        return { id: ruleId, results: [] };
      }
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const loadRuleResults = createAsyncThunk(
  'dataQuality/loadRuleResults',
  async (
    {
      workspaceId,
      datasetId,
      ruleId,
    }: { workspaceId: string; datasetId: string; ruleId: string },
    { rejectWithValue }
  ) => {
    try {
      return {
        id: ruleId,
        results: await dataQualityApi.getRuleResults(
          workspaceId,
          datasetId,
          ruleId
        ),
      };
    } catch (error) {
      // 404: not found is expected and is not an error
      if (isNotFoundError(error)) {
        return { id: ruleId, results: [] };
      }
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export const executeRule = createAsyncThunk(
  'dataQuality/executeRule',
  async (
    {
      workspaceId,
      datasetId,
      ruleId,
      name,
    }: { workspaceId: string; datasetId: string; ruleId: string; name: string },
    { rejectWithValue }
  ) => {
    const request: ExecuteRulesRequest = {
      datasetId,
      ruleIds: [ruleId],
      name,
    };
    try {
      return await dataQualityApi.executeRule(
        workspaceId,
        datasetId,
        ruleId,
        request
      );
    } catch (error) {
      return rejectWithValue(unwrapDqError(error));
    }
  }
);

export function unwrapDqError(error: any) {
  if (error.isAxiosError && error.response?.data) {
    return {
      ...error.response.data,
      name: error.name,
      message: error.message,
      stack: error.stack,
    };
  }
  return error;
}

const connectionsAdapter = createEntityAdapter<DataConnection>({
  selectId: (connection) => connection.connectionName,
});

export interface DatasetsStatus {
  [datasetId: string]: DataQualityStatus;
}

const datasetsAdapter = createEntityAdapter<Dataset>();

export interface DatasetsExpectationsStatus {
  [datasetId: string]: DataQualityStatus;
}

export interface ExpectationsStatus {
  [ruleId: string]: DataQualityStatus;
}

const expectationsAdapter = createEntityAdapter<ExpectationInfo>({
  selectId: (expectationInfo) => expectationInfo.rule.id,
});

export interface RuleResultsStatus {
  [ruleId: string]: DataQualityStatus;
}

const ruleResultsAdapter = createEntityAdapter<RuleExecutionResults>();

export interface DatasetsLatestRuleResultsStatus {
  [datasetId: string]: DataQualityStatus;
}

const latestRuleResultsAdapter = createEntityAdapter<RuleExecutionResults>();

export interface JobStatusesStatus {
  [jobName: string]: DataQualityStatus;
}

const jobStatusesAdapter = createEntityAdapter<any>({
  selectId: (jobStatus) => jobStatus.name,
});

const initialState = {
  currentWorkspaceId: privateWorkspaceId,
  connections: connectionsAdapter.getInitialState({
    status: DataQualityStatus.None,
  }),
  datasets: datasetsAdapter.getInitialState({
    allDatasetsStatus: DataQualityStatus.None,
    datasetsStatus: {} as DatasetsStatus,
  }),
  expectations: expectationsAdapter.getInitialState({
    datasetsExpectationsStatus: {} as DatasetsExpectationsStatus,
    expectationsStatus: {} as ExpectationsStatus,
  }),
  ruleResults: ruleResultsAdapter.getInitialState({
    ruleResultsStatus: {} as RuleResultsStatus,
  }),
  latestRuleResults: latestRuleResultsAdapter.getInitialState({
    latestRuleResultsStatus: {} as RuleResultsStatus,
    datasetsLatestRuleResultsStatus: {} as DatasetsLatestRuleResultsStatus,
  }),
  jobStatuses: jobStatusesAdapter.getInitialState({
    jobStatusesStatus: {} as JobStatusesStatus,
  }),
  dataQualityWorkspace: {
    status: DataQualityStatus.None,
    isDqEnabled: false,
  },
};

/**
 * Redux slice representing the current data Quality Connections and Datasets.
 */
const dataQualitySlice = createSlice({
  name: 'dataQuality',
  initialState: initialState,
  reducers: {
    reset: (state, action) => ({
      ...initialState,
      currentWorkspaceId: action.payload,
    }),
    clearRuleResults(state) {
      state.ruleResults = initialState.ruleResults;
      state.latestRuleResults = initialState.latestRuleResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadConnections.pending, (state, action) => {
        state.connections.status = DataQualityStatus.Loading;
      })
      .addCase(loadConnections.fulfilled, (state, action) => {
        state.connections.status = DataQualityStatus.Loaded;
        connectionsAdapter.upsertMany(state.connections, action.payload);
      })
      .addCase(loadConnections.rejected, (state, action) => {
        state.connections.status = DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(loadDataset.pending, (state, action) => {
        state.datasets.datasetsStatus[action.meta.arg.datasetId] =
          DataQualityStatus.Loading;
      })
      .addCase(loadDataset.fulfilled, (state, action) => {
        state.datasets.datasetsStatus[action.meta.arg.datasetId] =
          DataQualityStatus.Loaded;
        datasetsAdapter.upsertOne(state.datasets, action.payload);
      })
      .addCase(loadDataset.rejected, (state, action) => {
        state.datasets.datasetsStatus[action.meta.arg.datasetId] =
          DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(deleteDataset.fulfilled, (state, action) => {
        datasetsAdapter.removeOne(state.datasets, action.meta.arg.datasetId);
      })
      .addCase(deleteDataset.rejected, (state, action) => {
        notifier.error(action.payload as Error);
      })
      .addCase(loadDatasets.pending, (state, action) => {
        state.datasets.allDatasetsStatus = DataQualityStatus.Loading;
      })
      .addCase(loadDatasets.fulfilled, (state, action) => {
        state.datasets.allDatasetsStatus = DataQualityStatus.Loaded;
        // mark every dataset in the payload as loaded
        action.payload.forEach(
          (dataset) =>
            (state.expectations.expectationsStatus[dataset.id] =
              DataQualityStatus.Loaded)
        );
        datasetsAdapter.upsertMany(state.datasets, action.payload);
      })
      .addCase(loadDatasets.rejected, (state, action) => {
        state.datasets.allDatasetsStatus = DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(loadExpectations.pending, (state, action) => {
        state.expectations.datasetsExpectationsStatus[
          action.meta.arg.datasetId
        ] = DataQualityStatus.Loading;
      })
      .addCase(loadExpectations.fulfilled, (state, action) => {
        // mark rules are loaded for this dataset
        state.expectations.datasetsExpectationsStatus[
          action.meta.arg.datasetId
        ] = DataQualityStatus.Loaded;
        // mark every rule in the payload as loaded
        action.payload.forEach(
          (expectationInfo) =>
            (state.expectations.expectationsStatus[expectationInfo.rule.id] =
              DataQualityStatus.Loaded)
        );
        expectationsAdapter.upsertMany(state.expectations, action.payload);
      })
      .addCase(loadExpectations.rejected, (state, action) => {
        state.expectations.datasetsExpectationsStatus[
          action.meta.arg.datasetId
        ] = DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(loadExpectation.pending, (state, action) => {
        state.expectations.expectationsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Loading;
      })
      .addCase(loadExpectation.fulfilled, (state, action) => {
        state.expectations.expectationsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Loaded;
        expectationsAdapter.upsertOne(state.expectations, action.payload);
      })
      .addCase(loadExpectation.rejected, (state, action) => {
        state.expectations.expectationsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(loadRuleResults.pending, (state, action) => {
        state.ruleResults.ruleResultsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Loading;
      })
      .addCase(loadRuleResults.fulfilled, (state, action) => {
        state.ruleResults.ruleResultsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Loaded;
        ruleResultsAdapter.upsertOne(state.ruleResults, action.payload);
      })
      .addCase(loadRuleResults.rejected, (state, action) => {
        state.ruleResults.ruleResultsStatus[action.meta.arg.ruleId] =
          DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(loadLatestRuleResults.pending, (state, action) => {
        state.latestRuleResults.latestRuleResultsStatus[
          action.meta.arg.ruleId
        ] = DataQualityStatus.Loading;
      })
      .addCase(loadLatestRuleResults.fulfilled, (state, action) => {
        state.latestRuleResults.latestRuleResultsStatus[
          action.meta.arg.ruleId
        ] = DataQualityStatus.Loaded;
        latestRuleResultsAdapter.upsertOne(
          state.latestRuleResults,
          action.payload
        );
      })
      .addCase(loadLatestRuleResults.rejected, (state, action) => {
        state.latestRuleResults.latestRuleResultsStatus[
          action.meta.arg.ruleId
        ] = DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(createExpectation.rejected, (state, action) => {
        notifier.error(action.payload as Error);
      })
      .addCase(saveExpectation.rejected, (state, action) => {
        notifier.error(action.payload as Error);
      })
      .addCase(deleteExpectation.fulfilled, (state, action) => {
        expectationsAdapter.removeOne(
          state.expectations,
          action.meta.arg.expectationInfo.rule.id
        );
      })
      .addCase(deleteExpectation.rejected, (state, action) => {
        notifier.error(action.payload as Error);
      })
      .addCase(executeRule.pending, (state, action) => {
        state.jobStatuses.jobStatusesStatus[action.meta.arg.name] =
          DataQualityStatus.Loading;
      })
      .addCase(executeRule.fulfilled, (state, action) => {
        state.jobStatuses.jobStatusesStatus[action.meta.arg.name] =
          DataQualityStatus.Loaded;
        jobStatusesAdapter.upsertOne(state.jobStatuses, action.payload);
      })
      .addCase(executeRule.rejected, (state, action) => {
        state.jobStatuses.jobStatusesStatus[action.meta.arg.name] =
          DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      })
      .addCase(createDataQualityWorkspace.pending, (state, action) => {
        state.dataQualityWorkspace.status = DataQualityStatus.Loading;
      })
      .addCase(createDataQualityWorkspace.fulfilled, (state, action) => {
        state.dataQualityWorkspace.status = DataQualityStatus.Loaded;
      })
      .addCase(createDataQualityWorkspace.rejected, (state, action) => {
        state.dataQualityWorkspace.status = DataQualityStatus.Error;
        notifier.error(action.payload as Error);
      });
  },
});

// internal actions and selectors
const { reset } = dataQualitySlice.actions;

const selectDataQualityCurrentWorkspaceId = (state: RootState) =>
  state.dataQuality.currentWorkspaceId;

// exported actions and selectors
export const { clearRuleResults } = dataQualitySlice.actions;

export const {
  selectAll: selectAllDataQualityConnections,
  selectEntities: selectDataQualityConnectionEntities,
  selectById: selectDataQualityConnectionByName,
  selectIds: selectDataQualityConnectionNames,
} = connectionsAdapter.getSelectors(
  (state: RootState) => state.dataQuality.connections
);
export const selectDataQualityConnectionsStatus = (state: RootState) =>
  state.dataQuality.connections.status;

export const {
  selectAll: selectAllDataQualityDatasets,
  selectEntities: selectDataQualityDatasetEntities,
  selectById: selectDataQualityDatasetById,
  selectIds: selectDataQualityDatasetIds,
} = datasetsAdapter.getSelectors(
  (state: RootState) => state.dataQuality.datasets
);
export const selectDataQualityAllDatasetsStatus = (state: RootState) =>
  state.dataQuality.datasets.allDatasetsStatus;
export const selectDataQualityDatasetsStatus = (state: RootState) =>
  state.dataQuality.datasets.datasetsStatus;

export const {
  selectAll: selectAllDataQualityExpectations,
  selectEntities: selectDataQualityExpectationEntities,
  selectById: selectDataQualityExpectationByRuleId,
  selectIds: selectDataQualityExpectationRuleIds,
} = expectationsAdapter.getSelectors(
  (state: RootState) => state.dataQuality.expectations
);
export const selectDataQualityDatasetsExpectationsStatus = (state: RootState) =>
  state.dataQuality.expectations.datasetsExpectationsStatus;
export const selectDataQualityExpectationsStatus = (state: RootState) =>
  state.dataQuality.expectations.expectationsStatus;

export const {
  selectAll: selectAllDataQualityRuleResults,
  selectEntities: selectDataQualityRuleResultEntities,
  selectById: selectDataQualityRuleResultsByRuleId,
  selectIds: selectDataQualityRuleResultRuleIds,
} = ruleResultsAdapter.getSelectors(
  (state: RootState) => state.dataQuality.ruleResults
);
export const selectDataQualityRuleResultsStatus = (state: RootState) =>
  state.dataQuality.ruleResults.ruleResultsStatus;

export const {
  selectAll: selectAllDataQualityLatestRuleResults,
  selectEntities: selectDataQualityLatestRuleResultEntities,
  selectById: selectDataQualityLatestRuleResultsByRuleId,
  selectIds: selectDataQualityLatestRuleResultRuleIds,
} = latestRuleResultsAdapter.getSelectors(
  (state: RootState) => state.dataQuality.latestRuleResults
);
export const selectDataQualityDatasetsLatestRuleResultsStatus = (
  state: RootState
) => state.dataQuality.latestRuleResults.datasetsLatestRuleResultsStatus;
export const selectDataQualityLatestRuleResultsStatus = (state: RootState) =>
  state.dataQuality.latestRuleResults.latestRuleResultsStatus;

export const {
  selectAll: selectAllDataQualityJobStatuses,
  selectEntities: selectDataQualityJobStatusEntities,
  selectById: selectDataQualityJobStatusByJobName,
  selectIds: selectDataQualityJobStatusJobNames,
} = jobStatusesAdapter.getSelectors(
  (state: RootState) => state.dataQuality.jobStatuses
);
export const selectDataQualityJobStatusesStatus = (state: RootState) =>
  state.dataQuality.jobStatuses.jobStatusesStatus;

export const selectDataQualityWorkspaceStatus = (state: RootState) =>
  state.dataQuality.dataQualityWorkspace.status;

export default dataQualitySlice.reducer;
