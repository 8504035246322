import {
  ISearchBoxStyles,
  IStackTokens,
  IStackStyles,
  ILabelStyles,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
  mergeStyles,
  IStackItemStyles,
  ITheme,
} from '@fluentui/react';

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const AccessIcon = mergeStyles({
  padding: 0,
  color: palette.neutralTertiary,
  fontSize: '10px',
});

export const AccessIcon1 = mergeStyles({
  paddingRight: '3px',
  fontSize: '10px',
  color: '#000000',
});

export const AccessLabel = mergeStyles({
  padding: 0,
  color: palette.neutralTertiary,
  fontSize: '10px',
});

export const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: '0 5',
};

export const searchStyles: ISearchBoxStyles = {
  root: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginLeft: '10px',
  },
};

export const labelStyles1: Partial<ILabelStyles> = {
  root: { marginTop: 10, fontSize: '13px' },
};
export const labelStyles2: Partial<ILabelStyles> = {
  root: { marginLeft: 0, fontSize: '13px' },
};
export const labelStyles3: Partial<ILabelStyles> = {
  root: {
    padding: 0,
    marginTop: 0,
    marginLeft: 10,
    marginBottom: 5,
    fontSize: '13px',
  },
};
export const labelStyles4: Partial<ILabelStyles> = {
  root: { marginTop: 0, fontSize: '13px' },
};

export const stackStyles: Partial<IStackStyles> = {
  root: {
    height: 40,
  },
};

export const tagStyles: ILabelStyles = {
  root: {
    width: '100%',
    fontFamily: 'Segoe UI',
    fontSize: '10px',
    backgroundColor: '#EDEBE9',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export const tagStyles1: ILabelStyles = {
  root: {
    fontFamily: 'Segoe UI',
    width: '90px',
    fontSize: '10px',
    backgroundColor: '#EDEBE9',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export const stackItemStyles: IStackItemStyles = {
  root: {
    width: 490,
  },
};

export const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      height: 50,
      padding: 0,
      marginLeft: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
    },
  ],
  favorite: {
    alignSelf: 'right',
    marginRight: 10,
    color: '#FFB900',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  favoriteStar: {
    align: 'right',
  },
  tableStyles: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
  },
  pillFilter: {
    borderRadius: '10px',
  },
});
