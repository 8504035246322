import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IColumn, IObjectWithKey, Spinner } from '@fluentui/react';
import {
  DeleteIconProps,
  DisplayTypes,
  ElxTableContainer,
  IElxContainerProps,
  ITableAction,
  FilterOptionPillMode,
  FilterOptionMode,
  FilterDisplayMode,
  FilterOption,
  ElxIconButton,
  ElxDialog,
} from '@elixir/fx';
import { DatasetsPanel } from './datasets';
import connectDataset from '../../images/connectDataset.svg';
import { labelStyles, classNames } from './datasetsStyles';
import {
  useUserprofile,
  selectUserprofile,
  saveFavoriteDatasets,
} from 'features/userprofile/userprofileSlice';
import {
  DataQualityStatus,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
} from 'features/dataQuality/dataQualitySlice';

export interface DatasetsListItem extends IObjectWithKey {
  key: string;
  id: string;
  datasetName: string;
  datasetNameElement: JSX.Element;
  container?: string | null;
  table?: string | null;
  tableElement?: JSX.Element;
  passRate: JSX.Element;
  expectations: JSX.Element;
  lastExecuted: JSX.Element;
  service?: string | JSX.Element;
  description?: string | JSX.Element;
  lastModified: JSX.Element;
  isFavorite?: boolean | null;
}

export interface DatasetsListProps {
  items: DatasetsListItem[];
  isLoading?: boolean;
  loadingLabel?: string;
  onShowPanel: (panelId: DatasetsPanel, item?: DatasetsListItem) => void;
  onDeleteItems: (item: DatasetsListItem[]) => void;
  onRefresh: () => void;
  onExecuteRules: (items: DatasetsListItem[]) => void;
}

const StringOperators = ['==', '!=', 'Contains', '!Contains', 'Startswith'];
const NumberOperators = ['==', '<', '>', '<=', '>='];

/**
 * A list component that will display a list of DatasetListItems that are passed in as props
 * @param props
 * @returns
 */
export const DatasetsList = (props: DatasetsListProps): JSX.Element => {
  const {
    items,
    isLoading,
    loadingLabel,
    onShowPanel,
    onDeleteItems,
    onRefresh,
    onExecuteRules,
  } = props;
  const history = useHistory();

  useUserprofile();
  const userprofile = useSelector(selectUserprofile);

  const dispatch = useDispatch();

  const [deletePending, setDeletePending] = useState<DatasetsListItem[]>([]);
  const [deleteDenied, setDeleteDenied] = useState(false);

  // Note: Expectations will be loaded by the <ExpectationCount> element in in dataset row
  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  useEffect(() => {
    function canDeleteDataset(datasetId: string) {
      const expectationCount = Object.keys(expectationEntities).filter(
        (k) => expectationEntities[k]!.rule.datasetId === datasetId
      ).length;
      return (
        datasetsExpectationsStatus[datasetId] === DataQualityStatus.Loaded &&
        expectationCount === 0
      );
    }

    if (deletePending.length > 0) {
      const deleteWaiting = deletePending
        .map((item) => item.id)
        .filter(
          (datasetId) =>
            datasetsExpectationsStatus[datasetId] !== DataQualityStatus.Loaded
        );
      if (deleteWaiting.length === 0) {
        const canDelete = deletePending
          .map((item) => item.id)
          .filter(canDeleteDataset);

        if (canDelete.length === deletePending.length) {
          const pending = deletePending;
          setDeleteDenied(false);
          setDeletePending([]);
          onDeleteItems(pending);
        } else {
          setDeleteDenied(true);
        }
      }
    }
  }, [
    datasetsExpectationsStatus,
    expectationEntities,
    deletePending,
    onDeleteItems,
  ]);

  const actions: ITableAction[] = [
    {
      key: 'new Expectation',
      text: 'New Expectation',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: DatasetsListItem) =>
        onShowPanel(DatasetsPanel.EXPECTATIONS, item),
      iconProps: { iconName: 'Add' },
    },
    {
      key: 'edit',
      text: 'Edit',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: DatasetsListItem) =>
        onShowPanel(DatasetsPanel.ADD_DATASET, item),
      iconProps: { iconName: 'Edit' },
    },
    {
      key: 'execute',
      text: 'Execute',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: false,
      onAction: (item) => onExecuteRules([item]),
      onBulkAction: onExecuteRules,
      iconProps: { iconName: 'Play' },
    },
    {
      key: 'view Quality Report',
      text: 'View Quality Report',
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onAction: (item: DatasetsListItem) =>
        history.push('/dataquality/report/' + item.id),
      iconProps: { iconName: 'CustomList' },
    },
    {
      key: 'delete',
      text: 'Delete',
      defaultDisplay: DisplayTypes.Disabled,
      onAction: (item: DatasetsListItem) => setDeletePending([item]),
      onBulkAction: (items) => setDeletePending(items),
      iconProps: DeleteIconProps,
    },
    {
      key: 'connectNewDataset',
      text: 'Connect new Dataset',
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: () => onShowPanel(DatasetsPanel.ADD_DATASET),
      iconProps: { imageProps: { src: connectDataset } },
    },
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: onRefresh,
      iconProps: { iconName: 'Refresh' },
    },
  ];

  const containerProps = {
    headerText: 'Datasets',
    isLoading,
    loadingLabel,
  } as IElxContainerProps;

  const datasetNamePillFilter: FilterOption = {
    field: 'datasetName',
    label: 'Dataset Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Static,
    values: items
      .map((item) => item.datasetName)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const containerPillFilter: FilterOption = {
    field: 'container',
    label: 'Container',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const tablePillFilter: FilterOption = {
    field: 'table',
    label: 'Table',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const passRatePillFilter: FilterOption = {
    field: 'passRate',
    label: 'Pass Rate',
    operators: NumberOperators,
    mode: FilterOptionMode.Text,
    allowOperatorChange: true,
    pillMode: FilterOptionPillMode.Static,
  };

  const servicePillFilter: FilterOption = {
    field: 'service',
    label: 'Service',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const descriptionPillFilter: FilterOption = {
    field: 'description',
    label: 'Description',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const searchProps = {
    pillFilters: [
      datasetNamePillFilter,
      containerPillFilter,
      tablePillFilter,
      descriptionPillFilter,
      passRatePillFilter,
      servicePillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const updateFavoriteDatasetsInUserprofile = (datasetId: string) => {
    let favoritesList = JSON.parse(userprofile.FavoriteDatasets);
    let index = favoritesList.indexOf(datasetId);
    if (index > -1) {
      favoritesList.splice(index, 1);
    } else {
      favoritesList.push(datasetId);
    }
    favoritesList = JSON.stringify(favoritesList);
    dispatch(saveFavoriteDatasets({ favoritesList, userprofile }));
  };

  const tableStyles = {
    root: {
      '.ms-DetailsHeader-cellName': {
        fontWeight: '500 !important',
        fontSize: '12px !important',
      },
    },
  };

  const colDefaults = {
    minWidth: 100,
    isResizable: true,
  };
  const colSmall = {
    minWidth: 70,
    isResizable: true,
  };
  const colMedium = {
    minWidth: 160,
    isResizable: true,
  };

  const columns: IColumn[] = [
    {
      ...colMedium,
      key: 'datasetName',
      name: 'Dataset name',
      fieldName: 'datasetName',
      onRender: (item: DatasetsListItem) => {
        return item.datasetNameElement;
      },
    },
    {
      ...colDefaults,
      key: 'container',
      name: 'Container',
      fieldName: 'container',
    },
    {
      ...colMedium,
      key: 'table',
      name: 'Table',
      fieldName: 'table',
      onRender: (item: DatasetsListItem) => {
        return item.tableElement;
      },
    },
    {
      ...colSmall,
      key: 'passRate',
      name: 'Pass rate',
      fieldName: 'passRate',
    },
    {
      ...colSmall,
      key: 'expectations',
      name: 'Expectations',
      fieldName: 'expectations',
    },
    {
      ...colSmall,
      key: 'lastExecuted',
      name: 'Last executed',
      fieldName: 'lastExecuted',
    },
    {
      ...colDefaults,
      key: 'service',
      name: 'Service',
      fieldName: 'service',
    },
    {
      ...colDefaults,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
    },
    {
      ...colDefaults,
      key: 'lastModified',
      name: 'Last Modified',
      fieldName: 'lastModified',
    },
    {
      ...colSmall,
      key: 'favorites',
      name: '',
      onRender: (item: DatasetsListItem) => {
        if (item.isFavorite) {
          return (
            <ElxIconButton
              text=""
              styles={labelStyles}
              iconProps={{
                iconName: 'FavoriteStarFill',
                className: classNames.favorite,
              }}
              onClick={() => {
                updateFavoriteDatasetsInUserprofile(item.id);
              }}
            />
          );
        } else {
          return (
            <ElxIconButton
              text=""
              styles={labelStyles}
              iconProps={{
                iconName: 'FavoriteStar',
                className: classNames.favorite,
              }}
              onClick={() => {
                updateFavoriteDatasetsInUserprofile(item.id);
              }}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <ElxTableContainer
        containerProps={containerProps}
        tableProps={{
          columns,
          items,
          actions,
          styles: tableStyles,
        }}
        searchBoxProps={searchProps}
      />
      <ElxDialog
        hidden={deletePending.length === 0}
        dismissable={true}
        header={
          deleteDenied
            ? `Cannot delete ${
                deletePending.length === 1 ? 'this dataset' : 'these datasets'
              }`
            : `Checking dataset${deletePending.length > 1 ? 's' : ''}...`
        }
        cancelButtonText={deleteDenied ? 'Dismiss' : 'Cancel'}
        onCancelButtonClick={() => {
          setDeleteDenied(false);
          setDeletePending([]);
        }}
      >
        {deleteDenied ? (
          <>
            <p>Datasets cannot be deleted if they contain expectations.</p>
            <p>{`Please remove all expectations from ${
              deletePending.length === 1 ? 'this dataset' : 'these datasets'
            } and try again.`}</p>
          </>
        ) : (
          <>
            <p>{`Checking if dataset${
              deletePending.length > 1 ? 's' : ''
            } can be deleted...`}</p>
            <Spinner></Spinner>
          </>
        )}
      </ElxDialog>
    </>
  );
};

export default DatasetsList;
