import React, { useEffect, useState } from 'react';
import { IComboBoxOption, IComboBoxStyles, Stack } from '@fluentui/react';
import { Card } from 'components/cards/card';
import { EditorProps } from '../../base/userExpectation';
import {
  ClassifyResults,
  useClassifyResultsHelpers,
} from '../../../components/classifyResults/classifyResults';
import { ElxCombobox } from '@elixir/fx/lib/components/Combobox';
import { NullCheckState } from './nullCheckExpectation';
import { getDataSourceClient } from 'features/dataSources/registry';
import {
  KustoConnectionDetails,
  StoreType,
} from 'features/dataQuality/models/dataConnection';
import { KustoDatasetProperties } from 'features/dataQuality/models/dataset';
import { TableNode } from 'features/dataSources/models/schemaTree';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

const NullCheckEditor = ({
  dataset,
  connection,
  state,
  setState,
}: EditorProps<NullCheckState>): JSX.Element => {
  const classifyHelpers = useClassifyResultsHelpers(
    state,
    setState,
    StandardUnits.PERCENT
  );

  const dataSourceClient = getDataSourceClient(
    connection.connectionDetails.subType
  );
  const [columnNames, setColumnNames] = useState<string[] | undefined>(
    undefined
  );

  useEffect(() => {
    // TODO: some way for generic fetching of column names for all dataset types.
    if (
      dataSourceClient &&
      columnNames === undefined &&
      connection.connectionDetails.subType === StoreType.Kusto
    ) {
      let kustoConnectionDetails =
        connection.connectionDetails as KustoConnectionDetails;
      let kustoDatasetProperties =
        dataset.datasetProperties as KustoDatasetProperties;
      setColumnNames([]);
      dataSourceClient
        .getSchema(
          kustoConnectionDetails.cluster,
          kustoConnectionDetails.database
        )
        .then(function (schema) {
          if (Array.isArray(schema)) {
            let table = schema?.find(
              (node) => node.name === kustoDatasetProperties.table
            ) as TableNode;
            setColumnNames(table?.children?.map((c) => c.name) || []);
          }
        });
    }
  }, [dataSourceClient, connection, columnNames, dataset.datasetProperties]);

  const columnNameOptions: IComboBoxOption[] =
    columnNames?.map((col) => {
      return {
        key: col,
        text: col,
      };
    }) || [];

  // same as <HighlightText /> component
  const styles = {
    input: {
      backgroundColor: '#c0ecf8',
    },
  } as IComboBoxStyles;

  return (
    <>
      <Card>
        <Stack tokens={{ childrenGap: 16 }}>
          <ElxCombobox
            label={'The column'}
            text={state?.column}
            styles={styles}
            options={columnNameOptions}
            allowFreeform
            onChange={(e, option, i, value) => {
              const column = option?.key?.toString() || value;
              setState({
                ...state,
                column,
                stateIsReady: (column?.length || 0) > 0,
              });
            }}
          />
          <label>should not contain null values</label>
        </Stack>
      </Card>
      <ClassifyResults {...classifyHelpers} />
    </>
  );
};

export default NullCheckEditor;
