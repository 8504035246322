import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import Rule from 'features/dataQuality/models/rule';
import UserExpectation from './base/userExpectation';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';

const userExpectations: { [id: string]: UserExpectation<any> } = {};

export function registerUserExpectation(ex: UserExpectation<any>) {
  userExpectations[ex.id] = ex;
}

export function getUserExpectationById(id: string) {
  return userExpectations[id];
}

export function getUserExpectationFromRule(rule?: Rule) {
  return (
    rule &&
    Object.keys(userExpectations)
      .map((key) => userExpectations[key])
      .find((expectation) => {
        return (
          rule?.details.systemDefinedRuleCategories === expectation.category &&
          rule?.details.systemDefinedRuleSubCategories ===
            expectation.subCategory
        );
      })
  );
}

export function getUserExpectationSelectOptions(dataset: Dataset) {
  return Object.keys(userExpectations).map((key) =>
    userExpectations[key].getSelectOption(dataset)
  );
}

export function getExpectationBuilderById(
  id: string,
  dataset: Dataset,
  connection: DataConnection,
  expectationInfo?: ExpectationInfo
) {
  return getUserExpectationById(id)?.builderFactory(
    dataset,
    connection,
    expectationInfo
  );
}
