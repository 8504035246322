import { LeWebApi } from 'api/leWebApi';
import { CatalogEntity } from '../models/catalogEntity';

class CatalogApi extends LeWebApi {
  public getCatalogEntity = async (id: string): Promise<CatalogEntity> => {
    return await this.getEntity<CatalogEntity>('/assetcatalog/entity/' + id);
  };

  public getCatalogEntityByName = async (
    name: string
  ): Promise<CatalogEntity> => {
    return await this.getEntity<CatalogEntity>(
      '/assetcatalog/entitybyname/' + name
    );
  };
}

export const catalogApi = new CatalogApi();

export const getCatalogEntity = catalogApi.getCatalogEntity;
export const getCatalogEntityByName = catalogApi.getCatalogEntityByName;

export default catalogApi;
