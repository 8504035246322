import React from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';
import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import {
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
} from 'features/dataQuality/models/rule';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import UserExpectationBuilder from './userExpectationBuilder';
import {
  ResultsTableDescription,
  UserExpectationResultsDescription,
} from './userExpectationResultsBuilder';
import RuleExecutionResult from 'features/dataQuality/models/ruleExecutionResult';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export interface EditorProps<T> {
  dataset: Dataset;
  connection: DataConnection;
  state?: T;
  setState: React.Dispatch<T>;
}

/**
 * Base class for User Expectations.  This lays out the contract that all User Expectation implementations must fill.
 * A User Expectation is a user-level abstraction of a typical Data Quality "Rule" workflow.  For example a user wants to
 * be alerted when a certain column value has too many nulls for a dataset.  They will select the NullCheck User Expectation
 * by its getSelectOption() with a custom description e.g. 'Column values should be fully/mostly non-null'.
 * This custom select option with description is provided by the concrete implementation of the NullCheckExpectation,
 * which extends this base class.
 * User Expectations should not be confused with the DQS "Expectation" inside the DQS Alert object.  The User Expectation
 * encompasses the entire DQS workflow for a DQS Rule, including Rules, Alerts, Expectations, Conditions and Actions.
 * This class uses a singleton pattern, but there is a separate singleton for each User Expectation type
 *
 * T: the custom state for the User Expectation.  Must extend "EditExpectationState".
 */
export abstract class UserExpectation<T extends EditExpectationState> {
  // this id is a human-readable unique ID for this expectation.  This may appear in various rule names etc on DQS side.
  public abstract readonly id: string;

  // description in english for what the user wants to achieve with this User Expectation
  public abstract readonly description: string;

  // These settings come from the DQS rule we are implementing.  A User Expectation generates one DQS Rule.
  public abstract readonly category: SystemDefinedRuleCategory;
  public abstract readonly subCategory: SystemDefinedRuleSubCategory;
  public abstract readonly ruleType: RuleType;
  public abstract readonly ruleConditionType: RuleConditionType;

  /**
   * Provide the dropdown option for the main User Expectation type selector
   * Concrete classes don't need to override this.
   * @param dataset
   * @returns
   */
  public getSelectOption(dataset: Dataset): IDropdownOption {
    return {
      key: this.id,
      text: this.description,
      data: this.getDescriptionElement(undefined, dataset),
    };
  }

  /**
   * Get the React display element for this User Expectation's description.  This is in element form because
   * the column or table name is highlighted with extra CSS.  The portions that are customized by the user are
   * highlighted.  If we pass in the current editor state and the current dataset, the element can be customized
   * with the currently chosen values and current dataset table, making this a dynamic description that changes
   * with the user's settings for the User Expectation.
   * @param state
   * @param dataset
   * @returns
   */
  public getDescriptionElement = (
    state?: T,
    dataset?: Dataset
  ): JSX.Element => <>{this.description}</>;

  /**
   * React Element for the main User Expectation editor that appears on the Edit Expectation panel
   */
  public abstract Editor: (props: EditorProps<T>) => JSX.Element;

  /**
   * This decodes the previously saved DQS Rule and Alerts (a.k.a. ExpectationInfo) and generates the "State" object
   * for the User Expectation.
   * @param expectationInfo Rule and Alerts loaded from DQS api
   */
  public abstract getInitialState(expectationInfo?: ExpectationInfo): T;

  /**
   * Get the current unit used for Display of threshold ranges and result measurements.
   * @param expectationInfo
   * @returns
   */
  public getUnit(expectationInfo?: ExpectationInfo) {
    return StandardUnits.NUMBER;
  }

  /**
   * This is a factory for the UserExpectationBuilder.  The UserExpectationBuilder "builds" the DQS Rules and Alerts
   * that correspond to this User Expectation.  The UserExpectationBuilder class is NOT a singleton like the UserExpectation
   * classes are.  For this reason, a new instance of the class must be constructed by this factory for every save operation.
   * @param dataset
   * @param connection
   * @param prevExpectationInfo the DQS Rule and Alerts that were saved to DQS previously if we are editing an existing User Expectation
   * @param isClone supports the clone operation
   */
  public abstract builderFactory(
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo,
    isClone?: boolean
  ): UserExpectationBuilder<T>;

  /**
   * Get a description for the RuleExecutionResult.  The result description contains the phrases and values that are
   * displayed by the UX for every execution result.
   * @param ruleExecutionResult from DQS api
   */
  public abstract getResultDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription;

  /**
   * This processes the results for previous executions and compiles into a table that can displayed by a DetailsList.
   * @param ruleExecutionResults
   */
  public abstract getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription;
}

export default UserExpectation;
