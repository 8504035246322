import React from 'react';
import {
  useCatalogEntity,
  selectCatalogEntityByName,
} from 'features/catalog/catalogSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/lensShellUtility';
import { CatalogEntity } from '../../models/catalogEntity';

export interface CatalogEntityValueProps {
  entityName: string;
  valueSelector: (entity?: CatalogEntity) => string | null | undefined;
}

/**
 * A single value from a Catalog entity.  Contains hooks to automatically load and display the value on demand
 * @param props
 * @returns
 */
export const CatalogEntityValue = (
  props: CatalogEntityValueProps
): JSX.Element => {
  const { entityName, valueSelector } = props;
  useCatalogEntity(entityName);
  const entity = useSelector((state: RootState) =>
    selectCatalogEntityByName(state, entityName)
  );

  const text = valueSelector(entity);
  return <span title={text || undefined}>{text}</span>;
};
