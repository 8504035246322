import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  DataQualityStatus,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
} from 'features/dataQuality/dataQualitySlice';

export interface LastModifiedProps {
  datasetId: string;
}

/**
 * The most recent updatedAt date for all UserExpectations for a given dataset
 * @param props
 * @returns
 */
export const LastModified = (props: LastModifiedProps): JSX.Element => {
  const { datasetId } = props;

  // this Element relies on RulePassRate to "useDatasetsLatestRuleResults" to start loading the expectations for us

  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  if (expectationsStatus === DataQualityStatus.Loaded) {
    const lastModified: string[] = [];

    const expectationInfos = Object.keys(expectationEntities)
      .filter((k) => expectationEntities[k]!.rule.datasetId === datasetId)
      .map((k) => expectationEntities[k]!);

    expectationInfos.forEach((expectationInfo) => {
      const updatedAt = expectationInfo.rule.updatedAt;
      if (updatedAt) {
        lastModified.push(updatedAt);
      }
      expectationInfo.alerts.forEach((alert) => {
        const updatedAt = alert.updatedAt;
        if (updatedAt) {
          lastModified.push(updatedAt);
        }
      });
    });

    if (lastModified.length > 0) {
      const lastModifiedSorted = lastModified.sort();
      const dateString = lastModifiedSorted[lastModifiedSorted.length - 1];
      return <>{DateTime.fromISO(dateString).toRelative()}</>;
    }
  }

  return <></>;
};
