import React from 'react';
import { Route, Switch } from 'react-router-dom';
import JobList from './components/jobList';

const JobListRoute: React.FC = () => {
  return (
    <Switch>
      <Route path="/job/list" component={JobList} />
    </Switch>
  );
};

export default JobListRoute;
