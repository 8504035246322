import React from 'react';
import { IDropdownOption, Spinner, SpinnerSize, Stack } from '@fluentui/react';

/**
 * This helper can be passed to an ElxDropdown (as onRenderOption) to render a spinner
 * beside the dropdown option.  To turn on the spinner the IDropdownOption needs
 * to have an object in data like this: { spinner: 'left' }
 *
 * Example:
 * const options = [{
 *     key: 0,
 *     text: 'Loading options...',
 *     data: { spinner: 'right' },
 * }];
 *
 * <ElxDropdown
 *     options={options}
 *     onRenderOption={onRenderSpinnerOption}
 * />
 *
 * @param props
 * @param defaultRender
 * @returns
 */
export const onRenderSpinnerOption = (
  props?: IDropdownOption,
  defaultRender?: (props?: IDropdownOption) => JSX.Element | null
): JSX.Element | null => {
  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
      {props?.data?.spinner === 'left' && <Spinner size={SpinnerSize.small} />}
      <Stack.Item>{defaultRender?.(props)}</Stack.Item>
      {props?.data?.spinner === 'right' && <Spinner size={SpinnerSize.small} />}
    </Stack>
  );
};
