import React from 'react';
import UserExpectation from '../../base/userExpectation';
import { getClassifyResultsState } from '../../../components/classifyResults/classifyResults';
import {
  NullCheckCondition,
  RuleConditionType,
  RuleType,
  SystemDefinedRuleCategory,
  SystemDefinedRuleSubCategory,
} from 'features/dataQuality/models/rule';
import NullCheckEditor from './nullCheckEditor';
import NullCheckBuilder from './nullCheckBuilder';
import { ExpectationInfo } from 'features/dataQuality/dataQualitySlice';
import DataConnection from 'features/dataQuality/models/dataConnection';
import Dataset from 'features/dataQuality/models/dataset';
import { registerUserExpectation } from '../../userExpectationsRegistry';
import NullCheckResultsBuilder from './nullCheckResultsBuilder';
import { EditExpectationState } from 'features/dataQuality/components/editExpectation/editExpectation';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

export interface NullCheckState extends EditExpectationState {
  column?: string;
}

class NullCheckExpectation extends UserExpectation<NullCheckState> {
  id = 'NullCheck';
  description = 'Column values should be fully/mostly non-null';
  category = SystemDefinedRuleCategory.Completeness;
  subCategory = SystemDefinedRuleSubCategory.NullCheck;
  ruleType = RuleType.PreDefined;
  ruleConditionType = RuleConditionType.NullCheckCondition;

  getDescriptionElement = (state?: NullCheckState, dataset?: Dataset) => {
    const columnName = state?.column || 'Column';
    return (
      <span title={columnName + ' values should be fully/mostly non-null'}>
        <HighlightText>{columnName}</HighlightText> values should be
        fully/mostly non-null
      </span>
    );
  };

  Editor = NullCheckEditor;

  getInitialState(expectationInfo?: ExpectationInfo): NullCheckState {
    const columns2check = (
      expectationInfo?.rule?.details.ruleCondition as NullCheckCondition
    )?.ColumnsToCheck;
    const column =
      columns2check && columns2check.length > 0
        ? columns2check[0].columnName
        : undefined;

    return {
      ...getClassifyResultsState(expectationInfo),
      column,
      stateIsReady: (column?.length || 0) > 0,
    };
  }

  public getUnit(expectationInfo?: ExpectationInfo) {
    return StandardUnits.PERCENT;
  }

  builderFactory = (
    dataset: Dataset,
    connection: DataConnection,
    prevExpectationInfo?: ExpectationInfo
  ): NullCheckBuilder =>
    new NullCheckBuilder(this, dataset, connection, prevExpectationInfo);

  getResultDescription = NullCheckResultsBuilder.getResultDescription;
  getResultsTable = NullCheckResultsBuilder.getResultsTable;
}

registerUserExpectation(new NullCheckExpectation());
