import React from 'react';
import { ElxLink, ElxPanel, PanelSize } from '@elixir/fx';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { panelStyles } from 'utils/sharedstyles';
import { BUILD_NUM } from 'utils/sharedLibs';
import { ILinkStyles, Stack } from '@fluentui/react';

const helpLinkStyles = {
  root: {
    fontSize: '110%',
  },
} as ILinkStyles;

export const Help = ({ show, onShowPanel }: BasePanelProps): JSX.Element => {
  const theme = useLensShellTheme();

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText="Help Center"
        headerContent={
          <div>
            <p>
              {'Need additional help? '}
              <ElxLink
                style={{ fontSize: '1em' }}
                label="CreateIncident"
                href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=mLd617"
                target="_blank"
              >
                {'Contact the support team via IcM'}
              </ElxLink>
            </p>
            <div>
              <p>Lens Explorer Build Number: {BUILD_NUM} </p>
            </div>
          </div>
        }
        isOpen={show}
        size={PanelSize.large}
        fillBackground={true}
        onDismiss={() => onShowPanel(false)}
        styles={panelStyles}
      >
        <Stack tokens={{ padding: 24, childrenGap: 16 }}>
          <ElxLink
            styles={helpLinkStyles}
            label="LensHelp"
            href="https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensuserguide"
            target="_blank"
          >
            {'Lens Explorer documentation'}
          </ElxLink>
          <ElxLink
            styles={helpLinkStyles}
            label="DQHelp"
            href="https://eng.ms/docs/products/genevaanalytics/lensexplorer/dataquality/dataqualityhelp"
            target="_blank"
          >
            {'Lens Data Quality documentation'}
          </ElxLink>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default Help;
