/**
 * Represents a Geneva Orchestrator project.
 */
export interface Project {
  projectName: string;
  projectDisplayName: string;
  icMTeamPublicId?: string;
  authorizedAADClientIds: AuthorizedAADClientIds;
  dataConnections: DataConnection[];
}

export interface AuthorizedAADClientIds {
  [key: string]: string[];
}

export enum ConnectionDataSourceType {
  None = 'None',
  Sql = 'Sql',
  Kusto = 'Kusto',
  ApplicationInsights = 'ApplicationInsights',
  OmsLogAnalytics = 'OmsLogAnalytics',
  Cosmos = 'Cosmos',
  AzureStorage = 'AzureStorage',
  EventHub = 'EventHub',
  AzureDataLakeStorageGen2 = 'AzureDataLakeStorageGen2',
  Subscription = 'Subscription',
  AzureDataLakeAnalytics = 'AzureDataLakeAnalytics',
  Mdm = 'Mdm',
  AzureDataFactory = 'AzureDataFactory',
}

/*
 * The project data connection interface resembles the Orchestrator contracts:
 * https://msazure.visualstudio.com/One/_git/EngSys-CAS-DataStudioOrchestrator?path=%2FContracts%2FConnections&version=GBdevelop&_a=contents.
 */
export interface DataConnection {
  connectionName: string;
  connectionDisplayName: string;
  type: ConnectionDataSourceType;
  [extendedProp: string]: any;
}

export enum AadConnectionAuthType {
  AADApp = 'AADApp',
}

export const AadConnectionAuthTypeDisplayNames = {
  [AadConnectionAuthType.AADApp]: 'AAD Application Authentication',
};

export interface AadConnection {
  authType: AadConnectionAuthType;
  principalId: string;
  tenant: string;
  //aadClientSecretUri: string;
}

export interface KustoDataConnection extends ProjectDataConnection {
  type: ConnectionDataSourceType.Kusto;
  cluster: string;
  database: string;
  dataQualityServiceConnectionId?: string;
}

// Generic connection type (e.g. KustoConnection, CosmosConnection) using extended properties.
export interface ProjectDataConnection extends DataConnection, AadConnection {
  [extendedProp: string]: any;
}

interface WorkspaceDataSourceAadAuthentication {
  AuthenticationMode: AadConnectionAuthType;
  Tenant: string;
  ApplicationId?: string;
  UserId?: string;
}

export interface KustoDataConnectionRequest extends DataConnectionRequest {
  Type: ConnectionDataSourceType.Kusto;
  Cluster: string;
  Database: string;
  DataQualityServiceConnectionId?: string;
  [propName: string]: any;
}

export interface DataConnectionRequest
  extends WorkspaceDataSourceAadAuthentication {
  Id?: string;
  Name: string;
  Type: ConnectionDataSourceType;
  IsImported?: boolean;
  [propName: string]: any;
}
