import { IColumn } from '@fluentui/react';
import { decodeSeverity } from 'features/dataQuality/components/classifyResults/threshold';
import { ThresholdUnit } from 'features/dataQuality/components/classifyResults/thresholdUnits';
import {
  ConditionType,
  ConditionUnit,
  Expectation,
  StaticCondition,
} from 'features/dataQuality/models/alert';
import RuleExecutionResult from 'features/dataQuality/models/ruleExecutionResult';
import {
  getDefaultAlertForRuleExecutionResult,
  getFailedExpectations,
  getStaticConditionByLabel,
  getSucceededExpectations,
  parseStaticConditionValue,
} from 'features/dataQuality/utils/dataQualityUtils';

export interface UserExpectationResultsDescription {
  expectationDescription: string | JSX.Element;
  outcomeSummary?: string;
  failureValue?: number | string;
  failureValueDescription?: string;
  thresholdValueDescriptions: string[];
  columns?: IColumn[];
}

export interface ResultsTableItem {
  key: string;
  date: string;
  [name: string]: any;
}

export interface ResultsTableDescription {
  columns: IColumn[];
  items: ResultsTableItem[];
}

/**
 * The UserExpectationResultsBuilder class provides the public methods for getResultDescription and getResultsTable.
 * These two public methods are called by the UX to retrieve data to be displayed for the Result Details view.
 * Each User Expectation type has it's own format for result data.  This class, and its concrete implementations
 * are responsible for extracting the RuleExecutionResults coming from DQS and transforming this into "...Description"
 * objects that can be displayed by the UX in a shared component matter.
 * This is pretty much a utility class filled with static methods.
 */
export abstract class UserExpectationResultsBuilder {
  public static getResultsDescription(
    ruleExecutionResult: RuleExecutionResult
  ): UserExpectationResultsDescription {
    return {
      expectationDescription: '',
      outcomeSummary: '',
      thresholdValueDescriptions: [],
    };
  }

  public static getResultsTable(
    ruleExecutionResults: RuleExecutionResult[]
  ): ResultsTableDescription {
    return {
      columns: [],
      items: [],
    };
  }

  protected static readonly percentDecimals = 2;

  protected static readonly colDefaults = {
    isResizable: true,
    isMultiline: true,
  };

  protected static getShorterDate(date: number | string): string {
    const isoString = new Date(date).toISOString();
    return isoString?.replace('T', ' ').replace(/\.\d\d\d[A-Z]$/, '');
  }

  /**
   * Test if the rule expectations are made up "percentage" conditions.  Percentage conditions need to be described differently
   * @param result
   * @returns
   */
  protected static isPercentThresholds(result: RuleExecutionResult): boolean {
    const alert = getDefaultAlertForRuleExecutionResult(result);
    const somePercent = alert?.expectations.find((e) => {
      let staticConditions = e.conditions.filter(
        (c) => c.subType === ConditionType.Static
      ) as StaticCondition[];
      return staticConditions.find(
        (sc) => sc.unit === ConditionUnit.Percentage
      );
    });
    return !!somePercent;
  }

  /**
   * get the from and to values from an expectation.  also finds severity
   * @param expectation
   * @param value
   * @param percentValue
   * @returns
   */
  protected static decodeRangeParameters(
    expectation: Expectation,
    value: number,
    percentValue?: number
  ) {
    const fromCondition = getStaticConditionByLabel(expectation, 'from');
    const toCondition = getStaticConditionByLabel(expectation, 'to');

    const severity = decodeSeverity(expectation);
    const fromValue = parseStaticConditionValue(fromCondition);
    const toValue = parseStaticConditionValue(toCondition);

    return { fromValue, toValue, severity };
  }

  /**
   * Describe a range in English, relative to the current measured value
   * @param expectation
   * @param value
   * @param percentValue
   * @returns
   */
  protected static describeStaticRangeCondition(
    expectation: Expectation,
    unit: ThresholdUnit,
    value: number,
    percentValue?: number
  ): string {
    const { fromValue, toValue, severity } = this.decodeRangeParameters(
      expectation,
      value,
      percentValue
    );

    const compareValue = unit.isPercent ? percentValue : value;
    const severityString = severity ? `Sev ${severity}` : 'failure';
    const fromStr = unit.getDisplayPhrase(fromValue);
    const toStr = unit.getDisplayPhrase(toValue);

    if (fromValue && toValue) {
      // the expecation is a full range condition with a beginning and an end
      if (compareValue && compareValue < fromValue) {
        return `Greater than or equal to ${fromStr} (and less than ${toStr}) would have been a ${severityString}.`;
      } else if (compareValue && compareValue >= toValue) {
        return `Less than ${toStr} (and greater than or equal to ${fromStr}) would have been a ${severityString}.`;
      }
      // this generic expression is preferred if the percentValue is inside the range, and also serves as a fallback.
      return `Greater than or equal to ${fromStr} and less than ${toStr} is a ${severityString}.`;
    } else if (fromValue) {
      // the expecation is just a "greater than or equal to"
      if (compareValue && compareValue < fromValue) {
        return `Greater than or equal to ${fromStr} would have been a ${severityString}.`;
      }
      return `Greater than or equal to ${fromStr} is a ${severityString}.`;
    } else if (toValue) {
      // the expectation is a "less than" condition
      if (compareValue && compareValue >= toValue) {
        return `Less than ${toStr} would have been a ${severityString}.`;
      }
      return `Less than ${toStr} is a ${severityString}.`;
    }
    return '';
  }

  /**
   * retrieve the ranges that didn't fail.  break those into low (less than measured value) ranges and
   * high (greater than measured value) ranges.
   * @param result
   * @param value the current measured value for this execution result
   * @param percentValue for percent conditions, also provide the measured percent value
   * @returns an object with low list and high list.
   */
  protected static getSucceededStaticRangeConditions(
    result: RuleExecutionResult,
    value: number,
    percentValue?: number
  ) {
    const isPercentage = this.isPercentThresholds(result);

    // This list is sorted by "to" value.  We are assuming non-overlapping ranges for most calculations
    const succeeded = getSucceededExpectations(result)?.sort(
      (expectationA, expectationB) => {
        const conditionA = getStaticConditionByLabel(expectationA, 'to');
        const conditionB = getStaticConditionByLabel(expectationB, 'to');
        const valueA = parseStaticConditionValue(conditionA);
        const valueB = parseStaticConditionValue(conditionB);
        // a missing "to" value indicates unbounded upper end which is +Infinity
        return (valueA || Infinity) - (valueB || Infinity);
      }
    );

    const compareValue = isPercentage ? percentValue || -Infinity : value;

    const low =
      succeeded?.filter((expectation) => {
        const toCondition = getStaticConditionByLabel(expectation, 'to');
        const toValue = parseStaticConditionValue(toCondition) || Infinity;
        return toValue < compareValue;
      }) || [];

    const high =
      succeeded?.filter((expectation) => {
        const fromCondition = getStaticConditionByLabel(expectation, 'from');
        const fromValue = parseStaticConditionValue(fromCondition) || -Infinity;
        return fromValue > compareValue;
      }) || [];

    return { low, high };
  }

  /**
   * Describe, in English the nearby ranges that did not fail.  Only the "adjacent" ranges are described.
   * @param result
   * @param value the current measured value for this execution result
   * @param percentValue for percent conditions, also provide the measured percent value
   * @returns list of English strings describing the adjacent low threshold, and the adjacent high threshold
   */
  protected static describeNearbySuccededThresholds(
    result: RuleExecutionResult,
    unit: ThresholdUnit,
    value: number,
    percentValue?: number
  ) {
    const retVal: string[] = [];
    const { low, high } = this.getSucceededStaticRangeConditions(
      result,
      value,
      percentValue
    );
    if (low.length > 0) {
      retVal.push(
        this.describeStaticRangeCondition(
          low.pop() as Expectation,
          unit,
          value,
          percentValue
        )
      );
    }
    if (high.length > 0) {
      retVal.push(
        this.describeStaticRangeCondition(
          high.shift() as Expectation,
          unit,
          value,
          percentValue
        )
      );
    }
    return retVal;
  }

  /**
   * Find the rangeStart and rangeEnd range values for the current result value.  Note that if the result is a "Fail"
   * Then the rangeStart and rangeEnd values will be the "from" and "to" values of the failed range.
   * If the current result is a success, the the rangeStart value will be the "to" value from the closest "missed"
   * condition on the low side.  The rangeEnd value will be the "from" value from the next higher "missed"
   * condition on the high side.
   * @param ruleExecutionResult
   * @param value current measured result as reported by DQS
   * @param percentValue current measured result reported as percent by DQS, only for percent ranges.
   * @returns
   */
  protected static calcResultRange(
    ruleExecutionResult: RuleExecutionResult,
    value: number,
    percentValue?: number
  ) {
    const failedExpectations = getFailedExpectations(ruleExecutionResult);
    let rangeStart: number | undefined,
      rangeEnd: number | undefined,
      result: string;
    if (failedExpectations.length > 0) {
      const failedExpectation = failedExpectations[0];
      const severity = decodeSeverity(failedExpectation);
      result = severity ? `Sev ${severity}` : 'Fail';
      rangeStart = parseStaticConditionValue(
        getStaticConditionByLabel(failedExpectation, 'from')
      );
      rangeEnd = parseStaticConditionValue(
        getStaticConditionByLabel(failedExpectation, 'to')
      );
    } else {
      result = 'Pass';
      const { low, high } =
        UserExpectationResultsBuilder.getSucceededStaticRangeConditions(
          ruleExecutionResult,
          value,
          percentValue
        );
      const lowExpectation = (low.length > 0 && low.pop()) || undefined;
      const highExpectation = (high.length > 0 && high.shift()) || undefined;
      rangeStart =
        (lowExpectation &&
          parseStaticConditionValue(
            getStaticConditionByLabel(lowExpectation, 'to')
          )) ||
        undefined;
      rangeEnd =
        (highExpectation &&
          parseStaticConditionValue(
            getStaticConditionByLabel(highExpectation, 'from')
          )) ||
        undefined;
    }
    return { result, rangeStart, rangeEnd };
  }
}
