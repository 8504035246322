import { DataSourceClient } from './client/dataSourceClient';
import { kustoClient } from './kusto/client/kustoClient';

export const dataSourceClients: DataSourceClient[] = [kustoClient];

export const dataSourceTypes = dataSourceClients.map(
  (dsc) => dsc.dataSourceType
);

export function getDataSourceClient(dataSourceType: string) {
  return dataSourceClients.find((dsc) => dsc.dataSourceType === dataSourceType);
}

export default dataSourceClients;
