import React, { useEffect, useState } from 'react';
import {
  ElxChoiceGroup,
  ElxPanel,
  ElxTextField,
  ElxTimeSelector,
  IContainerAction,
  PanelSize,
} from '@elixir/fx';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import {
  Checkbox,
  DayOfWeek,
  Dropdown,
  IChoiceGroupStyles,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
  Toggle,
} from '@fluentui/react';
import { Card } from 'components/cards/card';
import Dataset from 'features/dataQuality/models/dataset';
import DataConnection from 'features/dataQuality/models/dataConnection';
import {
  ExpectationInfo,
  loadExpectation,
  saveExpectation,
} from 'features/dataQuality/dataQualitySlice';
import { RuleFrequency } from 'features/dataQuality/models/rule';
import {
  ITimeSelectorProps,
  TimeSelectorOutput,
} from '@elixir/fx/lib/components/DateTimeControls/TimeSelector/TimeSelector.types';
import {
  selectOrchestratorProject,
  selectOrchestratorProjectStatus,
} from 'features/workspaces/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';
import { buildNotificationExpectationInfo } from './notificationBuilder';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/lensShellUtility';
import { buildErrorMessage } from 'components/errorMessageBar/errorMessageBar';
import {
  Email,
  IcM,
  SupportedActionType,
} from 'features/dataQuality/models/alert';
import { SidePanel } from 'features/shell/lensShell';
import { getDefaultAlertForExpectation } from 'features/dataQuality/utils/dataQualityUtils';
import {
  EmailReportsHelpLabel,
  GeneralHelpLabel,
  ICMTicketsHelpLabel,
} from 'utils/helpIconText';

export interface EditNotificationProps extends BasePanelProps {
  workspaceId: string;
  dataset?: Dataset;
  connection?: DataConnection;
  expectationInfo?: ExpectationInfo;
}

export interface EditNotificationState {
  scheduleEnabled: boolean;
  scheduleTime: ITimeSelectorProps;
  scheduleWeekday: DayOfWeek;
  scheduleFrequency: RuleFrequency;

  emailEnabled: boolean;
  emailTo: string;
  emailCc: string;

  icmEnabled: boolean;
  icmSeverities: number[];
  icmServiceName?: string;
}

const frequencyOptions = [
  { key: RuleFrequency.Hour, text: 'Hourly' },
  { key: RuleFrequency.Day, text: 'Daily' },
  { key: RuleFrequency.Week, text: 'Weekly' },
];

const frequencyChoiceStyles = {
  flexContainer: {
    display: 'flex',
  },
  root: {
    marginLeft: 50,
    '.ms-ChoiceField': {
      marginRight: 16,
    },
  },
} as IChoiceGroupStyles;

const timeLabelStyles = {
  root: {
    paddingBottom: 6,
  },
};

const timeSelectorStyles = {
  root: {
    '.ms-spinButton-input': {
      textOverflow: 'unset',
    },
  },
};

export const EditNotification = (props: EditNotificationProps): JSX.Element => {
  const {
    show,
    onShowPanel,
    onAddPanel,
    workspaceId,
    dataset,
    connection,
    expectationInfo,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const orchestratorProjectStatus = useSelector(
    selectOrchestratorProjectStatus
  );
  const orchestratorProject = useSelector(selectOrchestratorProject);
  const noIcmServiceDefined =
    orchestratorProjectStatus === WorkspaceStatus.Loaded &&
    (!orchestratorProject ||
      !orchestratorProject.icMTeamPublicId ||
      orchestratorProject.icMTeamPublicId.length === 0);

  const theme = useLensShellTheme();

  const [editNotificationState, setEditNotificationState] =
    useState<EditNotificationState>({
      scheduleEnabled: false,
      scheduleTime: {
        hours: 0,
        minutes: 0,
        utcOffset: 0,
        showSeconds: false,
        showTimezoneSelector: false,
      },
      scheduleWeekday: DayOfWeek.Sunday,
      scheduleFrequency: RuleFrequency.Day,
      emailEnabled: false,
      emailTo: '',
      emailCc: '',
      icmEnabled: false,
      icmSeverities: [],
    });

  const days: IDropdownOption[] = [
    { text: 'Sunday', key: DayOfWeek.Sunday },
    { text: 'Monday', key: DayOfWeek.Monday },
    { text: 'Tuesday', key: DayOfWeek.Tuesday },
    { text: 'Wednesday', key: DayOfWeek.Wednesday },
    { text: 'Thursday', key: DayOfWeek.Thursday },
    { text: 'Friday', key: DayOfWeek.Friday },
    { text: 'Saturday', key: DayOfWeek.Saturday },
  ];

  useEffect(() => {
    if (expectationInfo) {
      setEditNotificationState(getInitialState(expectationInfo));
    }
  }, [show, expectationInfo]);

  const [saving, setSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  function onDismiss() {
    setErrorMessage('');
    onShowPanel(false);
  }

  const onDismissError = () => setErrorMessage('');
  const onShowNotifications = () => onAddPanel(SidePanel.NOTIFICATIONS);

  async function onSave() {
    if (!dataset || !connection || !expectationInfo) {
      return;
    }
    setErrorMessage('');
    setSaving(true);

    // inject the late-loading icmTeamPublicId
    const builderState: EditNotificationState = {
      ...editNotificationState,
      icmServiceName: orchestratorProject?.icMTeamPublicId,
    };

    try {
      const newExpectationInfo = buildNotificationExpectationInfo(
        builderState,
        expectationInfo
      );
      await dispatch(
        saveExpectation({ workspaceId, expectationInfo: newExpectationInfo })
      ).then(unwrapResult);
      await dispatch(
        loadExpectation({
          workspaceId,
          datasetId: dataset.id,
          ruleId: expectationInfo.rule.id,
        })
      ).then(unwrapResult);
      onDismiss();
    } catch (error) {
      setErrorMessage(
        (error as Error)?.message || (error as string) || 'unknown error'
      );
    } finally {
      setSaving(false);
    }
  }

  function renderActions(): IContainerAction[] {
    return [
      {
        key: 'Save',
        text: 'Save',
        isPrimary: true,
        disabled:
          editNotificationState.icmEnabled &&
          (orchestratorProjectStatus !== WorkspaceStatus.Loaded ||
            noIcmServiceDefined),
        onClick: onSave,
      },
      {
        key: 'Close',
        text: 'Close',
        onClick: onDismiss,
      },
    ];
  }

  function IcmCheck({ severity }: { severity: number }): JSX.Element {
    return (
      <Checkbox
        label={'Sev ' + severity}
        checked={editNotificationState.icmSeverities.includes(severity)}
        // disabled={!notificationState.icmEnabled}
        styles={{ root: { marginRight: 24 } }}
        onChange={(e, checked) => {
          let icms = editNotificationState.icmSeverities;
          if (!checked) {
            setEditNotificationState({
              ...editNotificationState,
              icmSeverities: icms.filter((icm) => icm === severity),
            });
          } else if (!icms.includes(severity)) {
            setEditNotificationState({
              ...editNotificationState,
              icmSeverities: [...icms, severity],
            });
          }
        }}
      />
    );
  }

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText="Notification Settings"
        headerContent={undefined}
        isOpen={show}
        message={
          (errorMessage &&
            buildErrorMessage(
              errorMessage,
              onDismissError,
              onShowNotifications
            )) ||
          undefined
        }
        isLoading={
          saving ||
          orchestratorProjectStatus === WorkspaceStatus.Loading ||
          !dataset ||
          !connection ||
          !expectationInfo
        }
        loadingLabel={saving ? 'Saving...' : undefined}
        size={PanelSize.medium}
        fillBackground={true}
        onDismiss={() => onShowPanel(false)}
        actions={renderActions()}
      >
        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
          <Card
            title="General"
            tokens={{ childrenGap: 8 }}
            hintText={GeneralHelpLabel}
          >
            <Stack.Item>
              <label>Include these datasets:</label>
              <p>{dataset?.datasetName}</p>
            </Stack.Item>
            <Toggle
              checked={editNotificationState.scheduleEnabled}
              label="Select running frequency"
              inlineLabel
              onChange={(e, checked) => {
                setEditNotificationState({
                  ...editNotificationState,
                  scheduleEnabled: checked || false,
                });
              }}
            />
            {editNotificationState.scheduleEnabled && (
              <>
                <ElxChoiceGroup
                  styles={frequencyChoiceStyles}
                  options={frequencyOptions}
                  selectedKey={editNotificationState.scheduleFrequency}
                  onChange={(_, value) => {
                    setEditNotificationState({
                      ...editNotificationState,
                      scheduleFrequency:
                        (value?.key as RuleFrequency) || RuleFrequency.Day,
                    });
                  }}
                />
                <Stack
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  styles={timeSelectorStyles}
                >
                  <Stack.Item align="end">
                    <Label styles={timeLabelStyles}>Run at&nbsp;</Label>
                  </Stack.Item>
                  {editNotificationState.scheduleFrequency ===
                    RuleFrequency.Week && (
                    <Dropdown
                      label="Weekday"
                      styles={{ root: { minWidth: 110 } }}
                      options={days}
                      selectedKey={editNotificationState.scheduleWeekday}
                      onChange={(_, option) => {
                        setEditNotificationState({
                          ...editNotificationState,
                          scheduleWeekday:
                            (option?.key as number) || DayOfWeek.Sunday,
                        });
                      }}
                    />
                  )}
                  <ElxTimeSelector
                    {...editNotificationState.scheduleTime}
                    showHours={
                      editNotificationState.scheduleFrequency !==
                      RuleFrequency.Hour
                    }
                    onChange={(value: TimeSelectorOutput) => {
                      setEditNotificationState({
                        ...editNotificationState,
                        scheduleTime: {
                          ...editNotificationState.scheduleTime,
                          ...value,
                        },
                      });
                    }}
                  />
                  <Stack.Item align="end">
                    <Label styles={timeLabelStyles}>UTC</Label>
                  </Stack.Item>
                </Stack>
              </>
            )}
          </Card>
          <Card
            title="Email Reports"
            tokens={{ childrenGap: 8 }}
            hintText={EmailReportsHelpLabel}
          >
            <Toggle
              checked={editNotificationState.emailEnabled}
              label="Send e-mail reports"
              inlineLabel
              onChange={(e, checked) => {
                setEditNotificationState({
                  ...editNotificationState,
                  emailEnabled: checked || false,
                });
              }}
            />
            {editNotificationState.emailEnabled && (
              <Stack tokens={{ childrenGap: 8 }}>
                <ElxTextField
                  label="Add recipients (separate by commas)"
                  required
                  placeholder="Enter email addresses"
                  value={editNotificationState.emailTo}
                  onChange={(_, value) => {
                    setEditNotificationState({
                      ...editNotificationState,
                      emailTo: value || '',
                    });
                  }}
                />
                <ElxTextField
                  label="Add 'cc:' recipients (separate by commas)"
                  placeholder="Enter cc: email addresses"
                  value={editNotificationState.emailCc}
                  onChange={(_, value) => {
                    setEditNotificationState({
                      ...editNotificationState,
                      emailCc: value || '',
                    });
                  }}
                />
              </Stack>
            )}
          </Card>
          <Card
            title="IcM Tickets"
            tokens={{ childrenGap: 8 }}
            hintText={ICMTicketsHelpLabel}
          >
            <Toggle
              checked={editNotificationState.icmEnabled}
              label="Log IcM tickets"
              inlineLabel
              onChange={(e, checked) => {
                setEditNotificationState({
                  ...editNotificationState,
                  icmEnabled: checked || false,
                });
              }}
            />
            {editNotificationState.icmEnabled && (
              <>
                {noIcmServiceDefined && (
                  <MessageBar messageBarType={MessageBarType.error}>
                    IcM tickets cannot be generated because your workspace does
                    not have an IcM service. Please edit your workspace
                    settings.
                  </MessageBar>
                )}
                <Label>Choose active severity levels</Label>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 16, padding: '8px 0px' }}
                >
                  {[1, 2, 3, 4].map((severity) => (
                    <IcmCheck key={severity} severity={severity} />
                  ))}
                </Stack>
                <p>
                  Note: Severity thresholds are set in the "Edit Expectations"
                  panel found inside the "Quality Report".
                </p>
              </>
            )}
          </Card>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

function getInitialState(
  expectationInfo?: ExpectationInfo
): EditNotificationState {
  const rule = expectationInfo?.rule;
  const alert = getDefaultAlertForExpectation(expectationInfo);

  const scheduleEnabled = !!(
    rule?.schedule &&
    [
      RuleFrequency.Week,
      RuleFrequency.Day,
      RuleFrequency.Hour,
      RuleFrequency.Minute,
    ].includes(rule.schedule.frequency as RuleFrequency)
  );
  const startTime =
    (scheduleEnabled && rule?.schedule && new Date(rule.schedule.startTime)) ||
    undefined;
  const scheduleWeekday =
    (scheduleEnabled && startTime?.getUTCDay()) || DayOfWeek.Sunday;
  const scheduleFrequency =
    (scheduleEnabled &&
      rule?.schedule &&
      (rule.schedule.frequency as RuleFrequency)) ||
    RuleFrequency.Day;

  const actionWithEmail = alert?.actions.find((action) =>
    action.actionTypes.find(
      (actionType) =>
        actionType.supportedActionType === SupportedActionType.Email
    )
  );
  const email = actionWithEmail?.actionTypes.find(
    (actionType) => actionType.supportedActionType === SupportedActionType.Email
  ) as Email | undefined;

  const actionWithIcMs = alert?.actions.find((action) =>
    action.actionTypes.find(
      (actionType) => actionType.supportedActionType === SupportedActionType.IcM
    )
  );
  const icmSeverities =
    (
      actionWithIcMs?.actionTypes.filter(
        (actionType) =>
          actionType.supportedActionType === SupportedActionType.IcM
      ) as IcM[]
    )?.map((icm: IcM) => icm.incidentSeverity) || [];

  return {
    scheduleEnabled,
    scheduleTime: {
      hours: startTime?.getUTCHours() || 0,
      minutes: startTime?.getUTCMinutes() || 0,
      utcOffset: 0,
      showSeconds: false,
      showTimezoneSelector: false,
    },
    scheduleWeekday,
    scheduleFrequency,
    emailEnabled: !!email,
    emailTo: email?.to.map((to) => to.address).join('; ') || '',
    emailCc: email?.cc?.map((cc) => cc.address).join('; ') || '',
    icmEnabled: icmSeverities?.length > 0,
    icmSeverities,
  };
}

export default EditNotification;
