import React from 'react';
import { useSelector } from 'react-redux';
import {
  DataQualityStatus,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
} from 'features/dataQuality/dataQualitySlice';

export interface ExpectationCountProps {
  datasetId: string;
}

/**
 * A simple count of the number of User Expecations present for this dataset.
 * @param props
 * @returns
 */
export const ExpectationCount = (props: ExpectationCountProps): JSX.Element => {
  const { datasetId } = props;

  // this Element relies on RulePassRate to "useDatasetsLatestRuleResults" to start loading the expectations for us

  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  if (expectationsStatus === DataQualityStatus.Loaded) {
    const ruleIds: string[] = Object.keys(expectationEntities).filter(
      (k) => expectationEntities[k]!.rule.datasetId === datasetId
    );
    return <>{ruleIds.length}</>;
  }

  return <></>;
};
