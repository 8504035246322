import { LeWebApi } from 'api/leWebApi';
import { Userprofile } from 'features/userprofile/models/userprofile';

export default class UserprofileApi extends LeWebApi {
  public getUserprofile = async (): Promise<Userprofile | undefined> => {
    return await this.getEntity<Userprofile>('/userprofiles');
  };

  public saveUserprofile = async (
    userprofile: Userprofile
  ): Promise<Userprofile | undefined> => {
    return await this.putEntity<Userprofile>('/userprofiles', userprofile);
  };
  public saveFavoriteWorkspaces = async (
    userprofile: Userprofile,
    favorites: string
  ): Promise<Userprofile | undefined> => {
    let parent = { ...userprofile };
    parent.FavoritedWorkspaces = favorites;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };
  public saveRecents = async (
    userprofile: Userprofile,
    recents: string
  ): Promise<Userprofile | undefined> => {
    let parent = { ...userprofile };
    parent.RecentWorkspaces = recents;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };
  public saveUserSelections = async (
    userprofile: Userprofile,
    userSelections: string
  ): Promise<Userprofile | undefined> => {
    let parent = { ...userprofile };
    parent.UserSelections = userSelections;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };
  public saveFavoriteDatasets = async (
    userprofile: Userprofile,
    favoriteDatasets: string
  ): Promise<Userprofile | undefined> => {
    let parent = { ...userprofile };
    parent.FavoriteDatasets = favoriteDatasets;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };

  public getCanAdmin = async (): Promise<boolean> => {
    return await this.getEntity<boolean>('/userprofiles/canadmin').then(
      (result) => result || false
    );
  };
  public saveSupportNotificationStatus = async (
    userprofile: Userprofile,
    supportNotificationStatus: string
  ): Promise<Userprofile | undefined> => {
    let parent = { ...userprofile };
    parent.SupportNotificationStatus = supportNotificationStatus;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };
}
