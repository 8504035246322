import React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import {
  CompactPeoplePicker,
  IBasePickerSuggestionsProps,
  ValidationState,
} from '@fluentui/react/lib/Pickers';
import { Workspace } from 'features/workspaces/models/workspace';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

interface PeoplePickerProps {
  people: string[];
  workspace: Workspace;
  updateParentState: (workspace: Workspace) => void;
  onChange: (value: any) => void;
  onBlur: () => void;
}
const PeoplePicker = (props: PeoplePickerProps): JSX.Element => {
  const people: any[] =
    props.people
      .filter((p) => p !== '')
      .map((p) => {
        return { text: p };
      }) || [];

  const mru = [
    // TODO:
    // change this(most rescently used) to the suggestions from the Azure graph
    // Add more details to the person based on the information returned
    {
      text: 'Annie Lindqvist',
    },
    {
      text: 'Banana Foster',
    },
    {
      text: 'Strawberry Whirl',
    },
    {
      text: 'Easter Bunny',
    },
  ];
  const [mostRecentlyUsed, setMostRecentlyUsed] =
    React.useState<IPersonaProps[]>(mru);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>(people);

  const picker = React.useRef(null);

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    // TODO: call the api hook here to get the suggestions
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(
        filteredPersonas,
        currentPersonas || []
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    // TODO: The suggestions that shows up. This will be replace with the results
    // from azure graph api
    //return peopleList.filter((item) =>
    return mru.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return convertResultsToPromise(personasToReturn);
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return filterPromise(
      removeDuplicates(mostRecentlyUsed, currentPersonas || [])
    );
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }

    //TODO: check if the suggestion added is either a group or a person
    // then update the workspace state accordingly.
    // props.updateParentState({
    //   ...props.workspace,
    // });
  };

  return (
    <CompactPeoplePicker
      onResolveSuggestions={onFilterChanged} //help suggest people based on input
      onEmptyInputFocus={returnMostRecentlyUsed}
      getTextFromItem={getTextFromItem}
      className={'ms-PeoplePicker'}
      aria-label={'Selected contacts'}
      defaultSelectedItems={peopleList}
      removeButtonAriaLabel={'Remove'}
      pickerSuggestionsProps={suggestionProps}
      onRemoveSuggestion={onRemoveSuggestion}
      onValidateInput={validateInput}
      inputProps={{
        onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
          props.onBlur();
        },
        'aria-label': 'People Picker', //TODO: change the aria label depending on the type of people picker
      }}
      componentRef={picker}
      resolveDelay={100}
    />
  );
};

function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function convertResultsToPromise(
  results: IPersonaProps[]
): Promise<IPersonaProps[]> {
  return new Promise<IPersonaProps[]>((resolve, reject) =>
    setTimeout(() => resolve(results), 2000)
  );
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

export default PeoplePicker;
