import { _ } from 'utils/sharedLibs';
import jsonpath from 'jsonpath';
import notifier from 'utils/notifier';
import BaseKustoClient from './baseKustoClient';
import kustoQueryHelper from '../utils/kustoQueryHelper';
import kustoClientUtils from '../utils/kustoClientUtils';
import dataSourceClientUtils from '../../client/dataSourceClientUtils';
import utils from 'utils/commonUtilities';
import { $laConstants } from 'utils/constants';
import {
  FunctionNode,
  InputParameter,
  SchemaTree,
  TableNode,
  TreeNode,
} from '../../models/schemaTree';
import { AxiosRequestConfig } from 'axios';
import KustoClientApi from '../api/kustoClientApi';
import timefilter, { Timefilter } from 'utils/timefilter';
import filterPlacementService from 'utils/filterPlacementService';
import {
  DataSourceClientResponse,
  DataSourceQueryObject,
  FetchParams,
} from 'features/dataSources/models/dataSourceClient';
import {
  Column,
  Database,
  DataSource,
} from 'features/dataSources/models/dataSource';

const myDataSourceTypeName = $laConstants.DataSourceTypes.KUSTO;

var clusterLevelQueryPrefixes = ['.show databases', '.show schema'];

/**
 * @name SchemaNodeTypes
 * @description
 * Represents schema node types.
 */
export enum SchemaNodeTypes {
  FIELD = 'field',
  FOLDER = 'folder',
  FUNCTION = 'function',
  TABLE = 'table',
}

function createQuery(
  cluster: string,
  database: string | null,
  text: string,
  name?: string
): DataSourceQueryObject {
  return {
    Type: myDataSourceTypeName,
    Cluster: cluster,
    Database: isClusterLevelQuery(text) ? '' : database || '',
    Query: text,
    QueryName: name,
  };
}

function isClusterLevelQuery(query: string) {
  var index = 0;
  for (; index < clusterLevelQueryPrefixes.length; index++) {
    if (_.startsWith(query, clusterLevelQueryPrefixes[index])) {
      return true;
    }
  }

  return false;
}

export function flattenSchemaTree(schema: any, type: SchemaNodeTypes) {
  var result: any[] = [];
  if (!_.isArray(schema)) {
    return result;
  }

  _.each(schema, function (node) {
    if (!node) {
      return;
    } else if (node.type === type) {
      result.push(node);
    } else if (node.type === SchemaNodeTypes.FOLDER) {
      var nodes = flattenSchemaTree(node.children, type);
      result = _.concat(result, nodes);
    }
  });

  return result;
}

interface ResponseFunction {
  Name: string;
  InputParameters: InputParameter[];
  Body: string;
  DocString: string;
  Folder: string;
}
interface ResponseColumn {
  Name: string;
  Type: string;
}

interface ResponseTable {
  Name: string;
  Folder: string;
  OrderedColumns: ResponseColumn[];
}

function transformSchemaResponseToSchemaTree(
  response: object,
  database: string
): SchemaTree {
  var schemaJson = _.get(response, 'Response.Tables[0].Rows[0]');
  if (_.isEmpty(schemaJson)) {
    return [];
  }

  var schema = JSON.parse(schemaJson);

  // Convert the functions to schema tree nodes.  The schema tree will be cached, so minimal properties should be set here.
  // monaco-kusto: Some properties are used for Kusto IntelliSense.
  var functionNodes: FunctionNode[] = [];
  var functions: ResponseFunction[] = _.get(
    schema,
    'Databases.' + database + '.Functions'
  );
  _.each(functions, function (func) {
    if (!_.isObject(func) || !func.Name) {
      return;
    }

    var node = {
      name: func.Name,
      type: $laConstants.SchemaTypes.Function,
      Name: func.Name, // original name
      InputParameters: func.InputParameters,
      Body: func.Body,
    } as FunctionNode;

    if (func.DocString) {
      node.DocString = func.DocString;
    }

    if (func.Folder) {
      node.folder = func.Folder;
    }

    var inputParameters = _.map(
      node.InputParameters,
      function (inputParameter) {
        if (inputParameter.Columns) {
          if (inputParameter.Columns.length === 0) {
            return inputParameter.Name + ':(*)';
          } else {
            return (
              inputParameter.Name +
              ':(' +
              _.join(
                _.map(inputParameter.Columns, function (column) {
                  return column.Name + ':' + column.CslType;
                }),
                ', '
              ) +
              ')'
            );
          }
        }
        return inputParameter.Name + ':' + inputParameter.CslType;
      }
    );
    node.name += '(' + _.join(inputParameters, ', ') + ')';

    functionNodes.push(node);
  });

  // Convert the function nodes to folders, and add them to the Functions folder.
  var functionsFolder = null;
  if (!_.isEmpty(functionNodes)) {
    functionsFolder = {
      name: 'Functions',
      type: $laConstants.SchemaTypes.Folder,
      children: [],
    };
    kustoClientUtils.convertFlatTreeToSchemaTree(
      functionNodes,
      functionsFolder
    );
  }

  // Convert the tables and columns to schema tree nodes.  The schema tree will be cached, so minimal properties should be set here.
  var tableNodes: TableNode[] = [];
  var tables: ResponseTable[] = _.get(
    schema,
    'Databases.' + database + '.Tables'
  );
  _.each(tables, function (table) {
    if (!_.isObject(table) || !_.isString(table.Name)) {
      return;
    }

    // Skip LensTemp and Lens_Temp tables.
    var nameLower = _.toLower(table.Name);
    if (
      _.startsWith(nameLower, 'lenstemp') ||
      _.startsWith(nameLower, 'lens_temp')
    ) {
      return;
    }

    var node = {
      name: table.Name,
      type: $laConstants.SchemaTypes.Table,
    } as TableNode;

    if (table.Folder) {
      node.folder = table.Folder;
    }

    if (_.isArray(table.OrderedColumns)) {
      node.children = _.map(table.OrderedColumns, function (column) {
        return {
          name: column.Name,
          type: column.Type && column.Type.replace(/^System\./i, ''), // remove "System." prefix
          Type: column.Type, // The full Kusto type, for example System.String.
        };
      });
    }

    tableNodes.push(node);
  });

  // Separate the table nodes into folders (under a Tables folder) and a flat array (root.children).
  var root = { children: [] };
  var tablesFolder = {
    name: 'Tables',
    type: $laConstants.SchemaTypes.Folder,
    children: [],
  };
  kustoClientUtils.convertFlatTreeToSchemaTree(tableNodes, root, tablesFolder);

  // Combine the folders and flat array into a schema tree.
  var tree: TreeNode[] = [];
  if (functionsFolder) {
    tree = _.concat(tree, functionsFolder);
  }
  if (!_.isEmpty(tablesFolder.children)) {
    tree = _.concat(tree, tablesFolder);
  }
  tree = _.concat(tree, root.children);

  // Sort the schema tree.
  return dataSourceClientUtils.sortSchemaTree(tree);
}

function transformQueryError(err: any) {
  if (!err) return;

  // Authentication error while trying to acquire an access token
  if (
    err.status === 401 &&
    err.statusText === $laConstants.SessionIsExpiredMessage
  ) {
    return;
  }

  // Access denied error
  if (err.status === 403) {
    err.statusText = $laConstants.AccessDenied;
    var clusterUrl = err.config.url;
    var statusDetails = '';
    if (err.data && err.data.error && err.data.error['@message']) {
      statusDetails = err.data.error['@message'];
    }
    err.statusDetails =
      statusDetails +
      ' of cluster: ' +
      clusterUrl.substring(clusterUrl.indexOf('//'), clusterUrl.indexOf('/v1'));
    return;
  }

  // Request throttling error
  if (err.status === 429) {
    err.statusText = $laConstants.ThrottlingErrorMessage;
    err.statusDetails = err.data.message;
    return;
  }

  // Connection error
  if (err.status === -1 || err.status === 502) {
    err.statusText = err.statusText || $laConstants.ConnectionErrorMessage;
    err.statusDetails =
      $laConstants.QueryErrorMessage +
      '\n\n' +
      $laConstants.ConnectionErrorMessageDetails;
    return;
  }

  // Kusto error
  if (err.status === 400 && err.data) {
    // Kusto error with message
    if (err.data.error && err.data.error['@message']) {
      statusDetails = err.data.error['@message'];

      // Syntax or semantic error
      if (
        0 ===
          statusDetails.indexOf($laConstants.ErrorMessagePrefix.SyntaxError) ||
        0 ===
          statusDetails.indexOf($laConstants.ErrorMessagePrefix.SemanticError)
      ) {
        err.statusText = $laConstants.SyntaxErrorMessage;
        err.statusDetails = statusDetails;
        err.level = utils.queryErrorLevel.Warning;
        return;
      }

      // Other Kusto error message
      err.statusText = err.data.error.message;
      err.statusDetails = statusDetails;
      return;
    }

    // Other Kusto error
    err.statusText = err.statusText || $laConstants.QueryErrorMessage;
    err.statusDetails = _.isArray(err.data)
      ? _.uniq(err.data).join('\n')
      : err.data;
    return;
  }

  // Default error message
  err.statusText = err.statusText || $laConstants.QueryErrorMessage;
}

function convertProxyUrl(cluster: string) {
  var result = null;
  var isKustoProxy = false;
  // var proxyAddress = '';

  if (!cluster) {
    return result;
  }

  isKustoProxy = cluster.indexOf($laConstants.KustoProxyUrl) > -1;

  result = isKustoProxy ? window.location.host + cluster : cluster;

  return result;
}

interface TableInfo {
  Type: string;
  Table: string;
  Database: string;
  Cluster: string;
}

function getExtentTables(cluster: string, queryPlan: any) {
  var extents = jsonpath.query(queryPlan, '$..ExtentAccess');
  var tables: TableInfo[] = [];
  _.each(extents, function (extent) {
    if (extent.TableName && extent.DatabaseName) {
      var entry = {
        Type: myDataSourceTypeName,
        Table: extent.TableName,
        Database: extent.DatabaseName,
        Cluster: cluster,
      };
      tables.push(entry);
    }
  });
  return tables;
}

function resolveClusterName(cluster: string) {
  if (_.startsWith(cluster, 'https://')) {
    cluster = _.trimStart(cluster, 'https://');
  }
  if (_.endsWith(cluster, '/')) {
    cluster = _.trimEnd(cluster, '/');
  }
  return cluster;
}

/**
 * @name KustoClient
 * @description
 * Represents the Kusto data source client.
 */
export class KustoClient extends BaseKustoClient {
  processQueryPlanResponse(
    cluster: string,
    database: string,
    query: string,
    tableList: TableInfo[]
  ): Promise<TableInfo[] | undefined> {
    cluster = resolveClusterName(cluster);
    var queryObject = createQuery(
      cluster,
      database,
      '.show queryplan <|' + query
    );
    var self = this;
    return this.getQueryData(queryObject).then(function (resp) {
      if (
        resp &&
        resp.Response &&
        resp.Response.Tables[0] &&
        resp.Response.Tables[0].Rows[2]
      ) {
        var queryPlan = JSON.parse(resp.Response.Tables[0].Rows[2][2]);
        var localTables = getExtentTables(cluster, queryPlan); // tables that are in the scope of the current cluster.
        if (localTables && localTables.length > 0) {
          tableList = _.concat(tableList, localTables);
        }
        if (queryPlan.IsCrossCluster) {
          var targetScope = jsonpath.query(queryPlan, '$..TargetScope')[0];
          var queryString = jsonpath.query(queryPlan, '$..QueryString')[0];
          return self.processQueryPlanResponse(
            targetScope.Cluster,
            targetScope.Database,
            queryString,
            tableList
          );
        }
        return Promise.all(tableList);
      }
    });
  }
  public get displayName() {
    return 'Azure Data Explorer (Kusto)';
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.KUSTO;
  }
  /**
   * @name validateDataSource
   * @description
   * Validates the data source.
   * @param {Object} dataSource The data source.
   * @returns {Promise} A promise returning the data source.
   */
  public validateDataSource(dataSource: DataSource) {
    var query = createQuery(
      dataSource.Cluster,
      null,
      '.show databases | take 0'
    );
    return this.getQueryData(query).then(function () {
      return dataSource;
    });
  }

  /**
   * @name getDatabases
   * @description
   * Gets a cluster's databases.
   * @param {String} cluster The cluster.
   * @returns {Promise} The databases.
   */
  public getDatabases(cluster: string): Promise<Database[]> {
    cluster = resolveClusterName(cluster);
    // Don't use project in below query. Aria proxy (kusto.aria.microsoft.com) doesn't allow it.
    var showDatabaseQuery =
      '.show databases' +
      (cluster.indexOf($laConstants.AriaProxyURL) === -1
        ? ' | project DatabaseName, PrettyName'
        : '');
    var query = createQuery(cluster, null, showDatabaseQuery);
    return this.getQueryData(query).then(function (response) {
      var databaseNameIndex = _.findIndex(
        _.get(response, 'Response.Tables[0].Columns'),
        function (column: Column) {
          return column.ColumnName === 'DatabaseName';
        }
      );

      var prettyNameIndex = _.findIndex(
        _.get(response, 'Response.Tables[0].Columns'),
        function (column: Column) {
          return column.ColumnName === 'PrettyName';
        }
      );

      return _.map(response.Response.Tables[0].Rows, function (row) {
        return {
          DatabaseName: row[databaseNameIndex],
          PrettyName: row[prettyNameIndex],
        };
      });
    });
  }

  /**
   * @name getSchema
   * @description
   * Gets a Kusto database schema.
   * @param {String} cluster The cluster.
   * @param {String} database The database.
   * @returns {Promise} The schema tree.
   */
  public getSchema(
    cluster: string,
    database: string
  ): Promise<SchemaTree | null> {
    cluster = resolveClusterName(cluster);
    // Kusto only has schemas at the database level.
    if (!database) return Promise.resolve(null);

    // Get the database schema.  Performance: show database schema as json is more efficient than other show schema commands.
    var schemaText = '.show database [' + database + '] schema as json';
    var schemaQuery = createQuery(
      cluster,
      database,
      schemaText,
      $laConstants.Queries.BuildSchemaOnCluster
    );
    return this.getQueryData(schemaQuery).then(function (response) {
      // Convert the response to a schema tree.
      return transformSchemaResponseToSchemaTree(response, database);
    });
  }

  /**
   * @name getTables
   * @description
   * Gets database tables.
   * @param {String} cluster The cluster.
   * @param {String} database The database.
   * @returns {Promise} The schema tree.
   */
  public getTables(
    cluster: string,
    database: string
  ): Promise<SchemaTree | null> {
    return this.getSchema(cluster, database).then(function (schema) {
      return flattenSchemaTree(schema, SchemaNodeTypes.TABLE);
    });
  }

  private initResponse(query: DataSourceQueryObject): DataSourceClientResponse {
    return {
      Request: query,
      IsSuccess: true,
      Response: { Tables: [] },
      RequestTime: 0,
      QueryTime: 0,
    };
  }

  /**
   * @name getQueryData
   * @description
   * Executes a query.
   * @param {Object} query The query object.
   * @param {Object} requestConfig The config for the http request, this is honored if passed in.
   * @returns {Promise} The query response.
   */
  public async getQueryData(
    query: DataSourceQueryObject,
    requestConfig?: AxiosRequestConfig
  ): Promise<DataSourceClientResponse> {
    // Handle case where the user refresh the page and the query string is empty
    if (query.Query === '') {
      return Promise.resolve(this.initResponse(query));
    }

    var startTime = new Date().getTime();

    // requestId to be used for telemetry
    query.requestId = query.requestId || utils.newGuid();

    var url = this.getEndpointUrl(query.Cluster, query.Database, query.Query);

    let kustoApi = new KustoClientApi(url);

    var body = {
      db: isClusterLevelQuery(query.Query) ? '' : query.Database,
      csl: query.Query,
    };

    // If the requestConfig isn't passed in, then initialie it, otherwise use it.
    var config =
      requestConfig !== undefined
        ? requestConfig
        : dataSourceClientUtils.createAxiosConfig(query);

    var sendTime = new Date().getTime();

    try {
      let data = await kustoApi.postQuery(body, config);
      // serialize any dynamic columns
      if (data && data.Tables && data.Tables[0]) {
        this.serializeDynamicColumns(data.Tables[0]);
      }

      // Start: Mimic the response returned by courier.getData
      var hasExceptionInData = false;

      let response: DataSourceClientResponse = {
        ...this.initResponse(query),
        Response: data || { Tables: [] },
      };

      // Check if there is any exception buried inside data rows, if so, remove them.
      if (
        response.Response &&
        response.Response.Exceptions &&
        response.Response.Tables[0] &&
        response.Response.Tables[0].Rows &&
        response.Response.Tables[0].Rows.length > 0
      ) {
        hasExceptionInData = utils.removeExceptionFromRows(
          response.Response.Tables[0].Rows
        );
        if (
          hasExceptionInData &&
          response.Response.Exceptions[0].indexOf(
            $laConstants.LensLimitError
          ) === -1
        ) {
          // workaround of IE
          notifier.warning(
            'Query exception encountered. Partial query results are discarded. Exception: ' +
              response.Response.Exceptions[0]
          );
        }
      }

      // End: Mimic the response returned by courier.getData

      dataSourceClientUtils.trackTelemetry(
        query,
        url,
        startTime,
        sendTime,
        response,
        true
      );

      // SqlDecimal type must be changed from String to decimal
      response = dataSourceClientUtils.convertSqlDecimal(response);

      return response;
    } catch (err) {
      if (err === $laConstants.OperationCanceledMessage) {
        // $log.debug('kusto_client: query canceled');
        return Promise.reject(err);
      }

      transformQueryError(err);

      dataSourceClientUtils.trackTelemetry(
        query,
        url,
        startTime,
        sendTime,
        err,
        false
      );

      return Promise.reject(err);
    }
  }

  /**
   * @name getDataSourceAliasFromCluster
   * @description Compute the alias for the DataSource from the given cluster name.  Extracted from url hostname e.g. <alias>.kusto.windows.net
   * @param {String} cluster The cluster.
   * @returns {string} The alias for the cluster
   */
  public getDataSourceAliasFromCluster(cluster: string): string | undefined {
    var hostname = cluster.replace('https://', '').replace('http://', '');
    return _.first(_.split(hostname, '.'));
  }

  /**
   * @name constructQuery
   * @description Translate a base query plus aggregations and filters to a query expressions
   * @param {Object} timefilter The timefilter
   * @param {Object} fetchParams the fetchParams
   * @returns {string} Query The query representing the passed in timefilter and fetchparams
   */
  public constructQuery(timefilter: Timefilter, fetchParams: FetchParams) {
    // Note: timefilter not currently used as passed in.  filterPlacementService retrieves it later on its own.
    return kustoClientUtils.constructQuery(
      myDataSourceTypeName,
      timefilter,
      fetchParams
    );
  }

  public createQuery(
    timefilter: Timefilter,
    savedVis: any,
    searchSource: any,
    isQuickEditMode: boolean,
    querySelectionText: string
  ) {
    return kustoClientUtils.createQuery(
      myDataSourceTypeName,
      timefilter,
      savedVis,
      searchSource,
      isQuickEditMode,
      querySelectionText
    );
  }

  public createQueryForSavedVis(
    timefilter: Timefilter,
    savedVis: any,
    searchSource: any,
    isDashboard: boolean,
    querySelectionText: string
  ) {
    return kustoClientUtils.createQueryForSavedVis(
      myDataSourceTypeName,
      timefilter,
      savedVis,
      searchSource,
      isDashboard,
      querySelectionText
    );
  }

  public getFieldsFromQuery(cluster: string, database: string, query: string) {
    return kustoClientUtils.getFieldsFromQuery(
      myDataSourceTypeName,
      cluster,
      database,
      query,
      KustoClient.prototype.getQueryData
    );
  }

  /**
   * @name getFieldValues
   * @description Gets top n values of field.
   * @param {Object} queryObject Kusto query object.
   * @returns {array} Top n values of field.
   */
  public getFieldValues(queryObject: DataSourceQueryObject) {
    return kustoClientUtils.getFieldValues(
      queryObject,
      KustoClient.prototype.getQueryData
    );
  }

  public checkTopNestedRequired(aggregationMap: any, bucketItems: any) {
    return kustoClientUtils.checkTopNestedRequired(aggregationMap, bucketItems);
  }

  public getTopNestedResults(vis: any, aggregationMap: any, queryInfo: any) {
    return kustoClientUtils.getTopNestedResults(
      vis,
      aggregationMap,
      queryInfo,
      KustoClient.prototype.getQueryData
    );
  }

  public getEndpointUrl(cluster: string, database: string, query: string) {
    // Send queries directly to Kusto.  Use management API if query starts with dot but not ".show" commands.
    // Consider .show through management - it was added to query for PowerBI, not us.
    var isManagementQuery =
      _.startsWith(query, '.') && !_.startsWith(query, '.show');

    // Convert Lens Kusto proxy URL to the real URL
    cluster = convertProxyUrl(cluster)!;

    var url =
      'https://' +
      cluster +
      (isManagementQuery ? '/v1/rest/mgmt' : '/v1/rest/query');
    return url;
  }

  public getTableInfoFromQuery(
    cluster: string,
    database: string,
    query: string
  ) {
    return this.processQueryPlanResponse(cluster, database, query, []);
  }

  public constructFinalQuery(savedItem: any) {
    // construct the final query with respect to the current state in the explore tab
    var datasourceType = savedItem.searchSource.get(
      $laConstants.SearchSource.DataSourceType
    );
    var cluster = savedItem.searchSource.get($laConstants.SearchSource.Cluster);
    var database = savedItem.searchSource.get(
      $laConstants.SearchSource.Database
    );

    var timeBounds = null;
    var hasTimeValue = timefilter.hasTimeValue();

    if (hasTimeValue) {
      timeBounds = timefilter.getBounds();
    }
    var query = savedItem.searchSource.get('query');
    var filters = _.cloneDeep(savedItem.searchSource.getOwn('filter') || []);

    // Ensure that filter.query is set
    _.each(filters, function (filter) {
      if (filter) {
        kustoQueryHelper.setFilterQueryInFilter(filter);
      }
    });

    if (query) {
      query = filterPlacementService.processIndividualFilterPlacement(
        query,
        filters
      );
      query = filterPlacementService.processTimeFilter(
        query,
        timeBounds,
        null,
        false
      );
      query = filterPlacementService.removeUnnecessaryCharacters(query);
    }

    return {
      Type: datasourceType,
      Cluster: cluster,
      Database: database,
      Query: query,
    };
  }

  public queryLimitStatementAsPrefix(count: number) {
    return kustoQueryHelper.queryLimitStatementAsPrefix(count);
  }

  /**
   * @name describe
   * @description Return a string description of the Kusto data source
   * @param {Object} cluster Cluster object
   * @param {string} database Database
   * @returns {string} a string representation of the data source object
   */
  public describe(cluster: string, database: string) {
    var result = myDataSourceTypeName;
    var kustoCluster = cluster;

    if (typeof cluster === 'object') {
      kustoCluster = _.get(cluster, 'Alias') || 'UnknownCluster';
    }

    var kustoDatabase = database || 'UnknownDatabase';

    if (
      kustoCluster
        .toLowerCase()
        .indexOf($laConstants.SampleClusterProxyUrl.toLowerCase()) === 0
    ) {
      kustoCluster = 'SampleCluster';
    }

    result = result + ' | ' + kustoCluster + ' | ' + kustoDatabase;
    return result;
  }

  public getWorkspaceDataSourceContainer(dataSource: DataSource) {
    return dataSource && dataSource.Cluster;
  }

  public getWorkspaceDataSourceTitle(dataSource: DataSource) {
    return dataSource && dataSource.Database;
  }
}

export const kustoClient = new KustoClient();

export default kustoClient;
