import React from 'react';

import { Stack, Label } from '@fluentui/react';
import { IButtonStyles } from 'office-ui-fabric-react';
import { HintText, HintTextProps } from './hintText';
import { ElxActionButton } from '@elixir/fx';

export interface LensButtonProps extends HintTextProps {
  buttonText: string;
  required: boolean;
  onClick?: () => void;
}

/**
 * Renders Lens Button with hint icon
 */
export const LensButton = (props: LensButtonProps): JSX.Element => {
  const { buttonText, hintText, required, onClick } = props;

  return (
    <Stack horizontal>
      {required && <Label styles={requiredLabelStyles}>*</Label>}
      <ElxActionButton
        styles={buttonStyles}
        text={buttonText}
        onClick={onClick}
      >
        {' '}
      </ElxActionButton>
      <HintText hintText={hintText} />
    </Stack>
  );
};

const buttonStyles: IButtonStyles = {
  label: {
    fontSize: '14px',
    fontWeight: '600',
  },
};

const requiredLabelStyles: IButtonStyles = {
  label: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(168, 0, 0)',
    margin: '0px -5px',
  },
};
