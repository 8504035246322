export enum JobExecutionStatus {
  CREATED = 'created',
  MANUAL = 'manual',
  QUEUED = 'queued',
  RUNNING = 'running',
  RETRY = 'retry',
}

export enum JobInstanceStatus {
  SUCCESS = 'Succeeded',
  FAILED = 'Failed',
  CANCELLED = 'Canceled',
  TERMINATED = 'Terminated',
  NOTRUNNING = 'NotRunning',
  RUNNING = 'Running',
  QUEUED = 'Queued',
}

export interface RetryPolicy {
  type: string;
}
export interface JobScheduleSettings {
  scheduleType: string;
}

export interface Parameters {}

export interface Activity {
  retryPolicy: {
    type: string;
  };
  activityDisplayName: string;
  activityType: string;
  parameters: [];
  id: string;
  transformEngine: string;
  input: {
    type: string;
    connectionName: string;
    query: string;
  };
  output: {
    type: string;
    connectionName: string;
    aadClientId: string;
    cluster: string;
    vcName: string;
    folder: string;
    tableType: string;
  };
}

export interface JobContext {
  uiCron?: {
    freq: string;
    rate: number;
    value: string;
    isEntered: boolean;
    hourMins: number;
    minuteSecs: number;
    time: string;
    weekDays: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    monthDay: number;
  };
}
export interface JobConfig {
  jobName: string;
  jobDisplayName: string;
  jobDescription: string;
  tags: string;
  maxJobInstances: number;
  retryPolicy: RetryPolicy;
  isDebug: boolean;
  appendUtcIndicator: boolean;
  allocationType: string;
  jobScheduleSettings: JobScheduleSettings;
  parameters: Parameters[];
  defaultICMAlerts: boolean;
  activities?: Activity[];
  project: string;
  context?: JobContext;
}
export interface JobInstance {
  id: string;
  jobId: string;
  workspace: string;
  createdBy: string;
  status: string;
  jobStatus: string;
  errorMsg: string;
  createdDate: string;
  startTime: string;
  endTime: string;
  elapsedTime: string;
  seed: string;
  logLink: string;
  elapsedMilliseconds: number;
  parameters: {};
  segments: [{}];
  groupId: string;
}

export interface Job {
  id: string;
  name: string;
  workspace: string;
  description: string;
  tags: string;
  config?: JobConfig;
  createdBy: string;
  createdDate: string;
  nextExecutionDate: string;
  modifiedBy: string;
  modifiedDate: string;
  version: string;
  isEnabled: true;
  isQuarantined: true;
  instances?: JobInstance[];
  shareType: string;
  partitionKey: string;
  rowKey: string;
  timestamp: string;
  eTag: string;
}
