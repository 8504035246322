import { store } from 'app/lensShellUtility';
import { uiNotifier as reactNotifier } from 'features/notifications/notificationsSlice';
interface NotifierErrorConfig {
  noConsole: boolean;
  noNotification: boolean;
  noTelemetry: boolean;
  noToaster: boolean;
}

class Notifier {
  private leApp: any; // AngularJS dependency - minimize use

  public error(error: string | object | Error, config?: NotifierErrorConfig) {
    this.leApp?.error(
      error,
      null,
      config?.noToaster,
      config?.noNotification,
      config?.noConsole,
      config?.noTelemetry
    );
    this.logIntoRedux(error, 'error');
  }

  public warning(message: string) {
    this.logIntoRedux(message, 'warning');
    this.leApp?.warning(message);
  }
  public logIntoRedux(message: any, type: string) {
    setTimeout(function () {
      try {
        switch (type) {
          case 'error':
            store.dispatch(reactNotifier.error(message));
            break;
          case 'warning':
            store.dispatch(reactNotifier.warning(message));
            break;
          case 'info':
            store.dispatch(reactNotifier.info(message));
            break;
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  public info(message: string) {
    this.logIntoRedux(message, 'info');
    this.leApp?.info(message);
  }

  public inject(leApp: any) {
    this.leApp = leApp;
  }
}

export const notifier = new Notifier();

export default notifier;
