import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  DataQualityStatus,
  selectDataQualityLatestRuleResultEntities,
  selectDataQualityLatestRuleResultsStatus,
  selectDataQualityDatasetsExpectationsStatus,
  selectDataQualityExpectationEntities,
} from 'features/dataQuality/dataQualitySlice';

export interface LastExecutedProps {
  datasetId: string;
}

/**
 * The relative date of the most recent rule execution for this User Expectation
 * @param props
 * @returns
 */
export const LastExecuted = (props: LastExecutedProps): JSX.Element => {
  const { datasetId } = props;

  // this Element relies on RulePassRate to "useDatasetsLatestRuleResults" to start loading the expectations for us

  const datasetsExpectationsStatus = useSelector(
    selectDataQualityDatasetsExpectationsStatus
  );
  const expectationsStatus = datasetsExpectationsStatus[datasetId];
  const expectationEntities = useSelector(selectDataQualityExpectationEntities);

  const latestRuleResultEntities = useSelector(
    selectDataQualityLatestRuleResultEntities
  );
  const latestRuleResultsStatus = useSelector(
    selectDataQualityLatestRuleResultsStatus
  );

  if (expectationsStatus === DataQualityStatus.Loaded) {
    const ruleIds: string[] =
      Object.keys(expectationEntities)
        .filter((k) => expectationEntities[k]!.rule.datasetId === datasetId)
        .map((k) => expectationEntities[k]!.rule.id) || [];

    const executionDates = ruleIds
      .map((ruleId) => {
        const ruleResultStatus = latestRuleResultsStatus[ruleId];
        if (ruleResultStatus === DataQualityStatus.Loaded) {
          const ruleResults = latestRuleResultEntities[ruleId];
          return ruleResults?.results.map(
            (result) => result.ruleExecutionStartTime
          );
        }
        return [];
      })
      .flat(1)
      .filter((d) => !!d)
      .sort() as number[];

    if (executionDates.length > 0) {
      const executionDatesSorted = executionDates.sort();
      const dateMillis = executionDatesSorted[executionDatesSorted.length - 1];
      return <>{DateTime.fromMillis(dateMillis).toRelative()}</>;
    }
  }

  return <></>;
};
