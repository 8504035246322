import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { History } from 'history';

import { ResponsiveMode, useResponsiveContext } from '@elixir/fx';
import { ElxSidebar } from '@elixir/fx';
import { IElxSidebarItemProps, SidebarDisplayTypes } from '@elixir/fx';
import { iconProps, LensRoute } from 'app/elxAdapter';
import { matchWithParams } from '@elixir/fx';
import { LensSidebarProps } from './lensSidebar.types';
import {
  elixirSelectors,
  uxSetSidebarDefaultCollapsedAction,
} from '@elixir/fx';

import { useRouter } from '@uirouter/react';
import { UIRouter } from '@uirouter/core';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel,
  PanelState,
  panelDefaults,
} from 'components/panelManager/panelManager';
import WorkspaceQuickSwitch from 'features/workspaces/components/workspaceQuickSwitch';
import workspace from 'app/images/workspace.svg';
import {
  selectWorkspace,
  selectWorkspaceStatus,
  useWorkspace,
} from 'features/workspaces/workspaceSlice';
import {
  Workspace,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import { mergeStyleSets } from '@fluentui/react';
import { useDataQuality } from 'features/dataQuality/dataQualitySlice';

export enum SidePanel {
  WORKSPACESELECTOR = 'WorkspaceSelector',
}

const getFooterRoutes = (): LensRoute[] => {
  const lensFooterRoute: LensRoute[] = [
    {
      index: 'Workspace Selector',
      name: 'Workspace Selector',
      inSidebar: true,
      iconProps: iconProps(workspace),
    },
  ];

  return lensFooterRoute;
};

export const LensSidebar = (props: LensSidebarProps): JSX.Element => {
  // Custom hooks for managing workspace-level redux state (which must be reset on workspace switch)
  useWorkspace();
  useDataQuality();

  const currentWorkspace = useSelector(selectWorkspace);
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  const footerRoutes = useMemo(() => getFooterRoutes(), []);
  const { routes, className, isDefaultCollapsed, styles } = props;
  const panelState = usePanelManagerState();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { responsiveMode } = useResponsiveContext();
  const allRoutes: LensRoute[] = useMemo(
    () => [...routes, ...footerRoutes],
    [footerRoutes, routes]
  );
  const sidebarItems = useMemo(() => getSidebarItems(routes), [routes]);
  const sidebarFooterItems = useMemo(
    () =>
      getSidebarFooterItems(
        footerRoutes,
        panelState,
        currentWorkspace,
        workspaceStatus
      ),
    [panelState, footerRoutes, currentWorkspace, workspaceStatus]
  );
  const collapsedState = useSelector(elixirSelectors.isSidebarDefaultCollapsed);
  const [isCollapsed, setIsCollapsed] = useState(
    () => isDefaultCollapsed || collapsedState
  );
  const [currentPath, setCurrentPath] = useState(() =>
    getCurrentPath(location.pathname, allRoutes)
  );
  const router = useRouter();

  useEffect(() => {
    setCurrentPath(getCurrentPath(location.pathname, allRoutes));
  }, [allRoutes, location.pathname]);

  useEffect(() => {
    if (collapsedState !== undefined) {
      setIsCollapsed(collapsedState);
    }
  }, [collapsedState]);

  let displayTypes = SidebarDisplayTypes.Regular;
  switch (responsiveMode) {
    case ResponsiveMode.Large:
    case ResponsiveMode.Medium:
      displayTypes =
        SidebarDisplayTypes.SingleExpanded | SidebarDisplayTypes.Overlay;
      break;
    case ResponsiveMode.Small:
      displayTypes = SidebarDisplayTypes.Thin;
      break;
    default:
      if (isCollapsed) {
        displayTypes = SidebarDisplayTypes.Collapsed;
      }
  }

  return (
    <>
      <ElxSidebar<LensRoute>
        className={className}
        items={sidebarItems}
        footerItems={sidebarFooterItems}
        selectedKey={currentPath}
        displayTypes={displayTypes}
        onItemSelect={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          item: IElxSidebarItemProps<LensRoute>
        ) => onNavigation(router, history, item.data)}
        onMainNavToggle={(isCollapsed) => {
          if (responsiveMode > ResponsiveMode.Large) {
            dispatch(uxSetSidebarDefaultCollapsedAction(isCollapsed));
          }
        }}
        styles={styles}
      />
      <PanelManager panelState={panelState}>
        <WorkspaceQuickSwitch
          panelId={SidePanel.WORKSPACESELECTOR}
          {...panelDefaults}
        />
      </PanelManager>
    </>
  );
};
function getCurrentPath(path: string, allRoutes: LensRoute[]) {
  const currentRoute = findCurrentRoute(path, allRoutes);
  return currentRoute && currentRoute.index;
}

function onNavigation(
  router: UIRouter,
  history: History,
  route?: LensRoute
): boolean {
  if (route && route.isRouteable !== false && (route.path || route.state)) {
    if (route.state) {
      router.stateService.go(route.state);
    } else if (route.path) {
      history.push(route.path);
    }
    return true;
  }

  if (route && route.isExternal && route.path) {
    window.open(route.path, '_blank');
  }

  return false;
}
//content tag
const footerStyles = mergeStyleSets({
  footer: {
    '.ms-Button-label': {
      fontSize: '12px',
      fontFamily: 'Segoe UI',
      lineHeight: '16px',
      color: '#323130',
      selectors: {
        '::before': {
          content: "'Workspace Selected:\\a'",
          whiteSpace: 'pre',
          fontFamily: 'Segoe UI',
          fontSize: '10px',
          color: '#605E5C',
          lineHeight: '12px',
        },
        '::after': {
          color: '#323130',
          marginRight: '10px',
          float: 'right',
          borderStyle: 'solid',
          borderWidth: '0.12em 0.12em 0 0',
          content: "' '",
          display: 'inline-block',
          height: '0.60em',
          width: '0.60em',
          transform: 'rotate(45deg)',
          position: 'relative',
        },
      },
    },
  },
});

function getSidebarItems(
  routes: LensRoute[]
): IElxSidebarItemProps<LensRoute>[] {
  return routes
    .filter((route: LensRoute) => route.inSidebar)
    .map((route: LensRoute) => ({
      key: route.index,
      text: route.name,
      iconProps: route.iconProps,
      data: route,
      items: route.subRoutes && getSidebarItems(route.subRoutes),
      onRenderNavPane: onRenderCustomPane(route),
    }));
}

function getSidebarFooterItems(
  routes: LensRoute[],
  panelState: PanelState,
  currentWorkspace: Workspace,
  workspaceStatus: WorkspaceStatus
): IElxSidebarItemProps<LensRoute>[] {
  let workspaceName = ' ';
  switch (workspaceStatus) {
    case WorkspaceStatus.Loaded:
      workspaceName =
        currentWorkspace.shareType === 'private'
          ? 'Private Workspace'
          : currentWorkspace.name;
      if (workspaceName.length > 24) {
        workspaceName = workspaceName.substring(0, 23);
      }
      break;
    case WorkspaceStatus.Error:
      workspaceName = 'Error';
      break;
    case WorkspaceStatus.Loading:
      workspaceName = 'Loading...';
      break;
    case WorkspaceStatus.None:
    default:
      workspaceName = ' ';
  }
  return routes
    .filter((route: LensRoute) => route.inSidebar)
    .map((route: LensRoute) => ({
      key: route.index,
      text: workspaceName,
      iconProps: route.iconProps,
      onClick: () => onShowPanel(panelState, SidePanel.WORKSPACESELECTOR, true),
      className: footerStyles.footer,
    }));
}

function onRenderCustomPane(
  route: LensRoute
): IRenderFunction<IElxSidebarItemProps> | undefined {
  if (route.customNavComponent) {
    return (props?: IElxSidebarItemProps) => {
      const CustomComponent = route.customNavComponent as ComponentType;
      return <CustomComponent />;
    };
  }
}

const findCurrentRoute = (
  path: string,
  routes: LensRoute[]
): LensRoute | undefined => {
  for (const route of routes) {
    if (route.isRouteable !== false && route.path) {
      let currPath = path,
        routePath = route.path;
      if (route.path.length && route.path.substring(0, 2) === '/#') {
        routePath = route.path.substring(2);
      }
      if (path.length && path.substring(0, 2) === '/#') {
        currPath = path.substring(2);
      }

      const matchValue = matchWithParams(currPath, routePath as string);

      if (matchValue) {
        return route;
      }
    }

    if (route.subRoutes) {
      let found;
      if ((found = findCurrentRoute(path, route.subRoutes))) {
        return found;
      }
    }
  }
};
