import { Client } from './client';

export enum ConditionType {
  None = 'None',
  Static = 'Static',
  Dynamic = 'Dynamic',
}

export enum ConditionUnit {
  None = 'None',
  Absolute = 'Absolute',
  Percentage = 'Percentage',
  TimeInMs = 'TimeInMs',
}

export enum ConditionOperator {
  None = 'None',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  Equals = 'Equals',
  NotEquals = 'NotEquals',
}

export interface Condition {
  subType: ConditionType;
  label: string;
}

export interface StaticCondition extends Condition {
  subType: ConditionType.Static;
  unit: ConditionUnit;
  operator: ConditionOperator;
  value: string;
}

export interface DynamicCondition extends Condition {
  subType: ConditionType.Dynamic;
  maxAnomalyRatio?: number | null;
  sensitivity?: number | null;
  numberOfDataPoints?: number | null;
}

export interface Expectation {
  id: string;
  conditions: Condition[];
  expression: string;
  tags?: string[] | null;
}

export enum SupportedActionType {
  None = 'None',
  Email = 'Email',
  IcM = 'IcM',
}

export interface ActionType {
  supportedActionType: SupportedActionType;
  id: string;
}

export interface EmailAddress {
  address: string;
}

export interface Email extends ActionType {
  supportedActionType: SupportedActionType.Email;
  to: EmailAddress[];
  cc?: EmailAddress[] | null;
}

export interface IcM extends ActionType {
  supportedActionType: SupportedActionType.IcM;
  incidentSeverity: number;
  serviceName: string;
}

export interface Action {
  id: string;
  expectationId: string;
  actionTypes: ActionType[];
  tags?: string[] | null;
}

export interface Alert {
  id: string;
  name: string;
  ruleId: string;
  ruleName: string;
  datasetId: string;
  workspaceId: string;
  workspaceDisplayName: string;
  expectations: Expectation[];
  actions: Action[];
  tags?: string[] | null;
  createdBy?: Client;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  isDeleted?: boolean | null;
  _etag?: string | null;
  // Other DQP properties that we don't need on UI
  [propName: string]: any;
}

export interface AlertRequest {
  id?: string;
  name: string;
  ruleId?: string; // we don't know this yet at rule building time
  ruleName: string;
  datasetId: string;
  workspaceId: string;
  workspaceDisplayName: string;
  expectations: Expectation[];
  actions: Action[];
  // Other DQP properties that we don't need on UI
  [propName: string]: any;
}

export default Alert;
