import React from 'react';
import { IChoiceGroupStyles, Stack } from '@fluentui/react';
import { Card } from 'components/cards/card';
import { EditorProps } from '../../base/userExpectation';
import {
  ClassifyResults,
  useClassifyResultsHelpers,
} from '../../../components/classifyResults/classifyResults';
import { PublishFrequencyState } from './publishFrequencyExpectation';
import { getTableName } from 'features/dataQuality/utils/dataQualityUtils';
import HighlightText from 'features/dataQuality/components/highlightText/highlightText';
import { HintText } from 'utils/hintText';
import { ElxChoiceGroup } from '@elixir/fx';
import StandardUnits from 'features/dataQuality/components/classifyResults/thresholdUnits';

const unitChoiceStyles = {
  flexContainer: {
    display: 'flex',
  },
  root: {
    marginLeft: 16,
    '.ms-ChoiceField': {
      marginRight: 16,
    },
  },
} as IChoiceGroupStyles;

const timeUnits = [
  StandardUnits.SECOND,
  StandardUnits.MINUTE,
  StandardUnits.HOUR,
  StandardUnits.DAY,
];

const unitOptions = timeUnits.map((unit) => ({
  key: unit.unitType,
  text: unit.unitDisplayNamePlural!,
}));

const PublishFrequencyEditor = ({
  dataset,
  connection,
  state,
  setState,
}: EditorProps<PublishFrequencyState>): JSX.Element => {
  const classifyHelpers = useClassifyResultsHelpers(
    state,
    setState,
    state?.unit || StandardUnits.MINUTE
  );
  const { tableName } = getTableName(dataset);

  return (
    <>
      <Card>
        <Stack tokens={{ childrenGap: 0 }}>
          <Stack.Item styles={{ root: { button: { marginTop: -1 } } }}>
            <HighlightText>{tableName + ' '}</HighlightText>must be updated
            every
            <HighlightText>{' few '}</HighlightText>
            <div style={{ display: 'inline-flex' }}>
              <HintText hintText="Use the threshold ranges below under Classify Results to define the meaning of 'few'." />
            </div>
          </Stack.Item>
          <ElxChoiceGroup
            styles={unitChoiceStyles}
            options={unitOptions}
            selectedKey={state?.unit?.unitType}
            onChange={(_, value) => {
              const units = timeUnits.filter((u) => u.unitType === value?.key);
              const unit = units.length > 0 ? units[0] : StandardUnits.MINUTE;
              setState({ ...state, unit, stateIsReady: true });
            }}
          />
        </Stack>
      </Card>
      <ClassifyResults {...classifyHelpers} />
    </>
  );
};

export default PublishFrequencyEditor;
