import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from 'app/lensShellUtility';
import { notifier } from 'utils/notifier';
import {
  Workspace,
  WorkspaceAccessFilter,
  WorkspaceBeforeFormat,
  WorkspaceSaveStatus,
  WorkspaceStatus,
} from './models/workspace';
import {
  formatWorkspaceForSave,
  formatWorkspaceForUse,
  getCurrentWorkspaceId,
  onWorkspaceSwitch,
} from './utils/workspaceUtils';
import { workspacesApi } from './api/workspacesApi';
import { DataConnectionRequest, Project } from './models/project';

export const useAppDispatch = () => useDispatch<AppDispatch>();

// keep track of the current requested workspace, because they can arrive out of order
let loadId = 0;
let editLoadId = 0;

/**
 * Custom hook to use the current workspace.
 * Should only be called once in the application e.g. by lensSidebar.
 * TODO: Should be changed to some sort of singleton.
 */
export const useWorkspace = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  useEffect(() => {
    function loadCompleteWorkspace() {
      dispatch(loadWorkspace());
      dispatch(loadProject());
    }

    if (workspaceStatus === WorkspaceStatus.None) {
      loadCompleteWorkspace();
    }

    // Reload on the workspace switch event.
    // Note: probably do not need to reload on the AngularJS workspace save event, assuming
    // a workspace is not edited in AngularJS and viewed in React within the same route.
    let deregisterWorkspaceSwitch = onWorkspaceSwitch(
      (event: any, workspace: any) => {
        dispatch(reset());
        loadCompleteWorkspace();
      }
    );

    return () => {
      // cleanup
      if (deregisterWorkspaceSwitch) {
        deregisterWorkspaceSwitch();
        deregisterWorkspaceSwitch = undefined;
      }
    };
  }, [dispatch, workspaceStatus]);
};

interface WorkspaceLoadState {
  loadId: number;
  workspace?: WorkspaceBeforeFormat;
}

export const loadWorkspace = createAsyncThunk(
  'workspace/loadWorkspace',
  async (): Promise<WorkspaceLoadState> => {
    var currentWorkspaceId = getCurrentWorkspaceId();
    loadId = loadId + 1;
    return {
      loadId,
      workspace: currentWorkspaceId
        ? await workspacesApi.getWorkspace(currentWorkspaceId) //change here
        : undefined,
    };
  }
);

export const loadEditWorkspace = createAsyncThunk(
  'workspace/loadEditWorkspace',
  async (workspaceId: string): Promise<WorkspaceLoadState> => {
    editLoadId = editLoadId + 1;
    return {
      loadId: editLoadId,
      workspace: await workspacesApi.getWorkspace(workspaceId), //change here
    };
  }
);

export const deleteWorkspace = createAsyncThunk(
  'workspace/deleteWorkspace',
  async (workspaceId: string) => {
    return await workspacesApi.deleteWorkspace(workspaceId);
  }
);
export const saveWorkspace = createAsyncThunk(
  'workspace/saveWorkspace',
  async (workspace: Workspace) => {
    // convert back to original format (put in utils)
    return await workspacesApi.saveWorkspace(
      workspace.id,
      formatWorkspaceForSave(workspace)
    );
  }
);

export const loadProject = createAsyncThunk(
  'workspace/loadProject',
  async () => {
    var currentWorkspaceId = getCurrentWorkspaceId();
    return currentWorkspaceId
      ? await workspacesApi.getProject(currentWorkspaceId)
      : undefined;
  }
);

export const enableOrchestration = createAsyncThunk(
  'workspace/enableOrchestration',
  async (workspaceId: string) => {
    return await workspacesApi.postEnableOrchestration(workspaceId);
  }
);

export const createDataConnection = createAsyncThunk(
  'dataQuality/createDataConnection',
  async ({
    workspaceId,
    connectionRequest,
  }: {
    workspaceId: string;
    connectionRequest: DataConnectionRequest;
  }) => {
    return await workspacesApi.postConnection(workspaceId, connectionRequest);
  }
);

export const saveDataConnection = createAsyncThunk(
  'dataQuality/createDataConnection',
  async ({
    workspaceId,
    connectionRequest,
  }: {
    workspaceId: string;
    connectionRequest: DataConnectionRequest;
  }) => {
    return await workspacesApi.putConnection(workspaceId, connectionRequest);
  }
);

interface WorkspaceState {
  current: Workspace;
  editWorkspace: Workspace;
  orchestratorProject: Project;
  status: WorkspaceStatus;
  editWorkspaceStatus: WorkspaceStatus;
  orchestratorProjectStatus: WorkspaceStatus;
  applicationsStatus: WorkspaceStatus;
  dataConnectionsStatus: WorkspaceStatus;
  dataConnectionSaveStatus: WorkspaceSaveStatus;
}

const workspaceInit: Workspace = {
  //  this may need to be updated and cleaned
  id: '',
  name: '',
  alias: '',
  dsoProject: '',
  applications: undefined,
  dataConnections: undefined,
  createdBy: '',
  accessFilter: WorkspaceAccessFilter.None,
  lastUpdated: '',
  description: '',
  tags: [],
  adminGroups: [],
  adminAliases: [],
  lastUpdatedBy: '',
  orchestrateGroups: [],
  orchestrateAliases: [],
  readOnlyGroups: [],
  readOnlyAliases: [],
  readWriteGroups: [],
  readWriteAliases: [],
  isReadOpen: false,
  shareType: '',
  dqsWorkspace: '',
};
const initialWorkspaceState: WorkspaceState = {
  current: { ...workspaceInit },
  editWorkspace: { ...workspaceInit },
  orchestratorProject: {
    projectName: '',
    projectDisplayName: '',
    authorizedAADClientIds: {},
    dataConnections: [],
  },
  status: WorkspaceStatus.None,
  editWorkspaceStatus: WorkspaceStatus.None,
  orchestratorProjectStatus: WorkspaceStatus.None,
  applicationsStatus: WorkspaceStatus.None,
  dataConnectionsStatus: WorkspaceStatus.None,
  dataConnectionSaveStatus: WorkspaceSaveStatus.None,
};

/**
 * Redux slice representing the current workspace.
 */
const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: initialWorkspaceState,
  reducers: {
    reset: (state) => initialWorkspaceState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkspace.pending, (state, action) => {
        state.status = WorkspaceStatus.Loading;
      })
      .addCase(loadWorkspace.fulfilled, (state, action) => {
        if (action.payload?.loadId === loadId) {
          state.status = WorkspaceStatus.Loaded;

          const workspaceFormatted = formatWorkspaceForUse(
            action.payload.workspace as WorkspaceBeforeFormat
          );
          state.current = {
            ...state.current,
            ...workspaceFormatted,
          };
        }
      })
      .addCase(loadWorkspace.rejected, (state, action) => {
        state.status = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(loadEditWorkspace.pending, (state, action) => {
        state.editWorkspaceStatus = WorkspaceStatus.Loading;
      })
      .addCase(loadEditWorkspace.fulfilled, (state, action) => {
        if (action.payload?.loadId === editLoadId) {
          state.editWorkspaceStatus = WorkspaceStatus.Loaded;

          const workspaceFormatted = formatWorkspaceForUse(
            action.payload.workspace as WorkspaceBeforeFormat
          );
          state.editWorkspace = {
            ...state.editWorkspace,
            ...workspaceFormatted,
          };
        }
      })
      .addCase(loadEditWorkspace.rejected, (state, action) => {
        state.editWorkspaceStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(loadProject.pending, (state, action) => {
        state.applicationsStatus = WorkspaceStatus.Loading;
        state.dataConnectionsStatus = WorkspaceStatus.Loading;
        state.orchestratorProjectStatus = WorkspaceStatus.Loading;
      })
      .addCase(loadProject.fulfilled, (state, action) => {
        state.applicationsStatus = WorkspaceStatus.Loaded;
        state.dataConnectionsStatus = WorkspaceStatus.Loaded;
        state.orchestratorProjectStatus = WorkspaceStatus.Loaded;

        // Note: perfecly valid that a workspace has no Project
        state.orchestratorProject =
          action.payload || initialWorkspaceState.orchestratorProject;

        state.current.dataConnections = action.payload?.dataConnections ?? [];

        state.current.applications = [];
        const clientIds = action.payload?.authorizedAADClientIds ?? {};
        for (const tenant in clientIds) {
          clientIds[tenant].forEach((id) => {
            state.current.applications?.push({ tenant, id });
          });
        }
      })
      .addCase(loadProject.rejected, (state, action) => {
        state.applicationsStatus = WorkspaceStatus.Error;
        state.dataConnectionsStatus = WorkspaceStatus.Error;
        state.orchestratorProjectStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(createDataConnection.pending, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saving;
      })
      .addCase(createDataConnection.fulfilled, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saved;
      })
      .addCase(createDataConnection.rejected, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Error;
        notifier.error(action.error);
      });
  },
});

const { reset } = workspaceSlice.actions;

// selectors
export const selectWorkspace = (state: RootState) => state.workspace.current;
export const selectWorkspaceId = (state: RootState) =>
  state.workspace.current.id;
export const selectWorkspaceName = (state: RootState) =>
  state.workspace.current.name;
export const selectWorkspaceStatus = (state: RootState) =>
  state.workspace.status;
export const selectWorkspaceApplications = (state: RootState) =>
  state.workspace.current.applications;
export const selectWorkspaceApplicationsStatus = (state: RootState) =>
  state.workspace.applicationsStatus;
export const selectWorkspaceDataConnections = (state: RootState) =>
  state.workspace.current.dataConnections;
export const selectWorkspaceDataConnectionsStatus = (state: RootState) =>
  state.workspace.dataConnectionsStatus;
export const selectWorkspaceDataConnectionSaveStatus = (state: RootState) =>
  state.workspace.dataConnectionSaveStatus;
export const selectOrchestratorProject = (state: RootState) =>
  state.workspace.orchestratorProject;
export const selectOrchestratorProjectStatus = (state: RootState) =>
  state.workspace.orchestratorProjectStatus;
export const editWorkspace = (state: RootState) =>
  state.workspace.editWorkspace;
export const editWorkspaceStatus = (state: RootState) =>
  state.workspace.editWorkspaceStatus;
export default workspaceSlice.reducer;
